import log from "loglevel";
import ExerciseNode from './ExerciseNode';
import NodePort from '../NodePort';

export default class ActionButton extends ExerciseNode 
{
    // Ports
    Input = new NodePort("Input", "input", this);
    Output = new NodePort("Output", "output", this);

    // Parameters
    ButtonName = "";

    // Internal values
    Button;

    constructor(iGraph, iProperties) 
    {
        super(iGraph, iProperties);

        this.ButtonName = iProperties.ButtonName;

        log.debug(this.GetIdentity() + " constructor: graph = " + this.Graph.ExerciseName + ", id = " + this.ID + ", ButtonName = " + this.ButtonName); 
    }

    OnActivated(iActivator, iInputPort)
    {
        super.OnActivated(iActivator, iInputPort);

        log.debug(this.GetIdentity() + " has been activated by '" + iActivator.GetIdentity() + "'.");
        
        // UI button setup
        this.Graph.ParentExerciseComponent.RegisterActionButtonCallbacks(
            this.ButtonName,
            (value) => this.RegisterButton(value), 
            (value) => this.OnClicked());
        this.Graph.ParentExerciseComponent.ShowActionButton(true);
    }

    RegisterButton(iButton)
    {
        log.debug(this.GetIdentity() + " RegisterButton: button = " + JSON.stringify(iButton));
        this.Button = iButton;
    }

    DestroyButton()
    {
        this.Graph.ParentExerciseComponent.ShowActionButton(false);
    }

    OnClicked()
    {        
        // Hide button
        this.DestroyButton();

        this.ActivateOutput();
    }
        
    ActivateOutput()
    {
        log.debug(this.GetIdentity() + "' activating output.");
        this.Output.ActivateAllConnections();
    }

    OnDeactivated()
    {
        super.OnDeactivated();
        
        // Hide button
        this.DestroyButton();
    }

    PrintParameters()
    {
    }
}
import React from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BootstrapButton from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import { LinkContainer } from 'react-router-bootstrap';
import Button from '../../components/Utilities/Button';


export function withRouter(Children) {
  return (props) => {
      const location = useLocation() ;
      const navigate = useNavigate() ;
      return <Children {...props} match={location.state} navigate={navigate} />
  }
}

class Login extends React.Component {

  listeners = [];

  state = {
    uname:this.props.match ? this.props.match.email : '',
    showPassword: false,
    password: '',
    failed: false,
    info: {},
    visible: false,
    valided: {
      number: false,
      special: false,
      length: false,
      min: false,
      maj: false,
      email: true,
      password: true,
    }
  }

  // Test si email valide
  validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  componentWillUnmount() {
    for (var i in this.listeners) {
      this.listeners[i]();
    }
  }

  // Envoie le formulaire
  submit = async () => {
    const { uname, password, valided } = this.state;
    if ( !uname || !valided.email ) {
      valided.email = false;
      this.setState({
        valided: valided
      });
      return false;
    }
    if ( !password || !valided.number || !valided.special || !valided.min || !valided.maj ) {
      valided.password = false;
      this.setState({
        valided: valided
      });
      return false;
    }

    

    let res;
    try {
      res = await window.sdk.user().login({
        username: this.state.uname,
        password: this.state.password,
        newpassword: this.state.password,
        name: this.state.uname,
        redirect_url: this.props.match.redirect_url,
      });
    } catch(e) {
      this.setState({
        failed: true,
        info: {
          message: 'Erreur connexion'
        }
      });
      window.sdk.event().emit('loaderHide');
    }

    if (res && res.state === 'ERROR') {
      this.setState({
        failed: true,
        info: res.error
      });
    } else {
    }
  }

  updateName = (value) => {
    const { valided } = this.state;
    valided.email = value.length === 0 ? true : this.validateEmail(value);
    this.setState({
      uname: value,
      failed: false,
      valided: valided,
    });
  }

  updatePassword = (value) => {
    const { valided } = this.state;
    valided.password = true;
    valided.number = value.search( /.*\d/ ) >= 0;
    //valided.special = value.search( /.*[!@#$%^&*()_+{}\[\]:;<>,.?~]/ ) >= 0;
    valided.special = /[^a-zA-Z0-9]/.test(value);
    valided.length = value.length >= 8;
    valided.min = value.search( /.*[a-z]/ ) >= 0;
    valided.maj = value.search( /.*[A-Z]/ ) >= 0;


    this.setState({
      password: value,
      failed: false,
      valided: valided
    });
  }

  togglePassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    });
  }

  render() {
    const { valided, showPassword } = this.state;
    return (
      <div className="login">
        <form className="login-layout__form login__form" onSubmit={(e) => {e.preventDefault()}}>
          <h2 className="form__title">Bienvenue, connectez-vous pour accéder à vos simulations</h2>
          <div className="form__inputs">
            
            { this.state.failed &&
              <Alert className="alert" variant="danger">{ this.state.info.message }</Alert>
            }

            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Email</Form.Label>
              <div className="form__email_txt">
                <i>{this.state.uname}</i>
                <LinkContainer to="/"><BootstrapButton variant="link" >Modifier</BootstrapButton></LinkContainer>
              </div>
            </Form.Group>

            <Form.Group className="mb-3 password-group" controlId="password">
              <Form.Label>Mot de passe</Form.Label>
              <Form.Control type={showPassword ? "text" : "password"} name="password" autoComplete="current-password" placeholder="Votre mot de passe" onChange={(e) => this.updatePassword(e.target.value)  } value={ this.state.password } isInvalid={ !valided.password }  required />
              <span className="password-toggle" onClick={this.togglePassword}>
                <FontAwesomeIcon icon={!showPassword ? faEye : faEyeSlash} />
              </span>
              <Form.Text className={"password-validated"}>
                <span className={ valided.number ? 'test-valided' : '' }><FontAwesomeIcon icon={faCheck} /> chiffres</span>
                <span className={ valided.special ? 'test-valided' : '' }><FontAwesomeIcon icon={faCheck} /> caractère spécial</span>
                <span className={ valided.length ? 'test-valided' : '' }><FontAwesomeIcon icon={faCheck} /> 8 caractère</span>
                <span className={ valided.min ? 'test-valided' : '' }><FontAwesomeIcon icon={faCheck} /> minuscule</span>
                <span className={ valided.maj ? 'test-valided' : '' }><FontAwesomeIcon icon={faCheck} /> majuscule</span>
              </Form.Text>
            </Form.Group>

            <div className="submit-container mt-4 mb-3">
              <Button variant="primary" type="submit" disabled={ this.props.loading } onClick={ this.submit }>Connexion</Button>
            </div>

            <div className="my-3">
              <LinkContainer to="/password-lost" state={{ email: this.state.uname }}>
                <BootstrapButton variant="link" className="password-lost-link">Mot de passe oublié ?</BootstrapButton>
              </LinkContainer>
            </div>
          </div>
        </form>
        <LinkContainer to="/register-pending">
                <BootstrapButton variant="link" className="debug">Tools blastream - add pending user</BootstrapButton>
              </LinkContainer>
      </div>
    );
  }
}

export default withRouter(Login);
import log from "loglevel";
import React from 'react';

import '../../styles/components/Exercise.scss';

import { useParams } from "react-router-dom";

import Exercise from '../../components/VisioExercise/Exercise';
//import Button from '../Utilities/Button';
//import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
//import ProgressBar from 'react-bootstrap/ProgressBar';

import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";
import CoachVoice from './CoachVoice';


export function withRouter(Children) {
    return (props) => {
        const match = { params: useParams() };
        return <Children {...props}  match={match} />
    }
}

class PitchExercisePanel extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {
            UserName: "Christophe",
            step: "ScenarioCreation",
            ScenarioContext: "",
            Scenario: "",
            Instructions: "",
            Feedbacks: "",
		};
	}

    componentDidMount() {
        //if (this.coachVoice) {
            this.coachVoice.speakText("Bienvenue " + this.state.UserName + " !");
        /*}
        else
        {
            this.speakText("Bienvenue " + this.state.UserName + " !");
        }*/
    }
    
    changeStep = (newStep) => {
        this.setState({ step: newStep });
    }

    newScenarioIdeas = (scenarioIdeas) => {
        this.setState({ScenarioIdeas: scenarioIdeas });
    }

    newScenario = (scenario) => {
        this.setState({ Scenario: scenario });
        
        //if (this.coachVoice) {
            this.coachVoice.speakText("Tu vas jouer le scénario suivant : " + scenario);
        /*}
        else
        {
            this.speakText("Tu vas jouer le scénario suivant : " + scenario);
        }*/
    }

    newInstructions = (instructions) => {
        
        log.debug("++++++++ instructions", instructions);

        this.setState({Instructions: instructions });
        
        this.coachVoice.speakText("Voilà comment nous allons commencer : " + instructions);
    }
    
    newSpeech = (speech) => {
        this.setState({Speech: speech });
    }
    
    newFeedbacks = (feedbacks) => {
        this.setState({Feedbacks: feedbacks });

        this.coachVoice.speakText(feedbacks);
    }

    onError = (err) => {
        log.debug("onError ", err);
        this.coachVoice.speakText("On a un problème, chef ! L'erreur dit : '" + err + "'.");
    }

    render() {
        return (
            <div>
            {true && <CoachVoice ref={(ref) => { this.coachVoice = ref; }} />}
            <Row style={{ alignItems: 'top', height: '100%' }}>
                    <Col xs={12} sm={6}>
                        <PitchScenarioCreation 
                            UserName={this.state.UserName}
                            newScenarioIdeas={this.newScenarioIdeas}
                            newScenario={this.newScenario}
                            newInstructions={this.newInstructions}
                            onError={this.onError}
                        />
                    </Col>
                    <Col xs={12} sm={6}>
                        <PitchScenarioPlay 
                            UserName={this.state.UserName}
                            Scenario={this.state.Scenario}
                            Instructions={this.state.Instructions}
                            changeStep={this.changeStep}
                            newSpeech={this.newSpeech}
                            newFeedbacks={this.newFeedbacks}
                            onError={this.onError}
                        />
                    </Col>
                </Row>
            </div>
        );
    }

    // Text to speech
    /*speakText = async (text) => {
        const speechConfig = SpeechSDK.SpeechConfig.fromSubscription("7ac8d8f8db724005911cc721cb116623", "francecentral");
        speechConfig.speechSynthesisLanguage = "fr-FR"; // Choisir la langue française
    
        const audioConfig = SpeechSDK.AudioConfig.fromDefaultSpeakerOutput();
        const synthesizer = new SpeechSDK.SpeechSynthesizer(speechConfig, audioConfig);
    
        const speakResult = await synthesizer.speakTextAsync(
            text,
            (result) => {
                if (result) {
                    log.debug(`Speech synthesis result: ${result}`);
                }
                synthesizer.close();
            },
            (error) => {
                log.error(`Erreur lors de la synthèse vocale: ${error}`);
                synthesizer.close();
            }
        );

        log.debug("speakText", speakResult);
    };*/
/*
    speakText = async (text) => {
        const speechConfig = SpeechSDK.SpeechConfig.fromSubscription("7ac8d8f8db724005911cc721cb116623", "francecentral");
        speechConfig.speechSynthesisLanguage = "fr-FR"; // Choisir la langue française
    
        const audioConfig = SpeechSDK.AudioConfig.fromDefaultSpeakerOutput();
        const synthesizer = new SpeechSDK.SpeechSynthesizer(speechConfig, audioConfig);
    
        // Ajout des log.debug pour chaque callback
        synthesizer.synthesisStarted = (sender, event) => {
            log.debug("Synthèse vocale démarrée :", event);
        };
    
        synthesizer.synthesizing = (sender, event) => {
            log.debug("Synthèse vocale en cours :", event);
        };
    
        synthesizer.synthesisCompleted = (sender, event) => {
            log.debug("Synthèse vocale terminée :", event);
        };
    
        synthesizer.SynthesisCanceled = (sender, event) => {
            log.debug("Synthèse vocale annulée :", event);
        };

        synthesizer.wordBoundary = (sender, event) => {
            log.debug("Limite de mot atteinte :", event);
        };

        synthesizer.bookmarkReached = (sender, event) => {
            log.debug("Marque-page atteint :", event);
        };

        synthesizer.visemeReceived = (sender, event) => {
            log.debug("Visème reçu :", event);
        };

        const speakResult = await synthesizer.speakTextAsync(
            text,
            (result) => {
                if (result) {
                    log.debug('Speech synthesis result:', result);
                }
                synthesizer.close();
            },
            (error) => {
                log.error('Erreur lors de la synthèse vocale:', error);
                synthesizer.close();
            }
        );

        log.debug("speakText result = ", speakResult);
    };*/
}

class PitchScenarioCreation extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {
            UserName: this.props.UserName,
			ScenarioIdeas: "Un entretien d'embauche pour un poste de développeur",
            Scenario: "",
            Instructions: ""
		};
	}

	componentDidMount() {
	}

	handleInstructionsChange = (event) => {
		//log.debug('++ handleInstructionsChange: ', event);
		this.setState({ ScenarioIdeas: event.target.value });
	};

    GenerateScenario = async () =>{
        
        //event.preventDefault();
        // Send the message
        //log.debug('++ GenerateScenarioSending: event ');
        log.debug('++ GenerateScenarioSending: InstructionGenerationScenario ', this.state.ScenarioIdeas);

        let messages = [
            {role: "system", content: "D'après ces éléments : '" + this.state.ScenarioIdeas + "'. Décris un contexte de scénario en 100 mots maximum qui permetttra à " + this.state.UserName + " de s'entraîner à bien gérér cette situation. Conserve bien les éléments de contexte précis qu'il te donne. Précise quel personnage " + this.state.UserName + " va jouer."},
        ];

        // iQuestion, iGPT3Engine, iMaxTokens, iTemperature, iTopP, iFrequencyPenalty, iPresencePenalty, iStopSequence
        let answer = await window.sdk.openaiAPI().CallChatAPI(messages, "gpt-4", 1000, 0.7, 1, 0, 0, "");
        
        log.debug('+++ Answer:', answer);

        if(answer.status === "error")
        {
            this.props.onError(answer.error);
            return;
        }

        this.setState({ Scenario: answer.completion })

        this.props.newScenarioIdeas(this.state.ScenarioIdeas);
        this.props.newScenario(answer.completion);
    };

    GenerateInstructions = async () => {
        
        //event.preventDefault();
        // Send the message
        //log.debug('++ GenerateScenarioSending: event ');
        log.debug('++ GenerateInstructions: scenario ', this.state.Scenario);

        let messages = [
            {role: "system", content: "D'après le scénario : '" + this.state.Scenario + "', dans le format d'une pièce de théatre écris une première question que poserait un personnage à " + this.state.UserName + ". N'hésite pas à créer des situatons difficiles pour qu'il s'entraîne."},
        ];

        // iQuestion, iGPT3Engine, iMaxTokens, iTemperature, iTopP, iFrequencyPenalty, iPresencePenalty, iStopSequence
        let answer = await window.sdk.openaiAPI().CallChatAPI(messages, "gpt-4", 1000, 0.7, 1, 0, 0, "");

        log.debug('+++ GenerateInstructions: Answer = :', answer);
        
        if(answer.status === "error")
        {
            this.props.onError(answer.error);
            return;
        }

        this.setState({ Instructions: answer.completion })
        this.props.newInstructions(answer.completion);
    };
	
    render() {
        return (
            <div>
                <br/>
                <center><h2>Création du scénario</h2></center>
                <br/>
                <div>
                    <b><p>Décrivez votre besoin de scénario :</p></b>
                        <textarea
                            id="message"
                            name="message"
                            value={this.state.ScenarioIdeas}
                            onChange={this.handleInstructionsChange}
                            style={{ height: '100px', width: '100%' }}
                        />
                        <br />
                        <button type="submit" onClick={this.GenerateScenario}>Générer scénario</button>
                </div>
                <div>
                    <b><p>Scénario :</p></b>
                    <div style={{
                        backgroundColor: '#a2a2a2',
                        padding: '5px',
                        maxHeight: '150px',
                        overflowY: 'scroll'
                    }}>
                        <p style={{ backgroundColor: '#a2a2a2', padding: '20px' }}>{this.state.Scenario}</p>
                    </div>
                    <button onClick={this.GenerateInstructions}>Créer instructions</button>
                </div>
                <div>
                    <b><p>Instructions :</p></b>
                    <div style={{
                        backgroundColor: '#a2a2a2',
                        padding: '5px',
                        maxHeight: '150px',
                        overflowY: 'scroll'
                    }}>
                        <p style={{ backgroundColor: '#a2a2a2', padding: '20px' }}>{this.state.Instructions}</p>
                    </div>
                </div>
            </div>);
    }
}

class PitchScenarioPlay extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {
            UserName: this.props.UserName,
            Scenario: this.props.Scenario,
            Instructions: this.props.Instructions,
            Speech: "",
            Feedbacks: ""
		};
	}

	componentDidMount() {
	}

    onStartTalking = async () => {
        log.debug('Starting scenario');
        this.props.startTalking();
    };

    onStart()
    {
        log.debug('Starting scenario');
    };
    
    startSpeechToText = async () => {
        log.debug('++ PitchSpeechToText startSpeechToText');        
        
        this.transcriptionSession = await window.sdk.videoconf().createTranscriptionSession({
            onTranscriptedText: (data) => {
                this.OnSpeechDetected(data.text);
            },
            onPartialTranscriptedText: (data) => {
                this.OnPartialSpeechDetected(data.text);                
            },
            onFailed: (data) => {
                this.OnFailed(data);                
            },
            customData: {
                "transcriptionID": "PitchSpeech_" + new Date().toUTCString(),
                "endpoint": "",
                "segmentationSilenceTimeoutMs": 2000,
                "phraseListGrammar": ""
            }
        });
        this.transcriptionSession.start();
    };

    // Triggered when the STT finished the speech recognition (EndSilence timeout)
    OnPartialSpeechDetected(iResult)
    {
        log.debug("PitchSpeechToText: OnPartialSpeechDetected: '" + iResult + "'.");
		
        this.setState({ Speech: iResult });
    }

    // Triggered when the STT finished the speech recognition (EndSilence timeout)
    OnSpeechDetected(iResult)
    {
        log.debug("PitchSpeechToText: OnSpeechDetected: '" + iResult + "'.");

        if(this.transcriptionSession) {
            this.transcriptionSession.close();
        }

        this.setState({ Speech: iResult });
        this.props.newSpeech(iResult);
        this.GenerateFeedbacks();
    }

    // Triggered when the STT finished the speech recognition (EndSilence timeout)
    OnFailed(iReason)
    {
        log.debug("PitchSpeechToText: OnFailed: Reason '" + iReason + "'.");
/*
        if(this.transcriptionSession) {
            this.transcriptionSession.close();
        }

        let message = "Failed: " + iReason + ". Partial speech detected: '" + this.state.Speech + "'.";
        this.setState({ Speech: message });*/
    }
    
    GenerateFeedbacks = async () =>{
    
        //event.preventDefault();
        // Send the message
        //log.debug('++ GenerateScenarioSending: event ');
  
        let prompt = "Scénario du jeu de rôle:\n" + this.props.Scenario 
                    + "\n\nIntroduction pour l'utilisateur:\n" + this.props.Instructions 
                    + "\n\nRéponse de l'utilisateur:\n" + this.state.Speech 
                    + "\n\n---\nTu es un formateur et tu coach l'utilisateur sur ses capacités en communication."
                    + "\nCommente la qualité de sa réponse sur sa façon de la formuler et donne-lui des pistes pour s'améliorer la prochaine fois qu'il se trouvera dans ce type de situation."
                    + "\nNe réponds que par les éléments nécessaires à sa progression."; 
            
        log.debug('++ GenerateFeedbacks: prompt = ', prompt);
  
        let messages = [
          {role: "system", content: prompt}
        ];
  
        // iQuestion, iGPT3Engine, iMaxTokens, iTemperature, iTopP, iFrequencyPenalty, iPresencePenalty, iStopSequence
        let answer = await window.sdk.openaiAPI().CallChatAPI(messages, "gpt-4", 1000, 0.7, 1, 0, 0, "");
      
        log.debug('+++ GenerateFeedbacks: Answer = :', answer);

        if(answer.status === "error")
        {
            this.props.onError(answer.error);
            return;
        }
  
        this.setState({ Feedbacks: answer.completion })
        this.props.newFeedbacks(answer.completion);
      };
      
	
    render() {
        return (
            <div>
                <br/>
                <center><h2>Essai...</h2></center>
                <br/>
                <button onClick={this.startSpeechToText}>Parler</button>
                <div>
                    <b><p>Speech</p></b>
                    <p style={{ backgroundColor: '#c2a7ef', padding: '20px' }}>{this.state.Speech}</p>
                </div>
                <div>
                    <b><p>Feedbacks</p></b>
                    <div style={{
                        backgroundColor: '#a2a2a2',
                        padding: '5px',
                        maxHeight: '300px',
                        overflowY: 'scroll'
                    }}>
                        <p style={{ backgroundColor: '#a2a2a2', padding: '20px' }}>{this.state.Feedbacks}</p>
                    </div>
                </div>
            </div>);
    }
}

export default withRouter(PitchExercisePanel);

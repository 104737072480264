import log from "loglevel";
import React from 'react';
import '../../styles/components/Welcome.scss';
// import ReactPlayer from 'react-player/file';
import { useNavigate } from "react-router-dom";

//import { SlideDown } from 'react-slidedown';
import Container from 'react-bootstrap/Container';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { CircularProgressbarWithChildren  } from 'react-circular-progressbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPlay, faArrowRight, faVideo, faMasksTheater, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from '../../components/Utilities/Tooltip';

// Sounds
import loginSound from '../../assets/sounds/LoginSound.mp3';


const ExerciseStatus = ({ status, ...props }) => {
  let icon = null;
  let title = '';
  switch(status) {
    case 'todo':
      icon = faArrowRight;
      title = 'A faire';
      break;
    case 'progress':
      icon = faPlay;
      title = 'En cours';
      break;
    case 'done':
      icon = faCheck;
      title = 'Terminé';
      break;
  }
  if ( !icon ) {
    return null;
  }
  return (
    <div className={'exercise-status exercise-status--'+status}>
      <Tooltip title={title} placement={'top'}>
        <FontAwesomeIcon icon={icon} />
      </Tooltip>
    </div>
  );
};

const ExerciseCategory = ({ category, ...props }) => {
  const { name } = category;
  return (
    <div className={'exercise-category'} {...props}>
      <FontAwesomeIcon icon={faMasksTheater} className={'exercise-category__icon'} />
      <p className={'exercise-category__name'}>{name}</p>
    </div>
  );
};

const ExerciseItem = ({ exercise, ...props }) => {
  let navigate = useNavigate();
  const {title, image, level, status, replay, disabled, category} = exercise;

  // Si clic sur replay, on redirige vers replay même si exercise desactivé
  const onClick = (e, exercise) => {
    e.stopPropagation();

    // If replay button
    if (e.currentTarget.classList.contains('item__replay_link')) {
      return navigate(exercise.replay, { replace: false });
    }

    // If disabled exercise line
    if (exercise.disabled) {
      window.sdk.event().emit('modalOpen', {
        title: 'Exercise désactivé',
        body: (
          <p>L'exercise <strong>"{exercise.title}"</strong> est pour le moment désactivé.</p>
        ),
        footer: '',
      });
      return false;
    }

    // If this exercise line is ok, start the exercise
    navigate("/exercise/" + exercise.id, { replace: false });
  };

  return (
    <li className={'exercise-item '+(disabled?'exercise-item--disabled' : '')} {...props} onClick={(e) => onClick(e, exercise)}>
      <div className={'exercise-item__inner'}>
        <div className={'item__level item__level--'+level}>
          <div className={'item__level_inner'}>
            Niveau {level}
          </div>
        </div>
        {image && (<div className={'item__image'}>
          <img className={'img-fluid'} src={image} alt={title} />
        </div>)}
        <div className={'item__description'}>
          <h3 className={'item__title'}>
            {title}
          </h3>
          <div className={'item__category'}>
            <ExerciseCategory category={category} />
          </div>
        </div>
        {replay && (<div className={'item__replay'}>
          <Tooltip title={'Accéder au replay'} placement={'top'}>
            <span className={'item__replay_link'} onClick={(e) => onClick(e, exercise)}>
              <FontAwesomeIcon icon={faVideo} />
            </span>
          </Tooltip>
        </div>)}
      </div>
      <div className={'item__status'}>
        <ExerciseStatus status={status} />
      </div>
    </li>
  );
};

class Welcome extends React.Component {

  state = {
    progress: {},
    opened: {},
    loaded: false,
	showNothing: false
  }

  slideDown(i) {
    let { opened } = this.state;
    opened[i] = !opened[i];
    this.setState({
      opened
    });
  }
  
  /*playSound = () => {
    const audio = new Audio(loginSound);
    audio.play();
  }*/

  async componentDidMount() {

    if(window.sdk.refreshingExercise) {
		this.setState({
			showNothing: true
		});
		return;
	}

    //this.playSound();
    let data = await window.sdk.exercise().getAll();
    log.debug('Refreshed exercises list, data = ', data);
    
    // Add a fake exercise to list
    data.exercises.push({
      title: "Prototypes",
      exercises: [
        {
          id: 'PitchTest', 
          version: 'PitchTest_2023-03-09-14-05-09', 
          category: {name: 'Pitch Tests'},
          title: 'Pitch', 
          filePath: '', 
          image: '',
          disabled: false,
          level: 1,
          replay: "",
          status: "progress"
        },
        {
          id: 'BuildYourself', 
          version: 'BuildYourself_2023-03-09-14-05-09', 
          category: {name: 'Pitch Tests'},
          title: 'Build Yourself', 
          filePath: '', 
          image: '',
          disabled: false,
          level: 1,
          replay: "",
          status: "progress"
        }
      ],
      progress: {value: 1, max: 3}
    });

    this.setState({
      exercises: data.exercises,
      progress: data.progress,
      loaded: true
    });
    document.body.classList.add('cmp-dashboard');
  }

  componentWillUnmount() {
    document.body.classList.remove('cmp-dashboard');
  }


  render() {

    if(!this.state.loaded || this.state.showNothing)
      return null; //show loader

    const sections = this.state.exercises;
    const { progress, opened } = this.state;
    return (
      <div className="dashboard">
        <Container>
        <h1 className="dashboard__title">Choix d'exercice</h1>
          <div className={'dashboard__sections'}>
            {sections.map(({ title, progress, exercises }, i) => (
              <div key={'section-' + i} className={'dashboard__section' + (opened[i] ? ' opened' : '')}>
                <div id={"exerciseMenuSection-"+i} className={'section__header'}>
                  <h2 className={'section__title'} onClick={() => this.slideDown(i)}>
                    {title}
                    <span className={'title__icon'}>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </span>
                  </h2>
                </div>

                {opened[i] ? (<ul className={'section__exercises'}>
                    {exercises.map(exercise => (
                      <ExerciseItem id={"exerciseMenuExercise-"+exercise.id} key={'exercise-'+exercise.id} exercise={exercise} />
                    ))}
                  </ul>) : null}
              </div>
            ))}
          </div>
        </Container>
      </div>
    );
  }
}

export default Welcome;
import React from 'react';

export default class Terms extends React.Component {
  
  render() 
  {
    return (
        <div>
            Copyright { new Date().getYear() }
        </div>
    );
  }
}

import log from "loglevel";
(function () {

  'use strict';

  var supported;

  var storageOrUndefined;
  try {
    storageOrUndefined = window.localStorage;
  } catch (e) {
    storageOrUndefined = null;
  }

  if(!storageOrUndefined){
    return;
  }

  if (window.hasOwnProperty('localStorage') && window.localStorage !== null) {

    supported = true;

    // Some browsers will return true when in private browsing mode so test to make sure it's functional.
    try {
      var key = 'swxTest_' + Math.round(Math.random() * 1e7);
      window.localStorage.setItem(key, 'test');
      window.localStorage.removeItem(key);
    }
    catch (e) {
      log.warn('localStorage not functional, falling back to session Object.');
      supported = false;
    }
  }

  if (!supported) {

    var data = {};

    window.localStorage = {
      setItem: function(key, value) {
        data[key] = value;
        return data[key];
      },
      getItem: function(key) {
        return (key in data) ? data[key] : undefined;
      },
      removeItem: function(key) {
        delete data[key];
        return undefined;
      },
      clear: function() {
        data = {};
        return data;
      }
    };
  }
}());

export default class LocalStorage {
    
    
    
}
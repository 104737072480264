export default class UserActionsDetectionAPI {

    constructor(sdk) {
        this.sdk = sdk;
    }
    
    async Initialize() {
        
    }

    async Ask(iSpeech, iExerciseID, iBranchingDecisionNodeID, iEndpoint) {
        
        let body = {
            input: iSpeech,
            ExerciseID: iExerciseID,
            BDNodeID: iBranchingDecisionNodeID,
            Endpoint: iEndpoint
        };
        
        let result = await this.sdk.fetch().post('/useractionsdetectionapi', {body: body});   
        
        return {body, result};
    }
  }
import log from "loglevel";
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { isSafari } from 'react-device-detect';
import '../styles/components/MainPage.scss';
import logoBlack from '../assets/images/logo-black.png';
import logoWhite from '../assets/images/logo-white.png';
import logoMin from '../assets/images/PractiVizioLogo-minimized.png';
import { Routes, Route , useNavigate } from "react-router-dom";
import Welcome from './dashboard/Welcome';
import Terms from './legal/Terms';
import EditPassword from './entrance/EditPassword';
import ParticipantsModule from '../AppClasses/Participants/ParticipantsModule';
import ExercisePanel from './exercise/ExercisePanel';
import FeedbackPanel from './exercise/FeedbackPanel';
import RetryPanel from './exercise/RetryPanel';
import TestBot from './test/Bot';
import TestDevice from './test/Device';

// import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import ButtonCustom from '../components/Utilities/Button';
import Modal from 'react-bootstrap/Modal';
import {browserName} from 'react-device-detect';

export function withRouter(Children) {
  return (props) => {
      const navigate = useNavigate() ;
      return <Children {...props} navigate={navigate} />
  }
}

const PauseTimeout = 900000; // 15 minutes
const PausePopinTimeout = 300000; // 5 minutes

class MainPage extends React.Component {
  state = {
    width: 0,
    height: 0,
    exercisePlaying: false,
    expanded: null,
    logoutButton: true,
    isFullScreen: false,
  };

  listeners = [];

  pausedDueToNoAudio = false;

  exercicePaused = false;

  // Constructor
  constructor(props) {
    super(props);

    // Participants module initialization or reset
    if (!ParticipantsModule.Instance) {
      new ParticipantsModule();
    } else {
      ParticipantsModule.Instance.Reset();
    }
  }

  // Déconnecte l'user
  logout = () => {
    window.sdk.event().emit("modalOpen", {
      title: "Confirmation",
      body: "Etes-vous sûr de vouloir vous déconnecter ?",
      footer: (
        <React.Fragment>
          <Button
            variant="secondary"
            onClick={() => window.sdk.event().emit("modalClose")}
          >
            Annuler
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              window.sdk.user().logout();
              window.sdk.event().emit("modalClose");
            }}
          >
            Déconnexion
          </Button>
        </React.Fragment>
      ),
    });
    // window.sdk.user().logout();
  };

  homepage = () => {};

  // ----------
  // Modifie le state en fonction de la taille de la fenetre
  setDimensions = () => {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  // ----------
  // Lors de l'initialisation
  // - Attache l'event lié au resize de la fenetre
  // - Modifie le state si un exercise a commencé ou s'est terminé
  componentDidMount() {
    this.setDimensions();
    window.addEventListener("resize", this.setDimensions);
    /*
	this is strange ...
	if(window.sdk.isInIframe()){
      this.setState({
        isFullScreen: true
      })
      window.sdk.fullscreenStatus = true;
    }*/

    if (typeof document.hidden !== "undefined") {
      document.addEventListener("visibilitychange", this.onVisibilityChange);
    }
    this.listeners.push(
      window.sdk.event().on("startExercise", () => {
        this.setState({
          exercisePlaying: true,
          expanded: false,
          exercisePause: false,
          feedback: false,
        });
      })
    );
    this.listeners.push(
      window.sdk.event().on("stopExercise", () => {
        this.setState({
          exercisePlaying: false,
        });
      })
    );
    this.listeners.push(
      window.sdk.event().on("endExercise", (data) => {
        this.setState({
          exercisePlaying: false,
          feedback: true,
        });
      })
    );
    this.listeners.push(
      window.sdk.event().on("pauseExercise", () => {
        this.setState({
          exercisePause: true,
          expanded: true,
          reasonPause: "pause",
        });
      })
    );

    this.listeners.push(
      window.sdk.event().on("FeedbackPanelEntered", () => {
        this.setState({
          feedback: true,
        });
      })
    );
    this.listeners.push(
      window.sdk.event().on("ExercisePanelEntered", () => {
        this.setState({
          logoutButton: false,
          feedback: false,
        });
      })
    );

    this.listeners.push(
      window.sdk.event().on("fullscreenOpended", () => {
        // Log to DynamoDB
        window.sdk.usersActivity().createOne("Fullscreen", { State: "Opened" });
        this.setState({
          isFullScreen: true,
        });
      })
    );

    this.listeners.push(
      window.sdk.event().on("fullscreenExited", () => {
        // Log to DynamoDB
        window.sdk.usersActivity().createOne("Fullscreen", { State: "Exited" });
        this.setState({
          isFullScreen: false,
        });
      })
    );

    this.listeners.push(
      window.sdk.event().on("keydownDetected", () => {
        clearInterval(this.intervalCheckPause);
        clearTimeout(this.pauseCountdown);
        this.pauseCountdown = null;
        this.checkPause();
      })
    );
      
    // On device changed error protection for unplugged microphone
    this.listeners.push(
      window.sdk.event().on("onDeviceChanged", (kind) => {
        log.debug("unplugged microphone, browser: " + browserName);
        if(!window.testMode.skipDeviceDisconnectCheck && browserName != "Safari") { // Skip if in test mode or on Safari browser
          clearInterval(this.intervalCheckPause);
          clearTimeout(this.pauseCountdown);
          clearTimeout(this.finalPauseCountdown);
          this.pausedDueToNoAudio = true;
          this.setState({
            exercisePause: true,
            expanded: true,
            reasonPause: "pause-due-device-changed",
            unpluggedDevice: kind
          });  
          window.sdk.event().emit("exerciceDeconnected");
          window.sdk.event().emit("forcePause");
        }
      })
    );

    this.listeners.push(
      window.sdk.event().on("onSttFailedNoAudio", () => {
        window.sdk.event().emit("forcePause");
        this.pausedDueToNoAudio = true;
        let txt =
          "Nous avons détecté un problème avec votre microphone. Merci de vous rapprocher du support client.";
        this.setState({
          exercisePause: true,
          expanded: true,
          reasonPause: "pausedDueToNoAudio",
          pauseTxt: txt,
        });
        this.showPopinPauseDueToNoAudio(txt);
      })
    );

    this.listeners.push(
      window.sdk.event().on("onSttFailedButAudio", () => {
        window.sdk.event().emit("forcePause");
        this.pausedDueToNoAudio = true;
        let txt =
          "Nous avons détecté un problème avec votre session. Merci de vous rapprocher du support client.";
        this.setState({
          exercisePause: true,
          expanded: true,
          reasonPause: "pausedDueToNoAudio",
          pauseTxt: txt,
        });
        this.showPopinPauseDueToNoAudio(txt);
      })
    );


    this.listeners.push(window.sdk.event().on('exercicePaused', () => {	 
      this.exercicePaused = true;
      this.setState({
        exercisePause: true,
        expanded: true
      })       
      this.checkPause();      
    }));

    this.listeners.push(window.sdk.event().on('exerciceResumed', () => {	
      this.setState({
        exercisePause: false,
        expanded: false
      })       
      this.reInitPauseCheck();
    }));
    window.addEventListener("popstate", this.navBack)
  }

  showPopinPauseDueToNoAudio(text) {
    window.sdk.event().emit("modalOpen", {
      title: "Attention !",
      body: text,
      hideCloseBt: true,
      disableCloseOnHide: true,
      footer: (
        <React.Fragment>
          <Button
            variant="secondary"
            onClick={() => {
              this.closePopinPauseDueToNoAudio();
              this.showZendesk();
            }}
          >
            Contacter le Support
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              this.closePopinPauseDueToNoAudio();
            }}
          >
            Retour
          </Button>
        </React.Fragment>
      ),
    });
  }

  closePopinPauseDueToNoAudio() {
    window.sdk.event().emit("modalClose");
    this.pausedDueToNoAudio = false;
    this.setState({
      reasonPause: "pausedDueToNoAudioAfter",
    });
  }

  navBack = () => {
    var url = window.location.pathname;
    if (url == "/") {
      this.setState({
        exercisePlaying: false,
      });
    }
  };
  // ----------
  // Supprime les events
  componentWillUnmount() {
    window.removeEventListener("resize", this.setDimensions);
    window.removeEventListener("popstate", this.navBack);

    for (var i in this.listeners) {
      this.listeners[i]();
    }
  }

  onVisibilityChange = () => {
    if (isSafari) return;
    if (document.hidden && this.state.exercisePlaying && !this.exercicePaused) {
      window.sdk.event().emit("pause");
      this.setState({
        expanded: true,
        exercisePause: true,
        reasonPause: "focus",
      });
    }
  };

  // Expand or reduce leftbar
  toggleSidebar = () => {
    let { expanded, exercisePause } = this.state;
    if (expanded) {
      exercisePause = false;
      window.sdk.event().emit("resume");
    } else {
      window.sdk.event().emit("pause", true);
    }
    this.setState({
      expanded: !expanded,
      exercisePause,
      reasonPause: "pause",
    });
  };

  resume = () => {
    this.setState({
      exercisePause: false,
      expanded: false,
    });
    window.sdk.event().emit("resume");
  };

  restart = () => {
    window.sdk.event().emit("restartExercise");
    this.setState({
      expanded: false,
      reasonPause: false,
    });
    this.reInitPauseCheck();
  };

  stop = () => {
    this.setState({
      exercisePlaying: false,
      expanded: false,
      exercisePause: false,
      reasonPause: "",
    });
    window.sdk.event().emit("stopExercise");
    this.reInitPauseCheck();
  };
  /*window.sdk.exitExercice(props.ExerciseGraph)*/
  menu = () => {
    this.setState({
      expanded: true,
      exercisePause : false,
      reasonPause: false,
    });
  }
  reInitPauseCheck() {
    log.debug('PAUSE', 'reInitPauseCheck');
    clearInterval(this.intervalCheckPause);
    clearTimeout(this.pauseCountdown);
    clearTimeout(this.finalPauseCountdown);
    this.exercicePaused = false;
    this.pauseCountdown = null;
    this.pausedDueToNoAudio = null;
    this.finalPauseCountdown = null;
  }
  
  checkPause(){
    log.debug('PAUSE', 'checkPause before', this.pauseCountdown, this.pausedDueToNoAudio, this.finalPauseCountdown)

    if(!this.exercicePaused)
      return;

    if(this.pauseCountdown)
      return;   
    
    if(this.pausedDueToNoAudio)
      return;

    if (this.finalPauseCountdown)
      return;

    log.debug('PAUSE', "==========================================> checkPause");

    this.intervalCheckPause = setInterval(() => {
      if (!this.exercicePaused) {
        log.debug('PAUSE', "we are not in pause anymore, stop checking");
        clearInterval(this.intervalCheckPause);
        clearTimeout(this.pauseCountdown);
        this.pauseCountdown = null;
      }
    }, 1000);

    this.pauseCountdown = setTimeout(() => {
      clearInterval(this.intervalCheckPause);
      clearTimeout(this.pauseCountdown);
      log.debug('PAUSE', 'pauseCountdown');
      this.pauseCountdown = null;
      this.showPopinPause();
    }, PauseTimeout);
  }

  showPopinPause() {
    log.debug('PAUSE', 'showPopinPause');
    window.sdk.event().emit("modalOpen", {
      title: "Vous êtes toujours là ?",
      body: 'Vous allez prochainement être déconnecté.e. Si vous souhaitez reprendre l\'exercice, cliquez sur "Continuer".',
      hideCloseBt: true,
      disableCloseOnHide: true,
      toTheTop: true,
      footer: (
        <React.Fragment>
          <Button
            variant="secondary"
            onClick={() => {
              window.sdk.event().emit("modalClose");
              clearTimeout(this.finalPauseCountdown);
              this.finalPauseCountdown = null;
              this.checkPause();
            }}
          >
            Continuer
          </Button>
        </React.Fragment>
      ),
    });

    this.finalPauseCountdown = setTimeout(() => {  
      //if(this.state.isFullScreen)
      //  window.sdk.toggleFullscreen();    
      window.sdk.event().emit("exerciceDeconnected");
      this.setState({
        exercisePause: true,
        expanded: true,
        reasonPause: "timeout-pause"
      });
      window.sdk.event().emit("modalClose");
    }, PausePopinTimeout);
  }

  renderDebugPanel() {
    return (
      <div className={"debug debug_graph_controls"}>
        <div
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            padding: "10px",
            zIndex: "1000",
          }}
        >
          <Row style={{ justifyContent: "center" }}>
            <Col xs={"auto"}>
              <h3>User:</h3>
            </Col>
            <Col xs={"auto"}>
              <div>
                {"UserID: " + window.sdk.user().userID}
                <br />
                {"Name: " +
                  window.sdk.user().firstName +
                  " " +
                  window.sdk.user().lastName}
                <br />
                {"Email: " + window.sdk.user().email}
                <br />
                {"Entity: " + window.sdk.user().entity}
                <br />
                {"Role: " + window.sdk.user().role}
                <br />
                {"AvailableExercises: " +
                  (!window.sdk.user().availableExercises ||
                  window.sdk.user().availableExercises == ""
                    ? "all"
                    : window.sdk.user().availableExercises)}
                <br />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  renderContent() {
    this.checkPause();
    switch (this.state.reasonPause) {
      case "focus":
        return (
          <div className="secondary-pause">
            <h2 className="secondary-pause__title">L'exercice est en pause</h2>
            <ul className="secondary-pause__list">
              {/*<li>Vous avez quitté l’exercice temporairement</li>*/}
              <li>
                Celui-ci a été mis en pause automatiquement afin que vous ne
                perdiez pas votre progression{" "}
              </li>
              <li>
                En cliquant sur "Reprendre", votre simulation reprendra où vous
                vous en étiez arrêté
              </li>
              <li>
                Si vous éprouvez des difficultés excessives, vous pouvez cliquer
                sur le bouton "Arrêter". Vous passerez directement à la suite de
                la formation 
              </li>
            </ul>
            <div className="secondary-pause__actions">
              <div className="secondary-pause__actions_left">
                    <ButtonCustom variant={"green"} onClick={this.resume}>
                  Reprendre
                </ButtonCustom>
                <ButtonCustom variant={"red"} onClick={this.stop}>
                  Arrêter
                </ButtonCustom>
                </div>
              <ButtonCustom variant={"grey"} onClick={this.menu}>
                Menu
              </ButtonCustom>
            </div>
          </div>
        );
        break;
      case "pausedDueToNoAudio":
        return (
          <div className="secondary-pause">
            <h2 className="secondary-pause__title"></h2>
            <ul className="secondary-pause__list">
              <li></li>
            </ul>
            <div className="secondary-pause__actions"></div>
          </div>
        );
        break;
      case "pausedDueToNoAudioAfter":
        return (
          <div className="secondary-pause">
            <h2 className="secondary-pause__title">L'exercice est en pause</h2>
            <ul className="secondary-pause__list">
              <li>{this.state.pauseTxt}</li>
            </ul>
            <div className="secondary-pause__actions">
              <div className="secondary-pause__actions_left">
                    <ButtonCustom variant={"green"} onClick={this.resume}>
                  Reprendre
                </ButtonCustom>
                <ButtonCustom variant={"red"} onClick={this.stop}>
                  Arrêter
                </ButtonCustom>
                </div>
              <ButtonCustom variant={"grey"} onClick={this.menu}>
                Menu
              </ButtonCustom>
            </div>
          </div>
        );
        break;
      case "timeout-pause":
        return (
          <div className="secondary-pause">
            <h2 className="secondary-pause__title">
              Vous avez été déconnecté.e
            </h2>
            <ul className="secondary-pause__list">
              <li>
                Vous avez été déconnecté.e de l'exercice suite à une période
                d'inactivité
              </li>
              <li>Cliquez sur "Recommencer" pour relancer l'exercice</li>
            </ul>
            <div className="secondary-pause__actions">
              <ButtonCustom variant={"green"} onClick={this.restart}>
                Recommencer
              </ButtonCustom>
            </div>
          </div>
        );
        break;
      case "pause-due-device-changed":
          return (
            <div className="secondary-pause">
              <h2 className="secondary-pause__title">
               Débranchement
              </h2>
              <ul className="secondary-pause__list">
                <li>
                 Nous avons détecté le débranchement de votre { this.state.unpluggedDevice }.
                </li>
                <li>Cliquez sur "Recommencer" pour relancer l'exercice</li>
              </ul>
              <div className="secondary-pause__actions">
                <ButtonCustom variant={"green"} onClick={this.restart}>
                  Recommencer
                </ButtonCustom>
              </div>
            </div>
          );
          break;
      default:
        return (
          <div className="secondary-pause">
            <h2 className="secondary-pause__title">L'exercice est en pause</h2>
            <ul className="secondary-pause__list">
              {/*<li>Prenez le temps nécessaire avant de reprendre l’exercice</li>*/}
              <li>
                En cliquant sur "Reprendre", votre simulation reprendra où vous
                vous en étiez arrêté
              </li>
              <li>
                Si vous éprouvez des difficultés excessives, vous pouvez cliquer
                sur le bouton "Arrêter". Vous passerez directement à la suite de
                la formation 
              </li>
            </ul>
            <div className="secondary-pause__actions">
              <div className="secondary-pause__actions_left">
                    <ButtonCustom variant={"green"} onClick={this.resume}>
                  Reprendre
                </ButtonCustom>
                <ButtonCustom variant={"red"} onClick={this.stop}>
                  Arrêter
                </ButtonCustom>
                </div>
              <ButtonCustom variant={"grey"} onClick={this.menu}>
                Menu
              </ButtonCustom>
            </div>
          </div>
        );
    }
  }

  showZendesk = () => {
    if (window.zE) {
      window.zE("webWidget", "open");
      window.zE("webWidget", "prefill", {
        name: {
          value: window.sdk.user().firstName + " " + window.sdk.user().lastName,
          readOnly: true, // optional
        },
        email: {
          value: window.sdk.user().email,
          readOnly: true, // optional
        },
      });
      window.zE("webWidget", "show");
    } else {
      window.location = "mailto:support@practicio.fr";
    }
  };


  confirmConfiguration = async() => {
    window.sdk.event().emit("modalOpen", {
      title:'Configuration',
      body:'Voulez-vous quitter cet exercice pour aller à la page de configuration de votre micro et caméra ?',
      footer:(
          <React.Fragment>
            <Button variant="secondary" onClick={ () => {window.sdk.event().emit("modalClose")} }>Non</Button>
            <Button variant="primary" onClick={ () => {window.sdk.videoconf().mediaDevices().resetDevice();window.sdk.event().emit("modalClose");this.restart();}}>Oui</Button>
          </React.Fragment>
        )
    });
      
  }


  // ----------
  // Affiche le rendu
  // - Teste si on est sur un petit ecran ou non, et modifie le rendu en fonction
  // - Change les boutons du sidebar par un bouton stop si on a commencé un exercise
  render() {

    //log.debug('===========> render', this.state.exercisePause, this.state.reasonPause);

    const { exercisePlaying, feedback, width, height, expanded, isFullScreen } = this.state;
    let isMobile = width < 600 || height < 600;
    return (
      <div
        className={
          "main-page main-page--style2" +
          (isMobile ? " main-page--mobile" : "") +
          (exercisePlaying ? " main-page--exercise" : "") +
          (expanded ? " left-bar--expanded" : "")
        }
      >
        {/* <div className={"main-page main-page--style2" + (isMobile ? ' main-page--mobile' : '')}> */}

        <header id="header">
          {!(
            this.state.exercisePause &&
            (this.state.reasonPause == "timeout-pause" || this.state.reasonPause == "pause-due-device-changed")
          ) && (
            <a href="#" className="header__menu" onClick={this.toggleSidebar}>
              <span className="menu__bars">
                {!expanded && (
                  <React.Fragment>
                    <i className="bar"></i>
                    <i className="bar"></i>
                    <i className="bar"></i>
                  </React.Fragment>
                )}
                {expanded && <i className="cross"></i>}
              </span>
              {expanded ? "Retour" : "Menu"}
            </a>
          )}
          {!exercisePlaying && !expanded && (
            <img src={logoBlack} className="header__logo" alt="logo" />
          )}
          {(exercisePlaying || expanded) && (
            <img src={logoWhite} className="header__logo" alt="logo" />
          )}
          <a
            href="#"
            className="header__scale"
            onClick={() => window.sdk.toggleFullscreen()}
          >
            {isFullScreen ? "Reduire" : "Agrandir"}
            <i
              className={`scale_icon ${
                isFullScreen ? "scale_icon--reduce" : ""
              }`}
            ></i>
          </a>
        </header>

        <main id="main">
          {
            // Debug panel
            (!exercisePlaying && !feedback) && this.renderDebugPanel()
          }

          <div id="secondary" className={"left-bar"}>
            {this.state.exercisePause ? (
              this.renderContent()
            ) : (
              <ul className="secondary-menu">
                {exercisePlaying && (
                  <li className="secondary-menu__item">
                    <a onClick={this.resume} className="">
                      Reprendre l'exercice
                    </a>
                  </li>
                )}
                {(exercisePlaying || feedback) && (
                  <React.Fragment>
                    <li className="secondary-menu__item">
                      <a onClick={this.restart} className="">
                        Recommencer l'exercice
                      </a>
                    </li>
                    <li className="secondary-menu__item">
                      <a onClick={this.stop} className="">
                        Quitter l'exercice
                      </a>
                    </li>
                  </React.Fragment>
                )}
                {(exercisePlaying && window.location.pathname != '/exercise/1') && (
                <li className="secondary-menu__item">
                  <a onClick={this.confirmConfiguration} className="">
                    Paramètres audio et vidéo
                  </a>
                </li>
                )}
                <li className="secondary-menu__item">
                  <a onClick={this.logout} className="">
                    Se déconnecter de PractiVizio
                  </a>
                </li>
                <li className="secondary-menu__item">
                  <a href={window.policyUri} target="_blank">
                    Politique de confidentialité
                  </a>
                </li>

                <li className="secondary-menu__item">
                  <span onClick={this.showZendesk} className="link">
                    Contacter le support
                  </span>
                </li>
              </ul>
            )}
            <div className="version">
              PractiVizio {window.infoVersion.version}
            </div>
          </div>

          <div id="primary" className="main-content">
            <div className="main-content__container">
              <Routes>
                <Route path="/" element={<Welcome />} />

                <Route path="/exercise/:id/:step" element={<ExercisePanel />} />
                <Route path="/exercise/:id" element={<ExercisePanel />} />
                <Route
                  path="/feedback/:idExercise/:idExerciseSession"
                  element={<FeedbackPanel />}
                />
                <Route
                  path="/retry/:idExercise/:idExerciseSession"
                  element={<RetryPanel />}
                />
                <Route path="/test/bot" element={<TestBot />} />
                <Route path="/test/device" element={<TestDevice />} />
                <Route path="/legal/terms" element={<Terms />} />
              </Routes>
            </div>
          </div>
        </main>
      </div>
    );
  }
}
export default withRouter(MainPage);
import log from "loglevel";
import Utils from '../Utils/Utils';

window.videos = [];

export default class Video {

    duration = -1;
    ready = false;
    end = -1;
    readingMustBeComplete = true;
    stopped = false;
	waiting = false;
	isSeeking = false;

    // Time trigger
    triggerTime = -1;
    callbackTimeTriggerDispatch = true;
    callbackTimeTriggered = () => {}

    // End trigger
    callbackEndedDispatch = true;
    callbackEnded = () => {}

    constructor(src, opt) {
		window.videos.push(this);
		this.init(src, opt);
        
    }
	
	async init(src, opt) {
        this.transitionTimeDelay = opt.transitionTimeDelay;
		let content = await window.sdk.cacheManager().get(src);
		this.player = document.createElement("video");
        
		if(content === false) {		
			window.sdk.cacheManager().checkIfNeedToJumpToNext(src);
			this.player.src = src; 
		}
		else {			
			//this.player.src = URL.createObjectURL(content);
			const source1 = document.createElement('source');
			source1.src = URL.createObjectURL(content);
			source1.type = 'video/mp4';
			this.player.appendChild(source1);
		}
		 // Add subtitle track
        const subtitleLang = Utils.GetSubtitleLanguage()
        if (subtitleLang && (subtitleLang === "fr" || subtitleLang === "en")) {
            const track = document.createElement('track');
            track.kind = 'subtitles';
            track.label = 'Practivizio subs';
            track.srclang = subtitleLang;
            track.src = window.sdk.CreateBotSubtitleURL(opt.iVideoName, subtitleLang);
            track.default = true; // enable subtitle by default
            this.player.appendChild(track);
        }
		this.opt = opt;
		this.src = src;

        if (opt.loop)
            this.player.loop = true;
            
        this.onCanPlay = this.onCanPlay.bind(this);
        this.onLoadedData = this.onLoadedData.bind(this);
        
        this.player.addEventListener('canplay', this.onCanPlay);
		
		this.player.addEventListener('loadedmetadata', this.onCanPlay);
		
        this.player.addEventListener('loadeddata', this.onLoadedData);
		
		this.player.addEventListener('waiting', this.onWaitingData);
		
		this.player.addEventListener('playing', this.onPlaying);
		
		this.player.addEventListener('seeking', this.onSeeking);
		
		this.player.addEventListener('seeked', this.onSeeked);
		
		this.player.addEventListener('playing', (event) => {
            log.debug('playing');
        });

        /*this.player.addEventListener('ended', (event) => {
            this.onEnded();
        });*/

        this.player.ontimeupdate = (event) => {
            this.onTimeUpdate();
        };
		
		this.player.setAttribute('autoplay', true);
		this.player.setAttribute('playsinline', '');		
        this.player.setAttribute('crossorigin', 'anonymous'); // add crossorigin attribute
		//this.player.setAttribute('controls', true);
		this.player.pause();
        this.player.load();
		
		this.checkDuration();
	}

	
	async checkDuration() { 
		while(Number.isNaN(this.player.duration)) {
			await Utils.Sleep(300);
			//log.debug('this.player.duration', this.player.duration);
		}
		this.ready = true;
	}
	
	async onSeeked() {
		await Utils.Sleep(300);
		this.isSeeking = false;
	}
	
	onSeeking() {
		log.debug('SEEEEEEEEEEEEEEEEEEEEKING');
		this.isSeeking = true;
	}
	
	onWaitingData() {
		if(!this.isSeeking) {
			log.debug('onWaitingDataonWaitingDataonWaitingDataonWaitingDataonWaitingDataonWaitingData', this.isSeeking);
			this.waiting = true;			
			//window.sdk.event().emit('waitingVideoData');
		}		
	}
	
	onPlaying() {
		if(this.waiting) {
			window.sdk.event().emit('videoDataIsLoaded');
			this.waiting = false;
		}
	}
    
    getSrc() {
        return this.player.src;
    }
    
    stopTimeTriggerListener() {
        this.callbackTimeTriggerDispatch = false;
    }
    
    stopVideoEndedListener() {
        this.callbackEndedDispatch = false;
    }
    
    onCanPlay(event) {
        this.ready = true;
    }

    onLoadedData(event) {
		//log.debug('===================> onLoadedData');
        this.duration = this.player.duration;
		if(this.waiting) {
			window.sdk.event().emit('videoDataIsLoaded');
			this.waiting = false;
		}
    }

    isReady() {
        return new Promise(async(resolve, reject) => {
            while (!this.ready) {
                await await Utils.Sleep(100);
            }
            resolve(true);
        });
    }

    /*play() {
        return this.player.play();
    }*/

    getMediaStream() {
        return this.player.captureStream();
    }

    initializeVideo(data, timeTriggerCallback, endCallback) {
        //log.debug(data);
        if (data.duration != 'full') {
            this.readingMustBeComplete = false;
            if (data.start != 0)
                this.player.currentTime = this.start;
            this.end = data.end;
        }
        this.triggerTime = data.triggerTime;
        this.callbackTimeTriggered = timeTriggerCallback;
        this.callbackEnded = endCallback;
    }

    onTimeUpdate() {
        if (this.stopped)
            return;
        
        // Time trigger detection
        if (this.triggerTime > 0 && this.player.currentTime >= this.triggerTime)
        {
            this.triggerTime = -1;
            this.callbackTimeTriggered();
        }

        // End detection
        if (this.readingMustBeComplete)
        {
            //log.debug(this.player.currentTime, this.player.duration, isNaN(this.player.duration))
            if (!isNaN(this.player.duration) && this.player.duration > 0 && this.player.currentTime >= (this.player.duration - this.transitionTimeDelay / 1000)) {
                this.goEnd();
            }
        }
        else 
        {
            if (this.player.currentTime >= this.end)
            {
                this.goEnd();
            }
        }
    }

    /*onEnded() {
        if(this.stopped)
            return;
        if(this.readingMustBeComplete)
            this.goEnd();
    }*/
	
	pause() {
		this.player.pause();
	}
	
	async play() {
		//log.debug('===============> play', this.ready);
		await this.player.play();
		
		//alert(this.player.paused);
	}

    reInit() {
        this.player.pause();
        this.player.currentTime = 0;
    }

    timeTriggered() {
        //log.debug(this.getSrc(), 'timeTriggered', this.callbackTimeTriggerDispatch);
        if(!this.callbackTimeTriggerDispatch)
            return;
        this.timeTriggered();
    }

    goEnd() {
        log.debug(this.getSrc(), 'go end', this.callbackEndedDispatch);
        this.stopped = true;
        if(!this.callbackEndedDispatch)
            return;
        this.callbackEnded();
        /*setTimeout(() => {
            this.reInit();
        }, this.transitionTimeDelay)*/
    }

    getPlayer() {
        return this.player;
    }

    remove() {
        log.debug('player', 'remove', this.player);
        this.player.pause();
		if(this.opt.loop) {
			this.player.currentTime = 0;
		}
		else {
			this.player.removeAttribute('src');            
			this.player.removeEventListener('canplay', this.onCanPlay);
			this.player.removeEventListener('loadeddata', this.onLoadedData);
		}
    }

    delete() {        
        log.debug('player', 'delete', this.player);
        this.stopped = true;
        this.player.pause();
        setTimeout(() => {
            if(!this.player)
                return;
            this.player.pause();
            this.player.removeEventListener('canplay', this.onCanPlay);            
		    this.player.removeEventListener('loadedmetadata', this.onCanPlay);            
            this.player.removeEventListener('loadeddata', this.onLoadedData);            
		    this.player.removeEventListener('waiting', this.onWaitingData);            
		    this.player.removeEventListener('playing', this.onPlaying);            
		    this.player.removeEventListener('seeking', this.onSeeking);            
		    this.player.removeEventListener('seeked', this.onSeeked);            
            this.player.removeAttribute('src');      
            this.player = null;
        }, 2000);
    }
}


        
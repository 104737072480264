import log from "loglevel";
import BaseNode from './BaseNode';

export default class ExerciseNode extends BaseNode
{
    // Internal values
    m_IsActive = false;


    constructor(iGraph, iProperties) 
    {        
        super(iGraph, iProperties);
        //log.debug(this.GetIdentity() + " constructor: graph = " + this.Graph.ExerciseName + ", id = " + this.ID);
    }

    Reset()
    {
        this.SetActive(false);
    }

    Update()
    {
        if(this.m_IsActive)
        {
            // Update
        }
    }

    OnActivated(iActivator, iInputPort)
    {
        //log.debug("ExerciseNode '" + this.GetIdentity() + "' has been activated by '" + (iActivator ? iActivator.GetIdentity() : "undefined") + "'.");
        this.SetActive(true);
    }

    OnDeactivated()
    {
        //log.debug(this.GetIdentity() + " OnDeactivated.");
        this.SetActive(false);
    }

    SetActive(iActive)
    {
        this.m_IsActive = iActive;
    }

    IsActive()
    {
        return this.m_IsActive;
    }
    
	Pause() {
	}
	
	Resume() {
	}

    FreezeSystem() {
    }
    
    UnfreezeSystem() {
    }

    IsPaused()
    {
        return this.Graph.IsPaused();
    }

    IsSystemFrozen()
    {
        return this.Graph.IsSystemFrozen();
    }
}
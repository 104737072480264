import log from "loglevel";
import ExerciseNode from './ExerciseNode';
import NodePort from '../NodePort';

export default class DynamicPanel extends ExerciseNode 
{
    // Ports
    Input = new NodePort("Input", "input", this);
    Output = new NodePort("Output", "output", this);
    OnActivated_Output = new NodePort("OnActivated_Output", "output", this);

    // Parameters
    PanelToShow = "";
    MaximumDuration = 0;

    // Internal values
    #StartTime = 0;
    #LogFrequency = 0.5;
    #LastLogTime = 0;


    constructor(iGraph, iProperties)
    {
        super(iGraph, iProperties);
 
        this.PanelToShow = iProperties.PanelToShow;
        this.MaximumDuration = iProperties.MaximumDuration;

        //log.debug(this.GetIdentity() + " constructor: graph = " + this.Graph.ExerciseName + ", id = " + this.ID + ", panelToShow = " + this.PanelToShow + ", maximumDuration = " + this.MaximumDuration); 
    }

    OnActivated(iActivator, iInputPort)
    {
        log.debug(this.GetIdentity() + "' has been activated by '" + iActivator.GetIdentity() + "'.");
        super.OnActivated(iActivator, iInputPort);

        // Show the panel
        this.#StartTime = (new Date()).getTime();
        
        // Activate "OnActivated" output port
        this.OnActivated_Output.ActivateAllConnections();

        // For now, we don't use this node for briefing, so just pass
        this.OnPanelFinished();

        //this.Update();
    }

    Update()
    {
        if(this.m_IsActive)
        {
            let timeSpent = 0.001 * ((new Date()).getTime() - this.#StartTime);

            // Log progress
            if(timeSpent / this.MaximumDuration > this.#LastLogTime + this.#LogFrequency)
            {
                log.debug(this.GetIdentity() + " has been updated. Time spent:" + timeSpent + " / " + this.MaximumDuration + " s.");
                this.#LastLogTime = timeSpent / this.MaximumDuration;
            }

            if(timeSpent >= this.MaximumDuration)
            {        
                log.debug(this.GetIdentity() + " reached its maximum duration!");
                this.ActivateOutput();
            }
        }
    }
        
    OnPanelFinished()
    {
        log.debug(this.GetIdentity() + ".OnPanelFinished.");

        // Hide/delete the panel
        
        this.ActivateOutput();
    }
        
    ActivateOutput()
    {
        log.debug(this.GetIdentity() + "' activating output.");

        this.SetActive(false);

        this.Output.ActivateAllConnections();
    }

    PrintParameters()
    {
        //log.debug("BotName = " + this.BotName + ", VideoName = " + this.VideoName);
    }
}
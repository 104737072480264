import log from "loglevel";
import ExerciseNode from './ExerciseNode';
import NodePort from '../NodePort';
import ParticipantsModule from '../../Participants/ParticipantsModule';

export default class RaiseHandButton extends ExerciseNode 
{
    // Ports
    Input = new NodePort("Input", "input", this);
    Output = new NodePort("Output", "output", this);

    // Parameters

    // Internal values
    HandRaised;
    Button;

    constructor(iGraph, iProperties) 
    {
        super(iGraph, iProperties);

        //log.debug(this.GetIdentity() + " constructor: graph = " + this.Graph.ExerciseName + ", id = " + this.ID); 
    }

    OnActivated(iActivator, iInputPort)
    {
        super.OnActivated(iActivator, iInputPort);

        log.debug(this.GetIdentity() + " has been activated by '" + iActivator.GetIdentity() + "'.");
        
        // Value setup
        this.HandRaised = false;
        this.Graph.SetBoolValue("HandRaised", this.HandRaised);
        // UI button setup
        this.Graph.ParentExerciseComponent.RegisterRaisehandCallbacks(
            (value) => this.RegisterButton(value), 
            (value) => this.OnClicked(value));
        this.Graph.ParentExerciseComponent.ShowRaisehandButton(true);
    }

    Update()
    {
        if(this.m_IsActive)
        {
            // Check if the RaiseHand value changed externally
            let handRaised = this.Graph.GetBoolValue("HandRaised");
            if(handRaised !== this.HandRaised)
            {
                log.debug(this.GetIdentity() + " Update: HandRaised value changed externally to " + handRaised);
                this.SetHandRaised(handRaised);
            }
        }
    }

    RegisterButton(iButton)
    {
        log.debug(this.GetIdentity() + " RegisterButton: button = " + JSON.stringify(iButton));
        this.Button = iButton;
    }

    DestroyButton()
    {
        this.Graph.ParentExerciseComponent.ShowRaisehandButton(false);
    }

    OnClicked(iHandRaised)
    {
        this.Graph.SetBoolValue("HandRaised", iHandRaised);
        
        this.SetHandRaised(iHandRaised);

        if(iHandRaised)
        {
            this.ActivateOutput();
        }
    }

    SetHandRaised(iHandRaised)
    {
        log.debug(this.GetIdentity() + " SetHandRaised: " + (iHandRaised ? "Raising hand." : "Lowering hand."));

        // Set node internal value
        this.HandRaised = iHandRaised;

        // Set the video speaking frame state
        let human = ParticipantsModule.Instance.GetHuman();
        human.setSpeakingState(iHandRaised ? "raising" : "no");

        // Set UI button state
        this.Button.UpdateState(iHandRaised);
        
        // Log event to database
        this.Graph.History.AddEvent("HandRaised", {State: iHandRaised ? "Raised" : "Lowered"});
    }
        
    ActivateOutput()
    {
        log.debug(this.GetIdentity() + "' activating output.");
        this.Output.ActivateAllConnections();
    }

    OnDeactivated()
    {
        super.OnDeactivated();
        
        // Hide raise hand button
        this.Graph.ParentExerciseComponent.ShowRaisehandButton(true);
    }

    PrintParameters()
    {
    }
}
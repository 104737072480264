/* global ResizeObserver, MediaStream */
import log from "loglevel";
import React from 'react';
import '../../styles/components/Exercise.scss';

import { useParams, Link, useNavigate } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';

import GetUserMedia from '../MediaDevices/GetUserMedia';
import WebcamView from '../MediaDevices/WebcamView';
import Settings from '../MediaDevices/Settings';

import Utils from '../../AppClasses/Utils/Utils';
import ExerciseGraphTester from '../../AppClasses/ExerciseScenario/ExerciseGraphTester';
import ExerciseGraph from '../../AppClasses/ExerciseScenario/ExerciseGraph';
import ParticipantsModule from '../../AppClasses/Participants/ParticipantsModule';
import PitchExercisePanel from '../../pages/exercise/PitchExercisePanel';
import BuildYourselfExercisePanel from '../../pages/exercise/BuildYourselfExercisePanel';

import Bot from './Bot';
import LocalUser from './LocalUser';
import RaiseHandButton from './RaiseHandButton';
import ActionButton from './ActionButton';
import BottomButton from './BottomButton';
import StrategicActions from './StrategicActionsPop';

import Button from '../Utilities/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ProgressBar from 'react-bootstrap/ProgressBar';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMasksTheater } from '@fortawesome/free-solid-svg-icons';

import { isEdge, isChrome, isFirefox, isSafari, isAndroid, isIos, isOpera } from 'react-device-detect';

// Sounds
//import notificationSound from '../../assets/sounds/NotificationSound.mp3';


const BottomBar = ({ children, speechFeedback, pauseExercise, played, step, pauseButtonDisabled, hide, hideCam, ...props }) => (
    <div className="bottom-bar exercise-bottom-bar" {...props}>
        <div className="bottom-bar__row">
            {speechFeedback && <div className="debug bottom-bar__speech">{speechFeedback}</div>}
        </div>
        <div className="bottom-bar__row">
            <div className="bottom-bar__left">
            </div>
            <div className="bottom-bar__center">
                {children}
                {
                    (step === "scenario") &&
                    <React.Fragment>
                        <a id='pause-button' className={`bottom-bar__control ${pauseButtonDisabled ? 'disabled' : ''} __bottom-disabled`} title="Mettre en pause" onClick={pauseExercise}>
                            <i className={'control__icon ' + (played ? 'icon-pause' : 'icon-play')}></i>
                        </a>
                        <a id='hide-camera-button' className={`bottom-bar__control button-camera ${hide ? 'button-camera-off' : ''}`} title="Masquer la camera" onClick={hideCam}>
                            <i className={'control__icon ' + (hide ? 'icon-camera-off' : 'icon-camera')}></i>
                        </a>
                    </React.Fragment>
                }
            </div>
            <div className="bottom-bar__right">
            </div>
        </div>
    </div>
);


class BriefingUserWebcam extends React.Component {

    constructor(props) {
        super(props);
        this.listeners = [];
        this.videoRef = React.createRef();
    }

    async componentDidMount() {
        this.videoObj = this.videoRef.current;
        this.stream = window.sdk.videoconf().mediaDevices().getStream();
        this.updateVideo();

        this.listeners.push(window.sdk.event().on('mediaDeviceUpdated', (data) => {
            if (data.kind == 'videoinput') {
                this.removeOldTrack();
                this.stream = data.stream;
                this.updateVideo();
            }
        }));
    }

    updateVideo() {
        if (this.stream && this.stream.getVideoTracks().length > 0) {
            this.videoObj.srcObject = new MediaStream([this.stream.getVideoTracks()[0].clone()]);
        }
    }

    removeOldTrack() {
        if (!this.videoRef.current.srcObject)
            return;

        const tracks = this.videoRef.current.srcObject.getVideoTracks();
        for (let j in tracks) {
            tracks[j].stop();
        }
    }   

    componentWillUnmount() {
        if (!this.videoRef.current.srcObject)
            return;

        const tracks = this.videoRef.current.srcObject.getVideoTracks();
        for (let j in tracks) {
            tracks[j].stop();
        }

        for (var i in this.listeners) {
            this.listeners[i]();
        }
    }

    render() {
        return (
            <div className={'video_layer video_layer--1 webcam_layer'}>
                <video playsInline autoPlay={true} ref={this.videoRef} muted></video>
            </div>
        );
    }
}

const BriefingVideoBot = ({ source }) => {
    return (
        <div className={'video_layer video_layer--1 webcam_layer'}>
            <video playsInline autoPlay={true} muted>
                <source src={source} type="video/mp4" />
            </video>
        </div>
    );
};

class BriefingVideoPresenter extends React.Component {

    constructor(props) {
        super(props);
    }

    async componentDidMount() {

    }

    render() {
        // Set a different ID to force video player to reload the new video from URL
        // Extract video file name from source URL
        let id = this.props.source.split('/').pop();

        return (
            <div className={'video_layer_presenter'}>
                <video playsInline key={id} autoPlay={true} onEnded={this.props.onEnded}>
                    <source src={this.props.source} type="video/mp4" />
                </video>
            </div>
        );
    }
}
export function withRouter(Children) {
    return (props) => {
        const navigate = useNavigate();
        return <Children {...props} navigate={navigate} />
    }
}

class Exercise extends React.Component {
    // References
    BotsModuleInstance;

    // Input parameters
    ExerciseID = this.props.ExerciseID;

    // Dynamic
    state = {
        step: "notInitialized",
        currentBriefingIndex: 0,
        cache: {
            currentFile: 'waiting size informations',
            currentFileProgression: 'waiting size informations',
            totalProgression: 0
        },
        micIsOk: false,
        exerciceStarted: false,
        buffering: false,
        played: true,
        pauseButtonDisabled: false,
        getUserMediaState: {},
        humanParticipantSpeaking: 'no',
        onShowLoaderWaitWebcam: true,
        averageBW: 0,
        hide: false,
        debug: {
            forceTTS: false
        },
        debugInfo: {},
        strategicActions:[]
    }
    Stopping = false;
    ExerciseGraph = null;
    ExerciseGraphTester = null;
    ParticipantsVideos = [];
    listeners = [];
    jsonGraph = {};
    isStreamReady = false;
    tryStart = 1;
    // --------
    // Initialisation
    constructor(props) {
        super(props);

        this.containerObs = new ResizeObserver(() => this.resetExecisesContainer());

        this.handleDeviceChange = this.handleDeviceChange.bind(this);
    }

    /*playSound = () => {
      const audio = new Audio(notificationSound);
      audio.play();
    }*/

    async componentDidMount() {

        window.sdk.event().on('waitingVideoData', () => {
            this.setState({
                buffering: true,
                played: false,
            });
        });

        window.sdk.event().on('videoDataIsLoaded', () => {
            this.setState({
                buffering: false,
                played: true,
            });
        });

        window.sdk.event().on('showStrategicUserActions', (actions) => {
                log.debug('showStrategicUserActions',actions)
                this.setState({
                    strategicActions : [...this.state.strategicActions, actions]
                })
                setTimeout(() => {
                    this.setState({
                        strategicActions: this.state.strategicActions.filter(item =>!actions.includes(item))
                    })
                }, 6000)
        });

        // Get the exercise ID from the URL
        var url = window.location.pathname;
        this.exerciseID = url.substring(url.lastIndexOf('/') + 1);
        log.debug("Loading Exercise ID: " + this.exerciseID + "...");
        // Start loading the exercise graph in the background

        const { navigate } = this.props;
        window.sdk.event().on('endExercise', (data) => {
            window.sdk.forbiddenInteractionWarning().destroy();
            this.exerciseCompleted = true;
            this.urlFeedBack = "/feedback/" + this.exerciseID + '/' + data.exerciseSessionID
            navigate(this.urlFeedBack, { replace: false });
        })

        if (this.exerciseID == 'PitchTest') {
            this.jsonGraph = {
                "Name": "Pitch Test",
                "ID": "PitchTest",
                "Version": "2023-03-19-08-30-00",
                "CustomizationValues": {},
                "AvailableAchievements": [],
                "AvailableObjectives": [],
                "PrioritaryVideosToPreload": [],
                "Nodes": [],
                "Links": []
            };
        }
        else if (this.exerciseID == 'BuildYourself') {
            this.jsonGraph = {
                "Name": "Build Yourself",
                "ID": "BuildYourself",
                "Version": "2023-03-19-08-30-00",
                "CustomizationValues": {},
                "AvailableAchievements": [],
                "AvailableObjectives": [],
                "PrioritaryVideosToPreload": [],
                "Nodes": [],
                "Links": []
            };
        }
        else {
            // Download exercise json graph
            this.jsonGraph = await window.sdk.exercise().downloadExerciseGraph(this.exerciseID);
            if (this.jsonGraph.state && this.jsonGraph.state == 'fail') {
                this.setState({
                    step: 'fail',
                    failMessage: this.jsonGraph.info.message,
                });
                return;
            }
        }

        // Temporaryily add a hardcoded briefing
        let Briefing = [
            /*{
                "title": "Réunion d'équipe",
                "subtitle": "Jeanne présente son logiciel",
                "presenterBot":{
                    "type": "bot",
                    "source": "https://d3mr3frbexldn2.cloudfront.net/Assistante/Videos/Assistant_Briefing_Stimulus_Intro.mp4"
                },
                "icon": null,
                "video": null
            },
            {
                "title": "Vous êtes Camille",
                "subtitle": "Réagissez quand vous voulez",
                "presenterBot":{
                    "type": "bot",
                    "source": "https://d3mr3frbexldn2.cloudfront.net/Assistante/Videos/Assistant_Briefing_Stimulus_PresentationDominique.mp4"
                },
                "icon": null,
                "video": {
                    "type": "local"
                }
            }*/
        ];
        this.jsonGraph.Briefing = Briefing;

        //log.debug("Loaded exercise data: " + JSON.stringify(this.jsonGraph));

        // Load the exercise graph
        this.ExerciseGraph = this.LoadExerciseGraph();
        log.debug("+ Exercise graph loaded !");
        let APIEndpoints = await window.sdk.ExercisesAPIEndpoints().getOne(this.ExerciseID);
        if (APIEndpoints['branchingdecisionapi']) {
            window.sdk.fetch().post(APIEndpoints['branchingdecisionapi'], {body: {
                input: "Warm-up call",
                ExerciseID: "4",
                BDNodeID: "BD0",
                BDName: "BD0"
            }}, true); // Sends a warm-up call to make sure Davinci is responding quickly
        }
        else {
            log.error("No branching decision API endpoint found for this exercise");
        }
        
        //this.ExerciseGraph.PrintNodesList();

        // Start preloading exercise assets (bots videos)
        window.sdk.exercise().preloadExerciseAssets(this.ExerciseGraph, (data) => {
            if (data.noPreloadNeed) {
                this.setState({
                    cache: {
                        currentFile: '',
                        currentFileProgression: '',
                        totalProgression: 100
                    }
                });
            }
            else {
                this.setState({
                    cache: {
                        currentFile: data.name.split('/').pop(),
                        currentFileProgression: Math.round((data.bytesReceived / data.fileTotalBytes) * 100),
                        totalProgression: Math.round((data.totalBytesReceived / data.totalSize) * 100)
                    }
                });
            }
        });

        let needInteraction = await window.sdk.videoconf().mediaDevices().autoplayDetection();
        log.debug('Exercise.componentDidMount: needInteraction ', needInteraction);
        if (!needInteraction) {
            log.debug('Exercise.componentDidMount: this.setState({ step: "initialization" });');
            this.setState({ step: "initialization" });
        } else {
            log.debug('Exercise.componentDidMount: this."waitWebcam"Step();');
            this.startWaitingForWebcam();
        }

        this.listeners.push(window.sdk.event().on('stopExercise', () => {
            log.debug('Exercise.on(stopExercise)');
            this.Stop(true);
            //this.setState({ step: "initialization" });
        }));

        window.sdk.event().on('humanParticipantSpeakingStateUpdated', (humanParticipantSpeaking) => {
            this.setState({
                humanParticipantSpeaking
            });
            log.debug('DEBUG  humanParticipantSpeaking',humanParticipantSpeaking)
            if(['speaking','readyforSpeaking','raising'].includes(humanParticipantSpeaking)){
                window.sdk.forbiddenInteractionWarning().pause(true);
            } else {
                window.sdk.forbiddenInteractionWarning().pause(false);
            }
        });

        this.listeners.push(window.sdk.event().on('restartExercise', () => {
            this.Restart();
        }))

        this.listeners.push(window.sdk.event().on('disablePauseButton', () => {
            this.setState({ pauseButtonDisabled: true });
        }))
        this.listeners.push(window.sdk.event().on('enablePauseButton', () => {
            this.setState({ pauseButtonDisabled: false });
        }))

        this.listeners.push(window.sdk.event().on('forcePause', () => {
            this.ExerciseGraph.Pause();
        }))

        log.debug('devicechange listener')
        navigator.mediaDevices.addEventListener('devicechange', this.handleDeviceChange);
        
        let sttFailed = 0;
        this.listeners.push(window.sdk.event().on('failedTranscription', (data, dataVolume) => {
            log.debug('exercise.jsx: On failedTranscription event', data, dataVolume);
            const sum = dataVolume.reduce((acc, curr) => acc + curr, 0);
            const average = sum / dataVolume.length;
            if(average <  0.05)
                window.sdk.event().emit('onSttFailedNoAudio');
            else {
                sttFailed++;
                if(sttFailed >= 2)
                    window.sdk.event().emit('onSttFailedButAudio');
            }                
        }));
        

        // Initialize debug info
        this.setDebugInfo("lastBranchingDecision", {ID: "NA", State: "NA"});

        // Affiche le bouton stop dans la sidebar
        window.sdk.event().emit('startExercise');

        
    }

    async handleDeviceChange(event) {        
        let devices = await event.target.enumerateDevices();
        let currentDeviceIdVideo = window.sdk.videoconf().mediaDevices().getCurrentDeviceId('videoinput');
        let currentDeviceIdAudio = window.sdk.videoconf().mediaDevices().getCurrentDeviceId('audioinput');
        let findedAudio = false;
        let findedVideo = false;
        for(var i in devices) {
            log.debug('DEVICE_CHANGE', devices[i].deviceId, devices[i].kind);        
            if(devices[i].kind == 'audioinput' && devices[i].deviceId == currentDeviceIdAudio)
                findedAudio = true;
            if(devices[i].kind == 'videoinput' && devices[i].deviceId == currentDeviceIdVideo)
                findedVideo = true;            
        }
        log.debug('DEVICE_CHANGE', findedAudio, findedVideo);        
        if(!findedAudio || !findedVideo){
            window.sdk.event().emit('onDeviceChanged', findedAudio? 'webcam':'microphone');
        }
        log.debug('DEVICE_CHANGE', 'Changement détecté dans les périphériques de média', await event.target.enumerateDevices());        
      }


    // --------
    // Lors de la suppresion du composant
    async componentWillUnmount() {
        log.debug('Exercise.componentWillUnmount');
        for (var i in this.listeners) {
            this.listeners[i]();
        }
        navigator.mediaDevices.removeEventListener('devicechange', this.handleDeviceChange);
        this.Stop(false);
        window.sdk.videoconf().mediaDevices().stop()
        this.containerObs.disconnect();
    }

    // Handle microphone working checkbox
    handleMicIsOkChange = (event) => {
        this.setState({ micIsOk: event.target.checked });
    }

    getBestItemBounds(config) {
        const actualRatio = config.width / config.height
        // Just make up theoretical sizes, we just care about ratio
        const theoreticalHeight = 100;
        const theoreticalWidth = theoreticalHeight * config.aspectRatio;
        // Go over each row count find the row and col count with the closest
        // ratio.
        let best;
        for (let rowCount = 1; rowCount <= config.maxRows; rowCount++) {
            // Row count can't be higher than item count
            if (rowCount > config.itemCount) continue;
            const colCount = Math.ceil(config.itemCount / rowCount);
            // Get the width/height ratio
            const ratio = (theoreticalWidth * colCount) / (theoreticalHeight * rowCount);
            if (!best || Math.abs(ratio - actualRatio) < Math.abs(best.ratio - actualRatio)) {
                best = { rowCount, colCount, ratio };
            }
        }
        // Build item height and width. If the best ratio is less than the actual ratio,
        // it's the height that determines the width, otherwise vice versa.
        const result = {
            rowCount: best.rowCount,
            colCount: best.colCount
        };
        if (best.ratio < actualRatio) {
            result.itemHeight = (config.height - (config.minGap * best.rowCount)) / best.rowCount;
            result.itemWidth = result.itemHeight * config.aspectRatio;
        } else {
            result.itemWidth = (config.width - (config.minGap * best.colCount)) / best.colCount;
            result.itemHeight = result.itemWidth / config.aspectRatio;
        }
        return result;
    }


    resetExecisesContainer() {
        if (!this.exercisesContainer) {
            return;
        }

        const items = this.exercisesContainer.querySelectorAll('.exercise__video');

        if (!items.length) return;
        setTimeout(() => {
            let el = document.querySelector('.exercise__inner');
            if(el){
                const rect = el.getBoundingClientRect();
                const { itemWidth, itemHeight, colCount } = this.getBestItemBounds({
                    width: rect.width,
                    height: rect.height,
                    itemCount: items.length,
                    aspectRatio: 4 / 3,
                    maxRows: 5,
                    minGap: 0
                });
                this.exercisesContainer.style.width = itemWidth * colCount + 'px';
                [...items].map(el => {
                    el.style.width = itemWidth + 'px';
                    el.style.height = itemHeight + 'px';
                    if (el.classList.contains('not-init')) {
                        el.classList.remove('not-init');
                    }
                });
            }
        }, 500);
    }


    // --------
    iHaveInteracted = () => {
        log.debug('iHaveInteracted');
        this.startWaitingForWebcam();
    }


    // --------
    // Passes sur le prochain layout de Briefing ou passe sur la mise en situation
    nextBriefingSlide = () => {
        const { Briefing } = this.jsonGraph;
        log.debug('Next briefing slide: ', this.state.currentBriefingIndex, ' Briefing lenght ', Briefing.length);
        let { currentBriefingIndex } = this.state;
        currentBriefingIndex++;
        if (currentBriefingIndex >= Briefing.length) {
            log.debug('Next briefing slide: Finished, starting the scenario...');
            log.debug('startScenario ici2')
            this.startScenario();
        } else {
            this.setState(function (state, props) {
                let newState = state.currentBriefingIndex + 1;
                log.debug('Next briefing slide: Going next slide = ', newState);
                return {
                    currentBriefingIndex: newState
                };
            });
        }
    }


    // --------
    // Check si les autorisations audio/camera sont correctes
    // Si c'est le cas, on passe au Briefing
    // Sinon on affiche le tuto pour activer les autorisations
    startWaitingForWebcam() {
        log.debug('Exercise.startWaitingForWebcam');
        if (!window.sdk.videoconf().mediaDevices().isStreamReady()) {
            log.debug('Exercise.startWaitingForWebcam: Stream not ready, waiting...');
            this.setState({ step: "waitWebcam" });
        } else {
            log.debug('Exercise."startWaitingForWebcam": Stream ready');
            this.OnStreamReady();
        }
    }


    // --------
    // Passes sur la page Briefing
    
    OnStreamReady = () => {    
        if(this.isStreamReady)
            return;
        this.isStreamReady = true;
        log.debug('Webcam OnStreamReady');
        if(window.sdk.videoconf().mediaDevices().videoDeviceId && window.sdk.videoconf().mediaDevices().videoDeviceId!= 'null' && window.sdk.videoconf().mediaDevices().audioDeviceId && window.sdk.videoconf().mediaDevices().audioDeviceId != 'null')
            this.micValidation();
        else 
            this.startCamMicSetup();
    }

    // --------
    startCamMicSetup() {
        if(window.zE){
            window.zE("webWidget", "prefill", {
                name: {
                  value: window.sdk.user().firstName + " " + window.sdk.user().lastName,
                  readOnly: true, // optional
                },
                email: {
                  value: window.sdk.user().email,
                  readOnly: true, // optional
                },
              });
            window.zE('webWidget', 'show');
            
            window.zE("webWidget:on", "close", function () {
                window.zE('webWidget', 'show');
            });
        } 
        log.debug('Exercise.CamMicSetup');
        
        // If skipCamMicSetup customization value is set to true or exercise ID is 1, skip camMicSetup and start briefing
        if (this.exerciseID == 1 || (this.jsonGraph.CustomizationValues && this.jsonGraph.CustomizationValues.skipCamMicSetup == true)) {
            log.debug('Exercise.CamMicSetup: Exercise ID is 1, skipping camMicSetup');
            this.startBriefing();
        }
        else {
            this.setState({ step: "camMicSetup" });
            
        }
    }

    backToCamMicSetup = async() => {
        this.tryStart++;
        if(this.transcriptionSession) {
            this.transcriptionSession.close();
        }
        this.setState({ step: "camMicSetup" });
    }

    // --------
    // Affiche la page briefing
    // Lance l'event qui permet de changer les boutons de la sidebar en bouton stop
    startBriefing = async () => {
        log.debug('Exercise.startBriefing');


        const { Briefing } = this.jsonGraph;
        if (Briefing.length === 0 || this.props.StepLabel == 'run') {
            log.debug('startScenario ici')
            this.startScenario();
        } else {
            this.setState({ step: "briefing" });
        }
    }

    micValidation = async () => {
        if (isSafari && !window.sdk.isInIframe()) {
            log.debug('safari in non iframe we do not go to fullscreen');
        }
        else {
            window.sdk.openFullscreen();
        }
        if (this.exerciseID == 1 || (this.jsonGraph.CustomizationValues && this.jsonGraph.CustomizationValues.skipCamMicSetup == true)) {
            this.startBriefing();
            return;
        }

        let deviceAudioId = window.localStorage.getItem('device-audioinput');
        if(!deviceAudioId){
            window.sdk.videoconf().mediaDevices().update('audioinput', window.sdk.videoconf().mediaDevices().getCurrentDeviceId('audioinput'));         
        }

        let deviceVideoId = window.localStorage.getItem('device-videoinput');
        if(!deviceVideoId){
            window.sdk.videoconf().mediaDevices().update('videoinput', window.sdk.videoconf().mediaDevices().getCurrentDeviceId('videoinput'));            
        }


        this.setState({ step: "micValidation" });
        if(window.zE){
            window.zE("webWidget", "prefill", {
                name: {
                  value: window.sdk.user().firstName + " " + window.sdk.user().lastName,
                  readOnly: true, // optional
                },
                email: {
                  value: window.sdk.user().email,
                  readOnly: true, // optional
                },
              });
            window.zE('webWidget', 'show');
        } 
        /*this.listenerMic = window.sdk.videoconf().mediaDevices().getAudioManager().on('volume', (data) => {
                const textToAnim = document.getElementById("mic-validation-instruction");
                if(textToAnim){
                    if(data > 0.05){
                        const scaleFactor = data * 40; 
                        const baseColor = [0x75, 0x30, 0x9a]; // #75309a

                        const red = Math.min(255, baseColor[0] + (scaleFactor * 100));
                        const green = Math.max(0, baseColor[1] - (scaleFactor * 50));
                        const blue = baseColor[2];
                        const textShadowValue = `0 0 ${10 + scaleFactor * 10}px rgba(${red}, ${green}, ${blue})`;
                        textToAnim.style.textShadow = textShadowValue;
                        textToAnim.style.transitionDuration = '0.3s';
                    } else {
                        textToAnim.style.textShadow = '0 0 0px';
                        textToAnim.style.transitionDuration = '1s';
                    }
                }
        });*/
            this.transcriptionSession = await window.sdk.videoconf().createTranscriptionSession({
                onTranscriptedText: (data) => {
                    this.OnSpeechDetected(data.text);
                },
                onPartialTranscriptedText: (data) => {
                    this.OnSpeechDetected(data.text);            
                },
                onFailed: (data) => {
                    this.OnSpeechDetected(data.text);          
                },
                customData: {
                    "transcriptionID": "PitchSpeech_" + new Date().toUTCString(),
                    "endpoint": "",
                    "segmentationSilenceTimeoutMs": 2000,
                    "phraseListGrammar": "",
                    "keepAlive": true
                }
            });

            this.transcriptionSession.start();
        
            // If test mode asks to skip this step, we send a fake "c'est parti" to continue after 3s
            if(window.testMode.skipSTTSetupTest)
            {
                log.debug("micvalidation skipping");
                this.OnSpeechDetected("c'est parti");
            }
    }

    OnSpeechDetected(iResult){
        if(!iResult)
            return;

        var regex = /c'est parti/g;
        var englishRegexp = /let's go/g;
        var tokenFound = iResult.match(regex) || iResult.match(englishRegexp); if(tokenFound) 
        if(tokenFound) 
        {
            if(window.zE){
                window.zE('webWidget', 'hide');
                window.zE("webWidget:on", "close", function () {
                    window.zE('webWidget', 'hide');
                });
            }
            //this.listenerMic();
            if(this.transcriptionSession)
            {
                this.transcriptionSession.close();
            }

            this.startBriefing();
        }
    }    

    // --------
    // Affiche la mise en situation
    async startScenario() {
        log.debug('Exercise.startScenario');

        this.setState({ step: "scenario", currentBriefingIndex: 0 });

        while (this.state.cache.totalProgression < 100) {
            log.debug("Exercise.startScenario: Waiting for videos caching... " + this.state.cache.totalProgression + "%");
            await Utils.Sleep(1000);
        }

        //do check of BW and decide if we want to preload all
        let needToWaitCompleted = false;
        await window.sdk.cacheManager().getBWAverage((bw, next) => {

            this.setState({
                averageBW: bw.toFixed(2)
            });

            if (bw < 2) {
                this.setState({
                    cache: {
                        needToWaitCompleted: true,
                        currentFile: 'waiting size informations',
                        currentFileProgression: 0,
                        totalProgression: 0
                    }
                });
                needToWaitCompleted = true;
                window.sdk.cacheManager().needToWaitCompleted((data) => {
                    this.setState({
                        cache: {
                            needToWaitCompleted: true,
                            currentFile: data.name.split('/').pop(),
                            currentFileProgression: Math.round((data.bytesReceived / data.fileTotalBytes) * 100),
                            totalProgression: Math.round((data.totalBytesReceived / data.totalSize) * 100)
                        }
                    });
                });
            }
            next();
        });

        if (needToWaitCompleted) {
            log.debug('we detect low bandwith so we have to wait all video to be in cache');

            while (window.sdk.cacheManager().getStatus() != 'completed') {
                log.debug("Exercise.startScenario: Waiting for all videos caching... ");
                await Utils.Sleep(1000);
            }
            this.setState({
                cache: {
                    totalProgression: 100
                }
            });
        }

        log.debug('Exercise.startScenario: Ready, starting!');

        await this.Start();

        // Prevent user interactions if the customization value is set to true or forced on exercise 1 
        if(this.exerciseID == 1 || (this.jsonGraph.CustomizationValues && this.jsonGraph.CustomizationValues.ForbiddenInteractionWarning)) {
            window.sdk.forbiddenInteractionWarning().init(
                document.querySelector('.exercise__main'),
                {
                    exerciseUI: this,
                    distanceMax: 50, 
                    timeMax : 500,
                    calibrationPhaseDuration: 1000,
                    detectionWindowDuration: 1000, 
                    soundVariationAllowed: 0.05,
                    timeResumeAfterWarning: 15000,
                    allowedClickZones :[
                        '#header',
                        '#secondary',
                        '.debug',
                        '.exercise-bottom-bar'
                    ]
                }
            );
        }

        this.setState({
            exerciseStarted: true
        });
    }


    // --------
    OnNewParticipantVideo(iVideoSlot) {
        log.debug(">>> Registering new VideoSlot");
        this.ParticipantsVideos.push(iVideoSlot);
        this.forceUpdate();
    }


    // --------
    async Start() {
        ////////////////////////////////////////////////////////////////////////////////
        // Start initializations
        log.debug("+++ Exercise Start +++");
        document.body.classList.add('exercise--start');

        // Instantiate singletons
        //ParticipantsModule.Instance.OnNewParticipant += this.OnNewParticipantVideo;

        // Start the exercise graph
        await this.ExerciseGraph.Start();

        // TODO: finish tester and start it elsewhere
        // Start the exercise graph tester
        //this.ExerciseGraphTester = new ExerciseGraphTester(this.ExerciseGraph);
        //await this.ExerciseGraphTester.Start();
    }


    // --------
    Stop(iShowExitedModal) {
        if(this.Stopping) return;
        
        this.Stopping = true;

        log.debug("+++ Exercise Stop +++");
        document.body.classList.remove('exercise--start');
        if (this.exerciseCompleted) {
            return;
        }
        if (this.ExerciseGraph) {
            // Log event to database
            this.ExerciseGraph.History.AddEvent("StopBeforeEnd", {});
            this.ExerciseGraph.Stop(true);
        }
        if (iShowExitedModal && window.sdk.isInIframe()) {
            window.sdk.event().emit('showExitedModal ',
            {
                ExitMessage: this.jsonGraph.CustomizationValues.ExitedMidExercise_Text,
                ExitedButton_Visible: this.jsonGraph.CustomizationValues.ExitedMidExerciseButton_Visible === 'true',
                ExitedButton_Text: this.jsonGraph.CustomizationValues.ExitedMidExerciseButton_Text
            });
        } else {
            this.props.navigate('/')
            //document.location.href = '/';
        }
    }
    
	async Restart() {
        if (this.ExerciseGraph) {
            // Log event to database
            this.ExerciseGraph.History.AddEvent("Restart", {});

            this.ExerciseGraph.Stop(true);
            log.debug('ExerciseGraph stopped')
        }
		
		window.sdk.refreshingExercise = true;
		
        this.Stopping = true;
        
		this.props.navigate('/', { replace: false });
		
		await Utils.Sleep(300);
		
        this.props.navigate('/exercise/' + this.exerciseID, { replace: false });
        this.setState({ step: "initialization" });
		
		window.sdk.refreshingExercise = false;
    }

    // --------
    LoadExerciseGraph() {

        if (this.jsonGraph === undefined) {
            log.debug("LoadExerciseGraph error: json graph not downloaded yet!")
            return undefined;
        }
        return new ExerciseGraph(this.jsonGraph, this);
    }


    // --------
    // Setters Getters
    SetSpeechFeedbackText(iText) {
        if (iText != "") {
            this.setState({ speechFeedbackText: "\"" + iText + "\"" });
        } else {
            this.setState({ speechFeedbackText: "" });
        }
    }

    ShowRaisehandButton(iShow) {
        this.setState({ showRaisehandButton: iShow });
    }

    ShowActionButton(iShow) {
        this.setState({ showActionButton: iShow });
    }

    ShowGoToButton(iShow) {
        this.setState({ showGoToButton: iShow });
    }

    RegisterRaisehandCallbacks(iRegisterButtonCallback, iOnClickedCallback) {
        this.setState({
            RegisterRaisehandButtonCallback: iRegisterButtonCallback,
            RaisehandCallback: iOnClickedCallback
        });
    }

    RegisterActionButtonCallbacks(iActionButtonName, iRegisterActionButtonCallback, iOnClickedCallback) {
        log.debug("RegisterActionButtonCallbacks: iActionButtonName = " + iActionButtonName + ", iRegisterActionButtonCallback = " + iRegisterActionButtonCallback + ", iOnClickedCallback = " + iOnClickedCallback); 
        this.setState({
            ActionButtonName: iActionButtonName,
            RegisterActionButtonCallback: iRegisterActionButtonCallback,
            ActionButtonCallback: iOnClickedCallback
        });
    }

    RegisterGoToButtonCallbacks(iGoToButtonName, iRegisterGoToButtonCallback, iOnClickedCallback) {
        this.setState({
            GoToButtonName: iGoToButtonName,
            RegisterGoToButtonCallback: iRegisterGoToButtonCallback,
            GoToButtonCallback: iOnClickedCallback
        });
    }


    ////////////////////////////////////////////////////////////////////////////////
    // Rendering
    ////////////////////////////////////////////////////////////////////////////////
    renderNeedInteraction() {
        return (
            <div className="exercise-interation exercise__inner">
                <div className="exercise__content"></div>
            </div>
        );
    }

    onButtonToShow = (changeBrowser, camUsed, showPopinInfo, showPopinRefuse, waitAuthorization) => {
        this.setState({
            getUserMediaState: {
                changeBrowser, camUsed, showPopinInfo, showPopinRefuse, waitAuthorization
            }
        });
    }

    onShowLoaderWaitWebcam = (value) => {
        this.setState({
            onShowLoaderWaitWebcam: value
        });
    }


    // --------
    // Rendu de la page demande d'autorisations
    renderGetUserMedia() {

        //this.playSound();
        return (
            <div className={`exercise-presentation exercise__inner`}>
                <div className="exercise__content">
                    <Row style={{ alignItems: 'center', height: '100%' }}>
                        <Col>
                            {this.state.onShowLoaderWaitWebcam &&
                                <div className="loader-wrapper">
                                    <div className="spinner-loader"></div>
                                </div>
                            }
                            <GetUserMedia showLoader={this.onShowLoaderWaitWebcam} onButtonToShow={this.onButtonToShow} onStreamReady={this.OnStreamReady} />
                        </Col>
                        {/*<Col xs={2} sm={4}>
                            <div className={'participantVideoSlot presentation__video participant--not-connected'}>
                                <div className={'participant__inner'}>
                                    <div className={'participant__info'}>
                                        <div className={'participant__username'}>Vous</div>
                                    </div>
                                    <div className="participant__video">
                                        <div className={'video_layer'}>
                                            <i className="picto-connecting1"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>*/}
                    </Row>
                </div>
            </div>
        );
    }

    renderCamMicSetup() {
        return (
            <div className={`exercise-presentation exercise-presentation--2 exercise__inner`}>
                <div className="exercise__content">
                    <Container style={{ height: '100%' }}>
                        <Row style={{ alignItems: 'center', height: '100%' }}>
                            <Col xs={12} sm={6}>
                                <Alert variant={'danger'} className={'device-alert mb-3'}>
                                    ⚠ Assurons-nous que votre micro fonctionne bien.
                                </Alert> 
                                <ol className='refuse__list'>
                                    <li>En bas à droite, choisissez votre micro</li>
                                    <li>Prononcez une phrase</li>
                                    <li>Vérifiez que la jauge réagisse bien à votre voix</li>
                                    <li>Si la jauge ne réagit pas :
                                        <ul>
                                            <li>Assurez-vous que votre micro n’est pas coupé</li>
                                            <li>Vérifiez que le bon micro est sélectionné</li>
                                            <li>Contactez le support via le menu en haut à gauche</li>
                                        </ul>
                                    </li>
                                </ol>
                                {/*<div style={{
                                    fontSize: "calc(10px + 1vmin)", 
                                    marginTop: "5vmin", 
                                    marginBottom: "5vmin", 
                                    display: "flex", 
                                    flexDirection: "row wrap",}}>
                                    <input type="checkbox" id="micOkCheckbox" onChange={this.handleMicIsOkChange} style={{width: "1rem", height: "1rem", marginLeft: "0.5rem", marginTop: "0.5vmin"}}/>
                                    <label htmlFor="micOkCheckbox" style={{
                                        whiteSpace: "normal",
                                        wordWrap: "break-word",
                                        marginLeft: "0.5rem",
                                        textShadow: "0 0 5px #bd22ff, 0 0 5px #bd22ff, 0 0 5px #bd22ff",
                                }}>Je confirme que la jauge réagit bien à ma voix</label>
                            </div>*/}
                            </Col>
                            <Col xs={6} sm={5}>
                                <div className={'participantVideoSlot presentation__video'}>
                                    <div className={'participant__inner'}>
                                        <div className={'participant__info'}>
                                            <div className={'participant__username'}>Vous</div>
                                        </div>
                                        <div className="participant__video">
                                            <BriefingUserWebcam />
                                        </div>
                                    </div>
                                    <Settings />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }

    // --------
    renderBriefing() {
        const { Briefing } = this.jsonGraph;
        const { currentBriefingIndex } = this.state;
        const currBrief = Briefing[currentBriefingIndex];
        return (
            <div className={`exercise-briefing exercise__inner exercise-briefing--${currentBriefingIndex}`}>
                <div className="exercise__content">
                    <div className="briefing__inner">
                        {currBrief.video &&
                            <Row style={{ justifyContent: 'center' }}>
                                <Col xs={'auto'}>
                                    <div className="briefing__participant">
                                        {(currBrief.video.type === "local") && <BriefingUserWebcam />}
                                        {(currBrief.video.type === "bot") && <BriefingVideoBot source={currBrief.video.source} />}
                                    </div>
                                </Col>
                            </Row>}

                        <Row style={{ alignItems: 'center' }}>
                            <Col xs={12} sm={6}>
                                <div className="briefing__speaker">
                                    <div className="briefing__speaker__inner">
                                        <div className="briefing__speaker__video">
                                            {(currBrief.presenterBot.type === "bot") && <BriefingVideoPresenter source={currBrief.presenterBot.source} onEnded={this.nextBriefingSlide} />}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} sm={6}>
                                <div className="briefing__desc">
                                    <div className="briefing__icon">
                                        <FontAwesomeIcon icon={faMasksTheater} />
                                    </div>
                                    <h3 className="briefing__title">
                                        {currBrief.title}
                                    </h3>
                                    <p className="briefing__subtitle">
                                        {currBrief.subtitle}
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }

    renderMicValidation() {
        return(
        <div className={`exercise-presentation exercise-presentation--2 exercise__inner`}>
            <div className="exercise__content">
                <Container style={{ height: '100%' }}>
                    <Row style={{ alignItems: 'center', height: '100%',justifyContent: 'center' }}>
                        <Col xs={10} sm={10}>
                            <div className="mic-validation-container">
                                <h2 className="mic-validation-instruction" id="mic-validation-instruction">Quand vous êtes prêt.e, dites "C'est parti !"</h2>
                                <div className="mic-validation-back">{this.tryStart == 1 ? 'Rien ne se passe ?' : 'Toujours rien ?'} </div>
                                <div className="mic-validation-back-2">
                                {this.tryStart == 1 ? 
                                   <React.Fragment>
                                     Reconfigurez votre micro et votre caméra en <span onClick={this.backToCamMicSetup}>cliquant ici</span>
                                   </React.Fragment>
                                :
                                    <React.Fragment>
                                      Reconfigurez votre micro en <span onClick={this.backToCamMicSetup}>cliquant ici</span> ou contactez le support à l'aide du widget en bas à droite
                                   </React.Fragment>
                                }
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
        );
    }


    // --------
    renderScenario() {

        // If exercise is a Pitch Test, display the Pitch Test component
        // check if this.jsonGraph.ID contains "Pitch"
        if (typeof this.jsonGraph.ID === 'string') {
            if (this.jsonGraph.ID.includes("Pitch")) {
                return (<PitchExercisePanel />);
            }
            if (this.jsonGraph.ID.includes("BuildYourself")) {
                return (<BuildYourselfExercisePanel />);
            }
        }

        // If caching the videos, display a wainting message
        if (this.state.cache.totalProgression < 100) {

            let warningIcon = require('../../assets/icons/warning.png');

            return (
                <div className="exercise-situation exercise__inner exercise-preload" onKeyDown={this.handleKeyDown} tabIndex="0">
                    {this.state.cache.needToWaitCompleted &&
                        <p><img src={warningIcon} width="64" height="64" /> Votre connexion internet semble très faible, il est possible que votre expérience soit dégradée.</p>
                    }
                    <p>Merci de patienter le temps que nous chargions les données de cet exercice...</p>
                    <ProgressBar animated now={this.state.cache.totalProgression} variant="warning" label={`${this.state.cache.totalProgression}%`} />
                </div>
            );
        }

        // If exercise started, display the scenario
        if (this.state.exerciseStarted) {
            // Add all bots video slots to participants videos list
            let participantsVideos = ParticipantsModule.Instance.m_Bots.map((participant, i) =>
            (<div className={'exercise__video not-init'} key={participant.ID}>
                <Bot bot={participant} />
            </div>)
            );
            // Add the human video slot to participants videos list
            ParticipantsModule.Instance.m_Humans.map((participant, i) =>
                participantsVideos.push(
                    <div key={participant.ID} className={'exercise__video not-init'}><LocalUser human={participant} /></div>)
            );

            return (
                <div className="exercise-situation exercise__inner">
                    <div className="exercise__content" ref={ref => { if (ref) { this.containerObs.observe(ref); } this.exercisesContainer = ref; }}>
                        {participantsVideos}
                    </div>
                </div>
            );
        }

        // TODO: If not caching the videos and not started, display a waiting message ?
        return null;
    }

    renderThanks() {
        return <div className="exercise-situation exercise__inner">Merci, au revoir</div>;
    }

    renderError() {
        return <div className="exercise-situation exercise__inner exercise_error_message">{this.state.failMessage}</div>;
    }

    renderGetUserMediaButton() {
        if (this.state.getUserMediaState.camUsed)
            return <Button size={'sm'} variant="white" onClick={el => { window.location.reload() }}>Rafraichir</Button>

        if (this.state.getUserMediaState.showPopinInfo)
            return <Button variant={'primary'} onClick={() => { window.sdk.event().emit('getUserMediaAsk') }}>Demandez l'autorisation</Button>

        if (this.state.getUserMediaState.showPopinRefuse)
            return (
                <React.Fragment>
                    {(isSafari || isAndroid) &&
                        <Button variant={'primary'} onClick={el => { window.location.reload() }}>Rééssayer</Button>
                    }
                    {!isSafari && !isAndroid && this.state.getUserMediaState.waitAuthorization &&
                        <Button variant={'primary'} onClick={() => { window.sdk.event().emit('getUserMediaAsk') }}>Vérifiez l'autorisation</Button>
                    }
                    {!this.state.getUserMediaState.waitAuthorization &&
                        <Button variant={'primary'} onClick={() => { window.sdk.event().emit('getUserMediaAsk2') }}>Demandez l'autorisation</Button>
                    }
                </React.Fragment>
            );
    }

    pauseExercise = () => {
        window.sdk.event().emit('pauseExercise')
        window.sdk.event().emit('pause',true)
        //this.ExerciseGraph.Pause()
    }

    setDebugInfo(iID, iValue) {

        if (this.state.debugInfo[iID] !== iValue) {
            this.setState({
                debugInfo: {
                    ...this.state.debugInfo,
                    [iID]: iValue
                }
            })
        }

        // Write state debug values
        if(window.testMode.fillAppStateValues)
            window.testMode.appStateValues[iID] = iValue;
        log.debug(" window.testMode.appStateValues",  window.testMode.appStateValues);
    }

    hideCam = () => {

        // Log to database
        if(this.ExerciseGraph)
            this.ExerciseGraph.History.AddEvent("HideCam", {State: this.state.hide ? "Visible" : "Hidden"});

        // Apply the change
        this.setState({
            hide: !this.state.hide
        })
        window.sdk.videoconf().toggleWebcamHide();
    }

    // Debug
    ForceTTS = () => {
        //window.sdk.event().emit('forceTTS')
    }

    ForceUserActionsPopup() {
        window.sdk.event().emit('showStrategicUserActions',/*this.ExerciseGraph.availableUserActions*/
        JSON.parse( '[{"ID": "Encouragement Jeanne","Type": "Encouragements","Strategic": true,"DisplayedName": "Encouragements"},{"ID": "Reformulation Clara","Type": "Reformulation","Strategic": true,"DisplayedName": "Questionnement"}]'));
    }

    // --------
    render() {
        let content = null;
        switch (this.state.step) {
            case "initialization":
                content = this.renderNeedInteraction();
                break;
            case "waitWebcam":
                content = this.renderGetUserMedia();
                break;
            case "camMicSetup":
                content = this.renderCamMicSetup();
                break;
            case "micValidation":
                content = this.renderMicValidation();
                break;
            case "briefing":
                content = this.renderBriefing();
                break;
            case "scenario":
                content = this.renderScenario();
                break;
            case "thanks":
                content = this.renderThanks();
                break;
            case "fail":
                content = this.renderError();
                break;
        }

        return (
            <div className="exercise">
                <div className="exercise__main">
                    <Container fluid className="exercise__container">
                        <StrategicActions />
                        {content}
                    </Container>

                    {   // Debug panel: Controls
                        this.ExerciseGraph &&
                        <div className={'debug debug_graph_controls'}>
                            <div style={{ backgroundColor: 'rgba(80, 50, 80, 0.5)', padding: '10px' }}>
                                <Row style={{ justifyContent: 'center' }}>
                                    <Col xs={'auto'}>
                                        <h3>User:</h3>
                                    </Col>
                                    <Col xs={'auto'}>
                                        <div>
                                            {"UserID: " + window.sdk.user().userID}<br />
                                            {"Name: " + window.sdk.user().firstName + " " + window.sdk.user().lastName}<br />
                                            {"Email: " + window.sdk.user().email}<br />
                                            {"Entity: " + window.sdk.user().entity}<br />
                                            {"Role: " + window.sdk.user().role}<br />
                                            {"AvailableExercises: " + ((!window.sdk.user().availableExercises || window.sdk.user().availableExercises == "") ? "all" : window.sdk.user().availableExercises)}<br />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div style={{ height: '10px' }}></div>
                            <h3>Debug Parameters</h3>
                            {typeof this.jsonGraph.ID === 'string' && (this.jsonGraph.ID.includes("Pitch") || this.jsonGraph.ID.includes("Build")) &&
                                <div>
                                    <input
                                        type="checkbox"
                                        id="tts"
                                        name="tts"
                                        value="tts"
                                        onChange={this.ForceTTS}
                                        checked={this.state.debug.forceTTS}
                                    />
                                    <label htmlFor="tts">Force text-to-speech</label>
                                </div>
                            }
                            <h3>LogLevel</h3>
                                <div>
                                    <select name="log-level" id="log-level" value={log.getLevel()} onChange={(e) => log.setLevel(parseInt(e.target.value))} onBlur={(e) => this.setState({ logLevel: e.target.value })}>
                                        <option value="0">0 - Trace</option>
                                        <option value="1">1 - Debug</option>
                                        <option value="2">2- Info</option>
                                        <option value="3">3 - Warn</option>
                                        <option value="4">4 - Error</option>
                                    </select>
                                </div>
                            <h3>Debug Controls</h3>
                            <Button id='debug-skip-button' style={{ fontSize: 'small', padding: '4px 8px' }} onClick={this.ExerciseGraph.Skip}>Skip scenario step</Button>
                            <br /><br />
                            <Button id='debug-force-actions-popups' style={{ fontSize: 'small', padding: '4px 8px' }} onClick={this.ForceUserActionsPopup}>Force pop user actions</Button>
                            <p></p>
                            <h3>Active Graph Nodes</h3>
                            <p dangerouslySetInnerHTML={{ __html: this.ExerciseGraph.GetActiveNodesText() }} />
                            <h3>Forbidden actions detectors</h3>
                            <h4>Microphone detector:</h4>
                            <p>State:         {this.state.debugInfo["forbiddenInteractionWarning.microphoneState"]} </p>
                            <p>Current level: {this.state.debugInfo["forbiddenInteractionWarning.microphoneSoundLevel"]} </p>
                            <p>Treshold:      {this.state.debugInfo["forbiddenInteractionWarning.microphoneTreshold"]} </p>
                        </div>
                    }
                    {   // Debug panel: Videos cache progression
                        this.state.cache &&
                        <div className={'debug debug_cache_progression'}>
                            <p>Current file: {this.state.cache.currentFile}</p>
                            <p>Current file progression: {this.state.cache.currentFileProgression} %</p>
                            <p>Total progression: {this.state.cache.totalProgression} %</p>
                            <p>Average BW: {this.state.averageBW} Mb/s</p>
                        </div>
                    }
                </div>

                {this.state.buffering &&
                    <p style={{ postion: 'absolute', backgroundColor: 'red', top: '0px' }}>BUFFERING</p>
                }


                <BottomBar ExerciseGraph={this.ExerciseGraph} pauseExercise={this.pauseExercise} played={this.state.played} speechFeedback={this.state.step === "scenario" && this.state.speechFeedbackText ? this.state.speechFeedbackText : ''} step={this.state.step} pauseButtonDisabled={this.state.pauseButtonDisabled} hideCam={this.hideCam} hide={this.state.hide}>
                    {this.state.step === "initialization" && (
                        <Button id='initialization-continue-button' size={'sm'} variant="white" onClick={this.iHaveInteracted}>Cliquer ici pour continuer</Button>
                    )}
                    {/*<Button id='camMicSetup-join-button' size={'sm'} variant="white" onClick={this.startBriefing} disabled={!this.state.micIsOk}>Rejoindre</Button>*/}
                    {this.state.step === "camMicSetup" && (
                        <Button id='camMicSetup-join-button' size={'sm'} variant="white" onClick={this.micValidation}>Suivant</Button>
                    )}
                    {this.state.step === "briefing" && (
                        <Button id='briefing-next-button' size={'sm'} variant="white" onClick={this.nextBriefingSlide}>Continuer</Button>
                    )}
                    {this.state.step === "scenario" && (
                        <React.Fragment>

                            {(this.state.humanParticipantSpeaking === 'no' && this.state.showRaisehandButton) && <RaiseHandButton registerButton={this.state.RegisterRaisehandButtonCallback} onClick={this.state.RaisehandCallback} />}
                            {(this.state.humanParticipantSpeaking === 'no' && this.state.showActionButton) && <ActionButton label={this.state.ActionButtonName} registerButton={this.state.RegisterActionButtonCallback} onClick={this.state.ActionButtonCallback} />}
                            {(this.state.humanParticipantSpeaking === 'no' && this.state.showGoToButton) && <ActionButton label={this.state.GoToButtonName} registerButton={this.state.RegisterGoToButtonCallback} onClick={this.state.GoToButtonCallback} />}

                            {this.state.humanParticipantSpeaking === 'raising' && <BottomButton variant="raising" waiting={true}>Patientez</BottomButton>}

                            {this.state.humanParticipantSpeaking === 'speaking' && <BottomButton variant="speaking">Vous parlez</BottomButton>}

                            {this.state.humanParticipantSpeaking === 'readyforSpeaking' && <BottomButton variant="speaking" waiting={true}>A vous de parler</BottomButton>}
                        </React.Fragment>
                    )}

                    {this.state.step === "waitWebcam" && (
                        <React.Fragment>
                            {this.renderGetUserMediaButton()}
                        </React.Fragment>
                    )}
                    
                    {/* <BottomButton variant="white" waiting text="Patientez" />
                    <BottomButton variant="raising" text="Lever la main" />
                    <BottomButton variant="raising" waiting text="Patientez" />
                    <BottomButton variant="speaking" waiting text="A vous de parler" />
                    <BottomButton variant="speaking" text="Vous parler" />
                    <BottomButton variant="hang-up" text="Raccrocher" /> */}
                </BottomBar>


                <div className="version">PractiVizio {window.infoVersion.version}</div>
            </div>
        );
    }

    //////
    // Error handling

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        log.debug("Exercise.js error: ", error, errorInfo);
        //logErrorToMyService(error, errorInfo);
    }
}

export default withRouter(Exercise);

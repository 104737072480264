import log from "loglevel";
import Utils from '../../Utils/Utils';
import ExerciseNode from './ExerciseNode';
import NodePort from '../NodePort';
import ParticipantsModule from '../../Participants/ParticipantsModule';

export default class BotVideo extends ExerciseNode 
{
    // Ports
    Input = new NodePort("Input", "input", this);
    Output = new NodePort("Output", "output", this);
    TriggerTimeReached = new NodePort("TriggerTimeReached", "output", this);

    // Parameters
    BotName = "";
    VideoName = "";
    PreventSpeakingFrame = false;
    TriggerTime = "";

    // Internal values
    Bot = null;


    constructor(iGraph, iProperties)
    {
        super(iGraph, iProperties);
 
        this.BotName = iProperties.BotName;
        this.VideoName = iProperties.Video;
        this.PreventSpeakingFrame = iProperties.PreventSpeakingFrame;
        this.TriggerTime = iProperties.TriggerTime;

        //log.debug(this.GetIdentity() + " constructor: graph = " + this.Graph.ExerciseName + ", id = " + this.ID + ", botName = " + this.BotName + ", videoName = " + this.VideoName + ", preventSpeakingFrame = " + this.PreventSpeakingFrame + ", triggerTime = " + this.TriggerTime); 
    }

    OnActivated(iActivator, iInputPort)
    {
        log.debug(this.GetIdentity() + "' has been activated by '" + iActivator.GetIdentity() + "'.");
        log.debug(this.GetIdentity() + " Bot '" + this.BotName + "' will play the video '" + this.VideoName + "'...");
        super.OnActivated(iActivator, iInputPort);

        // Get the bot if not already done
        if(this.Bot == null)
        {
            this.Bot = ParticipantsModule.Instance.GetBot(this.BotName);			
        }				

        if(this.Bot != null)
        {
            // Display automatically the speaking frame if allowed
            if(!this.PreventSpeakingFrame)
            {
                this.Bot.setSpeakingState("speaking");
            }
            
            // Play the video
			this.Bot.setConnectionState('connected');
            this.Bot.PlayVideo(this.VideoName, {
                triggerTime: Utils.StringVideoTimeToFloatSeconds(this.TriggerTime),
                onTimeTriggered: () => {
                    this.OnTriggerTimeReached();
                },
                onEnded: () => {
                    this.OnActionFinished();
                }
            });

            // Log action to history
            this.Graph.History.AddBotSpeech(this.ID, this.BotName, this.VideoName);
        }
        else
        {
            log.debug("BotVideo: Bot '" + this.BotName + "' not found!");
        }
    }
	
	Pause() {
		if(this.Bot != null && this.m_IsActive)
			this.Bot.Pause();
	}
	
	Resume() {
		if(this.Bot != null && this.m_IsActive && this.Graph.IsRunning())
			this.Bot.Resume();
	}

    FreezeSystem() {
        if(this.Bot != null && this.m_IsActive)
            this.Bot.Pause();
    }

    UnfreezeSystem() {
		if(this.Bot != null && this.m_IsActive && this.Graph.IsRunning())
            this.Bot.Resume();
    }

    Skip()
    {
        if(!this.m_IsActive)
        {        
            return;
        }

        log.debug(this.GetIdentity() + " Bot '" + this.BotName + "' skip.");
        
        // Make the video bot stop current action and return to wait loop
        this.Bot.ReturnToWaitLoop();

        // Activate Outputs and stop the node
        this.SetActive(false);
        this.TriggerTimeReached.ActivateAllConnections();
        this.Output.ActivateAllConnections();
    }

    OnTriggerTimeReached()
    {
        if(!this.m_IsActive)
        {        
            log.debug(this.GetIdentity() + " Bot '" + this.BotName + "' trigger time reached, but ignored since not active.");
            return;
        }

        log.debug(this.GetIdentity() + " Bot '" + this.BotName + "' trigger time reached.");

        // Hide automatically the speaking frame if allowed
        if(!this.PreventSpeakingFrame && this.Bot != null)
        {
            this.Bot.setSpeakingState("no");
        }
        
        // Activate Output
        log.debug(this.GetIdentity() + "' activating TriggerTimeReached output.");

        this.SetActive(false);

        this.TriggerTimeReached.ActivateAllConnections();
    }

    OnActionFinished()
    {
        if(!this.m_IsActive)
        {        
            log.debug(this.GetIdentity() + " Bot '" + this.BotName + "' finished its video play action, but ignored since not active.");
            return;
        }

        log.debug(this.GetIdentity() + " Bot '" + this.BotName + "' finished its video play action.");

        // Hide automatically the speaking frame if allowed
        if(!this.PreventSpeakingFrame && this.Bot != null)
        {
            this.Bot.setSpeakingState("no");
        }
        
        // Activate Output
        log.debug(this.GetIdentity() + "' activating output.");

        this.SetActive(false);

        this.Output.ActivateAllConnections();
    }
    

    GetDetailedIdentity()
    {
        return super.GetDetailedIdentity() + ":" + this.BotName + " - " + this.VideoName;
    }

    PrintParameters()
    {
        //log.debug("BotName = " + this.BotName + ", VideoName = " + this.VideoName);
    }
}
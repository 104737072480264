import log from "loglevel";
export default class SemanticSearchAPI {

    constructor(sdk) {
        this.sdk = sdk;
    }
    
    async Initialize() {
        
    }

    async Ask(iSpeech, iExerciseID, iBranchingDecisionNodeID, iTargetBot) {
        
        //log.debug("SemanticSearchAPI Ask: iSpeech = " + iSpeech + ", iExerciseID = " + iExerciseID + ", iBranchingDecisionNodeID = " + iBranchingDecisionNodeID + ", iTargetBot = " + iTargetBot + ".");
        let result = await this.sdk.fetch().post('/semanticsearchapi',  { 
		    body: {
                input: iSpeech,
                ExerciseID: iExerciseID,
                BDNodeID: iBranchingDecisionNodeID,
                BotName: iTargetBot
            }
		});        
        
        //log.debug("SemanticSearchAPI Ask result: ", result);


        return result;
    }
  }
  
import log from "loglevel";
import Utils from "../../AppClasses/Utils/Utils";

export default class ExerciseSession {
    
    UniqueIDIncrementer = 0;

    constructor(sdk) {
        this.sdk = sdk;
    }
    
    async init() {
        
    }
    
    // Exercise session
    async createOne(iAppVersion, iExerciseID, iExerciseVersion, iUserID, iStartTime) {

        const startTime = iStartTime.toISOString();

        let body = {
            ExerciseID: iExerciseID,
            ID: Utils.CreateObjectID(iStartTime, window.sdk.user().userID),
            AppVersion: iAppVersion,
            ExerciseVersion: iExerciseVersion,
            UserID: iUserID,
            StartTime: startTime, // Database format: 2022-10-14T00:09:48.875,
            Month: startTime.substring(0, 7), // Format: YYY-MM
            ActivatedBranchingDecisionsCount: "0"
        };

        let res = await this.sdk.fetch().post('/exercisesession', { body });                

        return res;
    }
    
    async updateItem(iID, iExerciseID, iValuesToUpdate) {

        let body = {
            ID: iID,
            ExerciseID: iExerciseID,
            Values: iValuesToUpdate
        };

        let res = await this.sdk.fetch().post('/exercisesession/update', { body });

        return res;
    }   

    async getOne(exerciseSessionID) {
        let res = await this.sdk.fetch().get('/exercisesession/' + exerciseSessionID);
        return res;
    }    
    
    async getOneWithExercise(exerciseID, exerciseSessionID) {
        let res = await this.sdk.fetch().get('/exercisesession/' + exerciseID +'/'+ exerciseSessionID);
        return res;
    } 
    
    // Reviews
    async saveReviews(userID,ExerciseSessionID,reviews){
        let body = {
            //UserID : userID, not secure to get userID from front
            ExerciseSessionID : ExerciseSessionID,
            reviews: []
        };
        let StartTime;
        log.debug(reviews);
        StartTime = new Date;
        reviews.forEach(review => {
            StartTime.setSeconds(StartTime.getSeconds() + 1);
            body.reviews.push({
                ID : Utils.CreateObjectID(StartTime, window.sdk.user().userID),
                Name : review.ID,
                Type : review.Type,
                Date : StartTime,
                Value: review.value
            }); 
        });
        let res = await this.sdk.fetch().post('/exercisesession/'+ ExerciseSessionID +'/reviews', { body });
        return res;
    }

    async saveReview(ExerciseSessionID,StartTime,reviewName,reviewType,reviewValue){
        let body = {
            //UserID : window.sdk.user().userID, => not secure to get userID from front
            ExerciseSessionID : ExerciseSessionID,
            ID : Utils.CreateObjectID(StartTime, window.sdk.user().userID),
            Name : reviewName,
            Type : reviewType,
            Date : StartTime,
            Value: reviewValue
        };
        let res = await this.sdk.fetch().post('/exercisesession/'+ ExerciseSessionID +'/review', { body });
        return res;
    }

    async getReviews(exerciseID, exerciseSessionID) {
        let res = await this.sdk.fetch().get('/exercisesession/' + exerciseID +'/'+ exerciseSessionID +'/reviews');
        let reviews = [];
        res.forEach(item =>{
            reviews.push({
                Date:item.Date ? item.Date.S : '',
                ExerciseSessionID:item.ExerciseSessionID ? item.ExerciseSessionID.S : '',
                ID:item.ID ? item.ID.S : '',
                Name:item.Name ? item.Name.S : '',
                Type:item.Type ? item.Type.S : '',
                UserID:item.UserID ? item.UserID.S : '',
                Value:item.Value ? item.Value.S : ''

            })
        }) 
        return reviews;
    } 

    // Achievements
    async getAchievements(currentExerciseSessionID){
        return  JSON.parse(window.localStorage.getItem('achievements-session-'+currentExerciseSessionID))
    }   

    // History
    async addEventToHistory(iEvent) {
        if(!iEvent.ExerciseSessionID)
         return;
        let event = {...iEvent};/*{
            EventID: Utils.CreateObjectIDWithIncrement(new Date(), window.sdk.user().userID, (this.UniqueIDIncrementer++)),
            ExerciseSessionID: iExerciseSessionID,
            UserID: window.sdk.user().userID,
            EventType: iEvent.EventType,
            Date: iEvent.Date,
            Content: JSON.stringify(iEvent.Content)
        };*/
        event.Content = JSON.stringify(event.Content);
        let res = await this.sdk.fetch().post('/exercisesessionhistory/' + event.ExerciseSessionID, { body: event });
        return res;
    }
    
    async getExerciseSessionHistory(iExerciseSessionID) {
        
        let res = await this.sdk.fetch().get('/exercisesessionhistory/' + iExerciseSessionID);
        
        let exerciseSessionHistory = [];
        res.forEach(item =>{
            exerciseSessionHistory.push({    
                ExerciseSessionID: item.ExerciseSessionID ? item.ExerciseSessionID.S : '',
                EventID: item.EventID ? item.EventID.S : '',
                UserID: item.UserID ? item.UserID.S : '', 
                EventType: item.EventType ? item.EventType.S : '',
                Date: item.Date ? item.Date.S : '',
                Content: item.Content ? JSON.parse(item.Content.S) : {}
            })
        });

        //log.debug("exerciseSessionHistory result:", exerciseSessionHistory);

        return exerciseSessionHistory;
    } 
}
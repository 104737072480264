import React from 'react';
import { isMobile, browserName } from 'react-device-detect';

// import android from '../../assets/images/device/android.png';
import chrome from '../../assets/images/device/chrome.png';
import edge from '../../assets/images/device/edge.png';
import firefox from '../../assets/images/device/firefox.png';
import safari from '../../assets/images/device/safari.png';


export default class Ask extends React.PureComponent {
    
    renderChrome() {
        return <img src={chrome} alt={'chrome'} className='tutorial-image img-fluid'></img>;
    }
    
    renderEdge() {
        return <img src={edge} alt={'edge'} className='tutorial-image img-fluid'></img>;
    }
    
    renderFirefox() {
        return <img src={firefox} alt={'firefox'} className='tutorial-image img-fluid'></img>;
    }
    
    renderSafari() {
        return <img src={safari} alt={'safari'} className='tutorial-image img-fluid'></img>;
    }
        
    render() {
		
		let renderName = 'render' + browserName;
		let renderFct;
		if(!this[renderName])
            renderFct = this['renderChrome']
		else 
			renderFct = this[renderName]
		
        if (isMobile)
            return ( 
                <React.Fragment>
            
                </React.Fragment>
            );
        return ( 
            <React.Fragment>
                { renderFct() }
            </React.Fragment>
        );
    }
}

import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import BootTooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import { LinkContainer } from 'react-router-bootstrap';

const Tooltip = ({ children, placement, title, ...props }) => (
  <OverlayTrigger
    placement={placement}
    overlay={
      <BootTooltip id={`tooltip-${Math.random(0, 999999)}`}>
        {title}
      </BootTooltip>
    }
    {...props}
  >
    {children}
  </OverlayTrigger>
);

const TooltipLink = React.forwardRef(({ to, children, ...props }, ref) => (
  <LinkContainer to={to}>
    <Button ref={ref} {...props}>
      {children}
    </Button>
  </LinkContainer>
));

export { Tooltip, TooltipLink };
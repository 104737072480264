/* global */
import React from 'react';
import { isMobile, browserName } from 'react-device-detect';


import chromeStep1 from '../../assets/images/device/chrome-refuse/step-1.png';
import chromeStep2 from '../../assets/images/device/chrome-refuse/step-2.png';

import firefoxStep1 from '../../assets/images/device/firefox-refuse/step-1.png';
import firefoxStep2 from '../../assets/images/device/firefox-refuse/step-2.png';


export default class Refuse extends React.PureComponent {
        
    renderChrome() {
        return  (
            <ol className='refuse__list'>
                <li class="list-item one">
                    <span className="refuse__instruction">Cliquez sur l'icône caméra à droite de la barre d'adresse URL.</span>
                    <img alt="chrome-step1" src={chromeStep1} className={'refuse__image img-fluid'} />
                </li>
                <li class="list-item two">
                    <span className="refuse__instruction">Selectionnez Toujours autoriser (le premier choix), et validez.</span>
                    <img alt="chrome-step2" src={chromeStep2} className={'refuse__image img-fluid'} />
                </li>
                <li class="list-item three">
                    <span className="refuse__instruction">Cliquez sur le boutton ci-dessous.</span>
                </li>
            </ol>
        );
    }
    
    renderEdge() {
        return  (
            <ol className='refuse__list'>
                <li class="list-item one">
                    <span className="refuse__instruction">Cliquez sur l'icône caméra à droite de la barre d'adresse URL.</span>
                    <img alt="chrome-step1" src={chromeStep1} className={'refuse__image img-fluid'} />
                </li>
                <li class="list-item two">
                    <span className="refuse__instruction">Selectionnez Toujours autoriser (le premier choix), et validez.</span>
                    <img alt="chrome-step2" src={chromeStep2} className={'refuse__image img-fluid'} />
                </li>
                <li class="list-item three">
                    <span className="refuse__instruction">Cliquez sur le boutton ci-dessous.</span>
                </li>
            </ol>
        );
    }
    
    renderFirefox() {
        return (
            <React.Fragment>
                <ol className='refuse__list'>
                    <li class="list-item one">
                        <span className="refuse__instruction">Cliquez sur l'icône caméra à gauche de la barre d'adresse URL.</span>
                        <img alt="chrome-step1" src={firefoxStep1} className={'refuse__image img-fluid'} />
                    </li>
                    <li class="list-item two">
                        <span className="refuse__instruction">Cliquez sur chaque croix "Bloqué temporairement" pour lever le bloquage</span>
                        <img alt="chrome-step2" src={firefoxStep2} className={'refuse__image img-fluid'} />
                    </li>
                    <li class="list-item three">
                        <span className="refuse__instruction"></span>
                    </li>
                </ol>
            </React.Fragment>
        );
    }
    
    renderSafari() {
        return <span>Vous devez autoriser l'accès à votre webcam et à votre micro pour joindre. Essayez encore et choisissez 'Autoriser' quand demandé</span>;
    }
    
    refreshPage() {
        document.location.href = document.location.href;
    }
    
    render() {
		
		let renderName = 'render' + browserName;
		let renderFct;
		if(!this[renderName])
            renderFct = this['renderChrome']
		else 
			renderFct = this[renderName]
		
        if (isMobile) {
            return (
                <React.Fragment>
                    <h3 className={'refuse__title'}>Il semblerait que votre navigateur ne puisse pas accéder à votre caméra et votre micro.</h3>
                </React.Fragment>
            )
        }
        return ( 
            <React.Fragment>
                <font size="4"><ol className='refuse__list'>
                    <li>A gauche de l’adresse web, cliquez sur l’icône de <b>cadenas</b></li>
                    <img width="264" height="36" src={require('../../assets/images/device/WebcamLockInstructions1.png')} />
                    <li>Cliquez sur les sliders de <b>caméra</b> et de <b>micro</b></li>
                    <img width="264" height="52" src={require('../../assets/images/device/WebcamLockInstructions2.png')} />
                    <li>Cliquez sur le bouton <b>Vérifier l’autorisation</b> ci-dessous :</li>
                </ol></font>
                { renderFct }
            </React.Fragment>
        );
    }
}
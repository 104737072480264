import log from "loglevel";
export default class Session {
    
    constructor(socket, mediaStream, opt = {}) {
        this.socket = socket;
        this.mediaStream = mediaStream;
        this.opt = opt;
        this.opt.encoding = this.opt.encoding || {};
        this.status = 'waiting';
        this.socket.on('recorderSessionReady', async (data) => {
            log.debug('recorderSessionReady', data);
            this.opt.onReady();
        });
        this.socket.emit('recorderCreate');
    }
    
    async start() {
        log.debug('recorder started');
        this.mediaRecorder = new window.MediaRecorder(this.mediaStream, this.opt.encoding);
        this.mediaRecorder.start(1000); // 1000 - the number of milliseconds to record into each Blob
        this.mediaRecorder.ondataavailable = (event) => {
          log.debug('Got blob data:', event.data);
          if (event.data && event.data.size > 0) {
            this.socket.emit('recordBinaryData', {
                buffer: event.data
            });
          }
        };

        this.mediaRecorder.onerror = (event) => {
            log.debug('MEDIA RECORDER onerror: ', event.data);
        };
        this.mediaRecorder.onpause = (event) => {
            log.debug('MEDIA RECORDER onpause: ', event.data);
        };
        this.mediaRecorder.onresume = (event) => {
            log.debug('MEDIA RECORDER onresume: ', event.data);
        };
        this.mediaRecorder.onstart = (event) => {
            log.debug('MEDIA RECORDER onstart: ', event.data);
        };
        this.mediaRecorder.onstop = (event) => {
            log.debug('MEDIA RECORDER onstop: ', event.data);
        };
    }
    
    stop() {
        this.status = 'stopped';
        this.socket.off('recorderSessionReady');
        this.mediaRecorder.stop();
        this.socket.emit('recordClose');
    }
    
    pause() {
        this.mediaRecorder.pause();
    }

    getState() {
        return this.mediaRecorder.state;
    }
}
import log from "loglevel";
import React, { useEffect, useState } from 'react';
import Animation from '../Utilities/Animation';

const StrategicActionsPop = () => {
  const [actions, setActions] = useState([]);

  const generateUniqueId = () => {
    return Math.random().toString(36).substr(2, 9);
  };
  useEffect(() => {

    window.sdk.event().on('showStrategicUserActions', (actions) => {
      actions = Array.from(actions.reduce((map, action) => map.set(action.Type, action), new Map()).values()
      ).slice(0, 3);
      actions.forEach((action, i) => {
        const index = i + 1; 
        setTimeout(() => {
          const idx = generateUniqueId();
          const newAction = { ...action, idx };
          setActions((prevActions) => [...prevActions, newAction]);
          let element;
          setTimeout(() => {
            element = document.querySelector('.action-' + idx);
            if(!element) {
              log.debug('Element not found');
              return;
            }
            element.classList.add('achievement-action-show')
              setTimeout(() => {
                window.sdk.event().emit('playLottie', "stratedic-pop-" + idx);
                        
                // Play pop sound
                //window.sdk.sound().play('Achievement')
              }, 400);
          }, 500);
          setTimeout(() => {
            element = document.querySelector('.action-' + idx);
            if(element){
              element.classList.add('fade-out');
              element.classList.remove('achievement-action-show')
              setTimeout(() => {
                  let listNotif = document.querySelectorAll('.achievement-action-show');
                  if(listNotif.length == 0){
                    document.querySelector('.achievement-push').innerHTML = '';
                  }
              }, 5000)
            } 
          }, 5000 + 500 * index);
        }, 1000);

        /*  setTimeout(() => {
              log.debug('ON AJOUTE ACTION',newAction);
              setActions([...actions,newAction]);
              let element;
              setTimeout(() => {
                  element  = document.querySelector('.action-'+idx);
                  log.debug('ON SHOW ACTION',newAction);
                  element.classList.add('show');
                }, 2000);
                setTimeout(() => {
                  log.debug('ON FADE ACTION',newAction);
                  element.classList.add('fade-out');
      
                  setTimeout(() => {
                      log.debug('ON REMOVE ACTION',newAction);
                      setActions(actions.filter(item => item !== newAction));
                  }, 2000); // 500ms correspond à la durée du fondu (transition)
                }, 10000); // 6000ms correspondent à 6 secondes
          }, 1000);
      })*/
      });
    });
  }, []);

  return (
    <div className="achievement-push">
      {actions.map((action,key) => <Component action={action} key={key} />)}
    </div>
  );
};

const Component = (props) => {
  return <div className={"achievement-push-item action-" + props.action.idx} id={"userAction-"+props.action.DisplayedName}>
    <Animation type={props.action.Type} name={"stratedic-pop-" + props.action.idx} autoplay={false} loop={false} />
    <div className="achievement-push-item-content">
      {props.action.Strategic == true && <div  className="achievement-push-item-type">Stratégie</div>}
      {props.action.DisplayedName}
    </div>
  </div>;
};

export default StrategicActionsPop;

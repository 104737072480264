import log from "loglevel";
import EventEmitter from '../../Utils/EventEmitter'
import { isFirefox } from 'react-device-detect';

export default class AudioManager extends EventEmitter {

    constructor(opt) {
        super();
        window.AudioContext = window.AudioContext || window.webkitAudioContext;

        this.listeningVolume = true;
        //if(isFirefox) {
        this.context = new window.AudioContext();
        /*}
        else {
            this.context = new window.AudioContext({
                sampleRate: 44100
            });
        }*/

        log.debug('AudioManager', 'sampleRate', this.context.sampleRate);
        this.destination = this.context.createMediaStreamDestination();

        this.binaryDataProcessing = 'Worklet'; //can be ScriptProcessor when you prefer

        this.opt = opt;

    }

    setBinaryDataProcessing(binaryDataProcessing) {
        this.binaryDataProcessing = binaryDataProcessing;
    }

    getStream() {
        return this.stream;
    }

    async setStream(stream) {
        let restartListenVolume = false;
        let restartListenBinaryData = false;
        if (this.listeningVolume) {
            restartListenVolume = true;
            this.stopListenVolume();
        }
        if (this.listeningBinaryData) {
            restartListenBinaryData = true;
            this.stopListenBinaryData();
        }

        this.stream = stream;
        if (!this.processingInitialized) {
            await this.initPorcessing();
        }
        this.startProcessing();

        if (restartListenVolume) {
            this.startListenVolume();
        }
        if (restartListenBinaryData) {
            this.startListenBinaryData();
        }
    }

    startListenVolume() {
        log.debug('AudioManager', 'startListenVolume');
        this.listeningVolume = true;
        this.volumeNode.port.postMessage({
            status: 'run'
        });
    }

    stopListenVolume() {
        if (this.volumeNode) {
            log.debug('AudioManager', 'stopListenVolume')
            this.volumeNode.port.postMessage({
                status: 'stop'
            });
        }
        this.listeningVolume = false;
    }

    startListenBinaryData() {
        this.listeningBinaryData = true;
        if (this.binaryDataProcessing == 'Worklet') {
            this.binaryDataNode.port.postMessage({ sampleRate: this.context.sampleRate });
            this.binaryDataNode.port.postMessage({ status: 'run' });
        }
        else
            this.startScriptProcessorBinaryData();
    }

    stopListenBinaryData() {
        if (this.binaryDataNode) {
            if (this.binaryDataProcessing == 'Worklet')
                this.binaryDataNode.port.postMessage({ status: 'stop' });
            else
                this.stopScriptProcessorBinaryData();
        }
        this.listeningBinaryData = false;
    }

    async initPorcessing() {
        //https://github.com/heyaphra/react-audio-worklet-example
        //https://stackoverflow.com/questions/62702721/how-to-get-microphone-volume-using-audioworklet
        log.debug('AudioManager', 'initPorcessing')
		await this.context.audioWorklet.addModule('/worklet/audio-volume.js');
        this.volumeNode = new AudioWorkletNode(this.context, 'audio-volume');
        let lastVolumeEmitTime = 0;
        let volumes = [];
        this.volumeNode.port.onmessage = event => {
            if (event.data.volume) {
                const currentTime = performance.now();
                if (currentTime - lastVolumeEmitTime >= 100) {
                    if (lastVolumeEmitTime != 0)
                        this.emit('volume', volumes.reduce((sum, sound) => sum + sound, 0) / volumes.length);
                    volumes = [];
                    lastVolumeEmitTime = currentTime;
                } else {
                    volumes.push(event.data.volume)
                }
            }
        }

        if (this.binaryDataProcessing == 'Worklet') {
            await this.context.audioWorklet.addModule('/worklet/audio-binarydata.js');
            this.binaryDataNode = new AudioWorkletNode(this.context, 'audio-binarydata');

            this.binaryDataNode.port.onmessage = event => {
                if (event.data.binaryData) {
                    this.emit('binaryData', event.data.binaryData);

                }
            }
        }

        this.processingInitialized = true;
    }

    startProcessing() {
        this.microphone = this.context.createMediaStreamSource(this.stream);
        this.microphone.connect(this.volumeNode).connect(this.destination);
        if (this.binaryDataProcessing == 'Worklet')
            this.microphone.connect(this.binaryDataNode).connect(this.destination);


    }

    /*startProcessing() {
        this.microphone = this.context.createMediaStreamSource(this.stream);	
        let merger = this.context.createChannelMerger(1);
        
        this.microphone.connect(merger, 0, 0); // left channel
        try {
            this.microphone.connect(merger, 1, 0); // right channel
        }
        catch(err) {
            log.debug('no right channel');
        }

        merger.connect(this.volumeNode).connect(this.destination);

        if(this.binaryDataProcessing == 'Worklet')
            this.microphone.connect(this.binaryDataNode).connect(this.destination);
        

    }*/

    stopProcessing() {
        if (this.microphone) {
            this.microphone.disconnect();
            this.microphone = null;
        }
    }

    getContext() {
        return this.context;
    }

    close() {
        log.error('audio close')
        
        for(var i in this.sources) {
            this.removeSource(i)
        }

        this.stopListenVolume();
        this.stopListenBinaryData();
        
        log.debug(this.context.state)
        try {
            if (this.context.state != 'closed')
                this.context.close();
        }
        catch (err) {

        }

        this.sources = {};
    }

    //the old way, but seems better
    startScriptProcessorBinaryData() {
        log.debug('startScriptProcessorBinaryData')
        this.streamStreaming = true;
        this.processor = this.context.createScriptProcessor(0, 1, 1);
        this.processor.connect(this.context.destination);

        this.input = this.context.createMediaStreamSource(this.stream);
        this.input.connect(this.processor);

        this.processor.onaudioprocess = (e) => {
            this.microphoneProcess(e);
        };
    }

    microphoneProcess(e) {
        var left = e.inputBuffer.getChannelData(0);
        //log.debug('left', this.context.sampleRate);

        var left16 = this.downsampleBuffer(left, this.context.sampleRate, 16000);
        this.emit('binaryData', left16);

    }

    async stopScriptProcessorBinaryData() {

        if (this.input && this.processor) {
            this.input.disconnect(this.processor);
            this.processor.disconnect(this.context.destination);
        }

        //await this.context.close();

        this.input = null;
        this.processor = null;

    }

    downsampleBuffer(buffer, sampleRate, outSampleRate) {
        /*if (outSampleRate == sampleRate) {
            return buffer;
        }
        if (outSampleRate > sampleRate) {
            throw 'downsampling rate show be smaller than original sample rate';
        }*/
        var sampleRateRatio = sampleRate / outSampleRate;
        var newLength = Math.round(buffer.length / sampleRateRatio);
        var result = new Int16Array(newLength);
        var offsetResult = 0;
        var offsetBuffer = 0;
        while (offsetResult < result.length) {
            var nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);
            var accum = 0,
                count = 0;
            for (var i = offsetBuffer; i < nextOffsetBuffer && i < buffer.length; i++) {
                accum += buffer[i];
                count++;
            }

            result[offsetResult] = Math.min(1, accum / count) * 0x7fff;
            offsetResult++;
            offsetBuffer = nextOffsetBuffer;
        }
        return result.buffer;
    };

    loadAudioFile(url) {
        return new Promise((resolve, reject) => {
            fetch(url)
                .then(response => response.arrayBuffer())
                .then(arrayBuffer => this.context.decodeAudioData(arrayBuffer))
                .then(audioBuffer => {
                   
                    this.sourceNode = this.context.createBufferSource();
                    this.sourceNode.buffer = audioBuffer;

                    const destination = this.context.createMediaStreamDestination();
                    this.sourceNode.connect(destination);

                    let mediaStream = destination.stream;
                    const track = mediaStream.getAudioTracks()[0];

                    // À partir d'ici, vous avez `track` qui est votre MediaStreamTrack
                    log.debug("Audio track:", track);

                    //this.sourceNode.start();

                    resolve({
                        mediaStream,
                        sourceNode: this.sourceNode
                    });

                })
                .catch(error => {
                    log.error("Erreur lors du chargement du fichier audio:", error);
                });
        });
    }


}


import React from 'react';
import Achievement from '../Utilities/Achievement';

export default class StepEndings extends React.Component {

    render() {
        return (
            <div className="feedback-step-endings">
                <div className="feedback-step__inner">
                    <div className="feedback-step__header">
                        <h1 className="feedback-step__title" dangerouslySetInnerHTML={{__html: this.props.text.EndingTitle_Text}}></h1>
                    </div>
                    <div className="feedback-step__content">
                        <div className="feedback-step-block feedback-ending">
                            <Achievement achievement={this.props.ending} type={'ending'} name="ending"></Achievement>
                        </div>
                    </div>
                </div>
            </div>)
    } 
}
StepEndings.defaultProps =  {
    text:{
        CongratsTitle_Text : "Découvrez la fin que<br />vos actions ont débloquée"
    }
}
import React from 'react';
import Form from 'react-bootstrap/Form';

import Goal from '../../components/Utilities/Goal';


export default class StepGoal extends React.Component {

    state = {
        goal_txt: ''
    }

    setGoalText = (e) => {
        this.setState({
            goal_txt: e.target.value
        })
    }

    render() {
        return (
            <div className="feedback-step__inner">
                <div className="feedback-step__header">
                    <h1 className="feedback-step__title">Décrivez votre objectif principal lors de ce prochain essai...</h1>
                </div>
                <div className="feedback-step__content">
                    <div className="feedback-step-block">
                        <Form.Group>
                            <Form.Label>Définissez vous-même votre objectif :</Form.Label>
                            <Form.Control as="textarea" placeholder="Lors de la prochaine session, je vais essayer de..." onChange={this.setGoalText} defaultValue={this.state.goal_txt}></Form.Control>
                        </Form.Group>
                    </div>
                    <div className="feedback-step-block">
                        <Form.Group>
                            <Form.Label>Ou tentez de suivre l’un des objectifs suggérés par nos experts :</Form.Label>
                            <div className="feedback-step-block feedback-goals">
                            {this.props.goals.map(goal => <Goal key={goal.ID} goal={goal} />)}
                            </div>
                        </Form.Group>
                    </div>
                </div>
            </div>
        )
    }
}
import Utils from "../../AppClasses/Utils/Utils";

export default class BotVideo {
    
    constructor(sdk) {
        this.sdk = sdk;
    }
    
    async init() {
    }
    
    async getOne(videoID) {
        let res = await this.sdk.fetch().get('/botvideo/' + videoID);
        return res;
    }
}
export default class Parameters {
    
    constructor(opt) {
        this._data = opt.data || {};
        this._provider = opt.provider || '';
        this._audioManager = opt.audioManager || null;
        this._lang = opt.lang || 'fr';
        this._customData = opt.customData || {};
        let defaultCallback = () => {};
        this._onBinaryData = opt.onBinaryData || defaultCallback;
        this._onPartialTranscriptedTextFromClient = opt.onPartialTranscriptedTextFromClient || defaultCallback
        this._onTranscriptedTextFromClient = opt.onTranscriptedTextFromClient || defaultCallback;
        this._onPartialTranscriptedText = opt.onPartialTranscriptedText || defaultCallback
        this._onTranscriptedText = opt.onTranscriptedText || defaultCallback;
        this._onFailed = opt.onFailed || defaultCallback;
    }
    
    set provider(provider) {
        this._provider = provider;
    }
    
    get provider() {
        return this._provider;
    }
    
    set audioManager(audioManager) {
        this._audioManager = audioManager;
    }
    
    get audioManager() {
        return this._audioManager;
    }
    
    set lang(lang) {
        this._lang = lang;
    }
    
    get lang() {
        return this._lang;
    }
    
    set data(data) {
        this._data = data;
    }
    
    get data() {
        return this._data;
    }
    
    set customData(customData) {
        this._customData = customData;
    }
    
    get customData() {
        return this._customData;
    }
    
    set onBinaryData(onBinaryData) {
        this._onBinaryData = onBinaryData;
    }
    
    get onBinaryData() {
        return this._onBinaryData;
    }
    
    set onPartialTranscriptedTextFromClient(onPartialTranscriptedTextFromClient) {
        this._onPartialTranscriptedTextFromClient = onPartialTranscriptedTextFromClient;
    }
    
    get onPartialTranscriptedTextFromClient() {
        return this._onPartialTranscriptedTextFromClient;
    }
    
    set onTranscriptedTextFromClient(onTranscriptedTextFromClient) {
        this._onTranscriptedTextFromClient = onTranscriptedTextFromClient;
    }
    
    get onTranscriptedTextFromClient() {
        return this._onTranscriptedTextFromClient;
    }
    
    set onPartialTranscriptedText(onPartialTranscriptedText) {
        this._onPartialTranscriptedText = onPartialTranscriptedText;
    }
    
    get onPartialTranscriptedText() {
        return this._onPartialTranscriptedText;
    }
    
    set onTranscriptedText(onTranscriptedText) {
        this._onTranscriptedText = onTranscriptedText;
    }
    
    get onTranscriptedText() {
        return this._onTranscriptedText;
    }
    
    set onFailed(onFailed) {
        this._onFailed = onFailed;
    }
    
    get onFailed() {
        return this._onFailed;
    }
}
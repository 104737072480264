import log from "loglevel";
export default class IndexedDB {
    
    constructor() {
        this.db = null;
		this.dbName = 'practicio';
    }
	
	async init(opt) {
		return new Promise((resolve, reject) => {
			let request = window.indexedDB.open(this.dbName, 1);
			request.addEventListener('error', () => reject(request.error));
			
			request.addEventListener('success', () => {
				log.debug('Cache database opened succesfully');				
				this.db = request.result;
				this.db.onerror = (event) => {				
					log.error(`Cache database error: ${event.target.errorCode}`);
					opt.onClose();
				};
				this.db.onclose = (event) => {				
					log.debug(`Cache database close`);
					opt.onClose();
				};
				resolve(true);
			});
			
			request.addEventListener('upgradeneeded', e => {
				const db = e.target.result;
				const objectStore = db.createObjectStore(this.dbName, { 
					keyPath: 'name' 
				});
			
				objectStore.createIndex('content', 'content', { unique: false });		
				log.debug('Cache database setup complete');
			});
		});
	}
    
	async add(name, blob) {
		return new Promise((resolve, reject) => {
			const objectStore = this.db.transaction([this.dbName], 'readwrite').objectStore(this.dbName);
	
			const record = {
				content : blob,
				name : name
			}
			
			log.debug('record', record);
			
			const request = objectStore.add(record);
			
			request.addEventListener('success', () => resolve(true));
			request.addEventListener('error', () => reject(request.error));
		});
	}
	
	async get(name) {
		//log.debug('this.db.transaction(this.dbName)', this.db, name);
		return new Promise((resolve, reject) => {
			
			const objectStore = this.db.transaction(this.dbName).objectStore(this.dbName);
			const request = objectStore.get(name);
			request.addEventListener('success', () => {
				if(request.result) {
					log.debug('taking videos from IDB');
					resolve(request.result.content);
				} 
				else {		
					resolve(false);
				}
			});
		});
	}
	
	async remove(name) {
		return new Promise((resolve, reject) => {
			const request = this.db.transaction(this.dbName, 'readwrite')
                      .objectStore(this.dbName)
                      .delete(name);

			request.onsuccess = () => {
				log.info(`Delete data: ${name} success`)
				resolve(true);
			}
			
			request.onerror = (err)=> {
				log.error(`Error to delete data: ${err}`)
				resolve(false);
			}
		});
	}
	
}
import log from "loglevel";
import React from 'react';

import '../../styles/components/Exercise.scss';

import { useParams } from "react-router-dom";

import Exercise from '../../components/VisioExercise/Exercise';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import CoachVoice from './CoachVoice';


export function withRouter(Children) {
    return (props) => {
        const match = { params: useParams() };
        return <Children {...props}  match={match} />
    }
}

class BuildYourselfExercisePanel extends React.Component {
	
	constructor(props) {
		super(props);

		this.state = {
            // Exercise info
            userName: window.sdk.user().firstName,
            userCompany: "",
            userService: "",
            botName: "Jeanne",
            botJob: "",
            chosenFeedbackReasons: [],

            // Progress
            courseStep: 0,
            step: "Intro",

            // Generated data
            scenario: "",
            speech: "",
            feedbacks: "",
            botIntro: "Bonjour, tu voulais me parler ?",
            nextBotAnswer: "",
            
            // System
            gptModel: "gpt-3.5-turbo",//"gpt-4",//"gpt-3.5-turbo", // 32k model: gpt-4-32k
            loading: false,
            error: false,
            errorMessage: "",
		};

        this.course = {
            courseName: "Effectuer un retour sur une performance à améliorer",
            courseDescription: "<p>Formuler des retours bien construits est clé pour:</p><ol><li>Encourager le développement des compétences</li><li>Accroître la confiance entre collaborateurs</li><li>Favoriser une communication ouverte</li><li>Accroître la performance d’une équipe</li></ol>",
            courseContent: [
                {
                    title: "<h2>1. S'assurer de manière bienveillante que l'autre est prêt à recevoir du feedback</h2>",
                    description: "",//<strong>Le manager doit spécifier le contexte du feedback et établir une connexion avec les objectifs et les intérêts du collaborateur pour le rendre rapidement compréhensible.</strong><br><br>Exemple : « Nous nous sommes fixés comme objectif de réduire de 30 % le coût du support commercial. Je sais que tu es déterminé à y arriver.",
                    advice: "",
                    evaluationCriteria: [
                        "0 point : l'utilisateur pose soit une question de manière inappropriée, impolie ou agressive soit qu'il dit des choses incohérentes ou qui sortent clairement de l'exercice",
                        "1 point : l'utilisateur pose une question de manière correcte, mais avec un ton légèrement négatif ou peu sensible aux sentiments de l'autre personne.",
                        "2 points : l'utilisateur pose une question de manière correcte et polie, sans toutefois montrer beaucoup d'empathie ou de bienveillance.",
                        "3 points : l'utilisateur pose une question de manière correcte et bienveillante, en prenant en compte les sentiments de l'autre personne, mais pourrait encore améliorer la formulation ou le contexte.",
                        "4 points : l'utilisateur pose une question de manière excellente, en étant à la fois bienveillant, empathique et en tenant compte des sentiments de l'autre personne, tout en communiquant clairement l'objectif de la discussion et en créant un environnement propice au feedback constructif."
                    ]
                },
                {
                    title: "<h2>2. Formuler le feedback en s'assurant de commencer en par quelques mots positifs, puis formuler le feedback négatif</h2>",
                    description: "",//<strong>Le manager doit décrire précisément le comportement du collaborateur dans ce contexte. Soulignez les faits sans  jugement. Évitez les généralisations, les approximations ou les « on dit ». Le manager doit avoir constaté lui-même le comportement à corriger.</strong><br><br>Exemple : « J’ai observé que tu n’avais pas participé aux deux dernières réunions du projet. »",
                    advice: "",
                    evaluationCriteria: [
                        "0 point : Formulation inappropriée, agressive ou irrespectueuse",
                        "1 point : Formulation correcte, mais manque de commentaires positifs et de précision",
                        "2 points : Formulation polie et précise, avec des commentaires positifs et des faits ou exemples précis, mais pourrait être plus empathique ou constructif",
                        "3 points : Formulation honnête et directe, avec des commentaires positifs, une formulation claire et précise, et une attention particulière portée à l'empathie et à la bienveillance",
                        "4 points : Formulation excellente, avec des commentaires positifs, des faits ou des exemples précis et des suggestions constructives et des actions spécifiques pour aider à améliorer les comportements ou les actions de la personne concernée"
                    ]
                },
                {
                    title: "<h2>3. Trouver des pistes constructives pour que les choses s'améliorent</h2>",
                    description: "",//<strong>Le manager doit rendre l’impact du comportement tangible pour le collaborateur (lui, ses pairs, l’entreprise, les clients).</strong><br><br>Exemple : « Aucune décision n’a pu être prise. Ceci a mis en retard d’autres membres du groupe et a entraîné une perte de revenus au cours de ce trimestre.»",
                    advice: "",
                    evaluationCriteria: [
                        "0 point : Aucune proposition de pistes d'amélioration ou pistes non pertinentes",
                        "1 point : Proposition de quelques pistes d'amélioration, mais manque de détails ou de pertinence",
                        "2 points : Proposition de plusieurs pistes d'amélioration pertinentes, mais pourrait être plus spécifique ou adapté à la situation",
                        "3 points : Proposition de pistes d'amélioration bien adaptées à la situation, avec une attention particulière portée à la faisabilité et à l'efficacité",
                        "4 points : Proposition excellente de pistes d'amélioration, incluant des détails précis, des étapes concrètes et un plan d'action clair"
                    ]
                },
                /*{
                    title: "<h2>4. Planifier l’après</h2>",
                    description: "<strong>Les prochaines étapes découlent logiquement des observations et de l’impact. Laissez le collaborateur s’exprimer au maximum, explorez les solutions avec lui et planifiez ensemble des échéances de réalisation pour assurer son engagement.</strong><br><br>Exemple : « Je pense que consacrer 100% de ton temps à ce projet sur les trois prochains jours pourrait aider à rattraper le retard accumulé. Qu’en penses-tu ? Est-ce réalisable de ton côté ? Vois-tu d’autres solutions ? »",
                    advice: "Enfin, récapitulez les engagements pris, les livrables attendus, les échéances fixées. Puis planifiez un prochain rendez-vous de suivi.",
                    evaluationCriteria: [
                        "0 point : l'utilisateur pose soit une question de manière inappropriée, impolie ou agressive soit qu'il dit des choses incohérentes ou qui sortent clairement de l'exercice",
                        "1 point : l'utilisateur pose une question de manière correcte, mais avec un ton légèrement négatif ou peu sensible aux sentiments de l'autre personne.",
                        "2 points : l'utilisateur pose une question de manière correcte et polie, sans toutefois montrer beaucoup d'empathie ou de bienveillance.",
                        "3 points : l'utilisateur pose une question de manière correcte et bienveillante, en prenant en compte les sentiments de l'autre personne, mais pourrait encore améliorer la formulation ou le contexte.",
                        "4 points : l'utilisateur pose une question de manière excellente, en étant à la fois bienveillant, empathique et en tenant compte des sentiments de l'autre personne, tout en communiquant clairement l'objectif de la discussion et en créant un environnement propice au feedback constructif."
                    ]
                },*/
                /*{
                    title: "<h2>Le Quoi et le Comment<br>Formuler un feedback implique de travailler le fond et la forme</h2>",
                    description: "<h3>Le Quoi :</h3><ol><li><strong>Causes</strong> : identifier et communiquer quels comportements ont créé un problème et devraient être travaillés</li><li><strong>Impact</strong> : identifier et communiquer comment le problème affecte les autres (collègues, client, entreprise, etc.)</li></ol><h3>Le Comment :</h3><ol><li><strong>Soyez respectueux</strong> : soyez bienveillants et évitez d’être aggressif</li><li><strong>Soyez spécifiques</strong> : donnez des détails concernant ce qui doit être amélioré et pourquoi</li></ol>",
                    advice: "Voici un conseil pour aborder cette situation : restez bienveillant et constructif."
                }*/
            ]
        }

        this.history = [];
	}

    componentDidMount() {
        this.coachVoice.speakText("Bienvenue " + this.state.userName + " ! Dans ce module, tu vas apprendre à " + this.course.courseName + ". Bonne formation !");
    }
    
    changeStep = (newStep) => {
        this.setState({ step: newStep });
    }

    addElementToHistory = (dateTime, elementType, author, content) => {
        let element = {
            dateTime: dateTime,
            elementType: elementType,
            author: author,
            content: content
        };
        this.history.push(element);
    }

    generateScenario = async (data) => {
        log.debug("generateScenario: data = ", data);
        this.setState({ userCompany: data.userCompany, 
                        userService: data.userService,
                        botJob: data.botJob,
                        chosenFeedbackReasons: data.chosenFeedbackReasons,
                        error: false });

        // Prepare GPT prompt
        let messages = [
            {
                role: "system", 
                content: this.state.userName + " se forme dans le module '" + this.course.courseName + "' : " + this.course.courseDescription + "\n---\n"
                        + "Voilà quelques éléments de contexte sur la situation à simuler :\n" 
                        + this.state.userName + " doit formuler un feedback négatif à sa collègue " + this.state.botName + ".\n"
                        + "Les raisons du feedback négatif à faire à " + this.state.botName + " : " + data.chosenFeedbackReasons + "\n"
                        + "L'entreprise s'apelle " + data.userCompany + "\n"
                        + "Le service de " + this.state.userName + " et " + this.state.botName + " s'appelle " + data.userService + "\n"
                        + "Le job de " + this.state.botName + " est " + data.botJob + "\n---\n"
                        + "Décris un contexte de scénario en 100 mots maximum qui permetttra à " + this.state.userName 
                        + " de s'entraîner à bien gérér cette situation. Conserve bien les éléments de contexte précis. Crée des exemples de faits précis et concrets qui ont déclenché le besoin de faire les feedbacks.\n"
                        + "Précise quel personnage " + this.state.userName + " va jouer.\n"
                        + "Ecris ta réponse au format html comme si tu étais à l'intérieur d'une div pour produire une belle mise en page."
            },
        ];
        log.debug("generateScenario: messages = ", messages);

        this.setState({ loading: true });

        // iQuestion, iGPT3Engine, iMaxTokens, iTemperature, iTopP, iFrequencyPenalty, iPresencePenalty, iStopSequence
        let answer = await window.sdk.openaiAPI().CallChatAPI(messages, this.state.gptModel, 1000, 0.7, 1, 0, 0, "");
        log.debug('generateScenario: GPT answer = ', answer);
        
        this.setState({ loading: false });

        if(answer.status === "error")
        {
            this.setState({ error: true,
                            errorMessage: "GPT Error: " + answer.error });
            
            this.generateScenario(data);
            return;
        }

        //this.addElementToHistory(new Date(), "scenario", "system", answer.completion);

        this.setState({ scenario: answer.completion, 
                        step: "ReadScenario"  })
    };
                    
    generateBotReaction = async (userSpeech) =>{
        log.debug("generateBotReaction: userSpeech = ", userSpeech);

        // Prepare GPT prompt to generate Jeanne reaction to the user speech
        let prompt =  "Vous êtes " + this.state.botName + ", " + this.state.botJob + " chez " + this.state.userCompany + ".\n"
                    + "Vous travaillez avec " + this.state.userName + ", votre manager, dans le service " + this.state.userService + ".\n"
                    //+ "Vous avez reçu un feedback négatif de la part de " + this.state.userName + ".\n"
                    //+ "Les raisons du feedback négatif à faire à " + this.state.botName + " : " + this.state.chosenFeedbackReasons + "\n"
                    + "Voici ce que " + this.state.userName + " vient de vous dire : " + userSpeech + "\n"
                    + "Comment réagissez-vous ?\n"
                    + "N'écris que la réplique de " + this.state.botName + ". Dans un style oral, un peu familier, direct et naturel.\n"

        //this.setState({ loading: true });
/*
        let messages = [
            {role: "system", content: prompt}
        ];
        let answer = await window.sdk.openaiAPI().CallChatAPI(messages, this.state.gptModel, 1000, 0.7, 1, 0, 0, "");
*/
        let answer = await window.sdk.openaiAPI().CallCompletionAPI(prompt, "text-davinci-003", 1000, 0.7, 1, 0, 0, "");

        log.debug('+++ Generated Bot reaction = ', answer.completion);

        this.setState({ nextBotAnswer: answer.completion});
        // Use coach voice object to pronouce the bot reaction
        //this.coachVoice.speakText(answer.completion);

        //this.setState({ loading: false });
    };
    
    generateFeedbacks = async (userSpeech) =>{

        this.generateBotReaction(userSpeech);
    
        log.debug("generateFeedbacks: userSpeech = ", userSpeech);
        this.setState({ speech: userSpeech,
                        error: false });
        let speechDate = new Date();
  
        let prompt =  "Nous sommes le " + new Date() + ".\n"
                    + "Vous êtes l'assistant qui aide " + this.state.userName + " à donner du feedback constructif à Jeanne.\n"
                    + "Description : Vous êtes un assistant qui aide les utilisateurs à donner un feedback constructif à leurs collaborateurs. Le processus de feedback se compose de trois étapes clés :\n"
                    + "Ces étapes sont :\n"
                    // output the course content list items with a "\n" separator
                    + this.course.courseContent.map((item) => item.title).join("\n") + "\n"
                    + "A ce stade, " + this.state.userName + " travaille l'étape " + (this.state.courseStep+1) + ". Donne lui un feedback sur cette étape.\n"
                    + "Donne une évaluation chiffrée de cette étape selon ces critères :\n"
                    + this.course.courseContent[this.state.courseStep].evaluationCriteria.map((item) => item.title).join("\n") + "\n"
                    + "Lorsque tu formules le feedback suis les étapes suivantes sans faire référence à ces étapes :\n"
                    + "1. Remercie l'utilisateur et en commençant sur une note positive.\n"
                    + "2. Explique à l'utilisateur comment tu évalues sa prestation.\n"
                    + "Si l'utilisateur propose des choses incohérentes ou qui sortent clairement de l'exercice, il faut lui dire simplement 'Je pense que nous avons perdu le fil de l'exercice, voulez-vous recommencer?'\n"
                    + "3. Donne lui la note que tu lui attribue.\n"
                    + "4. Termine par un exemple qui aurait permis à l'utilisateur d'atteindre la note de 4 points.\n"
                    + "Sois concis.";
        
                    /*"Nous sommes le " + new Date() + ".\n"
                    + "Voilà les consignes que " + this.state.userName + " est en train d'essayer d'appliquer :\n" + JSON.stringify(this.course[this.state.courseStep])
                    + "\n---\n" + "Scénario du jeu de rôle:\n" + this.state.scenario
                    + (this.history.length > 0 ? "\n---\n" + "Voilà un historique des précédentes fois :\n" + JSON.stringify(this.history) : "")
                    + "\n---\n" + "Cette fois-ci, voilà la réponse que " + this.state.userName + " a dite:\n" + userSpeech
                    + "\n---\n" + "Tu es un formateur et tu coach " + this.state.userName + " sur ses capacités en communication."
                    + "\nNotes la qualité de sa prestation du 5 et commente la qualité de la réponse de " + this.state.userName + " sur sa façon de la formuler et donne-lui des pistes pour s'améliorer la prochaine fois qu'il se trouvera dans ce type de situation."
                    + "\nNe réponds que par les éléments nécessaires à sa progression dans le cadre du cours."
                    + "\nEcris ta réponse au format html comme si tu étais à l'intérieur d'une div."; */
            
        log.debug('generateFeedbacks: prompt = ', prompt);
  
        let messages = [
          {role: "system", content: prompt}
        ];
  
        this.setState({ loading: true });

        // iQuestion, iGPT3Engine, iMaxTokens, iTemperature, iTopP, iFrequencyPenalty, iPresencePenalty, iStopSequence
        let answer = await window.sdk.openaiAPI().CallChatAPI(messages, this.state.gptModel, 1000, 0.7, 1, 0, 0, "");
      
        log.debug('generateFeedbacks: GPT answer = ', answer);

        this.setState({ loading: false });

        if(answer.status === "error")
        {
            this.setState({ error: true,
                            errorMessage: "GPT Error: " + answer.error });
            
            this.generateFeedbacks(userSpeech);
            return;
        }

        this.addElementToHistory(speechDate, "speech", "user", userSpeech);
        this.addElementToHistory(new Date(), "feedback", "system", answer.completion);
        this.setState({ feedbacks: answer.completion, 
                        step: "Feedbacks"  });
    };

    nextCourseStep = () => {
        // Increment course step, clean history and go to next step
        this.history = [];
        this.setState({ courseStep: this.state.courseStep + 1,
                        speech: "",
                        feedbacks: "",
                        step: "ReadScenario",
                        botIntro: this.state.nextBotAnswer,
                        nextBotAnswer: ""
                    });
    }

    render() {
        return (
            <div>
                {this.state.step === "Intro" && 
                    <Intro
                    CourseName={this.course.courseName}
                    CourseDescription={this.course.courseDescription}
                    changeStep={this.changeStep}/>
                }
                {this.state.step === "BuildScenario" && 
                    <BuildScenario 
                    updateScenarioData={this.generateScenario}
                    changeStep={this.changeStep}/>
                }
                {this.state.step === "ReadScenario" && 
                    <ReadScenario
                    courseContentOfStep={this.course.courseContent[this.state.courseStep]}
                    scenario={this.state.scenario}
                    changeStep={this.changeStep}/>
                }
                {this.state.step === "Practice" && 
                    <Practice
                    scenario={this.state.scenario}
                    botIntro={this.course.botIntro}
                    makeBotTalk={this.coachVoice.speakText}
                    generateFeedbacks={this.generateFeedbacks}
                    changeStep={this.changeStep}/>
                }
                {this.state.step === "Feedbacks" && 
                    <Feedbacks
                    feedbacks={this.state.feedbacks}
                    nextCourseStep={this.nextCourseStep}
                    changeStep={this.changeStep}/>
                }
                {this.state.error && 
                    <div>
                        <h3 color='red'>Erreur</h3>
                        <p color='red'>{this.state.errorMessage}</p>
                    </div>
                }
                {this.state.loading &&
                    <div className="loader-wrapper">
                        <div className="spinner-loader"></div>
                    </div>
                }
                {true && <CoachVoice ref={(ref) => { this.coachVoice = ref; }} />}
            </div>
        );
    }
}

class Intro extends React.Component {
	
    render() {
        return (
            <div>
                <h1>Bienvenue dans ce module :</h1>
                <h1>"{this.props.CourseName}"</h1>
                <div dangerouslySetInnerHTML={{ __html: this.props.CourseDescription }} />
                <button onClick={() => this.props.changeStep("BuildScenario")}>Suivant</button>
            </div>
        );
    }
}

class BuildScenario extends React.Component {
	
    onFinished() {
        let data = {
            userCompany: document.getElementById("entreprise").value,
            userService: document.getElementById("service").value,
            botJob: document.getElementById("poste").value,
            chosenFeedbackReasons: [],
        };
        if (document.getElementById("organisation").checked) {
            data.chosenFeedbackReasons.push("organisation");
        }
        if (document.getElementById("competences").checked) {
            data.chosenFeedbackReasons.push("competences");
        }
        if (document.getElementById("communication").checked) {
            data.chosenFeedbackReasons.push("communication");
        }
        if (document.getElementById("productivite").checked) {
            data.chosenFeedbackReasons.push("productivite");
        }
        if (document.getElementById("attitude").checked) {
            data.chosenFeedbackReasons.push("attitude");
        }
        this.props.updateScenarioData(data);
    }
    
    render() {
        return (
            <div>
                <h1>Construisez votre scénario !</h1>
                <p>Choisissez une raison pour laquelle vous devez formuler un retour négatif à votre collaboratrice Jeanne (sélectionner autant de causes que désiré) : </p>
                <div>
                    <input type="checkbox" id="organisation" />
                    <label htmlFor="organisation">Organisation</label>
                </div>
                <div>
                    <input type="checkbox" id="competences" />
                    <label htmlFor="competences">Compétences techniques</label>
                </div>
                <div>
                    <input type="checkbox" id="communication" />
                    <label htmlFor="communication">Communication</label>
                </div>
                <div>
                    <input type="checkbox" id="productivite" />
                    <label htmlFor="productivite">Productivité</label>
                </div>
                <div>
                    <input type="checkbox" id="attitude" />
                    <label htmlFor="attitude">Attitude</label>
                </div>
                <h3>Dites-nous en plus sur votre entreprise et Jeanne :</h3>
                <form>
                    <div>
                        <label htmlFor="entreprise">Entreprise :</label>
                        <input type="text" id="entreprise" />
                    </div>
                    <div>
                        <label htmlFor="service">Service :</label>
                        <input type="text" id="service" />
                    </div>
                    <div>
                        <label htmlFor="poste">Poste de Jeanne :</label>
                        <input type="text" id="poste" />
                    </div>
                </form>
                <button onClick={() => this.onFinished()}>Suivant</button>
            </div>
        );
    }
}

class ReadScenario extends React.Component {
    
    render() {
        
        const title = this.props.courseContentOfStep.title;// "<h2>Le Quoi et le Comment<br>Formuler un feedback implique de travailler le fond et la forme</h2>";
        const description =this.props.courseContentOfStep.description;// "<h3>Le Quoi :</h3><ol><li><strong>Causes</strong> : identifier et communiquer quels comportements ont créé un problème et devraient être travaillés</li><li><strong>Impact</strong> : identifier et communiquer comment le problème affecte les autres (collègues, client, entreprise, etc.)</li></ol><h3>Le Comment :</h3><ol><li><strong>Soyez respectueux</strong> : soyez bienveillants et évitez d’être aggressif</li><li><strong>Soyez spécifiques</strong> : donnez des détails concernant ce qui doit être amélioré et pourquoi</li></ol>";
        const advice =this.props.courseContentOfStep.advice;// "Voici un conseil pour aborder cette situation : restez bienveillant et constructif.";

        return (
            <div>
                <h1>Découvrez le résumé de votre scénario :</h1>
                <b>Dans ce scénario, vous allez devoir formuler un feedback sur la situation suivante :</b>
                <div style={{ backgroundColor: "darkgray", padding: "10px" }} dangerouslySetInnerHTML={{ __html: this.props.scenario }}></div>
                <div style={{ height: "50px" }}></div>
                <div dangerouslySetInnerHTML={{ __html: title }}></div>
                <div dangerouslySetInnerHTML={{ __html: description }}></div>
                <div style={{ color: "#BD00FF", padding: "10px", marginTop: "10px", alignItems: "center" }}>
                    {advice}
                </div>                
                <div style={{ height: "50px" }}></div>
                <p style={{ color: "#BD00FF", marginTop: "10px" }}>
                    Quand vous êtes prêt.e, appuyez sur le bouton suivant pour lancer démarrer l’interaction
                </p>
                <button onClick={() => this.props.changeStep("Practice")}>Suivant</button>
            </div>
        );
    }
}

class Practice extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
            speech: ""
		};
	}

    componentDidMount() {
        this.props.makeBotTalk(this.props.botIntro);
        this.startSpeechToText();
        log.debug("++ Step Practice, bot says : " + this.props.botIntro);
    }

    // Start speech recognition
    startSpeechToText = async () => {
        log.debug('startSpeechToText');        
        
        this.transcriptionSession = await window.sdk.videoconf().createTranscriptionSession({
            onTranscriptedText: (data) => {
                this.OnSpeechDetected(data.text);
            },
            onPartialTranscriptedText: (data) => {
                this.OnPartialSpeechDetected(data.text);                
            },
            onFailed: (data) => {
                this.OnFailed(data);                
            },
            customData: {
                "transcriptionID": "PitchSpeech_" + new Date().toUTCString(),
                "endpoint": "",
                "segmentationSilenceTimeoutMs": 2000,
                "phraseListGrammar": ""
            }
        });
        this.transcriptionSession.start();
    };

    // Triggered when the STT finished the speech recognition (EndSilence timeout)
    OnPartialSpeechDetected(iResult)
    {
        //log.debug("SpeechToText - OnPartialSpeechDetected: '" + iResult + "'.");
		
        this.setState({ speech: iResult });
    }

    // Triggered when the STT finished the speech recognition (EndSilence timeout)
    OnSpeechDetected(iResult)
    {
        log.debug("SpeechToText - OnSpeechDetected: '" + iResult + "'.");

        if(this.transcriptionSession) {
            this.transcriptionSession.close();
        }

        this.setState({ speech: iResult });
        this.props.generateFeedbacks(iResult);
    }

    // Triggered when the STT finished the speech recognition (EndSilence timeout)
    OnFailed(iReason)
    {
        log.debug("SpeechToText - OnFailed: Reason '" + iReason + "'.");
/*
        if(this.transcriptionSession) {
            this.transcriptionSession.close();
        }

        let message = "Failed: " + iReason + ". Partial speech detected: '" + this.state.Speech + "'.";
        this.setState({ Speech: message });*/
    }
    
    render() {

        return (
            <div>
                <div class="buildyourselfpracticecontainer">
                    <div class="buildyourselfpracticeleft-column">
                        <video controls="no" playsInline loop muted autoPlay preload="auto" id="botVideo" className="buildyourselfpractice-botvideo">
                            <source src={"https://d3mr3frbexldn2.cloudfront.net/Bots/Jeanne/Videos/Jeanne_S1_BoucleAttenteTendue_Acteur_V3.mp4"} type="video/mp4" />
                        </video>
                        <p>Exprimez votre feedback à Jeanne une fois prêt.e</p>
                    </div>
                    <div class="buildyourselfpracticevertical-divider"></div>
                    <div class="buildyourselfpracticeright-column">
                        <ul class="buildyourselfpracticeword-list">
                            <li>Causes</li>
                            <li>Impact</li>
                            <li>Be specific</li>
                            <li>Be respectful</li>
                        </ul>
                        <div class="buildyourselfpracticetext-block" dangerouslySetInnerHTML={{ __html: this.props.scenario }}/>
                    </div>
                </div>

                <button onClick={() => this.props.changeStep("Feedbacks")}>Suivant</button>
            </div>
        );
    }
}

class Feedbacks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isBlinking: false
        };
    }

    componentDidMount() {
        this.blinkInterval = setInterval(() => {
            this.setState({ isBlinking: !this.state.isBlinking });
        }, 1000);
    }

    componentWillUnmount() {
    clearInterval(this.blinkInterval);
    }

    render() {
        const { isBlinking } = this.state;
        
        const buttonContainerStyle = {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
        };

        const retryButtonStyle = {
            width: "200px",
            backgroundColor: "#BD00FF",
            color: isBlinking ? "white" : "darkviolet",
            fontSize: "20px",
            fontWeight: "bold",
            padding: "10px 20px",
            margin: "10px"
        };

        const createScenarioButtonStyle = {
            width: "200px",
            backgroundColor: "black",
            color: "white",
            fontSize: "10px",
            padding: "10px 20px",
            margin: "10px"
        };

        return (
            <div>
                <h1>Feedbacks</h1>
                <div dangerouslySetInnerHTML={{ __html: this.props.feedbacks }}></div>
                <div style={buttonContainerStyle}>
                    <button
                        style={retryButtonStyle}
                        onClick={() => this.props.changeStep("Practice")}
                    >
                        Réessayer l'étape
                    </button>
                    <button
                        style={createScenarioButtonStyle}
                        onClick={() => this.props.nextCourseStep()}
                    >
                        Prochaine étape
                    </button>
                </div>
            </div>
        );
    }
}

export default withRouter(BuildYourselfExercisePanel);

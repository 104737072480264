import React from 'react';

import Form from 'react-bootstrap/Form';
import Range from '../Utilities/Range';


export default class StepFeedback extends React.Component {

    exercice = {
        2: {
            title: 'Dans quelle mesure êtes-vous satisfait du déroulement de l\'interaction?',
            moins: 'Très insatisfait',
            plus: 'Très satisfait',
            label: 'Votre ressenti',
            media_type: 'intervenants',
            media_title: 'Les intervenants',
            media_infos: [
                { name:'Franck', pic: 'franck.png' },
                { name:'Jeanne', pic: 'jeanne.png' },
                { name:'Clara', pic: 'Clara.png' },
                { name:'Jules', pic: 'jules.png' }
            ],
            placeholder: 'Face aux différents intervenants, je me suis senti.e...'
        },
        'default':{
            title : 'Comment vous êtes-vous senti face à la situation ?',
            moins : 'Très mal à l’aise',
            plus: 'Très à l’aise',
            label:'Face à la situation',
            placeholder: 'Décrivez-nous ce que vous avez ressenti face à la situation...'          
        }
    };
    /*tab = {
        'scene': {
            title : 'Comment vous êtes-vous senti face à la situation ?',
            moins : 'Très mal à l’aise',
            plus: 'Très à l’aise',
            label:'Face à la situation',
            placeholder: 'Décrivez-nous ce que vous avez ressenti face à la situation...'
        },
        'franck': {
            title : 'Comment vous êtes-vous senti face au comportement de Franck ?',
            moins : 'Très mal à l’aise',
            plus: 'Très à l’aise',
            label:'Face au comportement de Franck',
            placeholder: 'Décrivez-nous ce que vous avez ressenti face au comportement de Franck...'
        },
        'jeanne': {
            title : 'Comment vous seriez-vous senti à la place de Jeanne ?',
            moins : 'Très mal à l’aise',
            plus: 'Très à l’aise',
            label:'A la place de Jeanne',
            placeholder: 'Décrivez ce que vous auriez ressenti à la place de Jeanne...'
        }
    }*/

    state = {
        satisfaction_txt: ''
    }

    setSatisfactionText = (e) => {
        this.setState({
            satisfaction_txt: e.target.value
        })
    }

    setSatisfaction = (val) => {
        this.setState({
            satisfaction_value: val
        })
    }

    render() {
        let data = this.exercice[this.props.exerciseID];
        if(!data){
            data = this.exercice['default']
        }
        //const image = require('../../assets/images/feedback/feedback-'+ this.props.identifier +'.png')
        //<img src={image} />
        if(!data)
            return null;
        return (
            <div className="feedback-step__inner">
                <div className="feedback-step__header">
                    <h1 className="feedback-step__title">{data.title}</h1>
                </div>
                <div className="feedback-step__content">
                    <div className="feedback-step-block">
                        <Range default={4} identifier="satisafaction" labelLeft={data.moins} labelRight={data.plus} onChange={this.setSatisfaction} />
                    </div>
                    <div className={"feedback-step-block feedback-satisfaction-block" + (data.media_type ? ' feedback-step-block-2col' : '')}>
                        {data.media_type == 'intervenants' &&
                        <Form.Group className="feedback-satisfaction-group">
                            <Form.Label>{data.media_title}</Form.Label>
                            <div className="feedback-intervenants-list">
                                {data.media_infos.map((intervant) => {
                                let img = require('../../assets/images/feedback/'+ intervant.pic)
                                return <div className="feedback-intervenants-item">{intervant.name}<img src={img} /></div>
                                })}
                            </div>
                        </Form.Group>
                        }
                        <Form.Group className="feedback-satisfaction-group">
                            <Form.Label>{data.label}</Form.Label>
                            <Form.Control as="textarea" placeholder={data.placeholder} onChange={this.setSatisfactionText} defaultValue={this.state.satisfaction_txt}></Form.Control>
                        </Form.Group>
                    </div>
                </div>
            </div>
        )
    }
}
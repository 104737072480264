import log from "loglevel";
import React from 'react';

import Button from 'react-bootstrap/Button';
import Animation from "../Utilities/Animation";

export default class ExerciseButton extends React.Component {

    buttonText = "DEFAULT BUTTON TEXT";
    onclickedCallback;
    variant = 'white';
    waiting = false;

    constructor(props) {
        super(props);
        this.state = {
            isClicked: false
        };
        this.buttonText = this.props.label;
        this.onclickedCallback = props.onClick;

        try {
            props.registerButton(this);
        } catch (e) {
            log.error("ExerciseButton: registerButton not found");
        }
    }

    onClick() {
        log.debug("ExerciseButton: onClick");
        this.setState({isClicked: !this.state.isClicked});
        this.onclickedCallback();
    }

    render() {
        const waiting = this.waiting;
        let icon = null;
        let iconPosition = 'left';
        if ( waiting ) {
            icon = (<i className="btn__icon icon-loading"></i>);
            if ( this.variant === 'white' ) {
                iconPosition = 'right';
            }
        } else {
            switch(this.variant) {
                case 'white':
                    break;
                case 'hang-up':
                    icon = (<i className="btn__icon icon-phone-alt"></i>);
                    break;
                case 'raising':
                    icon = (<Animation type={'raisingHand'} name="raisingHandBottom"  classCSS='anim-button' autoplay={false} loop={true}/>);
                    break;
                case 'speaking':
                    icon = (<i className="btn__icon icon-speaking-animated"></i>);
                    break;
            }
        }
        return (
            <Button id='raise-hand-button' className={'bottom-btn' + (waiting ? ' btn-waiting' : '')} variant={this.variant} 
                onClick={() => this.onClick()}
                onMouseEnter={() => { if(this.variant == 'raising'){window.sdk.event().emit('playLottie', 'raisingHandBottom')}} }
                onMouseLeave={() => { if(this.variant == 'raising'){window.sdk.event().emit('pauseLottie', 'raisingHandBottom')}} }
            >
                {iconPosition === 'left' && icon}
                {this.buttonText}
                {iconPosition === 'right' && icon}
            </Button>
        );
    }
}
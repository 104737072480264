import log from "loglevel";
import React from 'react';
import Utils from '../../AppClasses/Utils/Utils';
import ParticipantVideoSlot from './ParticipantVideoSlot';

export default class Bot extends ParticipantVideoSlot {

    type = 'bot';

    constructor(props) {
        log.debug('Bot:constructor', props.bot.getID());

        super(props, props.bot);

        this.botClass = '.participant_' + this.props.bot.getID();
    }

    async componentDidMount() {

        while(!document.querySelector(this.botClass + ' .bot_layer1')) {
            await Utils.Sleep(100);
        }
        this.props.bot.domIsReady(document.querySelector(this.botClass));

        super.componentDidMount();
    }

    renderVideo() {
        //log.debug('Bot:renderVideo', this.botClass);

        return (
            <div className={'participant__video'}>
                <div className={'video_layer video_layer--1 bot_layer1'}></div>
                <div className={'video_layer video_layer--2 bot_layer2'}></div>
            </div>
        );
    }
	
}
import Utils from "../../AppClasses/Utils/Utils";

export default class UsersActivity {
    
    constructor(sdk) {
        this.sdk = sdk;
    }
    
    async init() {
    }

    async createOne(iType, iData) {

        let body = {
            UserID:  window.sdk.user().userID ? window.sdk.user().userID : (iData.UserID ? iData.UserID : null),
            Date: new Date().toISOString(), // Database format: 2022-10-14T00:09:48.875
            Type: iType,
            Data: JSON.stringify(iData)
        };
        let res = await this.sdk.fetch().post('/usersactivity', { body });

        return res;
    }
}
import log from "loglevel";
import Utils from '../../Utils/Utils';
import ExerciseNode from './ExerciseNode';
import NodePort from '../NodePort';

export default class GPT3BoolQuestion extends ExerciseNode 
{
    // Ports
    Input = new NodePort("Input", "input", this);
    Output = new NodePort("Output", "output", this);

    // Input and output ports
    Speech = new NodePort("Speech", "input", this); // Connected to a string value node to get the speech detected string
    Result = new NodePort("Result", "output", this); // Connected to bool value nodes that store or use the boolean result

    // Parameters
    Question = "";
    GPT3Engine = "text-davinci-002";
	MaxTokens = 100;
	Temperature = 1;
	TopP = 0;
	FrequencyPenalty = 0;
	PresencePenalty = 0;
	StopSequence = "";

    // Internal values
    

    constructor(iGraph, iProperties)
    {        
        super(iGraph, iProperties);

        this.Question = iProperties.Question;
        this.GPT3Engine = iProperties.GPT3Engine;
        this.MaxTokens = iProperties.MaxTokens;
        this.Temperature = iProperties.Temperature;
        this.TopP = iProperties.TopP;
        this.FrequencyPenalty = iProperties.FrequencyPenalty;
        this.PresencePenalty = iProperties.PresencePenalty;
        this.StopSequence = iProperties.StopSequence;

        /*log.debug(this.GetIdentity() + " constructor: graph = " + this.Graph.ExerciseName 
                    + ", id = " + this.ID 
                    + ", Question = " + this.Question 
                    + ", GPT3 Engine = " + this.GPT3Engine 
                    + ", Max Tokens = " + this.MaxTokens 
                    + ", Temperature = " + this.Temperature 
                    + ", Top P = " + this.TopP 
                    + ", Frequency Penalty = " + this.FrequencyPenalty 
                    + ", Presence Penalty = " + this.PresencePenalty 
                    + ", Stop Sequence = " + this.StopSequence); */
    }

    OnActivated(iActivator, iInputPort)
    {
        super.OnActivated(iActivator, iInputPort);

        // Retrieve speech text from speech input node
        let speech = this.GetSourceText();
        
        log.debug(this.GetIdentity() + " has been activated by '" + iActivator.GetIdentity() + "'. Speech = '" + speech + "'.");

        // Ask GPT3
        // TODO: GPT3.Instance.AskGPT3BoolQuestion(this, speech);
        this.QuestionAnswered(true);
    }


    GetSourceText()
    {
        let speechSourceNode = this.Speech.GetFirstConnectedNode();
        if(speechSourceNode != null)
        {
            return speechSourceNode.GetStringValue();
        }
        else
        {
            return "";
        }
    }

    async QuestionAnswered(iBoolAnswer)
    {
        log.debug(this.GetIdentity() + ": OnQuestionAnswered = " + iBoolAnswer);

        this.OutputAnswer(iBoolAnswer);

        this.ActivateOutput();
    }

    OutputAnswer(iBoolAnswer)
    {
        log.debug(this.GetIdentity() + ": OutputAnswer '" + iBoolAnswer + "' to " + this.Result.GetConnectionsCount() + " nodes: " + this.Result.ListPortConnections());

        this.Result.GetConnectedNodes().forEach(node => {
            node.SetBoolValue(iBoolAnswer);
        });
    }
        
    ActivateOutput()
    {
        log.debug(this.GetIdentity() + "' activating output.");

        this.SetActive(false);

        this.Output.ActivateAllConnections();
    }

    OnDeactivated()
    {
        // Stop current GPT3 question if running
        // TODO: GPT3.Instance.CancelGPT3BoolQuestion(this);

        super.OnDeactivated();
    }

    PrintParameters()
    {
        /*log.debug("GPT3BoolQuestion: graph = " + this.Graph.ExerciseName 
                    + ", id = " + this.ID 
                    + ", Question = " + this.Question 
                    + ", GPT3 Engine = " + this.GPT3Engine 
                    + ", Max Tokens = " + this.MaxTokens 
                    + ", Temperature = " + this.Temperature 
                    + ", Top P = " + this.TopP 
                    + ", Frequency Penalty = " + this.FrequencyPenalty 
                    + ", Presence Penalty = " + this.PresencePenalty 
                    + ", Stop Sequence = " + this.StopSequence); */
    }
}
import log from "loglevel";
import Utils from '../../Utils/Utils';
import ExerciseNode from './ExerciseNode';
import NodePort from '../NodePort';

export default class GPTChoice extends ExerciseNode 
{
    // Ports
    Input = new NodePort("Input", "input", this);
    Speech = new NodePort("Speech", "input", this);
    Failed = new NodePort("Failed", "output", this);

    // Parameters
    Question = "";
    GPTEngine = "text-davinci-003"; // Or chat: "gpt-3.5-turbo",//"gpt-4"
	MaxTokens = 100;
	Temperature = 0.7;
	TopP = 0;
	FrequencyPenalty = 0;
	PresencePenalty = 0;
	StopSequence = "";

    Choices = [];

    // Dynamic Values
    StartTime = null;
    GPTAnswer = {};
    m_ParentNode = null;
    m_CurrentRequestController = null;


    constructor(iGraph, iProperties)
    {        
        super(iGraph, iProperties);

        // For smart branching decision inclusion, we need to know the parent node
        this.m_ParentNode = iProperties.ParentNode;

        this.Prompt = iProperties.Prompt;
        this.GPTEngine = iProperties.GPTEngine;
        this.MaxTokens = iProperties.MaxTokens;
        this.Temperature = iProperties.Temperature;
        this.TopP = iProperties.TopP;
        this.FrequencyPenalty = iProperties.FrequencyPenalty;
        this.PresencePenalty = iProperties.PresencePenalty;
        this.StopSequence = iProperties.StopSequence;

        iProperties.Choices.forEach(choice => {
            log.debug(this.GetIdentity() + " constructor: Adding choice '" + choice.Value + "'.");

            let newChoice = new Choice(choice.ID, choice.Value);
            this.Choices.push(newChoice);

            this[newChoice.GetPortName()] = new NodePort(newChoice.GetPortName(), "output", this);
        });

        this.m_CurrentRequestController = null;

        /*log.debug(this.GetIdentity() + " constructor: graph = " + this.Graph.ExerciseName 
                    + ", id = " + this.ID 
                    + ", choices count = " + this.Choices.length
                    + ", Question = " + this.Prompt 
                    + ", GPT Engine = " + this.GPTEngine 
                    + ", Max Tokens = " + this.MaxTokens 
                    + ", Temperature = " + this.Temperature 
                    + ", Top P = " + this.TopP 
                    + ", Frequency Penalty = " + this.FrequencyPenalty 
                    + ", Presence Penalty = " + this.PresencePenalty 
                    + ", Stop Sequence = " + this.StopSequence);*/
    }

    OnActivated(iActivator, iInputPort)
    {
        super.OnActivated(iActivator, iInputPort);
        
        this.StartTime = new Date();

        // Retrieve speech text from speech input node
        let speech = this.GetSourceText();
        
        log.debug(this.GetIdentity() + " has been activated by '" + iActivator.GetIdentity() + "'. Speech = '" + speech + "'.");

        this.AskGPTCompletion(speech);
    }

    GetSourceText()
    {
        // If this node is a child of a smart branching decision, we get the speech from it
        if(this.m_ParentNode)
        {
            return this.m_ParentNode.GetSpeech();
        }

        // Else, we get the speech from the first connected node to the Speech port
        let text = "";

        // Get the speech from the first connected node
        let speechNodes = this.Speech.GetConnectedNodes();
        if(speechNodes.length > 0)
        {
            text = speechNodes[0].GetStringValue();
        }

        return text;
    }

    async AskGPTCompletion(iSpeech)
    {
        // Abort current analysis task if running
        this.Abort();

        // Create a new controller for this request
        this.m_CurrentRequestController = new AbortController();
        
        // Replace [Speech] by the speech text
        let finalPrompt = this.Prompt.replace("[Speech]", iSpeech);

        // Ask GPT
        try {
            log.debug(this.GetIdentity() + ".AskGPTCompletion: Calling GPT engine " + this.GPTEngine + " with prompt = '" + finalPrompt + "'...");
            this.GPTAnswer = await window.sdk.openaiAPI().CallGPTAPI(
                finalPrompt, 
                this.GPTEngine, 
                this.MaxTokens, 
                this.Temperature, 
                this.TopP, 
                this.FrequencyPenalty, 
                this.PresencePenalty, 
                this.StopSequence,
                this.m_CurrentRequestController.signal
            );
        } catch (error) {
            if (error.name === 'AbortError') {
                log.debug(this.GetIdentity() + ".AskGPTCompletion: Request was aborted.");
                return;
            } else {
                log.error(this.GetIdentity() + ".AskGPTCompletion: Request failed:", error);
                this.GPTAnswer = {status: "failed", error: error.name, timeSpent: "unknown"};
            }
        }

        this.m_CurrentRequestController = null

        let selectedChoice = null;
        if(this.GPTAnswer && this.GPTAnswer.status == "success")
        {
            log.debug(this.GetIdentity() + ": After " + this.GPTAnswer.timeSpent + " ms, GPT answer = '" + this.GPTAnswer.completion + "'.");

            // Parse the answer to find the integer values
            let answerChoice = -1;

            var numberPattern = /\d+/;
            let number = this.GPTAnswer.completion.match( numberPattern );
            if(number != null && number.length > 0)
            {
                answerChoice = parseInt(number[0]);
            }

            log.debug(this.GetIdentity() + ": ChoiceMade = " + answerChoice);

            // Find the choice port where name integer value is equal to choice
            selectedChoice = this.Choices.find(choice => parseInt(choice.Value) == answerChoice);

            // Get the final choice name
            let choiceName = "Failed";
            if(selectedChoice != null)
            {
                choiceName = JSON.stringify(selectedChoice);
            }

            // Log to DynamoDB
            if(!this.m_ParentNode)
            {
                window.sdk.AnalysisTask().createOne(
                    this.Graph.LastBranchingDecisionNode.DatabaseID, // Parent Branching Decision Node
                    this.ID.toString(), // Node ID
                    "GPT", // analyzer Engine
                    this.GPTEngine, // Analyzer Version
                    "raw", // Analysis Status
                    finalPrompt, // Analysis Input
                    this.StartTime, // Start Time
                    (new Date().getTime() - this.StartTime.getTime()).toString(), // Analysis duration (milliseconds)
                    JSON.stringify(this.Choices), // Possible choices
                    JSON.stringify({
                        "GPT raw answer": this.GPTAnswer.completion,
                        "Final choice": choiceName
                    }), // Analysis Result
                    this.Graph.ExerciseID.toString() // Exercise ID
                );
            }
        }
        else
        {
            log.debug(this.GetIdentity() + ": After " + (this.GPTAnswer ? this.GPTAnswer.timeSpent : "unknown") + " ms, GPT error = '" + (this.GPTAnswer ? this.GPTAnswer.error : "unknown") + "'.");

            // Log to DynamoDB
            if(!this.m_ParentNode)
            {
                window.sdk.AnalysisTask().createOne(
                    this.Graph.LastBranchingDecisionNode.DatabaseID, // Parent Branching Decision Node
                    this.ID.toString(), // Node ID
                    "GPT", // analyzer Engine
                    this.GPTEngine, // Analyzer Version
                    "failed", // Analysis Status
                    finalPrompt, // Analysis Input
                    this.StartTime, // Start Time
                    (new Date().getTime() - this.StartTime.getTime()).toString(), // Analysis duration (milliseconds)
                    JSON.stringify(this.Choices), // Possible choices
                    "Failed! Reason: '" + (this.GPTAnswer ? this.GPTAnswer.status : "Unknown") + "'. Message: '" + (this.GPTAnswer ? this.GPTAnswer.error : "Unknown") + "'.", // Analysis Result
                    this.Graph.ExerciseID.toString() // Exercise ID
                );
            }
        }

        // Activate the chosen port
        this.SetActive(false);
        if(selectedChoice != null)
        {
            // If this node is a child of a smart branching decision, we need to send the chosen port to the parent node
            if(this.m_ParentNode)
            {
                this.m_ParentNode.OnGPTChoiceNodeAnswered({
                    "status": "success",
                    "request": finalPrompt,
                    "answer": this.GPTAnswer,
                    "choice": selectedChoice.Value,
                    "possibleChoices": JSON.stringify(this.Choices)
                });
            }

            let choicePort = this.GetPortByName(selectedChoice.GetPortName());

            if(choicePort != null)
            {
                log.debug(this.GetIdentity() + ": Activating Choice port '" + choicePort.Name + "'.");
                choicePort.ActivateAllConnections();
            }
            else
            {
                log.debug(this.GetIdentity() + ": Impossible to get choice port, activating Failed port.");
                this.Failed.ActivateAllConnections();
            }
        }
        else
        {
            // If this node is a child of a smart branching decision, we need to send the chosen port to the parent node
            if(this.m_ParentNode)
            {
                this.m_ParentNode.OnGPTChoiceNodeAnswered({
                    "status": "failed",
                    "request": finalPrompt,
                    "answer": this.GPTAnswer,
                    "choice": "",
                    "possibleChoices": JSON.stringify(this.Choices)
                });
            }

            log.debug(this.GetIdentity() + ": Impossible to get choice, activating Failed port.");
            this.Failed.ActivateAllConnections();
        }
    }

    Abort()
    {
        // Abort current analysis task if running
        if (this.m_CurrentRequestController) {
            this.m_CurrentRequestController.abort();
            this.m_CurrentRequestController = null;
        }
    }

    OnDeactivated()
    {
        // Stop current GPT question if running
        this.Abort();

        super.OnDeactivated();
    }

    PrintParameters()
    {
        /*log.debug("GPTChoice: graph = " + this.Graph.ExerciseName 
                    + ", id = " + this.ID 
                    + ", Question = " + this.Prompt 
                    + ", GPT Engine = " + this.GPTEngine 
                    + ", Max Tokens = " + this.MaxTokens 
                    + ", Temperature = " + this.Temperature 
                    + ", Top P = " + this.TopP 
                    + ", Frequency Penalty = " + this.FrequencyPenalty 
                    + ", Presence Penalty = " + this.PresencePenalty 
                    + ", Stop Sequence = " + this.StopSequence); */
    }
}

class Choice
{
    ID = -1;
    Value = "";

    constructor(iID, iValue)
    {
        this.ID = iID;
        this.Value = iValue;
    }
    
    GetPortName()
    {
        return "Choice" + this.ID;
    }

    ToString()
    {
        return "{" +
                "\n  Choice: '" + this.Value + "'" +
                "\n  Port: '" + this.GetPortName() + "'" +
                "\n}";
    }
}
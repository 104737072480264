import Utils from "../../AppClasses/Utils/Utils";

export default class BranchingDecision {
    
    constructor(sdk) {
        this.sdk = sdk;
    }
    
    async init() {
        
    }
    
    async createOne(iExerciseSessionID, iDecisionDate, iDecisionStatus, iNodeID, /*iBriefing, iUserAnswerUrl, iChosenBranch,*/ iSucceeded, iPossibleBranches) {

        let body = {
            ExerciseSessionID: iExerciseSessionID,
            ID: Utils.CreateObjectID(iDecisionDate, window.sdk.user().userID),
            DecisionDate: iDecisionDate.toISOString(), // Database format: 2022-10-14T00:09:48.875
            DecisionStatus: iDecisionStatus,
            NodeID: iNodeID,
            PossibleBranches: iPossibleBranches,
            ChosenBranch: "",// iChosenBranch,
            Succeeded: iSucceeded,
            //Briefing: "NA",// iBriefing,
            //UserAnswerUrl: "NA",// iUserAnswerUrl,
            //ExpectedResult: "NA",// iExpectedResult,
            //PropositionIsNeeded: "NA",// iPropositionIsNeeded,
            //S2TError: "NA"// iS2TError,
        };
        let res = await this.sdk.fetch().post('/branchingdecision', { body });

        return res;
    }
    
    async updateItem(iExerciseSessionID, iID, iValuesToUpdate) {

        let body = {
            ExerciseSessionID: iExerciseSessionID,
            ID: iID,
            Values: iValuesToUpdate
        };

        let res = await this.sdk.fetch().post('/branchingdecision/update', { body });

        return res;
    }    

    async getAllFromExerciseSessionID(iExerciseSessionID) {
        let res = await this.sdk.fetch().get('/branchingdecision/getallfromexercisesessionid/' + iExerciseSessionID);
        return res;
    }  
}
import log from "loglevel";
import React from 'react';
import Form from 'react-bootstrap/Form';
import Utils from '../../AppClasses/Utils/Utils';

class DeviceSelector extends React.PureComponent {
    
    constructor(props) {
        super(props);
        this.state = {
            selected: window.sdk.videoconf().mediaDevices().getCurrentDeviceId(this.props.kind)
        };
        this.dbMeter = React.createRef();
        this.listeners = [];
    }
    
    componentDidMount() {
        if(this.props.kind == 'audioinput') {
            this.listeners.push(window.sdk.videoconf().mediaDevices().getAudioManager().on('volume', (data) => {
                //log.debug('volume', data);
                if(this.dbMeter.current)
                    this.dbMeter.current.style.height = Math.round(5 * data * 100) + '%';
            }));
        }
    }

    componentWillUnmount() {        
        for(var i in this.listeners) {
            this.listeners[i]();
        }
    }
    
    updateDevice = (e) => {
        let deviceId = e.target.value;
        this.setState({
            selected: deviceId
        });
        window.sdk.videoconf().mediaDevices().update(this.props.kind, deviceId);
    }
    
    render() {
        log.debug('state', this.state.selected);
        return (
            <Form.Group className="mb-3 col-6 device-selector-item">
                <div className="device-selector-form">
                    <Form.Label>{ this.props.icon ?  <i className={this.props.icon} /> : this.props.label }</Form.Label>
                    <Form.Select onChange={ this.updateDevice } value={ this.state.selected }>
                        { this.props.devices.map((device, i) => (
                            <option key={'option-'+i} value={ device.deviceId }>{device.label}</option>
                        ))}
                    </Form.Select>   
                </div>
                { this.props.kind == 'audioinput' && (
                    
                    <div style={{
                        display: "flex", 
                        flexDirection: "row wrap",
                        alignItems: "stretch",
                        marginTop: "0.5rem"}}>
                        <div style={{
                                flexGrow: "1",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "auto",
                            }}>
                            <p style={{
                                fontSize: "0.8rem",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "auto",
                                textAlign: "right",
                                marginRight: "0.5rem"}}>Jauge microphone</p>
                        </div>
                        <div style={{
                            flexShrink: "1"}}>
                            <div className={'dbMeterContainer'}>
                                <div ref={ this.dbMeter } className={'dbMeter'}>
                                </div>
                            </div>  
                        </div>          
                    </div>)
                        }            
            </Form.Group>
        );
    }
    
}

export default class Settings extends React.PureComponent {
    
    state = {
        show: false,
        videoinput: [],
        audioinput: [],
    }
    
    async componentDidMount() {
        let devices = await window.sdk.videoconf().mediaDevices().enumerate();
        while(Object.keys(devices).length === 0) {
            
        log.debug('devices', devices)
			await Utils.Sleep(300);
			devices = await window.sdk.videoconf().mediaDevices().enumerate(true);
			
		}
        this.setState({
            show: true,
            audioinput: devices['audioinput'],
            videoinput: devices['videoinput']
        });
    }
    
    render() {
        if(!this.state.show)
            return null;
        return (
            <div className="row device-selector">
                <DeviceSelector kind="videoinput" label="Video input" icon={'icon-cam'} devices={ this.state.videoinput } />

                <DeviceSelector kind="audioinput" label="Audio input" icon={'icon-mic'} devices={ this.state.audioinput } />
                
            </div>
        );
    }
    
}
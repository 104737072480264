import log from "loglevel";
import IndexedDB from './Providers/IndexedDB';
import Memory from './Providers/Memory';
import Utils from '../../AppClasses/Utils/Utils.js';
import { isSafari } from 'react-device-detect';

const Providers = { IndexedDB, Memory }

export default class Manager {
	
	Logging = false;
	Status = 'waiting';
	needToJumb = 'no';
	bwChecks = [];
	_needToWaitCompleted = false;
	_bwAverage = 0;
	continueAfterBWCheck = false;
	
	constructor(providerName = 'IndexedDB') {
		
        this.provider = new Providers[providerName]();  
    }
    
    async init() {
		if(window.sdk.isInIframe() && isSafari) {
			this.provider = new Providers['Memory']();  
		}
		try {
			await this.provider.init({
				onClose: () => {
					this.goToFailbackProvider();
				}
			});
		}
		catch(err) {
			log.debug('err', err);
			this.goToFailbackProvider();
		}
    }
	
	goToFailbackProvider() {
		log.debug('CacheManager: goToFailbackProvider');
		let providerName = 'Memory';
		this.provider = new Providers[providerName]();  
		this.provider.init();
	}
    
    async get(name) {
        let content = await this.provider.get(name);
		/*if(content === false) {
			content = await this.fetch(name);
			await this.add(name, content);			
		}*/
		return content;
    }
	
	/*async getOnly(name) {
        let content = await this.provider.get(name);
		return content;
    }*/
	
	async doBWCheck() {
		for(var i=0; i<10; i++) {
			let startedTime = new Date().getTime();
			let envUrl = this._env === 'prod' ? '' : '.dev';
			await this.fetch("bots"+ envUrl + ".practivizio.ai" + '/SpeedTester/bw100kB', (data) => {});
			let endedTime = new Date().getTime();	
			let duration = (endedTime - startedTime) / 1000;
			let mbit = 0.1 * 8;
			let mbitPerSecond = mbit / duration;
			log.debug('doBWCheck', '=======>', mbitPerSecond + ' Mb/s');
			this.bwChecks.push(mbitPerSecond);
		}
	}
	
	
	async getBWAverage(callback) {
		while(this._bwAverage === 0) {
			await Utils.Sleep(300);
		}
		callback(this._bwAverage, () => {
			this.continueAfterBWCheck = true;
		});
	}
	
	getStatus() {
		return this.Status;
	}
	
	async preload(prioritaryList, restList, progression) {
		
		this.Status = 'preloading';
		
		let totalSize = 0;
		let totalBytesReceived = 0;
		
		// Preloading prioritary files to cache
		this.Logging && log.debug('CacheManager: Prioritary files list to preload ', prioritaryList);

		let filesToPreload = [];
		for(var i in prioritaryList) {
			let content = await this.get(prioritaryList[i]);
			if(content === false) {
				filesToPreload.push(prioritaryList[i]);
			}
		}
		
		let sizeByFile = {};
		for(var i in filesToPreload) {
			sizeByFile[i] = await this.getFilesize(filesToPreload[i]);
			totalSize += sizeByFile[i];
		}
		this.Logging && log.debug('CacheManager: TotalSize to preload ', totalSize);
				
		if(filesToPreload.length < 1) {
			await this.doBWCheck();
			progression({
				noPreloadNeed: true
			});
		}
		
		for(var i in filesToPreload) {
			
			this.Logging && log.debug('We are loading filesToPreload in the background', filesToPreload[i]);
			
			let startedTime = new Date().getTime();
			let contentToCache = await this.fetch(filesToPreload[i], (data) => {
					data.totalSize = totalSize;
					totalBytesReceived += data.byteLength;
					data.totalBytesReceived = totalBytesReceived;
					progression(data);
				});
			let endedTime = new Date().getTime();	
			let duration = (endedTime - startedTime) / 1000;
			let mbit = sizeByFile[i] / 1000000 * 8;
			let mbitPerSecond = mbit / duration;
			//log.debug('=======>', mbitPerSecond + ' Mb/s');
			if(mbitPerSecond !== Infinity) {
				this.bwChecks.push(mbitPerSecond);
				//this.add(filesToPreload[i], contentToCache);
			}
			
			if(contentToCache !== false) {
				await this.add(filesToPreload[i], contentToCache);
				this.Logging && log.debug('CacheManager: finished caching ', restList[i]);
			}
			else {
				this.Logging && log.debug('CacheManager: jumped to next');
				this.needToJumb = 'no';
			}
		}
		
		const average = arr => arr.reduce( ( p, c ) => p + c, 0 ) / arr.length;    
		this._bwAverage = average( this.bwChecks );
		
		log.debug('CacheManager: average bandwith :', this._bwAverage);	
		
		// Log average bandwith to DynamoDB
        window.sdk.usersActivity().createOne("Bandwidth", {
			Down: {
				Average: this._bwAverage.toFixed(2),
				Measures: this.bwChecks.map((item) => {
					return item.toFixed(2);
				})
			},
			Unit: 'Mb/s'
		});
		
		while(!this.continueAfterBWCheck) {
			await Utils.Sleep(300);
		}
		
		// Preloading other files to cache
		this.Logging && log.debug('CacheManager: Other files list to preload ', restList);
		
		if(this._needToWaitCompleted) {			
			totalSize = 0;
			totalBytesReceived = 0;
			for(var i in restList) {
				let content = await this.get(restList[i]);			
				if(content === false) {
					totalSize += await this.getFilesize(restList[i]);
				}
			}
		}
		
		for(var i in restList) {
			let content = await this.get(restList[i]);			
			if(content === false) {
				this.Logging && log.debug('We are loading in the background', restList[i]);
				
				let contentToCache = await this.fetch(restList[i], (data) => {
					if(this._needToWaitCompleted) {						
						data.totalSize = totalSize;
						totalBytesReceived += data.byteLength;
						data.totalBytesReceived = totalBytesReceived;
						this._waitCompletedCallback(data);
					}
				});
				
				if(contentToCache !== false) {
					await this.add(restList[i], contentToCache);
					this.Logging && log.debug('CacheManager: finished caching ', restList[i]);
				}
				else {
					this.Logging && log.debug('CacheManager: jumped to next');
					this.needToJumb = 'no';
				}
			}
		}
		
		this.Status = 'completed';
	}
	
	needToWaitCompleted(callback) {
		this._needToWaitCompleted = true;
		this._waitCompletedCallback = callback;
	}
	
	pause() {
		if(this.Status != 'completed')
			this.Status = 'pause';
	}
	
	resume() {
		if(this.Status != 'completed')
			this.Status = 'preloading';
	}
	
	async checkIfNeedToJumpToNext(name) {
		log.debug('checkIfNeedToJumpToNext', this.currentFetch, name);
		if(this.currentFetch == name) {
			this.Logging && log.debug('CacheManager: need to jump and cancel caching of', name);
			this.needToJumb = 'yes';
			while(this.needToJumb == 'yes') {
				await Utils.Sleep(300);
			}
			return true;
		}
		return false;
	}
	
	async fetch(name, progression) {
		
		this.currentFetch = name;
		
		const { headers, body } = await fetch(name);
		
		const contentLength = headers.get('Content-Length');
		if (contentLength === null) throw new Error('No Content-Length response header');
		const fileTotalBytes = parseInt(contentLength, 10);
		//progressIndicator.max = totalBytes;
		
		if (body === null) throw new Error('No response body');
		const reader = body.getReader();
		
		const content = []; // <===
		let bytesReceived = 0;
		
		while (true) {
			
			while(this.Status == 'pause') {
				await Utils.Sleep(300);
			}
			
			if(this.needToJumb == 'yes')
				break;
			
			const { done, value } = await reader.read();
			if (done) break;
		
			content.push(value); // <===
			let byteLength = value.byteLength;
			bytesReceived += byteLength;
		
			progression({
				fileTotalBytes, bytesReceived, byteLength, name
			});
		}
		
		if(this.needToJumb == 'yes')
			return false;
		
		return new Blob(content);
	}
	
	async getFilesize(names) {
		return new Promise((resolve, reject) => {
			var xhr = new XMLHttpRequest();
			xhr.open("HEAD", names, true);
			xhr.onreadystatechange = function() {
				if (this.readyState == this.DONE) {
					resolve(parseInt(xhr.getResponseHeader("Content-Length")));
				}
			};
			xhr.send();
		});
	}
    
    async add(name, content) {
        await this.provider.add(name, content);
    }
	
	async remove(name) {
		await this.provider.remove(name);
	}
	
}
import log from "loglevel";
import Bot from './Bot';
import Human from './Human';

export default class ParticipantsModule
{
    // Callbacks
    OnNewParticipant = null;

    // Internal values
    m_Bots = [];
    m_Humans = [];


    constructor()
    {   
        // Singleton initialization
        if(ParticipantsModule.Instance)
        {
            throw new Error("ParticipantsModule: Singleton classes can't be instantiated more than once.")
        }

        ParticipantsModule.Instance = this;
    }

    Reset()
    {
        this.StopAllParticipants();
    }


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Bots

    StartABot(iBotName, iLoopVideoName, iOnFinished)
    {
        log.debug("ParticipantsModule.StartABot " + iBotName);

        let bot = new Bot(iBotName, iLoopVideoName);
        this.m_Bots.push(bot);
        
        if(this.OnNewParticipant)
        {
            this.OnNewParticipant(bot);
        }
        
        // When the bot is created, call the callback to continue the scenario
        if(iOnFinished)
        {
            iOnFinished();
        }
    }

    GetBot(iBotName)
    {
        for(let i = 0; i < this.m_Bots.length; i++)
        {
            if(this.m_Bots[i].Name === iBotName)
            {
                return this.m_Bots[i];
            }
        }

        return null;
    }

    StopABot(iBotName)
    {
        let bot = this.m_Bots.find(bot => bot.Name === iBotName);
        if(bot)
        {
            bot.Stop();
            this.m_Bots.splice(this.m_Bots.indexOf(bot), 1);
        }
    }

    StopAllBots()
    {
        log.debug('Stop', 'StopAllBots', this.m_Bots)
        this.m_Bots.forEach(bot => {
            bot.Stop();
        });

        this.m_Bots = [];
        this.OnNewParticipant = null;

        for(var i in window.videos) {            
            window.videos[i].delete();
        }
        window.videos = [];
    }

    CreateBotVideoURL(iBotName, iVideoName)
    {
        return window.sdk.CreateBotVideoURL(iBotName, iVideoName); //this.BotsVideosRootFolder + "/" + iBotName + "/Videos/" + iVideoName + ".mp4";
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Humans

    StartLocalHuman(iHumanInfo)
    {
        // Add a new human to the list
        let human = new Human(iHumanInfo);
        this.m_Humans.push(human);

        // Send the human to the callback
        if(this.OnNewParticipant)
        {
            this.OnNewParticipant(human);
        }
    }

    GetHuman()
    {
        return this.m_Humans[0];    
    }

    StopAllParticipants()
    {
        this.m_Humans = [];

        this.StopAllBots();
    }
}
import log from "loglevel";
import React from 'react';
import Utils from '../../AppClasses/Utils/Utils';
import WebcamView from '../MediaDevices/WebcamView';
import ParticipantVideoSlot from './ParticipantVideoSlot';

export default class LocalUser extends ParticipantVideoSlot {

    type = 'human';

    constructor(props) {
        log.debug('LocalUser:constructor', props.human.getID());

        super(props, props.human);

        //this.botClass = '.participant_' + this.props.bot.getID();
    }

    async componentDidMount() {
        window.sdk.event().on('showForbiddenInteractionWarning', () => {
            this.setState({
                showForbiddenInteractionWarning: true
            });
            setTimeout(() => {
                this.setState({
                    showForbiddenInteractionWarning: false
                });              
            }, 10000)
        });
        window.sdk.event().on('hideForbiddenInteractionWarning', () => {
            this.setState({
                showForbiddenInteractionWarning: false
            });  
        });
    }
    close = () => {
        this.setState({
            showForbiddenInteractionWarning: false
        });  
    }
    renderVideo() {
        return (
            <div className={'participant__video'}>
                <div id="forbiddenInteractionsPopup" className={"forbidden-interaction-warning" + (this.state.showForbiddenInteractionWarning ? ' show' : '')}>
                    <div id="forbiddenInteractionsPopupCloseButton" className="close-forbidden-interaction-warning" onClick={this.close}>X</div>
                    <h3>Vous ne pouvez pas intervenir durant cette phase.</h3>
                    <p>Il s’agit d’une phase d’observation.<br />
                    Prenez le temps de découvrir le scénario et les personnages.</p>
                </div>
                <WebcamView />
            </div>
        );
    }
}
import React from 'react';
import Achievement from '../Utilities/Achievement';

export default class StepAchievement extends React.Component {

    render() {
        return (
            <div className={"feedback-step-achievements " + this.props.classAchievement}>
                <div className="feedback-step__inner">
                    <div className="feedback-step__header">
                        <h1 className="feedback-step__title" dangerouslySetInnerHTML={{__html: this.props.text.AchievementsTitle_Text}} />
                    </div>
                    <div className="feedback-step__content">
                        <div className="feedback-step-block feedback-ending">
                            { (this.props.actions.length <= 0) && <center>Vous n'avez pas obtenu de badge de réaction durant cette session.</center> }
                            { (this.props.actions.length > 0) && this.props.actions.map((action, key) => { if (!action.Type.startsWith("Ending")) return <Achievement key={action.ID} achievement={action} name={"achievement-" + key} /> }) }
                        </div>
                    </div>
                </div>
            </div>)
    }
}
StepAchievement.defaultProps =  {
    text:{
        AchievementsTitle_Text : "Découvrez les badges que vos actions ont débloqués"
    }
}
import log from "loglevel";
import SpeechRecognitionBrowser from './Transcripters/SpeechRecognitionBrowser';
import GoogleApi from './Transcripters/GoogleApi';
import AzureApi from './Transcripters/AzureApi';
import AzureBrowser from './Transcripters/AzureBrowser';

const Transcripters = { SpeechRecognitionBrowser, GoogleApi, AzureApi, AzureBrowser }

export default class Adapter {
    
    constructor(transcripterParameters) {
        this.transcripter = new Transcripters[transcripterParameters.provider](transcripterParameters);  
    }
    
    async init() {
        await this.transcripter.init();
    }
    
    start() {
        if(this.transcripter.getStatus() == 'stopped') {
            log.debug('start transcripter')
            this.transcripter.start(); //need Lang ?
        }
    }
    
    stop() {
        if(this.transcripter.getStatus() == 'started') {
            log.debug('stop transcripter')
            this.transcripter.stop();
        }
    }
    
    close() {
        this.transcripter.close();
    }
    
    
}
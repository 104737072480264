import log from "loglevel";
import Utils from '../../Utils/Utils';
import ExerciseNode from './ExerciseNode';
import NodePort from '../NodePort';

export default class GPT3Choice extends ExerciseNode 
{
    // Ports
    Input = new NodePort("Input", "input", this);
    Speech = new NodePort("Speech", "input", this);
    Default = new NodePort("Default", "output", this);

    // Parameters
    Question = "";
    GPT3Engine = "text-davinci-003"; // Or chat: "gpt-3.5-turbo",//"gpt-4"
	MaxTokens = 100;
	Temperature = 0.7;
	TopP = 0;
	FrequencyPenalty = 0;
	PresencePenalty = 0;
	StopSequence = "";

    Choices = [];

    // Dynamic Values
    StartTime = null;
    Prompt = "";
    GPTAnswer = {};

    constructor(iGraph, iProperties)
    {        
        super(iGraph, iProperties);

        this.Question = iProperties.Question;
        this.GPT3Engine = iProperties.GPT3Engine;
        this.MaxTokens = iProperties.MaxTokens;
        this.Temperature = iProperties.Temperature;
        this.TopP = iProperties.TopP;
        this.FrequencyPenalty = iProperties.FrequencyPenalty;
        this.PresencePenalty = iProperties.PresencePenalty;
        this.StopSequence = iProperties.StopSequence;

        iProperties.Choices.forEach(choice => {
            log.debug(this.GetIdentity() + " constructor: Adding choice '" + choice.Value + "'.");

            let newChoice = new Choice(choice.ID, choice.Value);
            this.Choices.push(newChoice);

            this[newChoice.GetPortName()] = new NodePort(newChoice.GetPortName(), "output", this);
        });

        /*log.debug(this.GetIdentity() + " constructor: graph = " + this.Graph.ExerciseName 
                    + ", id = " + this.ID 
                    + ", choices count = " + this.Choices.length
                    + ", Question = " + this.Question 
                    + ", GPT3 Engine = " + this.GPT3Engine 
                    + ", Max Tokens = " + this.MaxTokens 
                    + ", Temperature = " + this.Temperature 
                    + ", Top P = " + this.TopP 
                    + ", Frequency Penalty = " + this.FrequencyPenalty 
                    + ", Presence Penalty = " + this.PresencePenalty 
                    + ", Stop Sequence = " + this.StopSequence);*/
    }

    OnActivated(iActivator, iInputPort)
    {
        super.OnActivated(iActivator, iInputPort);
        
        this.StartTime = new Date();

        // Retrieve speech text from speech input node
        let speech = this.GetSourceText();
        
        log.debug(this.GetIdentity() + " has been activated by '" + iActivator.GetIdentity() + "'. Speech = '" + speech + "'.");

        this.AskGPTCompletion(speech);
    }

    GetSourceText()
    {
        let text = "";

        // Get the speech from the first connected node
        let speechNodes = this.Speech.GetConnectedNodes();
        if(speechNodes.length > 0)
        {
            text = speechNodes[0].GetStringValue();
        }

        return text;
    }

    async AskGPTCompletion(iSpeech)
    {
        // Replace [Speech] by the speech text
        this.Prompt = this.Question.replace("[Speech]", iSpeech);

        // Ask GPT
        log.debug(this.GetIdentity() + ": Calling GPT engine " + this.GPT3Engine + " with prompt = '" + this.Prompt + "'...");
        this.GPTAnswer = await window.sdk.openaiAPI().CallGPTAPI(
            this.Prompt, 
            this.GPT3Engine, 
            this.MaxTokens, 
            this.Temperature, 
            this.TopP, 
            this.FrequencyPenalty, 
            this.PresencePenalty, 
            this.StopSequence
        );

        let choiceObj = null;
        if(this.GPTAnswer.status == "success")
        {
            log.debug(this.GetIdentity() + ": After " + this.GPTAnswer.timeSpent + " ms, GPT-3 answer = '" + this.GPTAnswer.completion + "'.");

            // Parse the answer to find the integer values
            let answerChoice = -1;

            var numberPattern = /\d+/;
            let number = this.GPTAnswer.completion.match( numberPattern );
            if(number != null && number.length > 0)
            {
                answerChoice = parseInt(number[0]);
            }

            log.debug(this.GetIdentity() + ": ChoiceMade = " + answerChoice);

            // Find the choice port where name integer value is equal to choice
            choiceObj = this.Choices.find(choice => parseInt(choice.Value) == answerChoice);

            // Get the final choice name
            let choiceName = "Default";
            if(choiceObj != null)
            {
                choiceName = JSON.stringify(choiceObj);
            }

            // Log to DynamoDB
            window.sdk.AnalysisTask().createOne(
                this.Graph.LastBranchingDecisionNode.DatabaseID, // Parent Branching Decision Node
                this.ID.toString(), // Node ID
                "GPT-3", // analyzer Engine
                this.GPT3Engine, // Analyzer Version
                "raw", // Analysis Status
                this.Prompt, // Analysis Input
                this.StartTime, // Start Time
                (new Date().getTime() - this.StartTime.getTime()).toString(), // Analysis duration (milliseconds)
                JSON.stringify(this.Choices), // Possible choices
                JSON.stringify({
                    "GPT-3 raw answer": this.GPTAnswer.completion,
                    "Final choice": choiceName
                }), // Analysis Result
                this.Graph.ExerciseID.toString() // Exercise ID
            );
        }
        else
        {
            log.debug(this.GetIdentity() + ": After " + this.GPTAnswer.timeSpent + " ms, GPT-3 error = '" + this.GPTAnswer.error + "'.");

            // Log to DynamoDB
            window.sdk.AnalysisTask().createOne(
                this.Graph.LastBranchingDecisionNode.DatabaseID, // Parent Branching Decision Node
                this.ID.toString(), // Node ID
                "GPT-3", // analyzer Engine
                this.GPT3Engine, // Analyzer Version
                "failed", // Analysis Status
                this.Prompt, // Analysis Input
                this.StartTime, // Start Time
                (new Date().getTime() - this.StartTime.getTime()).toString(), // Analysis duration (milliseconds)
                JSON.stringify(this.Choices), // Possible choices
                "Failed! Reason: '" + this.GPTAnswer.status + "'. Message: '" + this.GPTAnswer.error + "'.", // Analysis Result
                this.Graph.ExerciseID.toString() // Exercise ID
            );
        }

        // Activate the choice port
        this.SetActive(false);
        if(choiceObj != null)
        {
            let choicePort = this.GetPortByName(choiceObj.GetPortName());

            if(choicePort != null)
            {
                log.debug(this.GetIdentity() + ": Activating Choice port '" + choicePort.Name + "'.");
                choicePort.ActivateAllConnections();
            }
            else
            {
                log.debug(this.GetIdentity() + ": Impossible to get choice port, activating Default port.");
                this.Default.ActivateAllConnections();
            }
        }
        else
        {
            log.debug(this.GetIdentity() + ": Impossible to get choice, activating Default port.");
            this.Default.ActivateAllConnections();
        }
    }

    OnDeactivated()
    {
        // Stop current GPT3 question if running
        // TODO: GPT3.Instance.CancelGPT3Choice(this);

        super.OnDeactivated();
    }

    PrintParameters()
    {
        /*log.debug("GPT3Choice: graph = " + this.Graph.ExerciseName 
                    + ", id = " + this.ID 
                    + ", Question = " + this.Question 
                    + ", GPT3 Engine = " + this.GPT3Engine 
                    + ", Max Tokens = " + this.MaxTokens 
                    + ", Temperature = " + this.Temperature 
                    + ", Top P = " + this.TopP 
                    + ", Frequency Penalty = " + this.FrequencyPenalty 
                    + ", Presence Penalty = " + this.PresencePenalty 
                    + ", Stop Sequence = " + this.StopSequence); */
    }
}

class Choice
{
    ID = -1;
    Value = "";

    constructor(iID, iValue)
    {
        this.ID = iID;
        this.Value = iValue;
    }
    
    GetPortName()
    {
        return "Choice" + this.ID;
    }

    ToString()
    {
        return "{" +
                "\n  Choice: '" + this.Value + "'" +
                "\n  Port: '" + this.GetPortName() + "'" +
                "\n}";
    }
}
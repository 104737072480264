import log from "loglevel";
import Abstract from './Abstract';
import { ResultReason } from 'microsoft-cognitiveservices-speech-sdk';
const sdk = require('microsoft-cognitiveservices-speech-sdk')


//https://docs.microsoft.com/en-us/javascript/api/microsoft-cognitiveservices-speech-sdk/audioconfig?view=azure-node-latest
//https://github.com/Azure-Samples/AzureSpeechReactSample/blob/main/src/App.js

export default class AzureBrowser extends Abstract {
    
    async init() {
        const speechConfig = sdk.SpeechConfig.fromAuthorizationToken(this.parameters.data.token, this.parameters.data.region);
        speechConfig.speechRecognitionLanguage = this.parameters.lang;
        const audioConfig = sdk.AudioConfig.fromStreamInput(this.parameters.audioManager.getStream());
        this.recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);
    }
    
    startAudioManager() {
    
    }
    
    stopAudioManager() {
    
    }
    
    _start() {
        log.debug('this.recognizer.recognized', this.recognizer)
        
        
        this.recognizer.recognizing = (s, e) => {
            log.debug(`RECOGNIZING: Text=${e.result.text}`, this.parameters);
            this.parameters.onPartialTranscriptedTextFromClient({
                text: e.result.text,
                raw: e
            });
        };

        this.recognizer.recognized = (s, e) => {
            if (e.result.reason == sdk.ResultReason.RecognizedSpeech) {
                log.debug(`RECOGNIZED: Text=${e.result.text}`);
                this.parameters.onTranscriptedTextFromClient({
                    text: e.result.text,
                    raw: e
                });
            }
            else if (e.result.reason == sdk.ResultReason.NoMatch) {
                log.debug("NOMATCH: Speech could not be recognized.");
            }
        };
        
        this.recognizer.startContinuousRecognitionAsync();
    }

    _stop() {
        this.recognizer.close();
    }
    
}

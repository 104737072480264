import log from "loglevel";
import Utils from '../../Utils/Utils';
import ExerciseNode from './ExerciseNode';
import NodePort from '../NodePort';

export default class SemanticSearch extends ExerciseNode 
{
    // Ports
    Input = new NodePort("Input", "input", this);
    Speech = new NodePort("Speech", "input", this);
    Failed = new NodePort("Failed", "output", this);

    // Parameters
    TargetBot = "";
    ItemsToFind = [];

    // Internal
    m_CurrentInputSpeech = "";
    m_StartTime = null;

    constructor(iGraph, iProperties)
    {        
        super(iGraph, iProperties);

        this.TargetBot = iProperties.TargetBot;

        iProperties.ItemsToFind.forEach(itemToFind => {
            log.debug(this.GetIdentity() + " constructor: Adding an item to find '" + itemToFind.Item + "'.");

            let newItemToFind = new ItemToFind(itemToFind.ID, itemToFind.Item);
            this.ItemsToFind.push(newItemToFind);

            this[newItemToFind.GetPortName()] = new NodePort(newItemToFind.GetPortName(), "output", this);
        });

        /*log.debug(this.GetIdentity() + " constructor: graph = " + this.Graph.ExerciseName 
                    + ", id = " + this.ID 
                    + ", ItemsToFind count = " + this.ItemsToFind.length);*/
    }

    async OnActivated(iActivator, iInputPort)
    {
        super.OnActivated(iActivator, iInputPort);

        // Retrieve speech text from speech input node
        let speech = this.GetSourceText();
        
        log.debug(this.GetIdentity() + " has been activated by '" + iActivator.GetIdentity() + "'. Speech = '" + speech + "'.");

        const result = await this.AskAPI(speech);
        this.LogToDatabase(result);
        this.ActivateSelectedOutput(result);
    }

    GetSourceText()
    {
        let text = "";

        // Get the speech from the first connected node
        let speechNodes = this.Speech.GetConnectedNodes();
        if(speechNodes.length > 0)
        {
            text = speechNodes[0].GetStringValue();
        }

        return text;
    }

    async AskAPI(iSpeech, iMode = "normal")
    {
        this.m_CurrentInputSpeech = iSpeech;
        this.m_StartTime = new Date();

        // Ask Semantic Search API       
        let answer = await window.sdk.semanticSearchAPI().Ask(iSpeech, this.Graph.ExerciseID.toString(), "BD" + this.Graph.LastBranchingDecisionNode.ID, this.TargetBot);

        // Extract item to find
        const itemToFind = this.ItemsToFind.find(itemToFind => itemToFind.Item === answer.label);

        // Get port to activate
        let portToActivate = this.Failed;
        if(itemToFind)
        {
            portToActivate = this[itemToFind.GetPortName()];
        }

        return {answer: answer, itemToFind: itemToFind, portToActivate: portToActivate};
    }

    async LogToDatabase(iResult)
    {
        // Log to DynamoDB
        window.sdk.AnalysisTask().createOne(
            this.Graph.LastBranchingDecisionNode.DatabaseID, // Parent Branching Decision Node
            this.ID.toString(), // Node ID
            "SemanticSearchAPI", // analyzer Engine
            iResult.answer["api-version"], // Analyzer Version
            "raw", // Analysis Status
            this.m_CurrentInputSpeech, // Analysis Input
            this.m_StartTime, // Start Time
            (new Date().getTime() - this.m_StartTime.getTime()).toString(), // Analysis duration (milliseconds)
            JSON.stringify(this.ItemsToFind.map(itemToFind => itemToFind.Item)), // Possible choices
            JSON.stringify(iResult.answer), // Analysis Result
            this.Graph.ExerciseID.toString() // Exercise ID
        );
    }

    async ActivateSelectedOutput(iResult)
    {
        // Activate the corresponding output
        this.SetActive(false);
        if(iResult.portToActivate === this.Failed)
        {
            log.error(this.GetIdentity() + ": answer = " + iResult.answer.label + " not found!\nActivating output 'Failed'.\nRequest result: " + JSON.stringify(iResult.answer));
        }
        else
        {
            log.debug(this.GetIdentity() + ": answer = " + iResult.answer.label + ".\nActivating output '" + iResult.itemToFind.GetPortName() + "'.\nRequest result: " + JSON.stringify(iResult.answer));
        }

        iResult.portToActivate.ActivateAllConnections();
    }

    OnDeactivated()
    {
        // Stop current API call if running

        super.OnDeactivated();
    }

    PrintParameters()
    {
    }
    
    

    //////////////////////////
    // Test functions
    //////////////////////////

    TestExecute(iActivator, iInputPort, iTestReport)
    {
        // Initialize the test
        this.SetTestMode(true);
        
        const result = this.AskAPI(iTestReport.UserSpeech);

        // TODO iTestReport
        
        result.portToActivate.TestActivateAllConnections(iTestReport);
    }
}

class ItemToFind
{
    ID = -1;
    Item = "";

    constructor(iID, iItem)
    {
        this.ID = iID;
        this.Item = iItem;
    }
    
    GetPortName()
    {
        return "Item" + this.ID;
    }

    ToString()
    {
        return "{" +
                "\n  Item: '" + this.Item + "'" +
                "\n  Port: '" + this.GetPortName() + "'" +
                "\n}";
    }
}
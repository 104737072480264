import log from "loglevel";
import React from 'react';
import BootstrapButton from 'react-bootstrap/Button';
import Button from '../../components/Utilities/Button';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation } from 'react-router';
import { useNavigate }  from "react-router-dom";


export function withRouter(Children) {
  return (props) => {
      const location = useLocation() ;
      const navigate = useNavigate();
      return <Children {...props} navigate={navigate} match={location.state}  />
  }
}

class PasswordLost extends React.Component {

  state = {
    email: this.props.match ? this.props.match.email : '',
    status: '',
    info: {}
  };


  // On form submit
  submit = async () => {
    let res = await window.sdk.user().forgotPassword({
      email: this.state.email,
    });

    log.debug('res', res);

    if (res.state === 'fail') {
      log.debug('res', res);
      this.setState({
        status: 'fail',
        info: res.info
      });
    } else {
      this.setState({
        status: 'success',
        info: res.info
      });
      this.props.navigate('/edit-password', false)
      //document.location.href = '/edit-password';
    }
  }

  // Handle email field change
  updateEmail = (value) => {
    this.setState({
      email: value,
      status: '',
    });
  }

  render() {
    return (
      <div className="password-lost">
        <form className="login-layout__form password-lost__form" onSubmit={(e) => {e.preventDefault()}}>
          <h2 className="form__title">Recevez par email un code de renouvellement de mot de passe </h2>
          <div className="form__inputs">

            { this.state.status === 'fail' &&
              <Alert className="alert" variant="danger">{ this.state.info.message }</Alert>
            }

            { this.state.status === 'success' &&
              <Alert className="alert" variant="success">{ this.state.info.message }</Alert>
            }

            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" name="email" autoComplete="email" placeholder="Email" onChange={(e) => this.updateEmail(e.target.value) } value={ this.state.email } required />
            </Form.Group>

            <div className="submit-container mt-4 mb-3">
              <Button variant="primary" disabled={ this.props.loading } onClick={ this.submit }>Envoyer</Button>
            </div>

            <div className="my-3">
              <LinkContainer to="/">
                <BootstrapButton variant="link">Connexion</BootstrapButton>
              </LinkContainer>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default withRouter(PasswordLost);
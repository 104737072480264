import log from "loglevel";
import ExerciseNode from './ExerciseNode';
import NodePort from '../NodePort';
import ParticipantsModule from '../../Participants/ParticipantsModule';

export default class BotConnection extends ExerciseNode 
{
    // Ports
    Input = new NodePort("Input", "input", this);
    Output = new NodePort("Output", "output", this);

    // Parameters
    BotName = "";
    DefaultLoop = "";
    PreferredPosition = 0;

    // Internal values


    constructor(iGraph, iProperties) 
    {
        super(iGraph, iProperties);
 
        this.BotName = iProperties.BotName;
        this.DefaultLoop = iProperties.DefaultLoop;
        this.PreferredPosition = iProperties.PreferredPosition;

        //log.debug(this.GetIdentity() + " constructor: graph = " + this.Graph.ExerciseName + ", id = " + this.ID + ", botName = " + this.BotName + ", defaultLoop = " + this.DefaultLoop + ", preferredPosition = " + this.PreferredPosition); 
    }

    OnActivated(iActivator, iInputPort)
    {
        log.debug(this.GetIdentity() + " has been activated by '" + iActivator.GetIdentity() + "'.");
        log.debug(this.GetIdentity() + " starting Bot '" + this.BotName + "'...");
        super.OnActivated(iActivator, iInputPort);

        // Start and gives a callback when finished
        if(ParticipantsModule.Instance != null)
        {
            log.debug("BotsModule.Instance != null");
            ParticipantsModule.Instance.StartABot(this.BotName, this.DefaultLoop, () => this.OnActionFinished());
            this.Graph.History.AddEvent("BotConnection", {
                NodeID: this.ID, 
                Character: this.BotName, 
                LoopVideo: this.DefaultLoop});
        }
        else
        {
            log.debug("BotsModule.Instance == null");
        }
    }

    OnActionFinished()
    {
        log.debug(this.GetIdentity() + ": Bot '" + this.BotName + "' finished its connection action.");

        this.ActivateOutput();
    }
        
    ActivateOutput()
    {
        log.debug(this.GetIdentity() + "' activating output.");

        this.SetActive(false);

        this.Output.ActivateAllConnections();
    }

    PrintParameters()
    {
        //log.debug("BotName = " + this.BotName + ", DefaultLoop = " + this.DefaultLoop + ", PreferredPosition = " + this.PreferredPosition);
    }
}
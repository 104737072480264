import log from "loglevel";
export default class BadWordsAPI {

    constructor(sdk) {
        this.sdk = sdk;
    }
    
    async Initialize() {
        
    }
    
    async Ask(iSpeech) {
        
        log.debug("BadWordsAPI Ask: iSpeech = " + iSpeech + ".");
        let result = await this.sdk.fetch().post('/badwordsapi',  { 
		    body: {
                text: iSpeech,
                custom_list:[]
            }
		});

        return result;
    }
  }
  
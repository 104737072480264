import log from "loglevel";
import * as Sentry from "@sentry/browser";
import Cognito from "../Utils/Cognito";

export default class User {
    
    isLogged = false;
    
    constructor(sdk) {
        this.sdk = sdk;
		this.cognito = new Cognito(sdk);
    }
    
    async init() {
		await this.cognito.init();
        let session;
        try {
            session = JSON.parse(window.localStorage.getItem("session"));
            if(session) {
				this.sdk.fetch().addGlobalHeader('X-Cognito-Token', session.token);
				this.sdk.fetch().addGlobalHeader('X-User-Session', session.id );
                let me = await this.sdk.fetch().get('/me');
                if(me.state == 'success')
				{
					if(this.sdk.isInIframe() && window.sdk.getParam('needCheck')) {
						this.sdk.event().emit('needSesionConfirmation', 
							me.session, 
							(confirmation) => {
								if(confirmation) {			
									this.createUser(session, me.session);
									this.sdk.event().emit('userLogged');
								}		
								else {
									this.logout();
								}
							}
						);
					}
					else {
						this.createUser(session, me.session);
						this.sdk.event().emit('userLogged');
					}
				}
            }
			else {
				//window.localStorage.addItem("redirect-url", document.location.href);
			}
        }
        catch(err) {
            log.debug(err);
        }
    }


	async createSessionFromCognito(idToken, accessToken, refreshToken) {
		return await this.sdk.fetch().post('/create-session-from-cognito', { body: { idToken, accessToken, refreshToken } });
	}

	async validateSession() {
		let res = await this.cognito.validateSession();
		log.debug('validateSession', res);
		if(res.state == 'EXPIRED') {
			await this.refreshSession();
		}
		else if(res.state == 'VALID') {
			this.sdk.fetch().addGlobalHeader('X-Cognito-Token', res.idToken);
		}
	}

	async refreshSession() {
		let newSession = await this.cognito.refreshSession();
		if(newSession.state == 'SUCCESS') {
			log.debug('new cognito token', newSession.idToken);
			this.sdk.fetch().addGlobalHeader('X-Cognito-Token', newSession.idToken);
		}
	}

    async login(body) {

		let cognitoLogin = await this.cognito.login(body.username, body.password);

        if(cognitoLogin.state == 'SUCCESS') {

			let cognitoUserSession = cognitoLogin.session;

			let redirect_url = window.sdk.getParam('redirect_url');
			if(!redirect_url){
				redirect_url = body.redirect_url;
			}
			let goTo = '/'
			if(redirect_url)
				goTo = redirect_url.split('needCheck').join('');

			log.debug('ModelUser', 'cognitoUserSession created', cognitoUserSession);

			let session = await this.createSessionFromCognito(cognitoUserSession.idToken.jwtToken, cognitoUserSession.accessToken.jwtToken, cognitoUserSession.refreshToken.token);

			log.debug('ModelUser', session);

			this.sdk.fetch().addGlobalHeader('X-Cognito-Token', cognitoUserSession.idToken.jwtToken);
			this.sdk.fetch().addGlobalHeader('X-User-Session', session.id);

            this.createUser(session, null);
			
			/*log.debug('--- DEBUG: Login redirect_url: ', redirect_url);
			log.debug('--- DEBUG: Login goTo: ', goTo);
			log.debug('--- DEBUG: Login goTo.replaced: ', goTo.replace(/^[a-z]{4,5}\:\/{2}[a-z]{1,}\:[0-9]{1,4}.(.*)/, '/$1'));
			log.debug('--- DEBUG: this.sdk: ', this.sdk);
			log.debug('--- DEBUG: this.sdk.event(): ', this.sdk.event());*/

			this.sdk.event().emit('goTo',goTo.replace(/^[a-z]{4,5}\:\/{2}[a-z]{1,}\:[0-9]{1,4}.(.*)/, '/$1'))

			// Log to DynamoDB
			window.sdk.usersActivity().createOne("Login", {State: "Success"});
        }
		else
		{
			log.debug('--- DEBUG: Login res.state != success)');	
		}
		
        return cognitoLogin;
    }
    
	async register(body){

		let res =  await this.sdk.fetch().post('/register', { body });

		// Log to DynamoDB
		if(res.state == 'success')
		{
			window.sdk.usersActivity().createOne("ActivateUser", {UserID: res.info.UserID});
		}

		return res;
	}

    async logout() {
	
		// Log to DynamoDB
		window.sdk.usersActivity().createOne("Logout", {});
		
		if(window.sdk.fullscreenStatus)
			this.sdk.closeFullscreen(); 
		this.sdk.event().emit('fetchStarted');
        await this.sdk.fetch().get('/logout');
        window.localStorage.removeItem("session", null);

		this.cognito.logout();
		
		// Remove User from Sentry
		Sentry.setUser(null);

        this.sdk.event().emit('userLogOut');
    }
    
    async createUser(session, userInfo) {
		this.isLogged = true;

		// Get session data
		this.sessionID = session.id
		this.userID = session.userID;
		this.name = session.name;
		this.email = session.email;

		// Get user data
		if(userInfo)
		{
			// Get user data from the userInfo input
			this.firstName = userInfo.firstName;
			this.lastName = userInfo.lastName;
			this.role = userInfo.role;
			this.entity = userInfo.entity;
			this.availableExercises = userInfo.availableExercises;
		}
		else
		{
			// Retrieve user data from the database
			let me = await this.sdk.fetch().get('/me');
			if(me.state == 'success')
			{
				this.firstName = me.session.firstName;
				this.lastName = me.session.lastName;
				this.role = me.session.role;
				this.entity = me.session.entity;
				this.availableExercises = me.session.availableExercises;
			}
			else
			{
				// User not found in the database
				Sentry.captureException(new Error('User not found in the database'));
				log.debug('Error: User not found in the database!\nUserID = ' + session.userID);
			}
		}

		// Save session data in local storage
        window.localStorage.setItem("session", JSON.stringify(session));

		// Set User in Sentry
		Sentry.setUser({
			id: session.userID});
       //this.sdk.event().emit('userLogged',redirect);
    }
    
    async forgotPassword(data) {
		
		// Log to DynamoDB
		window.sdk.usersActivity().createOne("ForgotPassword", {});

		return await this.sdk.fetch().post('/forgot-password', { 
		    body: data
		});
	}
	
	async passwordEdit(data) {

		return await this.sdk.fetch().post('/confirm-forgot-password', { 
		    body: data
		});
	}

async checkPending(data){
	let body = {
		Email: data.Email
	}
	let res = await this.sdk.fetch().post('/check-email', { body });
	return res;
}

    async registerPending(data){
		let iStartTime = new Date;
		let PendingUserID =  iStartTime.getFullYear().toString().padStart(4, '0') 
		+ (iStartTime.getMonth()+1).toString().padStart(2, '0')
		+ iStartTime.getDate().toString().padStart(2, '0')
		+ iStartTime.getHours().toString().padStart(2, '0')
		+ iStartTime.getMinutes().toString().padStart(2, '0')
		+ iStartTime.getSeconds().toString().padStart(2, '0')
		+ iStartTime.getMilliseconds().toString().padStart(3, '0')
		//+ '-' + window.sdk.user().userID;
			let body = {
				ID: PendingUserID,
				Email: data.Email,
				Entity: data.Entity,
				FirstName: data.FirstName,
				LastName: data.LastName
			};
			let res = await this.sdk.fetch().post('/register/pending', { body });
	
			return res;
	}
}
import log from "loglevel";
import Utils from "../../AppClasses/Utils/Utils";

export default class AnalysisTask {
    
    constructor(sdk) {
        this.sdk = sdk;
    }
    
    async init() {
        
    }
    
    async createOne(iBranchingDecisionID, 
                    iNodeID, 
                    iAnalyzerEngine, 
                    iAnalyzerVersion,
                    iAnalysisStatus, 
                    iAnalysisInput, 
                    iStartTime, 
                    iAnalysisDuration, 
                    iPossibleChoices,
                    iAnalysisResult,
                    iExerciseID) {
            
        let body = {
            BranchingDecisionID: iBranchingDecisionID,
            ID: Utils.CreateObjectIDWithIncrement(iStartTime, window.sdk.user().userID, iAnalyzerEngine),
            NodeID: iNodeID,
            AnalyzerEngine: iAnalyzerEngine,
            AnalyzerVersion: iAnalyzerVersion,
            AnalysisStatus: iAnalysisStatus,
            AnalysisInput: iAnalysisInput,
            StartTime: iStartTime,
            AnalysisDuration: iAnalysisDuration,
            PossibleChoices: iPossibleChoices,
            AnalysisResult: iAnalysisResult,
            ExerciseID: iExerciseID 
        };
        let res = await this.sdk.fetch().post('/analysistask', { body });

        return res;
    }

    async getAllFromBranchingDecisionID(iBranchingDecisionID) {
        log.debug("AnalysisTask model getAllFromBranchingDecisionID:", iBranchingDecisionID);
        let res = await this.sdk.fetch().get('/analysistask/getallfrombranchingdecisionid/' + iBranchingDecisionID);
        return res;
    }  
}
import React  from 'react';

import '../../styles/components/Feedback.scss';

import { useParams } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from '../../components/Utilities/Button';

import ExerciseGraph from '../../AppClasses/ExerciseScenario/ExerciseGraph';
import StepAchievement from '../../components/Retry/StepAchievement';
import StepGoal from '../../components/Retry/StepGoal';

export function withRouter(Children) {
    return (props) => {
        const match = { params: useParams() };
        return <Children {...props}  match={match} />
    }
}


class RetryPanel extends React.Component {

    state = {
        step: 1
    }
    translateAmount = 100;
    translate = 0;

    jsonGraph = {};
    goals = []

    async componentDidMount() {
        let res = await window.sdk.exerciseSession().getOneWithExercise(this.props.match.params.idExercise,this.props.match.params.idExerciseSession);
        
        this.jsonGraph = res.exercise;
        this.ExerciseGraph = new ExerciseGraph(this.jsonGraph);
        this.exerciseSession = res.exerciseSession;

        this.achievements = this.ExerciseGraph.GetAchievementsToDisplay();
        this.goals = this.ExerciseGraph.GetObjectivesToDisplay();
        
        this.setState({
            loaded: true
        });
    }

    submitStep = () => {
        if (this.state.step == 2){
            document.location.href = '/exercise/'+ this.props.match.params.idExercise;
        }
        else if (this.state.step < 2){        
        this.setState({
            step : this.state.step + 1
        })

        const pages = document.querySelectorAll(".feedback-step");

        this.translate -= this.translateAmount
        pages.forEach(
            pages => (pages.style.transform = `translateX(${this.translate}%)`)
        );
        }
    }

    stop = () => {
		if(window.sdk.isInIframe()) {			
			window.sdk.event().emit('showExitedModal ');
		}
		else {
			document.location.href = '/';
		}
			
    }

    render() {
        if(!this.state.loaded)
        return null; //show loader        
        
        return (
            <div className={"feedback retry retry-step-" + this.state.step}>
                <div className="feedback-step-container">
                    <div className={"feedback-step feedback-step-1" + (this.state.step == 1 ? ' active' : '')}>
                        <StepAchievement achievements={this.achievements} currentExerciseSessionID={this.props.match.params.idExerciseSession}  />
                    </div>
                    <div className={"feedback-step feedback-step-2" + (this.state.step == 2 ? ' active' : '')}>
                        <StepGoal goals={this.goals} />
                    </div>
                </div>
                <Container className={'feedback__actions'}>
                    <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                        {this.state.step == 1 &&<Col style={{ textAlign: 'center' }}>
                            <Button  variant="light" onClick={ this.stop }> Arrêter</Button>
                        </Col>}
                        <Col className="text-center">
                            <Button variant="primary" onClick={ this.submitStep }>
                                {(() => {
                                    switch (this.state.step) {
                                        case 1: return 'Réessayer'
                                        default: return 'Valider'
                                    }
                                })()}
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default withRouter(RetryPanel);

import log from "loglevel";
import ExerciseNode from './ExerciseNode';
import ValueBool from './ValueBool';
import NodePort from '../NodePort';

export default class If extends ExerciseNode
{
    // Ports
    Input = new NodePort("Input", "input", this);
    Condition = new NodePort("Condition", "input", this);
    OutputTrue = new NodePort("OutputTrue", "output", this);
    OutputFalse = new NodePort("OutputFalse", "output", this);

    // Parameters

    constructor(iGraph, iProperties) 
    {      
        super(iGraph, iProperties);
        //log.debug(this.GetIdentity() + " constructor: graph = " + this.Graph.ExerciseName + ", id = " + this.ID); 
    }
    
    OnActivated(iActivator, iInputPort)
    {
        super.OnActivated(iActivator, iInputPort);
        log.debug(this.GetIdentity() + " has been activated by '" + iActivator.GetIdentity() + "'.");
        
        let boolValue = this.GetValue();

        if(boolValue)
        {
            this.OutputTrue.ActivateAllConnections();
        }
        else
        {
            this.OutputFalse.ActivateAllConnections();
        }
        
        this.SetActive(false);
    }

    GetValue()
    {
        let value = undefined;
        this.Condition.GetConnectedNodes().forEach(node => {
            if(node instanceof ValueBool)
            {        
                value = node.GetValue();
                log.debug(this.GetIdentity() + " GetValue: received the value " + value);
                return value;
            }
        });

        if(value === undefined)
        {
                log.error(this.GetIdentity() + " GetValue: no value received!");
        }
        else
        {
            return value;
        }
    }

    PrintParameters()
    {
        //log.debug("ValueBool: ID = " + this.ID + ", Name = " + this.Name + ".");
    }
}
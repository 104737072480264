import log from "loglevel";
import ExerciseNode from './ExerciseNode';
import NodePort from '../NodePort';

export default class Act extends ExerciseNode 
{
    // Ports
    Input = new NodePort("Input", "input", this);
    Output = new NodePort("Output", "output", this);

    // Parameters
    NodeName = "";
    
    constructor(iGraph, iProperties) 
    {     
        super(iGraph, iProperties);   

        this.NodeName = iProperties.NodeName;
        
        //log.debug(this.GetIdentity() + " constructor: graph = " + this.Graph.ExerciseName + ", id = " + this.ID + ", iNodeName = " + this.NodeName + ".");
    }

    OnActivated(iActivator, iInputPort)
    {
        super.OnActivated(iActivator, iInputPort);

        log.debug(this.GetIdentity() + " has been activated by '" + iActivator.GetIdentity() + "'.");

        this.Graph.CurrentActNode = this;

        this.ActivateOutput();
    }
        
    ActivateOutput()
    {
        log.debug(this.GetIdentity() + "' activating output.");

        this.SetActive(false);

        this.Output.ActivateAllConnections();
    }

    PrintParameters()
    {
        //log.debug("Act: NodeName = " + this.NodeName + ".");
    }
}
import log from "loglevel";
import Utils from '../../Utils/Utils';
import ExerciseNode from './ExerciseNode';
import NodePort from '../NodePort';
import SpeechToText from './SpeechToText';
import GPTChoice from './GPTChoice';

// Status enums
const STTStatus = {
    Inactive: Symbol("inactive"),
}
const AnalysisStatus = {
    WaitingForFirstWord: Symbol("waitingForFirstWord"),
}

export default class SmartBranchingDecision extends ExerciseNode
{
    // Ports
    Input = new NodePort("Input", "input", this);
    SpeechStarted = new NodePort("SpeechStarted", "output", this);
    SpeechEnded = new NodePort("SpeechEnded", "output", this);
    Failed = new NodePort("Failed", "output", this);

    // Parameters
    BranchingDecisionName = "";
    STTPhraseList = [];
    STTEnSilence = 1;
    Branches = [];
    IgnoreUserActions = false;
    Exceptions = [];

    // GPT mode parameters
    GPTMode = false;
    GPT_Prompt = "";
    GPT_GPTEngine = "";
    GPT_MaxTokens = 0;
    GPT_Temperature = 0;
    GPT_TopP = 0;
    GPT_FrequencyPenalty = 0;
    GPT_PresencePenalty = 0;
    GPT_StopSequence = "";

    // Dynamic values
    DatabaseID = "";
    m_BDStartTime = null;

    // Speech to text
    m_InternalSpeechToTextNode = null;
    m_STTStatus = STTStatus.Inactive;
    m_Speech = "";
    m_IntermediateSilenceMs = 700;
    m_LastSpeechReceivedTime = null;

    // Analysis
    m_InternalGPTChoiceNode = null;
    m_APIStartTime = null;
    m_APIDuration = 0;
    m_UserActionsDetectionStartTime = null;
    m_AnalysisStatus = AnalysisStatus.WaitingForFirstWord;
    m_CurrentRequestController = null;
    m_AnalysisCounter = 0;
    m_GPTAnswer = null;


    constructor(iGraph, iProperties) 
    {      
        super(iGraph, iProperties);

        this.BranchingDecisionName = iProperties.BranchingDecisionName ? iProperties.BranchingDecisionName : "";
        this.IgnoreUserActions = iProperties.IgnoreUserActions;
        this.Exceptions = iProperties.Exceptions ? iProperties.Exceptions : [];
        this.STTPhraseList = iProperties.STTPhraseList ? iProperties.STTPhraseList : [];
        this.STTEnSilence = iProperties.STTEnSilence ? iProperties.STTEnSilence : this.STTEnSilence;

        iProperties.Branches.forEach(branch => {
            //log.debug(this.GetIdentity() + " constructor: Adding dynamic branch '" + branch.Name + "'.");

            let newBranch = new Branch(branch.ID, branch.Name);
            this.Branches.push(newBranch);

            this[newBranch.GetOutputPortName()] = new NodePort(newBranch.GetOutputPortName(), "output", this);
        });

        // GPT mode parameters
        this.GPTMode = iProperties.GPTMode;
        this.GPT_Prompt = iProperties.GPT_Prompt;
        this.GPT_GPTEngine = iProperties.GPT_GPTEngine;
        this.GPT_MaxTokens = iProperties.GPT_MaxTokens;
        this.GPT_Temperature = iProperties.GPT_Temperature;
        this.GPT_TopP = iProperties.GPT_TopP;
        this.GPT_FrequencyPenalty = iProperties.GPT_FrequencyPenalty;
        this.GPT_PresencePenalty = iProperties.GPT_PresencePenalty;
        this.GPT_StopSequence = iProperties.GPT_StopSequence;

        // Create an internal Speech To Text node
        const sttProps = {
            ID: iProperties.ID,
            Type: "SpeechToText",
            Endpoint: "",
            EndSilenceSeconds: this.STTEnSilence,
            PhraseList: this.STTPhraseList,
            ParentNode: this
        };
        this.m_InternalSpeechToTextNode = new SpeechToText(iGraph, sttProps);
        this.m_InternalSpeechToTextNode.Initialize();

        // Create an internal GPT Choice node
        const gptProps = {
            ID: iProperties.ID,
            Type: "GPTChoice",
            Prompt: this.GPT_Prompt,
            GPTEngine: this.GPT_GPTEngine,
            MaxTokens: this.GPT_MaxTokens,
            Temperature: this.GPT_Temperature,
            TopP: this.GPT_TopP,
            FrequencyPenalty: this.GPT_FrequencyPenalty,
            PresencePenalty: this.GPT_PresencePenalty,
            StopSequence: this.GPT_StopSequence,
            Choices: this.Branches.map(branch => ({ ID: branch.ID, Value: branch.ID.toString() })),
            ParentNode: this
        };
        this.m_InternalGPTChoiceNode = new GPTChoice(iGraph, gptProps);
        this.m_InternalGPTChoiceNode.Initialize();

        // Setup multiple requests controller
        this.m_CurrentRequestController = null;

        //log.debug(this.GetIdentity() + " constructor: graph = " + this.Graph.ExerciseName + ", id = " + this.ID + ", branches count = " + this.Branches.length + "."); 
    }
    
    async OnActivated(iActivator, iInputPort)
    {
        super.OnActivated(iActivator, iInputPort);
        
        log.debug(this.GetIdentity() + " has been activated by '" + iActivator.GetIdentity() + "' on port '" + iInputPort.Name + "'.");

        this.m_BDStartTime = new Date;
        this.m_STTStatus = "inactive";

        // Disable pause button at start
        window.sdk.event().emit('disablePauseButton');
    
        // Save this node as the last branching decision node
        this.Graph.SetCurrentBranchingDecision(this, false);
        this.Graph.IncrementBranchingDecisionsActivations();

        // Get the bot's video names for each branch
        this.Branches.forEach(branch => {
            branch.VideoName = Utils.GetNextBotVideoAfterPort(this[branch.GetOutputPortName()]);
        });

        // Log initialized BranchingDecision to DynamoDB
        let branchingDecision = await window.sdk.BranchingDecision().createOne(
            this.Graph.CurrentExerciseSessionID, 
            this.m_BDStartTime, 
            "initialized", 
            this.ID.toString(),
            "true", 
            JSON.stringify(this.Branches)
        );
        this.DatabaseID = branchingDecision.ID.S;
        log.debug(this.GetIdentity() + ".OnActivated: BranchingDecisionID = " + this.DatabaseID);

        // Get user speech
        this.m_AnalysisStatus = "waitingForFirstWord";
        this.StartSpeechToText();

        this.StartWaiters();
    }

    async StartWaiters()
    {
        log.debug(this.GetIdentity() + ".StartWaiters: Starting waiters.");

        while(this.m_IsActive && this.m_STTStatus != "success" && this.m_STTStatus != "failed")
        {
            if(this.m_AnalysisStatus === "waitingForSilence")
            {
                //log.debug(this.GetIdentity() + ".Waiters: Waiting for silence.");
                if(this.m_LastSpeechReceivedTime)
                {
                    let silenceDuration = new Date().getTime() - this.m_LastSpeechReceivedTime.getTime();
                    if(silenceDuration > this.m_IntermediateSilenceMs)
                    {
                        this.OnSilenceDetected();
                    }
                }
            }

            await Utils.Sleep(this.Graph.FrameDuration);
        }
    }

    StartSpeechToText()
    {
        log.debug(this.GetIdentity() + ".StartSpeechToText: Starting SpeechToText node.");
        this.m_STTStatus = "started";
        this.m_InternalSpeechToTextNode.OnActivated(this, null);
    }

    // Speech to text
    OnFirstWordDetected()
    {
        log.debug(this.GetIdentity() + ".OnFirstWordDetected: Activating SpeechStarted output port.");
        this.m_STTStatus = "firstword";
        this.SpeechStarted.ActivateAllConnections();
    }

    OnPartialSpeechDetected(iResult)
    {
        log.debug(this.GetIdentity() + ".OnPartialSpeechDetected. DebugDate : " + new Date().getHours() + "h:" + new Date().getMinutes() + "m:" + new Date().getSeconds() + "s:" + new Date().getMilliseconds() + "ms");
        this.m_LastSpeechReceivedTime = new Date();
        this.m_Speech = iResult;

        // Specific behavior depending on analysis status
        switch(this.m_AnalysisStatus)
        {
            case "waitingForFirstWord":
                log.debug(this.GetIdentity() + ".OnPartialSpeechDetected: When waiting for first word, now waiting for silence.");
                this.m_AnalysisStatus = "waitingForSilence";
                break;
            
            case "waitingForSilence":
                log.debug(this.GetIdentity() + ".OnPartialSpeechDetected: When waiting for silence, reset silence timer.");
                // Nothing else to do
                break;
    
            case "waitingForAnalysisResult":       
                log.debug(this.GetIdentity() + ".OnPartialSpeechDetected: When waiting for analysis result, abort current request and wait for silence.");        
                this.StopAnalysisAndWaitForSilence();
                break;

            case "waitingForAnalysisResultAndEndOfSpeech":
                log.debug(this.GetIdentity() + ".OnPartialSpeechDetected: When waiting for analysis result and end of speech, stop analysis and wait for silence.");
                this.StopAnalysisAndWaitForSilence();
                break;

            case "waitingForEndOfSpeech":
                log.debug(this.GetIdentity() + ".OnPartialSpeechDetected: When waiting for end of speech, stop analysis and wait for silence.");
                this.StopAnalysisAndWaitForSilence();
                break;

            default:
                log.debug(this.GetIdentity() + ".OnPartialSpeechDetected: Unknown analysis status '" + this.m_AnalysisStatus + "'.");
                break;
        }
    }

    OnSpeechDetected(iResult)
    {
        log.debug(this.GetIdentity() + ".OnSpeechDetected: Activating SpeechEnded output port. DebugDate : " + new Date().getHours() + "h:" + new Date().getMinutes() + "m:" + new Date().getSeconds() + "s:" + new Date().getMilliseconds() + "ms");
        //this.m_LastSpeechReceivedTime = new Date();
        this.m_STTStatus = "success";
        this.m_Speech = iResult;
        this.SpeechEnded.ActivateAllConnections();
        
        // Detect user actions
        if(!this.IgnoreUserActions || this.Exceptions.length > 0)
        {
            this.DetectUserActions();
        }
        else
        {
            log.debug(this.GetIdentity() + ".OnActivated: Ignoring user actions.");
        }
        
        // Specific behavior depending on analysis status
        switch(this.m_AnalysisStatus)
        {
            case "waitingForFirstWord":
                log.debug(this.GetIdentity() + ".OnSpeechDetected: When waiting for first word, should not happen.");
                this.m_AnalysisStatus = "waitingForAnalysisResult";
                this.AskAPI(this.m_Speech);
                break;
            
            case "waitingForSilence":
                log.debug(this.GetIdentity() + ".OnSpeechDetected: When waiting for silence, start analysis.");
                this.m_AnalysisStatus = "waitingForAnalysisResult";
                this.AskAPI(this.m_Speech);
                break;
    
            case "waitingForAnalysisResult":
                log.debug(this.GetIdentity() + ".OnSpeechDetected: When waiting for analysis result, should not happen.");
                // Nothing to do                
                break;

            case "waitingForAnalysisResultAndEndOfSpeech":
                log.debug(this.GetIdentity() + ".OnSpeechDetected: When waiting for analysis result and end of speech, waiting for analysis result.");
                this.m_AnalysisStatus = "waitingForAnalysisResult";
                break;

            case "waitingForEndOfSpeech":
                log.debug(this.GetIdentity() + ".OnSpeechDetected: When waiting for end of speech, storing speech and using analysis result.");
                this.UseAnalysisResult();
                break;

            default:
                log.debug(this.GetIdentity() + ".OnSpeechDetected: Unknown analysis status '" + this.m_AnalysisStatus + "'.");
                break;
        }
    }

    OnSTTFailed()
    {
        log.debug(this.GetIdentity() + ".OnSTTFailed: Activating Failed output port.");
        this.m_STTStatus = "failed";
        
        this.ActivateFailedOutput();
    }

    GetSpeech()
    {
        return this.m_Speech;
    }

    OnSilenceDetected()
    {
        log.debug(this.GetIdentity() + ".OnSilenceDetected: DebugDate : " + new Date().getHours() + "h:" + new Date().getMinutes() + "m:" + new Date().getSeconds() + "s:" + new Date().getMilliseconds() + "ms");
        
        // Specific behavior depending on analysis status
        switch(this.m_AnalysisStatus)
        {
            case "waitingForFirstWord":
                // Should not happen
                log.debug(this.GetIdentity() + ".OnSilenceDetected: Should not happen when waiting for first word.");
                break;
            
            case "waitingForSilence":
                this.m_AnalysisStatus = "waitingForAnalysisResultAndEndOfSpeech";
                this.AskAPI(this.m_Speech);
                break;
    
            case "waitingForAnalysisResult":
                // Should not happen
                log.debug(this.GetIdentity() + ".OnSilenceDetected: Should not happen when waiting for analysis result.");
                break;

            case "waitingForAnalysisResultAndEndOfSpeech":
                // Should not happen
                log.debug(this.GetIdentity() + ".OnSilenceDetected: Should not happen when waiting for analysis result and end of speech.");
                break;

            case "waitingForEndOfSpeech":
                // Should not happen
                log.debug(this.GetIdentity() + ".OnSilenceDetected: Should not happen when waiting for end of speech.");
                break;
                
            default:
                log.debug(this.GetIdentity() + ".OnSilenceDetected: Unknown analysis status '" + this.m_AnalysisStatus + "'.");
                break;
        }
    }

    async AskAPI(iSpeech)
    {
        log.debug(this.GetIdentity() + ".AskAPI: Asking API. DebugDate : " + new Date().getHours() + "h:" + new Date().getMinutes() + "m:" + new Date().getSeconds() + "s:" + new Date().getMilliseconds() + "ms");

        this.m_AnalysisCounter++; 

        this.m_Speech = iSpeech;
        this.m_APIStartTime = new Date();

        let result = null;
        try {
            if(this.GPTMode)
            {
                result = await this.AskGPTChoiceNode();
            }
            else
            {
                result = await this.AskBranchingDecisionAPI();
            }
        } catch (error) {
            if (error.name === 'AbortError') {
                log.debug(this.GetIdentity() + ".AskAPI: Request was aborted.");
                return;
            } else {
                log.error(this.GetIdentity() + ".AskAPI: Request failed:", error);
                result = {status: "failed", request: null, answer: null, branch: null};
            }
        }

        this.m_APIDuration = new Date().getTime() - this.m_APIStartTime.getTime();

        log.debug(this.GetIdentity() + ".AskAPI: result = ", result);

        this.OnAnalysisResult(result);
    }
    
    async AskBranchingDecisionAPI()
    {
        // Abort current analysis task if running
        if (this.m_CurrentRequestController) {
            log.debug(this.GetIdentity() + ".AskAPI: Aborting current request.");
            this.m_CurrentRequestController.abort();
        }

        // Create a new controller for this request
        this.m_CurrentRequestController = new AbortController();
        
        let answer = null;
        try {
            // Send the request to Branching Decision API
            answer = await window.sdk.branchingDecisionAPI().Ask(
                this.m_Speech,
                this.Graph.ExerciseID.toString(), 
                "BD" + this.ID, 
                this.BranchingDecisionName, 
                this.Graph.APIsEndpoints.branchingdecisionapi,
                this.m_CurrentRequestController.signal                
            );
            if(!answer.result)
            {
                log.debug(this.GetIdentity() + ".AskAPI: Call failed.");
                return {status: "failed", request: answer.body, answer: answer.result, branch: null};
            }

            // Get branch from answer
            let chosenBranch = this.Branches.find(branch => branch.Name == answer.result.chosen_branch);

            log.debug(this.GetIdentity() + ".AskBranchingDecisionAPI: answer = ", answer);
            return {status: "success", request: answer.body, answer: answer.result, branch: chosenBranch};
        }
        catch (error) {
            if (error.name === 'AbortError') {
                log.debug(this.GetIdentity() + ".AskBranchingDecisionAPI: Request was aborted.");
                throw error;
            } 
            else if(answer) {
                log.debug(this.GetIdentity() + ".AskBranchingDecisionAPI: An error occurred, but answer was filled.");
                let partialAnswer = {
                    status: "failed", 
                    request: answer.body ? answer.body : null, 
                    answer: answer.result ? answer.result : null, 
                    branch: null
                };
                return partialAnswer;
            } 
            else {
                log.debug(this.GetIdentity() + ".AskBranchingDecisionAPI: An error occurred, and answer was not filled.");
                return {status: "failed", request: null, answer: null, branch: null};
            }
        }
    }
    
    async AskGPTChoiceNode()
    {
        // Abort previous request if necessary
        this.m_InternalGPTChoiceNode.Abort();

        this.m_GPTAnswer = null;

        // Start the internal GPT Choice node
        this.m_InternalGPTChoiceNode.OnActivated(this, null);

        // Wait for the answer
        while(this.m_GPTAnswer == null)
        {
            await Utils.Sleep(this.Graph.FrameDuration);
        }

        return this.m_GPTAnswer;
    }

    OnGPTChoiceNodeAnswered(iAnswer)
    {
        let result = {
            status: "NA", 
            request: iAnswer.request, 
            answer: iAnswer.answer, 
            branch: null,
            possibleBranches: iAnswer.possibleChoices
        };

        if(!iAnswer)
        {
            log.debug(this.GetIdentity() + ".OnGPTChoiceNodeAnswered: Call failed. No answer from the API.");
            result.status = "failed";
            this.m_GPTAnswer = result;
            return;
        }

        switch(iAnswer.answer.status)
        {
            case "success":
                // Get branch from answer
                result.status = "success";
                result.branch = this.Branches.find(branch => branch.ID == iAnswer.choice);
                break;

            default:
                log.debug(this.GetIdentity() + ".OnGPTChoiceNodeAnswered: Call failed. Status " + iAnswer.answer.status);
                result.status = "failed";
        }
        
        this.m_GPTAnswer = result;
    }

    OnAnalysisResult(iResult)
    {
        log.debug(this.GetIdentity() + ".OnAnalysisResult: DebugDate : " + new Date().getHours() + "h:" + new Date().getMinutes() + "m:" + new Date().getSeconds() + "s:" + new Date().getMilliseconds() + "ms");
        
        // Specific behavior depending on analysis status
        switch(this.m_AnalysisStatus)
        {
            case "waitingForFirstWord":
                // Should not happen
                log.debug(this.GetIdentity() + ".OnAnalysisResult: Should not happen when waiting for first word.");
                break;

            case "waitingForSilence":
                // Should not happen
                log.debug(this.GetIdentity() + ".OnAnalysisResult: Should not happen when waiting for silence.");
                break;

            case "waitingForAnalysisResult":
                log.debug(this.GetIdentity() + ".OnAnalysisResult: Using analysis result. ", iResult);
                this.m_AnalysisStatus = "usingAnalysisResult";
                this.m_AnalysisResult = iResult;
                this.UseAnalysisResult();
                break;

            case "waitingForAnalysisResultAndEndOfSpeech":
                log.debug(this.GetIdentity() + ".OnAnalysisResult: Storing analysis result and waiting for end of speech.");
                this.m_AnalysisResult = iResult;
                this.m_AnalysisStatus = "waitingForEndOfSpeech";
                break;
    
            case "waitingForEndOfSpeech":
                // Should not happen
                log.debug(this.GetIdentity() + ".OnAnalysisResult: Should not happen when waiting for end of speech.");
                break;

            default:
                log.debug(this.GetIdentity() + ": Unknown analysis status '" + this.m_AnalysisStatus + "'.");
                break;
        }
    }
    
    StopAnalysisAndWaitForSilence()
    {
        // Abort current analysis task if running
        this.m_CurrentRequestController?.abort();
        this.m_CurrentRequestController = null;

        this.m_AnalysisStatus = "waitingForSilence";
    }

    async DetectUserActions()
    {
        this.m_UserActionsDetectionStartTime = new Date();

        // Send the request to User Actions Detection API
        let answer = await window.sdk.userActionsDetectionAPI().Ask(this.m_Speech, this.Graph.ExerciseID.toString(), "BD" + this.ID, this.Graph.APIsEndpoints.useractionsdetectionapi);
        log.debug(this.GetIdentity() + ".DetectUserActions: userActionsDetectionAPI answer = ", answer);

        // Push analyzer task to DynamoDB
        this.LogUserActionDetectionResultToDynamoDB(answer);

        // Push all detected actions to history and keep track of the strategic ones to pop-up
        let strategicUserActions = [];
        for(let i = 0; i < answer.result.chosen_labels.length; i++)
        {
            let userActionLabel = answer.result.chosen_labels[i];

            // Pass if we should ignore this user action
            //log.debug(this.GetIdentity() + ".DetectUserActions: userActionLabel = '" + userActionLabel + "', this.Exceptions.includes(userActionLabel) = " + this.Exceptions.includes(userActionLabel));
            if(this.IgnoreUserActions && !this.Exceptions.includes(userActionLabel))
            {
                log.debug(this.GetIdentity() + ".DetectUserActions: Ignoring user action '" + userActionLabel + "'.");
                continue;
            }
            log.debug( 'this.Graph.availableUserActions)',this.Graph.availableUserActions)
            // Get corresponding user action from graph
            let userAction = this.Graph.availableUserActions.find(item => item.ID == userActionLabel);
            //log.debug(this.GetIdentity() + ".DetectUserActions: userAction = ", userAction);
            if(userAction != null)
            {
                // Add user action to history
                this.Graph.History.AddUserAction(this.ID, userAction.ID, userAction.Type, this.Graph.GetCurrentActName());

                // Keep to pop-up later
                if(userAction.Strategic == true)
                {
                    strategicUserActions.push(userAction);
                }
            }
            else
            {
                log.debug(this.GetIdentity() + ".DetectUserActions: User action '" + userActionLabel + "' not found in graph.");
            }
        }

        // Pop-up strategic user actions
        window.sdk.event().emit('showStrategicUserActions', strategicUserActions);
        log.debug(this.GetIdentity() + ".DetectUserActions: strategic user actions to pop-up = ", strategicUserActions);
    }

    UseAnalysisResult()
    {
        log.debug(this.GetIdentity() + ".UseAnalysisResult: Analysis result = " + JSON.stringify(this.m_AnalysisResult) + "\nDebugDate : " + new Date().getHours() + "h:" + new Date().getMinutes() + "m:" + new Date().getSeconds() + "s:" + new Date().getMilliseconds() + "ms");

        if(this.m_AnalysisResult.status != "success")
        {
            log.debug(this.GetIdentity() + ".UseAnalysisResult: Request failed. Result = " + this.m_AnalysisResult.branch?.Name 
            + ".\n Analysis requests sent = " + this.m_AnalysisCounter
            + ".\n Speech used = " + this.m_AnalysisResult.request?.input
            + ".\n Time between last speech and bot video trigger = " + (new Date().getTime() - this.m_LastSpeechReceivedTime.getTime()) + "ms.");
            this.ActivateFailedOutput();
            return;
        }

        log.debug(this.GetIdentity() + ".UseAnalysisResult: result = " + this.m_AnalysisResult.branch?.Name 
                    + ".\n Analysis requests sent = " + this.m_AnalysisCounter
                    + ".\n Speech used = " + this.m_AnalysisResult.request?.input
                    + ".\n Time between last speech and bot video trigger = " + (new Date().getTime() - this.m_LastSpeechReceivedTime.getTime()) + "ms.");

        // Log new STT technique results to DynamoDB
        window.sdk.usersActivity().createOne("FasterBranchingDecisionInfo", { 
            BranchingDecisionNodeID: this.DatabaseID,
            BranchingDecisionID: this.ID.toString(),
            AnalysisCounter: this.m_AnalysisCounter,
            TimeBetweenLastSpeechAndBotVideoTrigger: (new Date().getTime() - this.m_LastSpeechReceivedTime.getTime())
        });

        // Log API result to DynamoDB
        if(!this.GPTMode)
        {
            this.LogAPIResultToDynamoDB(this.m_AnalysisResult);
        }
        else
        {
            this.LogGPTResultToDynamoDB(this.m_AnalysisResult);
        }
        
        // Notify debug values that the node is done
        this.Graph.SetCurrentBranchingDecision(this, true);

        // If no branch found, activate the failed output port
        if(this.m_AnalysisResult.branch == null)
        {
            log.debug(this.GetIdentity() + ".OnActivated: No branch found from name '" + this.m_AnalysisResult.answer.chosen_branch + "', activating failed output port.");
            this.ActivateFailedOutput();
        }
        else
        {
            log.debug(this.GetIdentity() + ".OnActivated: Branch '" + this.m_AnalysisResult.branch.Name + "' found, activating output port.");
            this.ActivateBranchOutput(this.m_AnalysisResult.branch);
        }
    }

    async LogUserActionDetectionResultToDynamoDB(iResult)
    {
        let apiVersion = "";
        let input = "";
        let result = "";
        let status = "raw";
        let analysisDuration = (new Date().getTime() - this.m_UserActionsDetectionStartTime.getTime()).toString();

        // Handle errors
        if(iResult.result != null)
        {
            input = JSON.stringify(iResult.body.input);
            result = JSON.stringify(iResult.result);

            if(iResult.result["api-version"] != null)
            {
                apiVersion = iResult.result["api-version"];
                log.debug(this.GetIdentity() + ".LogUserActionDetectionResultToDynamoDB: iResult.result[api-version] != null apiVersion = ", apiVersion);
            }
            else
            {
                status = "failed";
                log.debug(this.GetIdentity() + ".LogUserActionDetectionResultToDynamoDB: iResult.result != null && iResult.result[api-version] == null");
            }
        }
        else
        {
            status = "failed";
            log.debug(this.GetIdentity() + ".LogUserActionDetectionResultToDynamoDB: iResult.result == null");
        }

        // Log branching decision analysis to DynamoDB
        window.sdk.AnalysisTask().createOne(
            this.DatabaseID, // Parent Branching Decision Node
            this.ID.toString(), // Node ID
            "UserActionsDetectionAPI", // analyzer Engine
            apiVersion, // Analyzer Version
            status, // Analysis Status
            input, // Analysis Input
            this.m_UserActionsDetectionStartTime, // Start Time
            analysisDuration, // Analysis duration (milliseconds)
            ""/*JSON.stringify(this.ItemsToFind.map(itemToFind => itemToFind.Item))*/, // Possible choices
            result, // Analysis Result
            this.Graph.ExerciseID.toString(), // Exercise ID
        );
    }

    async LogAPIResultToDynamoDB(iResult)
    {
        let apiVersion = "";
        let input = "";
        let answer = "";
        let status = "raw";

        // Handle errors
        if(iResult.answer != null)// && iResult.answer != null && iResult.answer["api-version"] != null)
        {
            input = JSON.stringify(iResult.request);
            answer = JSON.stringify(iResult.answer);

            if(iResult.answer["api-version"] != null)
            {
                apiVersion = iResult.answer["api-version"];
            }
            else
            {
                status = "failed";
            }
        }
        else
        {
            status = "failed";
        }

        // Log branching decision analysis to DynamoDB
        window.sdk.AnalysisTask().createOne(
            this.DatabaseID, // Parent Branching Decision Node
            this.ID.toString(), // Node ID
            "BranchingDecisionAPI", // analyzer Engine
            apiVersion, // Analyzer Version
            status, // Analysis Status
            input, // Analysis Input
            this.m_APIStartTime, // Start Time
            this.m_APIDuration.toString(), // Analysis duration (milliseconds)
            ""/*JSON.stringify(this.ItemsToFind.map(itemToFind => itemToFind.Item))*/, // Possible choices
            answer, // Analysis Result
            this.Graph.ExerciseID.toString() // Exercise ID
        );
    }

    async LogGPTResultToDynamoDB(iResult)
    {
        let status = "raw";

        // Handle errors
        if(!iResult.answer || iResult.status != "success")
        {
            status = "failed";
        }

        // Log branching decision analysis to DynamoDB
        window.sdk.AnalysisTask().createOne(
            this.DatabaseID, // Parent Branching Decision Node
            this.ID.toString(), // Node ID
            "GPT", // analyzer Engine
            this.GPT_GPTEngine, // Analyzer Version
            status, // Analysis Status
            iResult.request, // Analysis Input
            this.m_APIStartTime, // Start Time
            this.m_APIDuration.toString(), // Analysis duration (milliseconds)
            iResult.possibleBranches, // Possible choices
            JSON.stringify({
                "GPT raw answer": iResult.answer,
                "Final choice": iResult.branch
            }), // Analysis Result
            this.Graph.ExerciseID.toString() // Exercise ID
        );
    }

    async FinalizeBranchingDecisionToDynamoDB(iChosenBranch)
    {
        let status = "raw";
        if(iChosenBranch == "Failed")
        {
            status = "failed";
        }

        // Log branching decision result to DynamoDB
        window.sdk.BranchingDecision().updateItem(
            this.Graph.CurrentExerciseSessionID, 
            this.DatabaseID,
            {
                "DecisionStatus": status,
                "ChosenBranch": iChosenBranch
            }
        );
    }

    async ActivateBranchOutput(iBranch)
    {
        this.Reset();
        this.FinalizeBranchingDecisionToDynamoDB(JSON.stringify(iBranch));
        
        this[iBranch.GetOutputPortName()].ActivateAllConnections();
    }

    async ActivateFailedOutput()
    {
        this.Reset();
        this.FinalizeBranchingDecisionToDynamoDB("Failed");
        this.Failed.ActivateAllConnections();
    }
	
	Pause() {
        super.Pause();

		if(this.m_IsActive)
        {
            // Pause the internal SpeechToText node
            this.m_InternalSpeechToTextNode.Pause();

            // Pause the internal GPT Choice node
            this.m_InternalGPTChoiceNode.Pause();
        }
	}
	
	Resume() {
        super.Resume();
        
		if(this.m_IsActive)
        {
            // Resume the internal SpeechToText node
            this.m_InternalSpeechToTextNode.Resume();

            // Resume the internal GPT Choice node
            this.m_InternalGPTChoiceNode.Resume();
        }
	}

    Reset()
    {
        super.Reset();
    
        // Reset the internal SpeechToText node
        this.m_InternalSpeechToTextNode.Reset();

        // Reset the internal GPT Choice node
        this.m_InternalGPTChoiceNode.Reset();

        // Reset state variables
        this.m_STTStatus = "inactive";
        this.m_AnalysisStatus = "waitingForFirstWord";
        this.m_CurrentRequestController?.abort();
        this.m_CurrentRequestController = null;
        this.m_Speech = "";
        this.m_GPTAnswer = null;
        this.m_LastSpeechReceivedTime = null;
        
        // Re-enable pause button when finished
        window.sdk.event().emit('enablePauseButton');
    }

    PrintParameters()
    {
        //log.debug("ValueBool: ID = " + this.ID + ", Name = " + this.Name + ".");
    }

    //////////////////////////
    // Test functions
    //////////////////////////

    TestExecute(iActivator, iInputPort, iTestReport)
    {
        // Start the test
        if (iInputPort == null || iInputPort.Name == this.Input.Name)
        {
            // Test-activate Analysis process
            this.Analysis.TestActivateAllConnections(iTestReport);
        }
        else
        {
            let chosenBranch = this.GetBranchFromInput(iInputPort);

            // Fill the test report
            iTestReport["ChosenBranch"] = chosenBranch;
        }
    }
}

class Branch
{
    ID = -1;
    Name = "";

    constructor(iID, iName)
    {
        this.ID = iID;
        this.Name = iName;
    }
    
    GetOutputPortName()
    {
        return "Branch" + this.ID;
    }

    ToString()
    {
        return "{" +
                "\n  Name: '" + this.Name + "'" +
                "\n  OutputPort: '" + this.GetOutputPortName() + "'" +
                "\n}";
    }
}
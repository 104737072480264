import log from "loglevel";
export default class BranchingDecisionAPI {

    constructor(sdk) {
        this.sdk = sdk;
    }
    
    async Initialize() {
        
    }

    async Ask(iSpeech, iExerciseID, iBranchingDecisionNodeID, iBranchingDecisionName, iEndpoint, iAbortSignal) {

        log.debug("BranchingDecisionAPI Ask: iSpeech = " + iSpeech + ", iExerciseID = " + iExerciseID + ", iBranchingDecisionNodeID = " + iBranchingDecisionNodeID + ", iBranchingDecisionName = " + iBranchingDecisionName + ", iEndpoint = " + iEndpoint);
        
        let body = {
            input: iSpeech,
            ExerciseID: iExerciseID,
            BDNodeID: iBranchingDecisionNodeID,
            BDName: iBranchingDecisionName,
            Endpoint: iEndpoint
        };

        if(!iEndpoint) {
            log.debug("BranchingDecisionAPI Ask: iEndpoint is not defined.");
            return {body, result: null};
        }

        let params = {
            body: body,
            signal: iAbortSignal
        };
        
        let result = await this.sdk.fetch().post(iEndpoint, params, true);  
        
        log.debug("BranchingDecisionAPI Ask result: ", result);

        return {body, result};
    }
  }
import React from 'react';

import Button from 'react-bootstrap/Button';
import Animation from "../Utilities/Animation";

export default class BottomButton extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        let { children, variant, waiting } = this.props;
        let icon = null;
        let iconPosition = 'left';
        if ( waiting ) {
            icon = (<Animation type={'waiting'} classCSS='anim-button' autoplay={true} loop={true}  />);
            if ( variant === 'white' ) {
                iconPosition = 'right';
            }
        } else {
            switch(variant) {
                case 'white':
                    break;
                case 'hang-up':
                    icon = (<i className="btn__icon icon-phone-alt"></i>);
                    break;
                case 'raising':
                    icon = (<Animation type={'raisingHand'}  classCSS='anim-button' autoplay={false} loop={true} name="raisingHandBottom2" />);
                    break;
                case 'speaking':
                    icon = (<Animation type={'speech'}  classCSS='anim-button' autoplay={true} loop={true} />);
                    break;
            }
        }
        return (
            <Button className={'bottom-btn' + (waiting ? ' btn-waiting' : '')} variant={variant}
                onMouseEnter={() => { if(this.variant == 'raising'){window.sdk.event().emit('playLottie', 'raisingHandBottom2')}} }
                onMouseLeave={() => { if(this.variant == 'raising'){window.sdk.event().emit('pauseLottie', 'raisingHandBottom2')}} }
            >
                {iconPosition === 'left' && icon}
                {children}
                {iconPosition === 'right' && icon}
            </Button>
        );
    }
}
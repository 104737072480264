import log from "loglevel";
import ExerciseNode from './ExerciseNode';
import NodePort from '../NodePort';
import ValueInt from './ValueInt';

export default class TrackedEvent extends ExerciseNode {
    // Ports
    Input = new NodePort("Input", "input", this);

    // Parameters
    Name = "";

    constructor(iGraph, iProperties) {
        super(iGraph, iProperties);

        this.Name = iProperties.Name;
        //log.debug(this.GetIdentity() + " constructor: graph = " + this.Graph.ExerciseName + ", id = " + this.ID + ", name = " + this.Name); 
    }

    OnActivated(iActivator, iInputPort) {
        super.OnActivated(iActivator, iInputPort);
        log.debug(this.GetIdentity() + " has been activated by '" + iActivator.GetIdentity() + "'.");

        this.Graph.IncrementIntValue(this.Name);
        this.SetActive(false);
    }

    PrintParameters() {
        //log.debug("ValueBool: ID = " + this.ID + ", Name = " + this.Name + ".");
    }
}
import log from "loglevel";
import Achievement from '../../components/Utilities/Achievement';
import ExerciseNode from './ExerciseNodes/ExerciseNode';
import ExerciseGraph from './ExerciseGraph';
import Utils from "../Utils/Utils";


export default class ExerciseSessionHistory{

    Graph;
    History = [];
    UserName = "";
    UniqueIDIncrementer = 0;

    constructor(iGraph, iUserName) {
        this.Graph = iGraph;
        this.UserName = iUserName;
        this.UniqueIDIncrementer = 0;
    }

    // TODO: link with the Smart Branching Decision
    // Save achievements to display in real time

    AddEvent(iEventType, iContent)
    {
        let newEvent = {
            EventID: Utils.CreateObjectIDWithIncrement(new Date(), window.sdk.user().userID, (this.UniqueIDIncrementer++)),
            ExerciseSessionID: this.Graph.CurrentExerciseSessionID,
            //UserID: window.sdk.user().userID, not secure to get userID from front
            Date: new Date().toISOString(),
            EventType: iEventType,
            Content: iContent
        };

        this.History.push(newEvent);

        // Log history event in DynamoDB
        window.sdk.exerciseSession().addEventToHistory(newEvent);

        //this.Print();
    }

    AddUserSpeech(iNodeID, iAnalysisTaskID, iSpeech)
    {
        this.AddEvent("UserSpeech", {
            NodeID: iNodeID, 
            Actor: "User", 
            Character: this.UserName,
            AnalysisTaskID: iAnalysisTaskID,
            Speech: iSpeech});
    }

    async AddBotSpeech(iNodeID, iBotName, iBotVideoName)
    {
        // Retrieve the speech from the database before adding it to the history
        //let botVideoData = await window.sdk.BotVideo().getOne(iBotVideoName);
        //let transcript = botVideoData.transcript ? botVideoData.transcript : iBotVideoName;
        this.AddEvent("BotSpeech", {
            NodeID: iNodeID, 
            Actor: "Bot", 
            Character: iBotName,
            Video: iBotVideoName, 
            /*Speech: transcript*/});
    } 

    AddUserAction(iNodeID, iUserActionID, iUserEventTypes, iPhase)
    {        
        // Save a UserAction when it is done.
        this.AddEvent("UserAction", {
            NodeID: iNodeID, 
            UserActionID: iUserActionID, 
            UserEventTypes: iUserEventTypes, 
            Phase: iPhase});
    }

    AddNarrativeEnd(iNodeID, iNarrativeEndName)
    {
        // Save chosen narrative from narrative solver 
        this.AddEvent("NarrativeEnd", {
            NodeID: iNodeID, 
            Name: iNarrativeEndName});
    }

    AddAchievementsDone(iAchievementsToDisplay)
    {
        // Save achievements done
        iAchievementsToDisplay.forEach(achievement => {
            this.AddEvent("Achievement", {
                ID: achievement.ID, 
                Type: achievement.Type,
                DisplayedName: achievement.DisplayedName,
                Description: achievement.Description});
        })
    }

    AddPedagologicalEnd(iPedagologicalEnd)
    {
        if(iPedagologicalEnd != null)
        {
            // Save the pedagological end choosen
            this.AddEvent("PedagologicalEnd", {
                ID: iPedagologicalEnd.ID,
                Type: iPedagologicalEnd.Type, 
                DisplayedName: iPedagologicalEnd.DisplayedName,
                Description: iPedagologicalEnd.Description});
        }
        else {
            this.AddEvent("PedagologicalEnd", {PedagologicalEndName: "No pedagologicalEndFound"})
        }
    }

    AddPedagologicalRecommendations(iPedagologicalRecommendationsToDisplay)
    {
        iPedagologicalRecommendationsToDisplay.forEach(pedagologicalRecommendation => {
            // Save the pedagological Recommendations to display
            this.AddEvent("PedagologicalRecommendation", {
                ID: pedagologicalRecommendation.ID,
                Type: pedagologicalRecommendation.Type,
                DisplayedName: pedagologicalRecommendation.DisplayedName,
                Description: pedagologicalRecommendation.Description});
        })
    }

    AddPedagologicalAdditions(iPedagologicalAdditionsToDisplay)
    {
        log.debug("AddPedagologicalAdditions: PedagologicalAdditionsToDisplay = ", iPedagologicalAdditionsToDisplay)
        iPedagologicalAdditionsToDisplay.forEach(pedagologicalAddition => {
            // Save the pedagological additions to display
            this.AddEvent("PedagologicalAddition", {
                ID: pedagologicalAddition.ID, 
                Type: pedagologicalAddition.Type, 
                Description: pedagologicalAddition.Description});
        })
    }

    GetUserActions()
    {
        // Send saved user action
        let userActions = [];
        this.History.forEach(historyEvent => {
            if(historyEvent.EventType == "UserAction")
            {
                userActions.push(historyEvent);
            }
        });

        return userActions;
    }

    GetNarrativeEnd()
    {
        // Send choosen narrative end
        let narrativeEnd = null;
        this.History.forEach(historyEvent => {
            if(historyEvent.EventType == "NarrativeEnd")
            {
                narrativeEnd = historyEvent;
            }
        });

        return narrativeEnd;
    }

    // Sample to call when we want to display the feedbacks panel
    GetFeedbackToDisplay()
    {
        let feedbackToDisplay = [];
        // Send saved feedback to display
        this.History.forEach(historyEvent => {
            if(historyEvent.EventType == "Achievement")
            {
                feedbackToDisplay.push(historyEvent);
            }
            if(historyEvent.EventType == "PedagologicalEnd")
            {
                feedbackToDisplay.push(historyEvent);
            }
            if(historyEvent.EventType == "PedagologicalRecommendation")
            {
                feedbackToDisplay.push(historyEvent);
            }
            if(historyEvent.EventType == "PedagologicalAddition")
            {
                feedbackToDisplay.push(historyEvent);
            }
        });
        log.debug("FEEDBACKTODISPLAY = ", feedbackToDisplay)
        return feedbackToDisplay;
    }

    GetConversation()
    {
        let conversation = [];
        this.History.forEach(historyEvent => {
            if(historyEvent.EventType == "UserSpeech" || historyEvent.EventType == "BotSpeech")
            {
                conversation.push(historyEvent);
            }
        });
        return conversation;
    }

    async GetConversationAsText()
    {
        let conversation = "";
        this.GetConversation().forEach(historyEvent => {
            /*if(historyEvent.EventType == "BotSpeech" && !historyEvent.Content.Speech)
            {
                // Retrieve the speech from the database before adding it to the history
                let botVideoData = await window.sdk.BotVideo().getOne(iBotVideoName);
                let transcript = botVideoData.transcript ? botVideoData.transcript : iBotVideoName;
            }  */  
            conversation += historyEvent.Character + "(" + historyEvent.Actor + "): '" + (historyEvent.Content.Speech ? historyEvent.Content.Speech : (historyEvent.EventType == "BotSpeech" ? historyEvent.Content.Video : "User speech not found!")) + "'\n";
        });
        return conversation;
    }

    GetUserSpeeches()
    {
        let userSpeeches = [];
        this.History.forEach(historyEvent => {
            if(historyEvent.EventType == "UserSpeech")
            {
                userSpeeches.push(historyEvent);
            }
        });
        return userSpeeches;
    }    

    Print()
    {
        /*let logString = "- History:";
        this.History.forEach(historyEvent => {
            logString += "\n" + historyEvent;
        });
        log.debug(logString);*/
        log.debug("- History:", this.History);
    }
}
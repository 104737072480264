import log from "loglevel";
import React from 'react';
import Utils from '../../AppClasses/Utils/Utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophoneSlash, faVideoSlash } from '@fortawesome/free-solid-svg-icons';

import Lottie from "lottie-react";
import waitingAnimation from "../../assets/animations/Waiting";
import speechAnimation from "../../assets/animations/Speech";
import raisingHandAnimation from "../../assets/animations/RaisingHand";

import Animation from "../Utilities/Animation";

export default class ParticipantVideoSlot extends React.Component {

    // References
    Participant = null;
    type = null;

    

    constructor(props, iParticipant) {
        log.debug('ParticipantVideoSlot:constructor', iParticipant.getName(), iParticipant.getID());

        super(props);

        this.Participant = iParticipant;
		
		
		this.state = {
			connection: this.Participant.getConnectionState(),
			mic: 'on',
			cam: 'on',
			speaking: this.Participant.getSpeakingState(),
		}
		
		this.Participant.on('stateChange', this.onStateChange);
		
		log.debug('onStateChange constructor', this.Participant.getName(), this.Participant.on); 
    }

    onStateChange = ({state, value}) => {
		log.debug('onStateChange listener', state, value);
        this.setState({
            [state] : value
        });
    }

    async componentDidMount() {
        /*while(!document.querySelector(this.botClass + ' .bot_layer1')) {
                await Utils.Sleep(100);
        }
        this.props.bot.domIsReady(document.querySelector(this.botClass));*/

        /*
        if (this.Participant.getName() == 'Camille') { //pour tester
            setTimeout(() => {
                this.Participant.setConnectionState('connecting');
            }, 1000);

            setTimeout(() => {
                this.Participant.setConnectionState('connected');
                this.Participant.setCamState('off');
                this.Participant.setMic('off');
            }, 2000);

            setTimeout(() => {
                this.Participant.setCamState('on');
            }, 3000);

            setTimeout(() => {
                this.Participant.setSpeakingState('raising');
            }, 4000);

            setTimeout(() => {
                this.Participant.setMic('on');
                this.Participant.setSpeakingState('speaking');
            }, 6000);

            setTimeout(() => {
                this.Participant.setSpeakingState('no');
            }, 10000);
        }
        */	
        //
		
		
		
    }

    componentWillUnmount() {
        this.Participant.removeListener('stateChange', this.onStateChange);
    }

    renderVideo() {
        log.debug('ParticipantVideoSlot:renderVideo', this.Participant.getID());

        return (
            <div className='participant__video'>
                Empty
            </div>
        );
    }

    renderNoCamera() {
        return (
            <div className='participant__video'>
                <div className={'video_layer video--no-camera'}>
                    <i className="picto-connecting1"></i>
                </div>
            </div>
        );
    }

    renderNotConnected() {
        return (
            <div className='participant__video'>
                <div className={'video_layer'}>
                    <Animation type={'bulles'} classCSS="video-pending" autoplay={true} loop={true}/>
                </div>
            </div>
        );
    }

    render() {
        const { connection, mic, cam, speaking } = this.state;
        let classes = [];
        let icon = null;
        if (speaking === 'speaking') { // Speaker actuel
            classes.push('participant--speaking');
            icon = (
                <div className={'participant__statusicon'}>
                    <Lottie animationData={speechAnimation} />
                </div>
            );
        } else if (speaking === 'raising') { // Leve la main
            classes.push('participant--raising');
            icon = (
                <div className={'participant__statusicon'}>
                    <Lottie animationData={raisingHandAnimation} />
                </div>
            );
        } else if (speaking === 'readyforSpeaking') {
            classes.push('participant--waiting');
            icon = (
                <div className={'participant__statusicon'}>
                    <Lottie animationData={waitingAnimation} />
                </div>
            );
        }

        if ( this.type === 'bot' ) {
            icon = null;
        }

        let content = this.renderVideo();
        if ( connection === 'connecting' ) {
            content = this.renderNotConnected();
            classes.push('participant--not-connected');
        }
        else if ( cam === 'off' ) {
            classes.push('participant--no-camera');
            content = this.renderNoCamera();
        }

        return (
            <div className={'participantVideoSlot participant_' + this.Participant.getID() + ' ' + classes.join(' ') }>
                <div className={'participant__inner'}>
                    {icon}
                    {(connection !== 'connecting') && (
                        <div className={'participant__info'}>
                            <div className={'participant__username'}>
                                { this.Participant.getName() }
                            </div>
                            {(mic === 'off') && (<div className={'participant__audio'}>
                                <FontAwesomeIcon icon={faMicrophoneSlash} />
                            </div>)}
                            {(cam === 'off') && (<div className={'participant__camera'}>
                                <FontAwesomeIcon  icon={faVideoSlash} />
                            </div>)}
                        </div>
                    )}
                    { content }
                </div>
            </div>
        );
    }
}

import log from "loglevel";
import React from 'react';

import '../../styles/components/TestDevice.scss';

import GetUserMedia from '../../components/MediaDevices/GetUserMedia';
import WebcamView from '../../components/MediaDevices/WebcamView';
import Settings from '../../components/MediaDevices/Settings';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default class TestBot extends React.Component {

    state = {
        showDeviceWorkflow: true,
        showDeviceSettings: false,
    }


    componentDidMount() {

    }


    onStreamReady = () => {
        log.debug('on stream ready');
        this.setState({
            showDeviceWorkflow: false,
            showDeviceSettings: true
        });
    }


    render() {
        return (
            <div className={'test-device'}>
                <Container fluid className={'test-device__container'}>
                    <Row className={this.state.showDeviceWorkflow ? 'align-items--center' : ''} style={{ height: '100%' }}>
                        <Col>
                            { this.state.showDeviceWorkflow &&
                              <GetUserMedia onStreamReady={ this.onStreamReady } />
                            }

                            {this.state.showDeviceSettings &&
                                <Row xs={1} sm={2} md={3} style={{ minHeight: '100%' }}>
                                    <Col xs={12} sm={6} md={8} lg={9} className={'test-device__output'}>
                                        <div className={'output__inner'}>
                                            <WebcamView />
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={4} lg={3} className={'test-device__settings'}>
                                        <h3 className={'settings__title'}>Paramètres des périphériques</h3>
                                        <Settings />
                                    </Col>
                                </Row>
                            }
                        </Col>
                    </Row>
                </Container>
            </div>

        );
    }

}

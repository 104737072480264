import log from "loglevel";
import Utils from "../Utils/Utils";

export default class NodePort {
    Name = "";
    Direction = "output";
    ParentNode = null;
    ConnectedPorts = [];

    constructor(iName, iDirection, iParentNode) {
        this.Name = iName;
        this.Direction = iDirection;
        this.ParentNode = iParentNode;
    }
    
    Connect(iOtherPort)
    {
        this.ConnectedPorts.push(iOtherPort);
    }

    Disconnect(iOtherPort)
    {
        var index = this.ConnectedPorts.indexOf(iOtherPort);
        if(index > -1)
        {
            this.ConnectedPorts.splice(index, 1);
        }
    }

    ListPortConnections()
    {
        let connections = "none";

        let connectionsList = this.ConnectedPorts.map(port => {
            return  port.Name + "-" + port.ParentNode.GetIdentity();
        });
        
        if(connectionsList.length > 0)
        {
            connections = connectionsList.join(", ");
        }

        return connections;
    }

    GetConnectionsCount()
    {
        return this.ConnectedPorts.length;
    }

    GetFirstConnectedNode()
    {
        if(this.ConnectedPorts.length > 0)
        {
            return this.ConnectedPorts[0].ParentNode;
        }

        return null;
    }

    GetConnectedNodes()
    {
        return this.ConnectedPorts.map(port => {
            return port.ParentNode;
        });
    }

    async ActivateAllConnections()
    {
        //log.debug("ActivateAllConnections: " + this.ParentNode.GetIdentity() + "-" + this.Name + " activating nodes: " + this.ListPortConnections());

        // Wait for the graph to be running
        const graph = this.ParentNode.Graph;
        while(!graph.IsRunning())
        {
            await Utils.Sleep(graph.FrameDuration);
        }

        this.ConnectedPorts.forEach(port => {
            port.ParentNode.OnActivated(this.ParentNode, port);
        });
    }

    TestActivateAllConnections(iTestReport)
    {
        this.ConnectedPorts.forEach(port => {
            port.ParentNode.TestExecute(this.ParentNode, port, iTestReport);
        });
    }
}
import React from 'react';

import Form from 'react-bootstrap/Form';
import Achievement from '../Utilities/Achievement';

import '../../styles/components/Feedback.scss';

export default class StepAchievement extends React.Component {

    achievementsDone= []
    achievementsToDo = []

    async componentDidMount() {

        let achievements =  await window.sdk.exerciseSession().getAchievements(this.props.currentExerciseSessionID);

        if(!achievements){
            achievements = this.props.achievements;
        }
        if(achievements.endingsDone[0]){
            let ending = achievements.endingsDone[0];
            ending.done = true;
            this.achievementsDone = [ending];
        }
        if(this.achievementsDone.length > 0)
            this.achievementsDone.push(...achievements.actionsDone.slice(0, 2).map(action => {action.done = true; return action;}));
        
        this.achievementsToDo = achievements.allAchievementsNotDone.slice(0, 3)

        /*
        let typeExclude = this.props.typeExclude || {};     
        JSON.parse(JSON.stringify(this.props.achievements)).some( action => {
            if(!typeExclude.includes(action.type)){
                if( res.some(done => action.ID == done.ID)){
                    if(this.achievementsDone.length <3){
                    action.done= true;
                    this.achievementsDone.push(action)
                    }
                }
                else{
                    if(this.achievementsToDo.length <3)
                        this.achievementsToDo.push(action)
                }
            }
        })
        */
        this.setState({
            loaded: true
        });        
    }    
    render() {
        return (
            <div className="feedback-step__inner">
                <div className="feedback-step__header">
                    <h1 className="feedback-step__title">Adaptez votre intervention pour changer l’issue de la conversation !</h1>
                </div>
                <div className="feedback-step__content feedback-step__content--colored">
                    <div className="feedback-step-block feedback-step-block-2col">
                        <div className='feedback-step-block-col'>
                            {this.achievementsDone.length > 0 ?
                                <Form.Label>Vous avez déverrouillé : </Form.Label>
                                : 
                                <Form.Label>Vous n'avez rien déverrouillé au cours de cette session.</Form.Label>
                            }
                            {this.achievementsDone.length > 0 ?
                                <React.Fragment>
                                    {this.achievementsDone.map(action => <Achievement key={action.ID} achievement={action} small={true} />)}
                                </React.Fragment>
                                :
                                ""
                            }
                        </div>
                        <div className='feedback-step-block-col'>
                            <Form.Label>Il vous reste à découvrir : </Form.Label>
                            {this.achievementsToDo.map(action => <Achievement key={action.ID} achievement={action} small={true} />)}            
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
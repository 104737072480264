import React from 'react';
import '../../styles/components/Button.scss';
import Button from 'react-bootstrap/Button';


export default class CustomButton extends React.Component {

  timer;

  constructor(props) {
    super(props);
    this.button = React.createRef();
  }

  onMouseEnter() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.classList.remove('btn-mouse-leave');
    this.classList.add('btn-mouse-enter');
  }

  onMouseLeave() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.classList.remove('btn-mouse-enter');
    this.classList.add('btn-mouse-leave');
    setTimeout(() => {
      this.classList.remove('btn-mouse-leave');
    }, 2000);
  }

  componentDidMount() {
    this.button.current.addEventListener('mouseenter', this.onMouseEnter);
    this.button.current.addEventListener('mouseleave', this.onMouseLeave);
  }

  componentWillUnmount() {
    this.button.current.removeEventListener('mouseenter', this.onMouseEnter);
    this.button.current.removeEventListener('mouseleave', this.onMouseLeave);
  }


  render() {
    const { children, ...rest } = this.props;
    return (
      <Button className={'cbtn'} ref={this.button} {...rest}>
        <span className={'cbtn__text1'}>{children}</span>
        <span className={'cbtn__text2'}>{children}</span>
      </Button>
    );
  }
}

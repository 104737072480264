import log from "loglevel";
import React from 'react';
import { useNavigate } from "react-router-dom";

import BootstrapButton from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import { LinkContainer } from 'react-router-bootstrap';
import Button from '../../components/Utilities/Button';

export function withRouter(Children) {
  return (props) => {
      const navigate = useNavigate() ;
      return <Children {...props}  navigate={navigate} />
  }
}


class Register extends React.Component {

  state = {
    email: '',
    password: '',
    password2: '',
    entity: '',
    failed: false,
    successed: false,
    info: {},
    visible: false,
    privacy:false,
    valided: {
      number: false,
      special: false,
      length: false,
      min: false,
      maj: false,
      email: true,
      password: true,
      password2: true,
      entity: true,
      privacy:true
    }
  }

  // Test si email valide
  validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };


  // Envoie le formulaire
  check = async () => {

    const { navigate } = this.props;

    let error = false;
    const { email, valided } = this.state;
    if (!email || !valided.email) {
      valided.email = false;
      this.setState({
        valided: valided
      });
      return false;
    }

    let res;
    try {
      res = await window.sdk.user().checkPending({
        Email: this.state.email
      });
    } catch (e) {
      this.setState({
        failed: true,
        info: {
          message: 'Erreur connexion'
        }
      });
      window.sdk.event().emit('loaderHide');
    }
    log.debug(res);
    if (res && res.state === 'fail') {
      if(res.info.message == 'already active'){
        navigate("/login", {state:{email:this.state.email,redirect_url:window.sdk.getParam('redirect_url')}})
      } else {
      this.setState({
        failed: true,
        info: res.info
      });
    }
    } else {
      this.setState({
        isPending: true
      })
    }
  }

  submit = async () => {
    let error = false;
    const { email, password, password2, privacy, valided } = this.state;
    if(!privacy || !valided.privacy)
      return false;
    if ( !password || !valided.number || !valided.special || !valided.min || !valided.maj ) {
      valided.password = false;
      this.setState({
        valided: valided
      });
      return false;
    }
    if (! password2 || password2 != password) {
      return false;
    }

    let res;
    try {
      res = await window.sdk.user().register({
        email: this.state.email,
        password: this.state.password
      });
    } catch (e) {
      this.setState({
        failed: true,
        info: {
          message: 'Erreur connexion'
        }
      });
      window.sdk.event().emit('loaderHide');
    }


    if (res && res.state === 'fail') {
      this.setState({
        failed: true,
        info: res.info
      });
    } else {
      try {
        res = await window.sdk.user().login({
          username: this.state.email,
          password: this.state.password,
          newpassword: this.state.password,
          name: this.state.email
        });
      } catch(e) {
        this.setState({
          failed: true,
          info: {
            message: 'Erreur connexion'
          }
        });
        window.sdk.event().emit('loaderHide');
      }
  
      if (res && res.state === 'fail') {
        this.setState({
          failed: true,
          info: res.info
        });
      } else {
        
        //document.location.href = '/';
      }
    }

  }


  updateEmail = (value) => {
    const { valided } = this.state;
    valided.email = value.length === 0 ? true : this.validateEmail(value);
    this.setState({
      email: value,
      failed: false,
      valided: valided,
    });
  }

  updatePrivacy(value) {
    const { valided } = this.state;
    valided.privacy = value == 1 ? true : false;
    this.setState({
      privacy: value,
      failed: false,
      valided: valided,
    });
  }

  updateField = (key, value) => {
    this.state[key] = value;
    this.forceUpdate();
  }

  updatePassword = (value) => {
    const { valided } = this.state;
    valided.password = true;
    valided.number = value.search( /.*\d/ ) >= 0;
    valided.special = /[^a-zA-Z0-9]/.test(value);
    valided.length = value.length >= 8;
    valided.min = value.search( /.*[a-z]/ ) >= 0;
    valided.maj = value.search( /.*[A-Z]/ ) >= 0;     
    this.setState({
      password: value,
      failed: false,
      valided: valided,
    });
  }
  
  updatePasswordConfirm = (value) => {
    const { valided } = this.state;
    valided.password2 = true;
    if(value != this.state.password)
      valided.password2 = false;
    this.setState({
      password2: value,
      failed: false,
      valided: valided,
    });
  }

  clearEmail = () => {
    this.setState({
      isPending: false,
      email: ''
    })
  } 

  togglePassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    });
  }
  togglePassword2 = () => {
    this.setState({
      showPassword2: !this.state.showPassword2
    });
  } 

  render() {
    const { valided, showPassword, showPassword2 } = this.state;
    return (
      <div className="login">
          <form className="login-layout__form login__form" onSubmit={(e) => { e.preventDefault() }}>
            {this.state.isPending ?
              <h2 className="form__title">Veuillez saisir les informations nécessaires à la création du compte</h2>
            :
            <h2 className="form__title">Renseigner votre adresse email pour créer un compte ou vous connecter</h2>
          }
            <div className="form__inputs">
              {this.state.failed &&
                <Alert className="alert" variant="danger">{this.state.info.message == 'not found' ? 'Désolé, vous ne semblez pas être dans la liste des emails autorisés' : this.state.info.message}</Alert>
              }
              {this.state.isPending ?
                <React.Fragment>
                  <Form.Group className="mb-3" controlId="email">
                  <Form.Label>Email</Form.Label>
                  <div class="form__email_txt">
                    <i>{this.state.email}</i>
                    <BootstrapButton variant="link"  onClick={this.clearEmail}>Modifier</BootstrapButton></div>
                  
                </Form.Group>
                  <Form.Group className="mb-3 password-group" controlId="password">
                    <Form.Label>Mot de passe</Form.Label>
                    <Form.Control type={showPassword ? "text" : "password"}  name="password" placeholder="Votre mot de passe" onChange={(e) => this.updatePassword(e.target.value)} value={this.state.password} required isInvalid={!valided.password} />
                    <span className="password-toggle" onClick={this.togglePassword}>
                      <FontAwesomeIcon icon={!showPassword ? faEye : faEyeSlash} />
                    </span>
                    <Form.Text className={"password-validated"}>
                <span className={ valided.number ? 'test-valided' : '' }><FontAwesomeIcon icon={faCheck} /> chiffres</span>
                <span className={ valided.special ? 'test-valided' : '' }><FontAwesomeIcon icon={faCheck} /> caractère spécial</span>
                <span className={ valided.length ? 'test-valided' : '' }><FontAwesomeIcon icon={faCheck} /> 8 caractère</span>
                <span className={ valided.min ? 'test-valided' : '' }><FontAwesomeIcon icon={faCheck} /> minuscule</span>
                <span className={ valided.maj ? 'test-valided' : '' }><FontAwesomeIcon icon={faCheck} /> majuscule</span>
              </Form.Text>
                  </Form.Group>
                  <Form.Group className="mb-3 password-group" controlId="confirmPassword">
                    <Form.Label>Confirmer votre mot de passe</Form.Label>
                    <Form.Control type={showPassword2 ? "text" : "password"}  name="password2" placeholder="Confirmez votre mot de passe" onChange={(e) => this.updatePasswordConfirm(e.target.value)} value={this.state.password2} required isInvalid={!valided.password2} />
                    <span className="password-toggle" onClick={this.togglePassword2}>
                      <FontAwesomeIcon icon={!showPassword2 ? faEye : faEyeSlash} />
                    </span>                    
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="privacy">
                    <Form.Check type="checkbox">
                    <Form.Check.Input type="checkbox" name="privacy" checked={this.state.privacy == 1} onChange={(e) => this.updatePrivacy(e.target.checked)} value="1" required isInvalid={!valided.privacy} />
                      <Form.Check.Label>J'ai lu et j'accepte <a href={window.policyUri} className='btn-link' target="_blank">la politique de confidentialité</a></Form.Check.Label>
                    </Form.Check>
                </Form.Group>
                </React.Fragment>
                :
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" name="email" autoComplete="email" placeholder="Votre adresse email" onChange={(e) => this.updateEmail(e.target.value)} value={this.state.uname} required isInvalid={!valided.email} />
                </Form.Group>
              }
              <div className="submit-container mt-4 mb-3">
                <Button variant="primary" disabled={this.props.loading} type="submit" onClick={this.state.isPending ? this.submit : this.check}>Valider</Button>
              </div>
            </div>
          </form>
        
      </div>
    );
  }
}

// Wrap and export
export default withRouter(Register);

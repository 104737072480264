import React, {useRef, useEffect} from 'react';

import Lottie from "lottie-react";
import waitingAnimation from "../../assets/animations/Waiting";
import raisingHandAnimation from "../../assets/animations/RaisingHand";
import speechAnimation from "../../assets/animations/Speech";
import BullesAnimation from "../../assets/animations/Bulles";
import Ending1Animation from "../../assets/animations/Ending1.json";
import Ending2Animation from "../../assets/animations/Ending2.json";
import Ending3Animation from "../../assets/animations/Ending3.json";
import AchievementDoneAnimation from "../../assets/animations/AchievementDone";
import AchievementFailAnimation from "../../assets/animations/AchievementFail";
import EncouragementsAnimation from "../../assets/animations/Encouragements";
import InsultesAnimation from "../../assets/animations/Insultes";
import NonInterventionAnimation from "../../assets/animations/NonIntervention";
import Phase4Animation from "../../assets/animations/Phase4";
import RecadrageAnimation from "../../assets/animations/Recadrage";
import RecentrageAnimation from "../../assets/animations/Recentrage";
import WellRoundedAnimation from "../../assets/animations/WellRounded";
import ReformulationAnimation from "../../assets/animations/Reformulation";

const Animation = (props) => {

const components = {
    waiting: waitingAnimation,
    speech: speechAnimation,
    bulles: BullesAnimation,
    raisingHand: raisingHandAnimation,
    Ending_0: Ending1Animation,
    Ending_1: Ending1Animation,
    Ending_2: Ending2Animation,
    Ending_3: Ending3Animation,
    Ending: Ending3Animation,
    AchievementDone: AchievementDoneAnimation,
    AchievementFail: AchievementFailAnimation,
    Encouragements:EncouragementsAnimation,
    Insultes:InsultesAnimation,
    NonIntervention: NonInterventionAnimation,
    Phase4:Phase4Animation,
    Recadrage:RecadrageAnimation,
    Recentrage:RecentrageAnimation,
    WellRounded : WellRoundedAnimation,
    Reformulation: ReformulationAnimation,
    Questionnement:ReformulationAnimation
};
  const lottieRef = useRef();

  useEffect(() => {
    window.sdk.event().on('playLottie', (name) => {
        if(name == props.name && props.type != 'Ending_0') 
            lottieRef.current.play()
    })
    window.sdk.event().on('pauseLottie', (name) => {
      if(name == props.name  && props.type != 'Ending_0') 
          lottieRef.current.stop()
    })
  }, []);

  return <span className={"icon-animation-lottie " + (props.classCSS ? props.classCSS : '')}>
        <Lottie lottieRef={lottieRef} animationData={components[props.type]} loop={props.loop} autoplay={props.autoplay}  />
        </span>;
};

export default Animation;

import log from "loglevel";
import ExerciseNode from './ExerciseNode';
import ValueString from './ValueString';
import NodePort from '../NodePort';
import ParticipantsModule from '../../Participants/ParticipantsModule';
import Utils from '../../Utils/Utils';

export default class SpeechToText extends ExerciseNode 
{
    // References
    transcriptionSession;
    
    // Ports
    Input = new NodePort("Input", "input", this);
    Output = new NodePort("Output", "output", this);
    FirstWord = new NodePort("FirstWord", "output", this);  // Connected to nodes waiting for first words event
    Result = new NodePort("Result", "output", this);  // Connected to string value nodes that store or use the string result of the speech recognition 
    Failed = new NodePort("Failed", "output", this);  // If an error or timeout occurs

    // Parameters
    Endpoint = "";
    EndSilenceSeconds = 2;
    PhraseList = [];
    
    // Internal values
    m_Engine = "MicrosoftCognitiveServicesSpeech";
    m_StartTime = null;
    m_PartialSpeechDetected = "";
    m_SpeechDetected = "";
    m_LastSpeechDate = null;
    m_TimeOutDelay = 10000; // ms
    m_TranscriptionID = "";
    m_ParentNode = null;
    

    constructor(iGraph, iProperties) 
    {        
        super(iGraph, iProperties);

        this.Endpoint = iProperties.Endpoint;
        this.EndSilenceSeconds = iProperties.EndSilenceSeconds;
        this.PhraseList = iProperties.PhraseList;
        // For smart branching decision inclusion, we need to know the parent node
        this.m_ParentNode = iProperties.ParentNode;

        /*log.debug(this.GetIdentity() + " constructor: graph = " + this.Graph.ExerciseName 
        + ", id = " + this.ID 
        + ", Endpoint = " + this.Endpoint 
        + ", End Silence = " + this.EndSilenceSeconds 
        + ", PhraseList = " + this.PhraseList); */
    }

    async OnActivated(iActivator, iInputPort)
    {
        super.OnActivated(iActivator, iInputPort);

        log.debug(this.GetIdentity() + " has been activated by '" + iActivator.GetIdentity() + "'.");

        // Initializations
        this.m_SpeechDetected = "";
        this.m_PartialSpeechDetected = "";
        this.m_StartTime = new Date();
        this.m_LastSpeechDate = new Date();
        this.m_TranscriptionID = Utils.CreateObjectIDWithIncrement(this.m_StartTime, window.sdk.user().userID, this.m_Engine);

        // Start speech recognition
        let human = ParticipantsModule.Instance.GetHuman();
        human.setSpeakingState("readyforSpeaking");

        this.m_SpeechDetected = "";

        // Setup the phrase list
        let phraseListToUse = [];
        if(!this.PhraseList.length)
        {
            // If nothing received, we use the default phrase list
            phraseListToUse = [
                "Clara",
                "Jeanne",
                "notre Jeanne",
                "Franck",
                "Jules",
                "Camille",
                "yeux bleus",
                "cerveau qui tourne",
                "équipe de vieux",
                "sang neuf"
            ];
        }
        else
        {
            // Else we use the received phrase list instead
            phraseListToUse = this.PhraseList;
        }
        
        this.transcriptionSession = await window.sdk.videoconf().createTranscriptionSession({
            onTranscriptedText: (data) => {
                this.OnSpeechDetected(data.text);
            },
            onPartialTranscriptedText: (data) => {
                this.OnPartialSpeechDetected(data.text);                
            },
            onFailed: (data) => {
                this.OnFailed(data);                
            },
            customData: {
                "transcriptionID": this.m_TranscriptionID,
                "endpoint": this.Endpoint,
                "segmentationSilenceTimeoutMs": this.EndSilenceSeconds * 1000,
                "phraseListGrammar": phraseListToUse
            }
        });
        this.transcriptionSession.start();
    }

    // Triggered when the STT finds the first words
    OnFirstWordDetected()
    {      
        // If inactive, ignore
        if(!this.m_IsActive)
        {
            log.debug(this.GetIdentity() + " OnFirstWordDetected: ignored because node is inactive!");
            return;
        }

        // If paused, ignore
        if(this.IsPaused())
        {
            log.debug(this.GetIdentity() + " OnFirstWordDetected: ignored because node is paused!");
            return;
        }

        // Show "Vous parlez" state on the user's video slot
		let human = ParticipantsModule.Instance.GetHuman();
		human.setSpeakingState("speaking");
		
        log.debug(this.GetIdentity() + " OnFirstWordDetected.");
        
        this.FirstWord.ActivateAllConnections();

        // If this node is a child of a smart branching decision, we need to send the first word to the parent node
        if(this.m_ParentNode)
        {
            this.m_ParentNode.OnFirstWordDetected();
        }
    }

    // Triggered when the STT finished the speech recognition (EndSilence timeout)
    OnPartialSpeechDetected(iResult)
    {
        // If inactive, ignore
        if(!this.m_IsActive)
        {
            log.debug(this.GetIdentity() + " OnPartialSpeechDetected: '" + iResult + "' ignored because node is inactive!");
            return;
        }

        log.debug(this.GetIdentity() + " OnPartialSpeechDetected: '" + iResult + "'.");
		
        // If these are the first words, trigger the first word detection event
        if(this.m_PartialSpeechDetected == "")
        {
            this.OnFirstWordDetected();
        }

        this.m_LastSpeechDate = new Date();
        this.m_PartialSpeechDetected = iResult;
        
        // Send the string result to the Exercise React component
        this.Graph.ParentExerciseComponent.SetSpeechFeedbackText(this.m_PartialSpeechDetected);
        
        // If this node is a child of a smart branching decision, send the speech to the parent node
        if(this.m_ParentNode)
        {
            this.m_ParentNode.OnPartialSpeechDetected(iResult);
        }
    }

    // Triggered when the STT finished the speech recognition (EndSilence timeout)
    OnSpeechDetected(iResult)
    {
        // If inactive, ignore
        if(!this.m_IsActive)
        {
            log.debug(this.GetIdentity() + " OnSpeechDetected: '" + iResult + "' ignored because node is inactive!");
            return;
        }

        // If paused, ignore
        if(this.IsPaused())
        {
            log.debug(this.GetIdentity() + " OnSpeechDetected: '" + iResult + "' ignored because node is paused!");
            return;
        }

        log.debug(this.GetIdentity() + " OnSpeechDetected: '" + iResult + "'.");

        // Log to test variables
        if(window.testMode.fillAppStateValues)
        {
            window.testMode.appStateValues["lastSpeechToText"] = iResult;
        }

        // Log to DynamoDB
        window.sdk.AnalysisTask().createOne(
            this.Graph.LastBranchingDecisionNode.DatabaseID ? this.Graph.LastBranchingDecisionNode.DatabaseID : "undefined", // Parent Branching Decision Node
            this.ID.toString(), // Node ID
            this.m_Engine, // analyzer Engine
            "1", // Analyzer Version
            "raw", // Analysis Status
            this.m_TranscriptionID, // Analysis Input
            this.m_StartTime, // Start Time
            (new Date().getTime() - this.m_StartTime.getTime()).toString(), // Analysis duration (milliseconds)
            "", // Possible choices
            iResult, // Analysis Result
            this.Graph.ExerciseID.toString() // Exercise ID
        ).then((analysisTask) => {
            // Log action to history
            this.Graph.History.AddUserSpeech(this.ID, analysisTask.ID.S, iResult);
        });


        this.m_SpeechDetected = iResult;
        this.Graph.ParentExerciseComponent.SetSpeechFeedbackText(this.m_SpeechDetected);
        this.OutputResult();
        
        if(this.transcriptionSession) {
            this.transcriptionSession.close();
        }
        
        let human = ParticipantsModule.Instance.GetHuman();
        human.setSpeakingState("no");
        
        // Reset
        this.m_PartialSpeechDetected = "";

        this.ActivateOutput();
        
        // If this node is a child of a smart branching decision, send the speech to the parent node
        if(this.m_ParentNode)
        {
            this.m_ParentNode.OnSpeechDetected(iResult);
        }
    }

    // Triggered when the STT finished the speech recognition (EndSilence timeout)
    OnFailed(iReason)
    {
        log.debug(this.GetIdentity() + " OnFailed: Reason '" + iReason + "'.");

        if(this.transcriptionSession) {
            this.transcriptionSession.close();
        }

        // Log to DynamoDB
        window.sdk.AnalysisTask().createOne(
            this.Graph.LastBranchingDecisionNode.DatabaseID ? this.Graph.LastBranchingDecisionNode.DatabaseID : "undefined", // Parent Branching Decision Node
            this.ID.toString(), // Node ID
            this.m_Engine, // analyzer Engine
            "1", // Analyzer Version
            "failed", // Analysis Status
            this.m_TranscriptionID, // Analysis Input
            this.m_StartTime, // Start Time
            (new Date().getTime() - this.m_StartTime.getTime()).toString(), // Analysis duration (milliseconds)
            "", // Possible choices
            "Failed! Reason: '" + iReason + "'. Partial speech detected: '" + this.m_PartialSpeechDetected + "'.",
            this.Graph.ExerciseID.toString() // Exercise ID // Analysis Result
        );

        this.Graph.ParentExerciseComponent.SetSpeechFeedbackText("Failed: " + iReason + ". Partial speech detected: '" + this.m_PartialSpeechDetected + "'.");
        
        let human = ParticipantsModule.Instance.GetHuman();
        human.setSpeakingState("no");
        
        // Reset
        this.m_PartialSpeechDetected = "";
        this.SetActive(false);

        // Output to 'Failed' port
        this.Failed.ActivateAllConnections();
        
        // If this node is a child of a smart branching decision, send the failed event to the parent node
        if(this.m_ParentNode)
        {
            this.m_ParentNode.OnSTTFailed();
        }
    }

    // Send the string result to the nodes connected to the SpeechResult_OutputNodes port
    OutputResult()
    {
        log.debug(this.GetIdentity() + " OutputResult '" + this.m_SpeechDetected + "' to " + this.Result.GetConnectionsCount() + " nodes: " + this.Result.ListPortConnections());

        this.Result.GetConnectedNodes().forEach(node => {
            if(node instanceof ValueString)
            {
                node.SetValue(this.m_SpeechDetected);
            }
        });
    }
    
    Update()
    {
        if(this.m_IsActive)
        {
            // If request took too long, cancel it and don't block the scenario
            if((new Date().getTime() - this.m_LastSpeechDate.getTime()) > this.m_TimeOutDelay)
            {
                log.debug(this.GetIdentity() + " Update: request took too long, cancelling.");
                this.OnFailed("Timeout reached: " + this.m_TimeOutDelay + "ms.");
            }
        }
    }
	
	Resume() {
        super.Resume();
        
		if(this.m_IsActive)
        {
            this.OnFailed("Node was paused while waiting for speech recognition.");
        }
	}
        
    ActivateOutput()
    {
        log.debug(this.GetIdentity() + "' activating output.");

        this.SetActive(false);

        this.Output.ActivateAllConnections();
    }

    GetStringValue()
    {
        return this.m_SpeechDetected;
    }

    OnDeactivated()
    {
        log.debug(this.GetIdentity() + " OnDeactivated: speech found = '" + this.m_SpeechDetected + "'.");

        // Stop current speech recognition if running
        // TODO: VoiceControlManager.Instance.CancelSpeechRecognition(this);
        if(this.transcriptionSession) {
            this.transcriptionSession.close();
        }
        
        super.OnDeactivated();
    }

    PrintParameters()
    {
        //log.debug("SpeechToText: graph = " + this.Graph.ExerciseName + ", id = " + this.ID + ", End Silence = " + this.EndSilence); 
    }
    

    //////////////////////////
    // Test functions
    //////////////////////////

    TestExecute(iActivator, iInputPort, iTestReport)
    {
        // Initialize the test
        //this.m_ExecutionMode = "Test"; Not needed?
        this.m_SpeechDetected = iTestReport.UserSpeech;

        this.Output.TestActivateAllConnections(iTestReport);
    }
}
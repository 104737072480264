import log from "loglevel";
import React from 'react';

import '../../styles/components/Feedback.scss';

import { useParams, useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BootstrapButton from 'react-bootstrap/Button';

import Button from '../../components/Utilities/Button';
import ExerciseGraph from '../../AppClasses/ExerciseScenario/ExerciseGraph';

import StepCongrats from '../../components/Feedback/StepCongrats';
import StepCongrats_1 from '../../components/Feedback/StepCongrats_1';
import StepCongrats_2 from '../../components/Feedback/StepCongrats_2';
import StepCongrats_4 from '../../components/Feedback/StepCongrats_4';
import StepEnding from '../../components/Feedback/StepEnding';
import StepRetry from '../../components/Feedback/StepRetry';
import StepGoals_2 from '../../components/Feedback/StepGoals_2';
import StepGoals_4 from '../../components/Feedback/StepGoals_4';
import StepAchievement from '../../components/Feedback/StepAchievement';
import StepFeedback from '../../components/Feedback/StepFeedback';

// Sounds
//import congratsSound from '../../assets/sounds/Bravo_Felicitation_Screen.mp3';
//import swooshSound from '../../assets/sounds/Swoosh.mp3';


export function withRouter(Children) {
    return (props) => {
        const match = { params: useParams() };
        const navigate = useNavigate() ;
        return <Children {...props} match={match} navigate={navigate} />
    }
}


class FeedbackPanel extends React.Component {

    listeners = [];

    state = {
        stepName: '',
        loaded: false
    }
    translateAmount = 80;
    translate = 0;
    totalStep;
    jsonGraph = {};
    stepName = '';
    step = 0;


    stepByExercise = {
        0: [ // Test exercise
            'Feedback',
            'Congrats',
            'Ending',
            'Achievements',
            'Retry'
        ],
        1: [ // Stimulus - Observation
            'Congrats'
        ],
        2: [ // Stimulus - Reaction (non guide)
            'Congrats',
            'Ending',
            'Achievements',
            'Retry'
        ],
        3: [ // Formuler un feedback negatif
            'Congrats',
            'Ending',
            'Achievements',
            'Retry'
        ],
        4: [ // Stimulus - Reaction (guide)
            'Congrats',
            'Ending',
            //'Achievements',
            'Retry'
        ],
        6: [ // Donner du Feedback 1v2
            'Congrats',
            'Ending',
            //'Achievements',
            'Retry'
        ]

    }

    async componentDidMount() {
        
        // Play congrats sound
        window.sdk.sound().play('Bravo_Felicitation_Screen')

        // Retrieve exercise graph
        const res = await window.sdk.exerciseSession().getOneWithExercise(this.props.match.params.idExercise, this.props.match.params.idExerciseSession);
        this.exerciseSession = res.exerciseSession;
        this.jsonGraph = res.exercise;
        this.uiCustomization = this.jsonGraph.CustomizationValues;
        let availableAchievements = this.jsonGraph.AvailableAchievements;
        let availablePedagologicalEnds = this.jsonGraph.AvailablePedagologicalEnds;

        // Retrieve exercise session history
        const exerciseSessionHistory = await window.sdk.exerciseSession().getExerciseSessionHistory(this.props.match.params.idExerciseSession);

        /////////////////////////////
        // ENDING
        
        // Extract ending done from history
        const endingDone = exerciseSessionHistory.find(item => item.EventType == 'PedagologicalEnd');
        this.endingDone = endingDone.Content;
        
        // List all endings not done
        if(availablePedagologicalEnds)
        {
            let endingsNotDone = [...availablePedagologicalEnds];
            const isSameEnd = (end) => end.ID === endingDone.Content.ID;
            let endingDoneIndex = endingsNotDone.findIndex(isSameEnd);
            endingsNotDone.splice(endingDoneIndex, 1);
        }

        // Extract pedagological addition from history and add it to the ending description
        const pedagologicalAddition = exerciseSessionHistory.find(item => item.EventType == 'PedagologicalAddition');
        this.endingDone.Description = this.endingDone.Description + (pedagologicalAddition ? "<br>" + pedagologicalAddition.Content.Description : "");

        /////////////////////////////
        // ACHIEVEMENTS

        // Extract achievements done from history
        const achievementsDoneEvents = exerciseSessionHistory.filter(item => item.EventType == 'Achievement');
        let achievementsDone = achievementsDoneEvents.map(item => item.Content);
        
        // Keep 3 random achievements done to show
        this.achievementsDoneToDisplay = achievementsDone.sort((a, b) => 0.5 - Math.random()).slice(0,3);

        /////////////////////////////
        // RETRY

        // Extract pedagological recommendation from history
        let pedagologicalRecommendations = exerciseSessionHistory.filter(item => item.EventType == 'PedagologicalRecommendation');

        // Keep 3 random endings not done to show them as goals
        this.goals = (pedagologicalRecommendations.sort((a, b) => 0.5 - Math.random()).slice(0,3)).map(item => item.Content);

        /////////////////////////////
        // STEPS

        this.steps = this.stepByExercise[res.exercise.ID];
        if (!this.steps)
            this.steps = this.stepByExercise[0];
        this.totalStep = this.steps.length;
        this.stepName = this.steps[0];
        this.step = 0;
        this.setState({
            loaded: true,
            stepName: this.stepName
        });
        
        window.sdk.event().emit('FeedbackPanelEntered');


        this.listeners.push(window.sdk.event().on('restartExercise', () => {
            this.props.navigate('/exercise/' + this.props.match.params.idExercise, { replace: false });
        }));

        this.listeners.push(window.sdk.event().on('stopExercise', () => {
            this.stop()
        }));
    }

    componentWillUnmount() {
        for (var i in this.listeners) {
            this.listeners[i]();
        }
    }

    submitStep = async () => {
        
        //this.playSound(swooshSound);
        window.sdk.sound().play('Swoosh');
        let el = document.querySelector('.main-content__container')
        el.classList.add('noscroll')
        setTimeout(() => {
            el.classList.remove('noscroll')
        },650)
        /*if (this.state.step == this.totalStep) {
            document.location.href = document.location.href.replace('feedback', 'retry')
        }*/
        //if (this.state.step < this.totalStep) {
        
        this.step++;
        this.stepName = this.steps[this.step]

        await this.setState({
            stepName: this.stepName
        });
        if (this.stepName == 'Ending') {
            setTimeout(() => {
                window.sdk.event().emit('playLottie', 'ending');
            }, 500)
        }
        if (this.stepName == 'Achievements') {
            this.scrollAchievement()
            setTimeout(() => {
                window.sdk.event().emit('playLottie', 'achievement-0');
               
                    setTimeout(() => {
                        this.setState({
                            classAchievement: 'show2'
                        })
                        if (this.achievementsDoneToDisplay.length > 1) {
                        setTimeout(() => {
                            window.sdk.event().emit('playLottie', 'achievement-1')
                        }, 500)
                    }
                            setTimeout(() => {
                                this.setState({
                                    classAchievement: 'show3'
                                })
                                if (this.achievementsDoneToDisplay.length > 2) {
                                setTimeout(() => {
                                    window.sdk.event().emit('playLottie', 'achievement-2');
                                }, 500)
                            }
                            }, 1000)
                        
                    }, 500);
                
            }, 250)
        }
        if (this.stepName == 'Retry') {
            document.querySelector('.feedback-step-step-retry').scrollTop = 0;
        }
        /*if (this.stepName == 'goals') {
            const footer = document.querySelector(".feedback__actions");
            footer.style.display = 'none';
        }*/

        //const pages = document.querySelectorAll(".feedback-step");

        {/*this.translate -= this.translateAmount
            pages.forEach(
                pages => (pages.style.transform = `translateY(${this.translate}%)`)
            );*/}
        //}
    }
    retry = () => {
        this.props.navigate('/exercise/' + this.props.match.params.idExercise, { replace: false });
    }
    stop = () => {
        if (window.sdk.isInIframe()) {
            window.sdk.event().emit('showExitedModal ',
            { 
                ExitMessage: this.jsonGraph.CustomizationValues.CompletedExercise_Text,
                ExitedButton_Visible: this.jsonGraph.CustomizationValues.CompletedExerciseButton_Visible === 'true',
                ExitedButton_Text: this.jsonGraph.CustomizationValues.CompletedExerciseButton_Text
            });
        }
        else {
            this.props.navigate('/', { replace: false });
        }
    }
    scrollAchievement = () => {
        let el = document.querySelector('.feedback-step-container')
        let child = document.querySelector('.feedback-step-achievements')
        let x = 0;
        var scrollTimer = setInterval(() => {
            el.scrollBy(0, 10);
            x += 10;
            if (x >= child.offsetHeight) {
                // you're at the bottom of the page
                clearInterval(scrollTimer);
            }
        }, 5);
    }

    /*back = () => {
        this.setState({
            step : this.state.step - 1
        });
        const pages = document.querySelectorAll(".feedback-step");

        this.translate += this.translateAmount
        pages.forEach(
            pages => (pages.style.transform = `translateY(${this.translate}%)`)
        );
    }*/
    buttonAction =(action, defaultAction) => {
        log.debug('buttonAction',action,defaultAction)
        action = action ? action : defaultAction;
        log.debug('buttonAction',action)
        switch(action){
            case 'quit':
            case 'stop':
                this.stop();
                break;
            case 'continue': 
                this.submitStep();
                break;
            case 'retry':
                this.retry();
                break;
            default:
                log.debug('action not exist :( -> ',action)
                break;
        }
    }

    render() {
        if (!this.state.loaded)
            return null; //show loader
        return (
            <div className="feedback">
                <div className={"feedback-step-container feedback-step-step-" + (this.state.stepName.toLowerCase())}>
                    {this.state.stepName == 'Feedback' ?
                        <StepFeedback exerciseID={this.jsonGraph.ID} />
                        :
                        <React.Fragment>
                            {this.steps.includes('Congrats') && <StepCongrats text={this.uiCustomization} />}
                            {/*this.steps.includes('congrats_1') && <StepCongrats_1 text={this.uiCustomization} />}
                            {this.steps.includes('congrats_2') && <StepCongrats_2 text={this.uiCustomization} />}
        {this.steps.includes('congrats_4') && <StepCongrats_4 text={this.uiCustomization} />*/}
                            {(this.steps.includes('Ending') || this.steps.includes('Achievements')) &&
                                <div className="feedback-step feedback-step-rewards">
                                    {this.steps.includes('Ending') && <StepEnding ending={this.endingDone} text={this.uiCustomization} />}
                                    {this.steps.includes('Achievements') && <StepAchievement text={this.uiCustomization} actions={this.achievementsDoneToDisplay} classAchievement={this.state.classAchievement} />}
                                </div>
                            }
                            {this.steps.includes('Retry') && <StepRetry goals={this.goals} text={this.uiCustomization} />}
                            {/*this.steps.includes('retry_2') && <StepGoals_2 goals={this.goals} text={this.uiCustomization} />}
                            {this.steps.includes('retry_4') && <StepGoals_4 goals={this.goals} text={this.uiCustomization} />*/}
                        </React.Fragment>
                    }
                    <div className={'feedback__actions'}>
                        {this.stepName == 'Feedback' ?
                            <Button variant="primary" onClick={this.submitStep}>Valider</Button>
                        :
                        <React.Fragment>
                            {((this.stepName == 'Retry' && this.uiCustomization[this.state.stepName+'ButtonStop_Visible'] != 'false') || this.uiCustomization[this.state.stepName+'ButtonStop_Visible'] == 'true') && 
                                <Button variant="light" onClick={() =>{this.buttonAction(this.uiCustomization[this.state.stepName+'ButtonStop_Action'],'stop')}}>{this.uiCustomization[this.state.stepName+'ButtonStop_Text'] ? this.uiCustomization[this.state.stepName+'ButtonStop_Text'] : 'Arrêter'}</Button>
                            }
                            {this.stepName !== 'Retry' && this.uiCustomization[this.state.stepName+'ButtonValidation_Visible'] !== 'false' && 
                                <Button variant="primary" onClick={() =>{this.buttonAction(this.uiCustomization[this.state.stepName+'ButtonValidation_Action'],'continue')}}>{this.uiCustomization[this.state.stepName+'ButtonValidation_Text'] ? this.uiCustomization[this.state.stepName+'ButtonValidation_Text'] : 'Valider'}</Button>
                            }     
                            {((this.stepName == 'Retry' && this.uiCustomization[this.state.stepName+'ButtonRetry_Visible'] != 'false') || this.uiCustomization[this.state.stepName+'ButtonRetry_Visible'] == 'true') &&                                 
                                <Button variant="primary" onClick={() =>{this.buttonAction(this.uiCustomization[this.state.stepName+'ButtonRetry_Action'],'retry')}}>{this.uiCustomization[this.state.stepName+'ButtonRetry_Text'] ? this.uiCustomization[this.state.stepName+'ButtonRetry_Text'] : 'Recommencer'}</Button>
                            }  
                        </React.Fragment>
                        }                     
                        {/*(() => {
                            switch (this.state.stepName) {
                                case 'goals':
                                    return ( <React.Fragment>
                                            <Button variant="light" onClick={this.stop}> Arrêter</Button>
                                            <Button variant="primary" onClick={this.retry}>Recommencer</Button>
                                        </React.Fragment>
                                    )
                                case 'congrats':
                                    return null;
                                default: return(
                                    <Col className="text-center">
                                        <Button variant="primary" onClick={this.submitStep}>Valider</Button>
                                    </Col>
                                )
                            }
                        })()*/}
                </div>
                </div>
                {1 == 2  && <Container className={'feedback__actions'}>
                    <Row style={{ justifyContent: 'space-between', alignItems: 'center', margin: 0 }}>
                        {/*<Col>
                            {this.state.step > 1 && <BootstrapButton className="feedback__back" variant="link" onClick={this.back}>{'< Retour'}</BootstrapButton>}
                        </Col>*/}
                        { !this.steps.includes('congrats_1') &&
                        <Col className="text-center">
                            <Button variant="primary" onClick={this.submitStep}>
                                {(() => {
                                    switch (this.state.step) {
                                        case 1: return 'Suivant'
                                        default: return 'Valider'
                                    }
                                })()}
                            </Button>
                        </Col> }
                        {/*<Col>
                        </Col>*/}
                    </Row>
                </Container> }
            </div>);
        return (

            <div className="feedback">
                <div className="feedback-step-container" style={{ width: this.totalStep * 100 + '%' }}>
                    <div className={"feedback-step feedback-step-1" + (this.state.step == 1 ? ' active' : '')}>
                        <StepAchievement step={this.state.step} currentExerciseSessionID={this.props.match.params.idExerciseSession} />
                    </div>
                    <div className={"feedback-step feedback-step-2" + (this.state.step == 2 ? ' active' : '')}>
                        <StepFeedback
                            currentExerciseSessionID={this.props.currentExerciseSessionID}
                            identifier={'scene'}
                        />
                    </div>
                    <div className={"feedback-step feedback-step-3" + (this.state.step == 3 ? ' active' : '')}>
                        <StepFeedback
                            currentExerciseSessionID={this.props.currentExerciseSessionID}
                            identifier={'franck'}
                        />
                    </div>
                    <div className={"feedback-step feedback-step-4" + (this.state.step == 4 ? ' active' : '')}>
                        <StepFeedback
                            currentExerciseSessionID={this.props.currentExerciseSessionID}
                            identifier={'jeanne'}
                        />
                    </div>
                    {/*
                        <div className={"feedback-step feedback-step-3" + (this.state.step == 3 ? ' active' : '')}>
                            <div className="feedback-step-inner">
                                <div className="title">
                                    <h1>Indiquez ci-dessous dans quelle mesure vous avez ressenti les émotions suivantes :</h1>
                                </div>
                                <div className="feedback-step-content">
                                <Form.Group>
                                    <Range  identifier="satisafaction"  box={true} labelLeft="Peur" more={true} onChange={this.setEmotion} />
                                    <Range  identifier="satisafaction"  box={true} labelLeft="Colère" more={true} onChange={this.setEmotion} />
                                    <Range  identifier="satisafaction"  box={true} labelLeft="Surprise" more={true} onChange={this.setEmotion} />
                                    <Range  identifier="satisafaction"  box={true} labelLeft="Peur" more={true} onChange={this.setEmotion} />
                                    <Range  identifier="satisafaction"  box={true} labelLeft="Joie" more={true} onChange={this.setEmotion} />
                                    <Range  identifier="satisafaction"  box={true} labelLeft="Dégoût" more={true} onChange={this.setEmotion} />
                                </Form.Group>
                                </div>
                            </div>
                        </div>
                        <div className={"feedback-step feedback-step-4" + (this.state.step == 4 ? ' active' : '')}>
                            <div className="feedback-step-inner">
                                <div className="title">
                                    <h1>Emotional data viz stabbat matter consectetur virum</h1>
                                </div>
                                <div className="feedback-step-content">
                                    <div>GRAPH</div>
                                    <ul>
                                        <li>Facilisis habitasse orci donec tempus euismod. In consectetur risus sit phasellus rutrum. Non lacinia fermentum amet urna gravida quisque. </li>
                                        <li>Consequat porta quisque aliquam blandit in duis. Sed lectus risus habitant pretium tellus cursus in.</li>
                                        <li>Consequat porta quisque aliquam blandit in duis. Sed lectus risus habitant pretium tellus cursus in.</li>
                                        <li>Imperdiet semper diam nec gravida. Est iaculis enim magna gravida id. Tortor eu sodales tempus volutpat et sit arcu. </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    */}
                </div>
                <Container className={'feedback__actions'}>
                    <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                        <Col>
                            {this.state.step > 1 && <BootstrapButton className="feedback__back" variant="link" onClick={this.back}>{'< Retour'}</BootstrapButton>}
                        </Col>
                        <Col className="text-center">
                            <Button variant="primary" onClick={this.submitStep}>
                                {(() => {
                                    switch (this.state.step) {
                                        case 1: return 'Suivant'
                                        default: return 'Valider'
                                    }
                                })()}
                            </Button>
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default withRouter(FeedbackPanel);

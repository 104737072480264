import log from "loglevel";
import seedrandom from 'seedrandom';
import Utils from '../Utils/Utils';
import ScenarioRoot from './ExerciseNodes/ScenarioRoot';
import StopExercise from './ExerciseNodes/StopExercise';
import Act from './ExerciseNodes/Act';
import Scene from './ExerciseNodes/Scene';
import DynamicPanel from './ExerciseNodes/DynamicPanel';
import BranchingDecision from './ExerciseNodes/BranchingDecision';
import Delay from './ExerciseNodes/Delay';
import GroupPaths from './ExerciseNodes/GroupPaths';
import WaitForAll from './ExerciseNodes/WaitForAll';
import RandomBranch from './ExerciseNodes/RandomBranch';
import Deactivate from './ExerciseNodes/Deactivate';
import StatusChange from './ExerciseNodes/StatusChange';
import BotConnection from './ExerciseNodes/BotConnection';
import BotDisconnection from './ExerciseNodes/BotDisconnection';
import BotVideo from './ExerciseNodes/BotVideo';
import BotRandomVideo from './ExerciseNodes/BotRandomVideo';
import SpeechToText from './ExerciseNodes/SpeechToText';
import GPT3BoolQuestion from './ExerciseNodes/GPT3BoolQuestion';
import GPT3Choice from './ExerciseNodes/GPT3Choice';
import GPTChoice from './ExerciseNodes/GPTChoice';
import GPT3Classifier from './ExerciseNodes/GPT3Classifier';
import ToWhomDetector from './ExerciseNodes/ToWhomDetector';
import BadWordDetector from './ExerciseNodes/BadWordDetector';
import WordDetector from './ExerciseNodes/WordDetector';
import SemanticSearch from './ExerciseNodes/SemanticSearch';
import SmartBranchingDecision from './ExerciseNodes/SmartBranchingDecision';
import GoOnstage from './ExerciseNodes/GoOnstage';
import GoOffstage from './ExerciseNodes/GoOffstage';
import GoOnstageBriefing from './ExerciseNodes/GoOnstageBriefing';
import ActionButton from './ExerciseNodes/ActionButton';
import GoToButton from './ExerciseNodes/GoToButton';
import RaiseHandButton from './ExerciseNodes/RaiseHandButton';
import If from './ExerciseNodes/If';
import TrackedEvent from './ExerciseNodes/TrackedEvent';
import SetBool from './ExerciseNodes/SetBool';
import SetInt from './ExerciseNodes/SetInt';
import ValueBool from './ExerciseNodes/ValueBool';
import ValueInt from './ExerciseNodes/ValueInt';
import ValueString from './ExerciseNodes/ValueString';
import ParticipantsModule from '../Participants/ParticipantsModule';
import ExerciseNode from './ExerciseNodes/ExerciseNode';
import NarrativeSolver from './ExerciseNodes/NarrativeSolver';
import UserAction from './ExerciseNodes/UserAction';
import AppEvent from './ExerciseNodes/AppEvent';
import ExerciseSessionHistory from './ExerciseSessionHistory';
import FeedbackSolver from './FeedbackSolver';
import AchievementsSolver from './AchievementsSolver';

export default class ExerciseGraph 
{
    // References
    ParentExerciseComponent = null;

    // Parameters
    FrameDuration = 1000/30; // Running at 30 fps

    // Graph content
    ExerciseName;
    ExerciseID;
    ScenarioID;
    UserName = "Camille";
    Nodes;
    BoolValues = {};
    IntValues = {};
    StringValues = {};
    PrioritaryVideosToPreload = [];
    APIsEndpoints = {};

    // Types
    Type = "ExerciseGraph";
    NodeTypes = {
        "ScenarioRoot": ScenarioRoot,
        "StopExercise": StopExercise,
        "Act": Act,
        "Scene": Scene,
        "DynamicPanel": DynamicPanel,
        "BranchingDecision": BranchingDecision,
        "Delay": Delay,
        "GroupPaths": GroupPaths,
        "WaitForAll": WaitForAll,
        "RandomBranch": RandomBranch,
        "Deactivate": Deactivate,
        "StatusChange": StatusChange,
        "BotConnection": BotConnection,
        "BotDisconnection": BotDisconnection,
        "BotVideo": BotVideo,
        "BotRandomVideo": BotRandomVideo,
        "SpeechToText": SpeechToText,
        "GPT3BoolQuestion": GPT3BoolQuestion,
        "GPT3Choice": GPT3Choice,
        "GPTChoice": GPTChoice,
        "GPT3Classifier": GPT3Classifier,
        "ToWhomDetector": ToWhomDetector,
        "BadWordDetector": BadWordDetector,
        "WordDetector": WordDetector,
        "SemanticSearch": SemanticSearch,
        "SmartBranchingDecision": SmartBranchingDecision,
        "GoOnstage": GoOnstage,
        "GoOffstage": GoOffstage,
        "GoOnstageBriefing": GoOnstageBriefing,
        "ActionButton": ActionButton,
        "GoToButton": GoToButton,
        "RaiseHandButton": RaiseHandButton,
        "If": If,
        "TrackedEvent": TrackedEvent,
        "UserAction": UserAction,
        "NarrativeSolver": NarrativeSolver,
        "SetBool": SetBool,
        "SetInt": SetInt,
        "ValueBool": ValueBool,
        "ValueInt": ValueInt,
        "ValueString": ValueString,
        "AppEvent": AppEvent
    };

    // Dynamic values
    CurrentExerciseSessionID = "";

	State = "";
    History = null;
    FeedbackSolver = null;
    AchievementsSolver = null;
    Paused = false;
    SystemFrozen = false;
    StoppedByUser = false;
    LastBranchingDecisionNode = null;
    StartTime = null;
    RandomSeed = -1;

    CurrentActNode = null;
    CurrentSceneNode = null;

    ActivatedBranchingDecisionsCount = 0;

    DeconnectedAfterPauseTimeout = false;

    // DEBUG Tools
    //ActiveNodes = [];


    constructor(iJsonGraph, iParentExerciseComponent) 
    {
        log.debug("ExerciseGraph.constructor: loading exercise '" + iJsonGraph.Name + "' version '" + iJsonGraph.Version + "'.");
        
        this.ParentExerciseComponent = iParentExerciseComponent;

        // Create the history
        this.History = new ExerciseSessionHistory(this, this.UserName);

        // Create the solvers
        this.FeedbackSolver = new FeedbackSolver(this);
        this.AchievementsSolver = new AchievementsSolver(this);
    
        // Base information
        this.ExerciseName = iJsonGraph.Name;
        this.ExerciseID = iJsonGraph.ID;
        this.ExerciseVersion = iJsonGraph.Version;
        this.ScenarioID = iJsonGraph.ScenarioID;

        this.RetrieveAPIsEndpoints();

        // ============= FEEDBACK 
        // Available user actions
        if(iJsonGraph.AvailableUserActions == null)
        {
            this.availableUserActions = [];
            log.debug("ExerciseGraph.constructor: There is no Available User Actions in this exercise")
        }
        else {
            this.availableUserActions = iJsonGraph.AvailableUserActions;
        }

        // Available achievements
        if(iJsonGraph.AvailableAchievements == null)
        {
            this.availableAchievements = [];
            log.debug("ExerciseGraph.constructor: There is no Available Achievements in this exercise")
        }
        else {
            this.availableAchievements = iJsonGraph.AvailableAchievements;
        }

        // AvailablePedagologicalEnds
        if(iJsonGraph.AvailablePedagologicalEnds == null)
        {
            this.availablePedagologicalEnds = [];
            log.debug("ExerciseGraph.constructor: There is no Pedagological Ends in this exercise")
        }
        else {
            this.availablePedagologicalEnds = iJsonGraph.AvailablePedagologicalEnds;
        }

        // AvailablePedagologicalRecommendation
        if(iJsonGraph.AvailablePedagologicalRecommendations == null)
        {
            this.availablePedagologicalRecommendations = [];
            log.debug("ExerciseGraph.constructor: There is no Pedagological Recommendations in this exercise")
        }
        else {
            this.availablePedagologicalRecommendations = iJsonGraph.AvailablePedagologicalRecommendations;
        }
        
        // AvailablePedagologicalAddition
        if(iJsonGraph.AvailablePedagologicalAdditions == null)
        {
            this.availablePedagologicalAdditions = [];
            log.debug("ExerciseGraph.constructor: There is no Pedagological Additions in this exercise")
        }
        else {
            this.availablePedagologicalAdditions = iJsonGraph.AvailablePedagologicalAdditions;
        }

        // PrioritaryVideosToPreload
        this.PrioritaryVideosToPreload = iJsonGraph.PrioritaryVideosToPreload;

        // Nodes
        this.Nodes = [];
        for(var n = 0; n < iJsonGraph.Nodes.length; n++)
        {
            // Create and initialize each node
            log.debug("ExerciseGraph.constructor: node " + n + " = " + JSON.stringify(iJsonGraph.Nodes[n]));
            this.CreateNode(iJsonGraph.Nodes[n]).Initialize();
        }

        // Links
        for(var l = 0; l < iJsonGraph.Links.length; l++)
        {
            this.CreateLink(iJsonGraph.Links[l].Source.NodeID, iJsonGraph.Links[l].Source.PortName, iJsonGraph.Links[l].Target.NodeID, iJsonGraph.Links[l].Target.PortName);
        }

        // Events
        window.sdk.event().on('waitingVideoData', () => {
            this.waitingVideoData = true;
			this.FreezeSystem();
		});

        window.sdk.event().on('videoDataIsLoaded', () => {
            if(this.waitingVideoData) {
			    this.UnfreezeSystem();                
                this.waitingVideoData = false;
            }
        });

        window.sdk.event().on('resume', () => {
            this.Resume();
            window.sdk.forbiddenInteractionWarning().pause(false);
        });

        window.sdk.event().on('pause', (byUser = false) => {
            this.Pause();
            this.PausedByUser = false;
            if(byUser)
                this.PausedByUser = true;
        });

        window.sdk.event().on('fullscreenExited', () => {
			if(window.sdk.isInIframe()) {
                log.debug('fullscreenExited');
                this.Pause();
            }
        });

        window.sdk.event().on('fullscreenOpended', () => {
			if(window.sdk.isInIframe() && this.IsPaused() && !this.PausedByUser && !this.DeconnectedAfterPauseTimeout) {
                this.Resume();
            }
        });

        window.sdk.event().on('exerciceDeconnected', () => {
            this.DeconnectedAfterPauseTimeout = true;
        })

		window.sdk.event().on('stopExercise', () => {
			this.StoppedByUser = true;
            window.sdk.forbiddenInteractionWarning().destroy();
		});

        window.sdk.event().on('restartExercise', () => {
            this.StoppedByUser = true;
            window.sdk.forbiddenInteractionWarning().destroy();
        });
        
        window.sdk.event().on('RewindGraphToNode', (params) => {
            log.debug("Graph: RewindGraphToNode event received, rewinding to node '" + params.nodeID + "'.");
            this.RewindGraphToNode(params.nodeID);
        });
    }

    async RetrieveAPIsEndpoints()
    {
        this.APIsEndpoints = await window.sdk.ExercisesAPIEndpoints().getOne(this.ExerciseID);
    }

    CreateNode(iProperties)
    {
        let newNode = undefined;

        // Create the node if type exists
        if(iProperties.Type in this.NodeTypes)
        {
            newNode = new this.NodeTypes[iProperties.Type](this, iProperties);
        }

        // Add the new node if it is not null
        if(newNode != undefined)
        {
            this.AddNode(newNode);
        }

        return newNode;
    }

    AddNode(iNode)
    {
        this.Nodes.push(iNode);
    }

    GetNode(iID)
    {
        for(var i = 0; i < this.Nodes.length; i++)
        {
            if(this.Nodes[i].ID === iID)
            {
                return this.Nodes[i];
            }
        }
    }

    CreateLink(iSourceNodeID, iSourcePortName, iTargetNodeID, iTargetPortName)
    {
        //log.debug("ExerciseGraph.CreateLink: Connecting Source node '" + iSourceNodeID + "' on port '" + iSourcePortName + "' to port '" + iTargetPortName + "' of node '" + iTargetNodeID + "'.");

        let sourceNode = this.GetNode(iSourceNodeID);
        if(sourceNode === undefined)
        {
            log.error("ExerciseGraph.CreateLink Error: sourceNode ID '" + iSourceNodeID + "' not found!");
            return;
        }

        let targetNode = this.GetNode(iTargetNodeID);
        if(targetNode === undefined)
        {
            log.error("ExerciseGraph.CreateLink Error: sourceNode ID '" + iTargetNodeID + "' not found!");
            return;
        }

        let sourcePort = sourceNode.GetPortByName(iSourcePortName);
        if(sourcePort === undefined)
        {
            log.error("ExerciseGraph.CreateLink Error: sourcePort named '" + iSourcePortName + "' not found in node '" + sourceNode.GetIdentity() + "'!");
            return;
        }

        let targetPort = targetNode.GetPortByName(iTargetPortName);
        if(targetPort === undefined)
        {
            log.error("ExerciseGraph.CreateLink Error: targetPort named '" + iTargetPortName + "' not found in node '" + targetNode.GetIdentity() + "'!");
            return;
        }

        sourcePort.Connect(targetPort);
        targetPort.Connect(sourcePort);
    }

    Reset()
    {
        this.Paused = false;
        this.SystemFrozen = false;
        this.ActivatedBranchingDecisionsCount = 0;
        
        // Reset all ExerciseNode instances
        this.Nodes.forEach(node => {
            if(node instanceof ExerciseNode)
            {
                node.Reset();
            }
        });
    }

    async Start()
    {
        log.debug(this.GetIdentity() + " has started.");

		this.Reset();
        this.StartTime = new Date;
		this.State = 'started';

        // Log ExerciseSession in DynamoDB
        let session = await window.sdk.exerciseSession().createOne(
            window.infoVersion.version,
            this.ExerciseID.toString(),
            this.ExerciseVersion.toString(),
            window.sdk.user().userID,
            this.StartTime
        );
        log.debug("ExerciseGraph.Start: exercise session logged.", session);
        this.CurrentExerciseSessionID = session.ID.S;

        // Random seed
        this.RandomSeed = Math.random();
        log.debug("ExerciseGraph.constructor: Random seed = " + this.RandomSeed);
        this.History.AddEvent("RandomSeed", {Seed: this.RandomSeed});

        // Create the local human
        ParticipantsModule.Instance.StartLocalHuman({ name: this.UserName });

        // Start recording
        //log.debug("DEBUG Start recording");
        //window.sdk.videoconf().startRecorderSession();

        // Activate the first node
        let startNode = this.GetStartNode();
        if(startNode != undefined)
        {
            startNode.OnActivated(this, null);
        }
        else
        {
            log.error("ExerciseGraph.Start Error: No start node found!");
        }

        // Start the update loop
        this.Update();

        log.debug(this.GetIdentity() + " has started.");
    }
	
	Pause() {                
		this.Paused = true;
		this.Nodes.forEach(node => {
            if(node instanceof ExerciseNode)
            {
                try {
                    node.Pause();
                }
                catch(err) {
                    log.warn('This node must implement pause function', node.GetIdentity());
                }
            }
		});
        // Log event to database
        this.History.AddEvent("Pause", {State: "Paused"});
        window.sdk.forbiddenInteractionWarning().pause(true);
        window.sdk.event().emit('exercicePaused');
	}
	
	Resume() {
		if(!this.IsPaused())
			return;
		this.Paused = false;
        this.PausedByUser = false;
		this.Nodes.forEach(node => {
            if(node instanceof ExerciseNode)
            {
                try {
                    node.Resume();
                }
                catch(err) {
                    log.warn('This node must implement resume function', node.GetIdentity());
                }
            }
		});
        
        // Log event to database
        this.History.AddEvent("Pause", {State: "Resumed"});
        window.sdk.forbiddenInteractionWarning().pause(false);        
        window.sdk.event().emit('exerciceResumed');
	}

    FreezeSystem()
    {
        this.SystemFrozen = true;
        
		this.Nodes.forEach(node => {
            if(node instanceof ExerciseNode)
            {
                try {
                    node.FreezeSystem();
                }
                catch(err) {
                    log.warn('This node must implement freeze function', node.GetIdentity());
                }
            }
		});
    }

    UnfreezeSystem()
    {
        this.SystemFrozen = false;

        this.Nodes.forEach(node => {
            if(node instanceof ExerciseNode)
            {
                try {
                    node.UnfreezeSystem();
                }
                catch(err) {
                    log.warn('This node must implement unfreeze function', node.GetIdentity());
                }
            }
        });
    }

    RewindGraphToNode(iNodeID)
    {
        //log.debug("ExerciseGraph.RewindGraphToNode: Rewinding graph to node '" + iNodeID + "'.");
        this.StopAllActiveNodes();

        // Activate the target node
        let targetNode = this.GetNodeByID(iNodeID);
        if(!targetNode)
        {
            log.error("ExerciseGraph.RewindGraphToNode Error: Node '" + iNodeID + "' wasn't found!");
            return;
        }
        
        targetNode.OnActivated(this, null);
    }

    StopAllActiveNodes()
    {
        this.Nodes.forEach(node => {
            if(node instanceof ExerciseNode && node.IsActive())
            {
                try {
                    node.Reset();
                }
                catch(err) {
                    log.warn('This node must implement stop function', node.GetIdentity());
                }
            }
        });
    }
    
    async Stop(iStoppedByUser = false)
    {
		this.State = 'stopped';
        this.Paused = false;
        this.SystemFrozen = false;
        window.sdk.forbiddenInteractionWarning().destroy();
        // Stop video recording
        //log.debug("DEBUG Stop recording. Current state: '" + window.sdk.videoconf().getRecorderState() + "'.");
        //window.sdk.videoconf().stopRecorderSession();

        // Stop the local human and bots
        log.debug('Stop', 'StopAllParticipants')
        ParticipantsModule.Instance.StopAllParticipants();

        // Stop all ExerciseNode nodes
        this.Nodes.forEach(node => {
            if(node instanceof ExerciseNode)
            {
                node.OnDeactivated();
            }
        });
        
        if(!this.StoppedByUser && !iStoppedByUser){

            this.SolveAchievements();
            this.SolveFeedback();
            //log.debug("================= FEEDBACKTODISPLAY +++++++",this.History.GetFeedbackToDisplay());

            window.sdk.event().emit('endExercise',{exerciseID : this.ExerciseID, exerciseSessionID : this.CurrentExerciseSessionID });
        }
    }

    IsPaused()
    {
        return this.Paused;
    }

    IsSystemFrozen()
    {
        return this.SystemFrozen;
    }

    IsStopped()
    {
        return this.State === 'stopped';
    }

    IsStarted()
    {
        return this.State === 'started';
    }

    IsRunning()
    {
        return this.IsStarted() && !this.IsPaused() && !this.IsSystemFrozen();
    }

    Skip = () =>
    {
        const activeNodes = this.GetActiveNodes();

        // Send skip event to each bot video node if they are active
        let skippedNodes = [];
        activeNodes.forEach(node => {
            if((node.Type == 'BotVideo' || node.Type == 'Delay'))
            {
                skippedNodes.push(node.GetDetailedIdentity());
                node.Skip();
            }
        });

        log.debug("DEBUG ACTION: Skipped nodes ", skippedNodes);
    }

    // Game loop
    Update = async function()
    {
        while(this.IsRunning)
        {
            // Don't update nodes if paused
			while(this.IsPaused())
                await Utils.Sleep(this.FrameDuration);

            // Update nodes
            this.Nodes.forEach(node => {
                node.Update();
            });

            // Sleep for 30ms with Utils
            await Utils.Sleep(this.FrameDuration);
        }
    }

    GetIdentity()
    {
        return this.Type + "_" + this.ExerciseName;
    }

    PrintNodesList()
    {
        log.debug(this.GetIdentity() + " has the following " + this.Nodes.length + " nodes:");

        for(var i = 0; i < this.Nodes.length; i++)
        {
            this.Nodes[i].PrintName();
            //this.Nodes[i].PrintParameters();
        }
    }

    GetStartNode()
    {
        return this.Nodes.find(node => node.Type == 'ScenarioRoot');
    }

    GetNodeByID(iID)
    {
        return this.Nodes.find(node => node.ID == iID);
    }

    GetPiroritaryBotsVideosToCache()
    {
        let videosToCache = [];
        this.PrioritaryVideosToPreload.forEach(videoInfo => {
            let videoUrl = window.sdk.CreateBotVideoURL(videoInfo.BotName, videoInfo.VideoName);
            videosToCache.push(videoUrl);
        });

        return videosToCache;
    }

    GetBotsVideosToCache(iIncludePrioritaryVideos)
    {
        let videosToCache = [];
        let checkedNodesIDs = [];
        let pendingNodes = [];

        // Get start node
        let currentNode = this.GetStartNode();
        if(currentNode === undefined)
        {
            log.debug("ExerciseGraph.GetBotsVideosToCache Error: No start node found!");
            return [];
        }

        // Initialize lists
        checkedNodesIDs.push(currentNode.ID);
        pendingNodes.push(currentNode);

        // Start browsing the graph
        while(pendingNodes.length > 0)
        {
            let currentPendingNodes = pendingNodes;

            currentPendingNodes.forEach(node => {

                node.GetOutputPorts().forEach(outputPort => {
                    let connections = outputPort.ConnectedPorts;
                    connections.forEach(nextNodePort => {
                        let nextNode = nextNodePort.ParentNode;

                        // Make sure nextNode inherits from ExerciseNode
                        if(!nextNode.prototype instanceof ExerciseNode)
                        {
                            log.debug("GetBotsVideosToCache: Connected node is not an ExerciseNode.");
                            return;
                        }

                        // Check nextNode is not already checked
                        if (!checkedNodesIDs.includes(nextNode.ID))
                        {
                            checkedNodesIDs.push(nextNode.ID);
                            pendingNodes.push(nextNode);

                            if (nextNode.Type == 'BotVideo')
                            {
                                let videoUrl = window.sdk.CreateBotVideoURL(nextNode.BotName, nextNode.VideoName);

                                if(!iIncludePrioritaryVideos)
                                {
                                    let isPrioritaryVideo = this.PrioritaryVideosToPreload.find(videoInfo => videoInfo.BotName == nextNode.BotName && videoInfo.VideoName == nextNode.VideoName);
                                    if(isPrioritaryVideo === undefined)
                                    {
                                        // This video is not in the priority list, we can add it
                                        videosToCache.push(videoUrl);
                                    }
                                }
                                else
                                {
                                    // We dont care if the video is in the priority list, we add it
                                    videosToCache.push(videoUrl);
                                }
                            }
                            else if (nextNode.Type == 'BotConnection')
                            {
                                let videoUrl = window.sdk.CreateBotVideoURL(nextNode.BotName, nextNode.DefaultLoop);

                                if(!iIncludePrioritaryVideos)
                                {
                                    let isPrioritaryVideo = this.PrioritaryVideosToPreload.find(videoInfo => videoInfo.BotName == nextNode.BotName && videoInfo.VideoName == nextNode.DefaultLoop);
                                    if(isPrioritaryVideo === undefined)
                                    {
                                        // This video is not in the priority list, we can add it
                                        videosToCache.push(videoUrl);
                                    }
                                }
                                else
                                {
                                    // We dont care if the video is in the priority list, we add it
                                    videosToCache.push(videoUrl);
                                }
                            }
                        }
                    });
                });

                // Remove the node from the pending nodes list since it has been processed
                for( var i = 0; i < pendingNodes.length; i++){ 

                    if ( pendingNodes[i] === node) { 

                        pendingNodes.splice(i, 1);
                    }

                }
            });
        }

        return videosToCache;
    }
    
    GenerateRandomValue()
    {
        this.RandomSeed = this.RandomSeed + 1;
        return seedrandom(this.RandomSeed)();
    }

    SetBoolValue(iName, iValue)
    {
        this.BoolValues[iName] = iValue;
    }

    GetBoolValue(iName)
    {
        if(this.BoolValues[iName] === undefined)
        {
            //log.debug("ExerciseGraph.GetBoolValue : Bool value '" + iName + "' not set yet, assuming it's false.");
            return false;
        }

        return this.BoolValues[iName];
    }

    SetIntValue(iName, iValue)
    {
        this.IntValues[iName] = iValue;
    }

    GetIntValue(iName) {
        if (this.IntValues[iName] === undefined) {
            //log.debug("ExerciseGraph.GetIntValue Error: Int value '" + iName + "' not found!");
            this.SetIntValue(iName, 0);
        }
        return this.IntValues[iName];
    }

    IncrementIntValue(iName) {
        this.IntValues[iName] = this.GetIntValue(iName) + 1;
        return this.IntValues[iName];
    }

    SetStringValue(iName, iValue)
    {
        this.StringValues[iName] = iValue;
    }

    GetStringValue(iName)
    {
        if(this.StringValues[iName] === undefined)
        {
            log.debug("ExerciseGraph.GetStringValue Error: String value '" + iName + "' not found!");
            return "";
        }

        return this.StringValues[iName];
    }

    GetCurrentActName()
    {
        return this.CurrentActNode ? this.CurrentActNode.NodeName : "";
    }

    GetCurrentSceneName()
    {
        return this.CurrentSceneNode.NodeName;
    }

    CountUserActionsByType(userActionTypes) {
        // Return the number of time this type of user action was trigger during the exercice session
        let userActions = this.History.GetUserActions();
        const filteredActions = userActions.filter(action => {
            return (userActionTypes == action.Content.UserEventTypes);
         });
        return filteredActions.length;
    }

    CountUserActionsByID(userActionID) {
        // Return the number of UserActionID with this name triggered during the exercice session
        let userActions = this.History.GetUserActions();
        const filteredActions = userActions.filter(action => {
           return (userActionID == action.Content.UserActionID);
        });
        return filteredActions.length;
    }

    CountNarrativeEnd(narrativeEnd) {
        // Return the number of NarrativeEnd with this name triggered during the exercice session
        let chosenNarrativeEnd = this.History.GetNarrativeEnd();
        let filteredEnd = 0; 
        if(chosenNarrativeEnd != null && chosenNarrativeEnd.Content.Name == narrativeEnd)
        {
            filteredEnd = 1;
        }
        return filteredEnd;
    }

    // CountActions(userActionTypes, targetPhase) {
    //     // Return the number of time this type of user action was trigger during the exercice session during the target phase
    //     let userActions = this.History.GetUserActions();
    //     const filteredActions = userActions.filter(action => {
    //         return userActionTypes.includes(action.Content.UserActionID) && action.Content.Phase === targetPhase;
    //     });
    //     return filteredActions.length;
    // }

    GetAvailableAchievements()
    {
        return this.availableAchievements;
    }

    GetAvailablePedagologicalEnds()
    {
        return this.availablePedagologicalEnds;
    }

    GetAvailablePedagologicalRecommendations()
    {
        return this.availablePedagologicalRecommendations;
    }

    GetAvailablePedalogologicalAdditions()
    {
        return this.availablePedagologicalAdditions;
    }

    SolveAchievements()
    {
        // Solve which achievements to display with the achievements solver.
        const achievementsToDisplay = this.AchievementsSolver.GetAchievementsToDisplay();

        // Save the result in the exercise session history
        this.History.AddAchievementsDone(achievementsToDisplay);
    }

    SolveFeedback()
    {
        // Solve which feedback to display with the feedback solver.
        const pedagologicalEndToDisplay = this.FeedbackSolver.GetPedagologicalEndToDisplay();
        const pedagologicalRecommendationsToDisplay = this.FeedbackSolver.GetPedagologicalRecommendationsToDisplay();
        const pedagologicalAdditionsToDisplay = this.FeedbackSolver.GetPedagologicalAdditionsToDisplay();

        // Save the result in the exercise session history
        this.History.AddPedagologicalEnd(pedagologicalEndToDisplay);
        this.History.AddPedagologicalRecommendations(pedagologicalRecommendationsToDisplay);
        this.History.AddPedagologicalAdditions(pedagologicalAdditionsToDisplay);
    }

    SetCurrentBranchingDecision(iBranchingDecision, iDone)
    {
        this.LastBranchingDecisionNode = iBranchingDecision;
        this.ParentExerciseComponent.setDebugInfo("lastBranchingDecision", {ID: iBranchingDecision.ID, State: (iDone ? "done" : "running")});
    }

    IncrementBranchingDecisionsActivations()
    {
        // Count the number of time a branching decision was activated and update the session in database
        this.ActivatedBranchingDecisionsCount++;
                
        window.sdk.exerciseSession().updateItem(
            this.CurrentExerciseSessionID,
            this.ExerciseID.toString(),
            { "ActivatedBranchingDecisionsCount": this.ActivatedBranchingDecisionsCount.toString() }
        );
    }

    GetActiveNodes()
    {
        // Check and reference all the active nodes
        let activeNodes = [];
        this.Nodes.forEach(node => {
            if(node.IsActive && node.IsActive())
            {
                activeNodes.push(node);
            }
        });

        return activeNodes;
    }

    // DEBUG Tools
    GetActiveNodesText()
    {
        // Check and reference all the active nodes
        const activeNodes = this.GetActiveNodes();
        let activeNodesText = [];
        activeNodes.forEach(node => {
            activeNodesText.push("<li>" + node.GetDetailedIdentity() + "</li>");
        });

        return activeNodesText.join("");
    }
}

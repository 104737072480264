import log from "loglevel";
import TranscripterAdapter from './Adapter';
import TranscripterParameters from './Parameters';

export default class Session {
    
    constructor(socket, transcripterParameters) {

        log.debug("Transcription Session contructor: parameters ", transcripterParameters);

        this.socket = socket;
        this.transcripterParameters = new TranscripterParameters(transcripterParameters);
        this.transcripterParameters.onPartialTranscriptedTextFromClient = (data) => {
            this.onPartialTranscriptedText(data);
        }
        this.transcripterParameters.onTranscriptedTextFromClient = (data) => {
            this.onTranscriptedText(data);
        }
        //this.transcriptionToken;
    }
    
    init() {
        
        log.debug("Transcription Session init. TranscripterParameters: ", this.transcripterParameters);
        log.debug("Transcription Session init. Socket: ", this.socket);

        return new Promise((resolve, reject) => {
            this.socket.on('transcriptionSessionReady', async (data) => {
                log.debug('transcriptionSessionReady', data);
                await this.onReady(data);
                resolve(true);
            });
            
            this.socket.on('transcriptedText', (data) => {
                this.onTranscriptedText(data);
            });
            
            this.socket.on('partialTranscriptedText', (data) => {
                this.onPartialTranscriptedText(data);
            });
            
            this.socket.on('failedTranscription', (data) => {
                log.debug('Frontend session failedTranscription: ', data);
                this.onFailed(data);
            });
            
            this.socket.emit('transcriptionCreate', {
                provider: this.transcripterParameters.provider,
                lang: this.transcripterParameters.lang,
                customData: this.transcripterParameters.customData
            })
        });
    }
    
    async onReady(data) {
        
        this.transcripterParameters.data = data;
        
        this.transcripterParameters.onBinaryData = (data) => {
            this.socket.emit('transcriptionBinaryData', data);
        };
        
        this.transcripter = new TranscripterAdapter(this.transcripterParameters);
        await this.transcripter.init();
    }
    
    onPartialTranscriptedText(data) {
        this.transcripterParameters.onPartialTranscriptedText(data)
    }
    
    onTranscriptedText(data) {
        this.transcripterParameters.onTranscriptedText(data)
    }
    
    onFailed(data) {
        this.transcripterParameters.onFailed(data)
    }
    
    start() {
        this.socket.emit('transcriptionStart');
        this.transcripter.start();
    }
    
    stop() {
        // log date and time
        log.debug(new Date().toUTCString() + " - transcription session stopped");
        this.socket.emit('transcriptionStop');
        this.transcripter.stop();
        
    }
    
    //this._sdk.event().emit('newText', text)
    
    close() {
        this.transcripter.close();
        this.socket.emit('transcriptionClose');
        this.socket.off('transcriptionSessionReady');
        this.socket.off('transcriptedText');
        this.socket.off('partialTranscriptedText');
        this.socket.off('failedTranscription');
    }
    
}
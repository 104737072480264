import React from 'react';

export default class StepCongrats extends React.Component {

    render() {
        return (
            <div className="feedback-step feedback-step-congrats">
                <div className="feedback-step__inner feedback-step-inner-congrats">
                    <div className="feedback-step-congrats-star-left"></div>
                    <div className="feedback-step-congrats-star-right"></div>
                    <div className="feedback-step__header">
                        <h1 className="feedback-step__title" dangerouslySetInnerHTML={{__html: this.props.text.CongratsTitle_Text}} />
                    </div>
                    <p dangerouslySetInnerHTML={{__html: this.props.text.CongratsText_Text}} />
                </div>
            </div>)
    }
}
StepCongrats.defaultProps =  {
    text:{
      CongratsTitle_Text : "Félicitations !",
      CongratsText_Text: "Vous avez terminé le scénario.<br />Passez à la page suivante pour découvrir vos badges !"
  }
}
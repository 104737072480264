import log from "loglevel";
import React from 'react';

import Button from 'react-bootstrap/Button';

export default class ActionButton extends React.Component {

    variant = 'white';

    constructor(props) {
      super(props);
      
      try {
          props.registerButton(this);
      } catch (e) {
          log.error("ActionButton: registerButton not found");
      }
    }

    onClick() {
        log.debug("ActionButton: onClick");
        this.props.onClick();
    }

    render() {
        return (
            <Button id='action-button' className={'bottom-btn'} variant={this.variant} onClick={() => this.onClick()}>
                {this.props.label}
            </Button>
        );
    }
}
/* global navigator */
import log from "loglevel";
import React from 'react';
import { isEdge, isChrome, isFirefox, isSafari, isAndroid, isIos, isOpera } from 'react-device-detect';
import Ask from './Ask';
import Refuse from './Refuse';

import Alert from 'react-bootstrap/Alert';
import Button from '../Utilities/Button';



export default class GetUserMedia extends React.PureComponent {

    state = {
        showPopinInfo: false,
        showPopinRefuse: false,
        changeBrowser: false,
        authorized: false,
        waitAuthorization: false
    }

    listeners = [];

    async componentDidMount() {
        
        if (!isChrome && !isFirefox && !isSafari && !isEdge && !isOpera) {
            this.setState({
                changeBrowser: true
            });
            return;
        }

        this.state.showPopinInfo = await this.check();

        if (this.state.showPopinInfo) {
            this.forceUpdate();
        } else {
            try {
                this.getStream();
            } catch(err) {
                log.debug('err', err);
            }
        }
		
		this.listeners.push(window.sdk.event().on('getUserMediaAsk', () => {
			this.ask();
		}))
		
		this.listeners.push(window.sdk.event().on('getUserMediaAsk2', () => {
			this.ask2();
		}));
    }

    componentWillUnmount() {
       
        for (var i in this.listeners) {
            this.listeners[i]();
        }
    }

    async check() {
        return await window.sdk.videoconf().mediaDevices().needAccept();
    }

    ask2 = async () => {
        this.step2 = true;
        this.ask();
    }

    ask = async () => {
        await this.setState({
            showPopinInfo: false,
            showPopinRefuse: false
        });
        this.getStream();
    }

    async getStream() {
		this.props.showLoader(true);
        let stream = await window.sdk.videoconf().mediaDevices().getUserMedia();
        if (stream.error) {
            this.setState({
                showPopinRefuse: true,
                waitAuthorization: true
            });
            log.debug('GetUserMedia error', stream.errorObject.name + ": " + stream.errorObject.message);
            window.sdk.usersActivity().createOne("GetUserMediaError", {Error: stream.errorObject.name});
            if (stream.errorObject.name == 'AbortError' || stream.errorObject.name == 'NotReadableError')
                this.step2 = true;
            if (!this.step2) {
                this.interval = setInterval(async () => {
                    let c = await this.check();
                    if(!c) {
                        clearInterval(this.interval);
                        this.setState({
                            waitAuthorization: false
                        });
                    }
                }, 1000);
            } else {
                this.setState({
                    camUsed: true
                });
                if(window.zE){
                    window.zE("webWidget", "prefill", {
                        name: {
                          value: window.sdk.user().firstName + " " + window.sdk.user().lastName,
                          readOnly: true, // optional
                        },
                        email: {
                          value: window.sdk.user().email,
                          readOnly: true, // optional
                        },
                      });
                    window.zE('webWidget', 'show');
                    
                    window.zE("webWidget:on", "close", function () {
                        window.zE('webWidget', 'show');
                    });
                } 

            }
			this.props.showLoader(false);
        } else {
            this.setState({
                authorized: true
            });

            this.props.onStreamReady({
                stream
            });
			this.props.showLoader(false);
        }
    }


    renderPopin() {
		
		this.props.onButtonToShow(this.state.changeBrowser, this.state.camUsed, this.state.showPopinInfo, this.state.showPopinRefuse, this.state.waitAuthorization);
		
        if (this.state.changeBrowser) {
            return (
                <div className={'device-workflow mb-3'}>
                    <Alert variant={'danger'} className={'device-alert mb-3'}>
                        ⚠ Votre navigateur n'est pas supporté, merci d'utiliser un navigateur tel chrome, firefox, edge ou safari
                    </Alert>
                </div>
            );
        }

        if (this.state.camUsed) {
            return (
                <div className={'device-workflow mb-3'}>
                    <Alert variant={'danger'} className={'device-alert mb-3'}>
                        ⚠ Il semblerait que votre webcam soit utilisé par un autre logiciel (skype, teams, zooms ou un autre navigateur)<br />
                        Merci de vérifier et puis de cliquer sur le bouton « rafraîchir »
                    </Alert>
                    <div style={{ display: 'none' }} className={'device-actions text-center'}>
                        <Button variant={'primary'} onClick={el=>{ window.location.reload() }}>Rafraichir</Button>
                    </div>
                </div>
            );
        }


        if (this.state.showPopinInfo) {
            return (
                <React.Fragment>
                    <div className={'device-workflow mb-3'}>
                        <Alert variant={'danger'} className={'device-alert mb-3'}>
                            ⚠ Afin que les autres puissent vous voir et vous entendre, votre navigateur va demander l'accès à votre caméra et votre micro.
                        </Alert>
                        <div className={'text-center'}>
                            <Ask />
                        </div>
                    </div>
                    <div style={{ display: 'none' }} className={'device-actions text-center'}>
                        <Button variant={'primary'} onClick={ this.ask }>Demandez l'autorisation</Button>
                    </div>
                </React.Fragment>
            );
        }

        if (this.state.showPopinRefuse) {
            return (
                <React.Fragment>
                    <div className={'device-workflow mb-3'}>
                        <Alert variant={'danger'} className={'device-alert mb-3'}>
                            ⚠ Votre navigateur n'a pas accès à votre caméra et votre micro. Suivez la procédure ci-dessous pour autoriser l'accès et rejoindre PractiVizio.
                        </Alert>  
                        <br />
                        <Refuse />
                    </div>
                    <div style={{ display: 'none' }} className={'device-actions ps-3'}>
                        {(isSafari || isAndroid) &&
                            <Button variant={'primary'} onClick={el=>{ window.location.reload() }}>Rééssayer</Button>
                        }
                        { !isSafari && !isAndroid && this.state.waitAuthorization &&
                            <Button variant={'primary'} onClick={ this.ask }>Vérifiez l'autorisation</Button>
                        }
                        { !this.state.waitAuthorization &&
                            <Button variant={'primary'} onClick={ this.ask2 }>Demandez l'autorisation</Button>
                        }

                    </div>
                </React.Fragment>
            );
        }

        return null;
    }

    render() {
        if (this.state.authorized) {
            return null;
        }
		
		this.props.showLoader(false);

        return (
            <div className={'cam-box'}>
                 <div className={'cam-box-content'}>
                    { this.renderPopin() }
                 </div>
            </div>
        );
    }

}



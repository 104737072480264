import React from 'react';
import '../styles/components/LoginLayout.scss';
import logo from '../assets/images/PractiVizioLogo.png';
import { Routes, Route, Navigate } from "react-router-dom";

import Login from './entrance/Login';
import Register from './entrance/Register';
import RegisterPending from './entrance/RegisterPending';
import PasswordLost from './entrance/PasswordLost';
import EditPassword from './entrance/EditPassword';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import Loader from '../components/Utilities/Loader';

import background from '../assets/videos/login-background.mp4';
import video1 from '../assets/videos/login-video1.mp4';
import video2 from '../assets/videos/login-video2.mp4';
import video3 from '../assets/videos/login-video3.mp4';


class LoginLayout extends React.Component {

  state = {
    loading: false,
  }

  listeners = [];

  // Affiche le chargement
  componentDidMount() {
    this.listeners.push(window.sdk.event().on('fetchStarted', () => {
      this.setState({
        loading: true
      });
    }));

    this.listeners.push(window.sdk.event().on('fetchFinished', () => {
      this.setState({
        loading: false
      });
    }));

    this.listeners.push(window.sdk.event().on('loaderHide', () => {
      this.setState({
        loading: false
      });
    }));

    document.documentElement.classList.add('template-login-page');
  }

  // Supprime les events
  componentWillUnmount() {
    for (var i in this.listeners) {
      this.listeners[i]();
    }
    document.documentElement.classList.remove('template-login-page');
  }

  showZendesk = () => {
    if(window.zE){
      window.zE('webWidget', 'open');
      window.zE('webWidget', 'show');
    } else {
      window.location = 'mailto:support@practicio.fr'
    }
  }  

  render() {
    return (
      <div className="login-layout">
        <Loader display={this.state.loading} />
        <div className="login__main">
          <Container>
            <Row>
              <Col>
                <header className="login__header">
                  <img src={logo} className="logo" alt="logo" />
                </header>
              </Col>
            </Row>
            <Row>
              <Col>
                <Routes>
                  <Route path="/" element={<Register loading={this.state.loading} />} />
                  <Route path="/register-pending" element={<RegisterPending loading={this.state.loading} />} />
                  <Route path="/login" element={<Login loading={this.state.loading} />} />
                  <Route path="/password-lost" element={<PasswordLost loading={this.state.loading} />} />
                  <Route path="/edit-password" element={<EditPassword />} />
                  <Route path="*" element={<Navigate to={"/?redirect_url=" + document.location.href} replace />} />
                </Routes>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="version">
                <span onClick={this.showZendesk} className='link-bottom'>Contacter le support</span> - <a href={window.policyUri} className='link-bottom' target="_blank">Politique de confidentialité</a> - PractiVizio {window.infoVersion.version}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="login__background">
          <video  playsInline loop muted autoPlay preload="auto" id="LoginBackground" className="login__video_background">
             <source src={background} type="video/mp4" />
          </video>
          <div className="login__video login__video1">
            <video  playsInline loop muted autoPlay preload="auto" id="LoginVideo1" className="login__video_player">
              <source src={video1} type="video/mp4" />
            </video>
          <div className="login__text login__text1">PRATICE</div>
          </div>
          <div className="login__video login__video2">
            <video playsInline loop muted autoPlay preload="auto" id="LoginVideo2" className="login__video_player">
              <source src={video2} type="video/mp4" />
            </video>
          <div className="login__text login__text2">MAKES</div>
          </div>
          <div className="login__video login__video3">
            <video playsInline loop muted autoPlay preload="auto" id="LoginVideo3" className="login__video_player">
              <source src={video3} type="video/mp4" />
            </video>
          <div className="login__text login__text3">PERFECT</div>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginLayout;
import React from 'react';
import '../../styles/components/Loader.scss';


export default class Loader extends React.Component {

  render() {
    let color = this.props.color || 'white';
    return (
      <div className={"loader" + (this.props.static ? " loader--static" : "")} style={{ display: this.props.display ? 'block': 'none' }}>
        <div className="loader__inner">
          <div className="loader__bar" style={{ borderColor: color + ' transparent ' + color + ' transparent ' }}></div>
        </div>
      </div>
    );
  }
}

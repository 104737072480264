import log from "loglevel";
import Utils from '../../Utils/Utils';
import ExerciseNode from './ExerciseNode';
import NodePort from '../NodePort';
import ParticipantsModule from '../../Participants/ParticipantsModule';

export default class BotRandomVideo extends ExerciseNode 
{
    // Ports
    Input = new NodePort("Input", "input", this);
    Output = new NodePort("Output", "output", this);

    // Parameters
    BotVideos = [];

    // Internal values
    ChosenBot = null;
    ChosenBotVideo = null;


    constructor(iGraph, iProperties)
    {
        super(iGraph, iProperties);
 
        this.PreventSpeakingFrame = iProperties.PreventSpeakingFrame;

        
        iProperties.BotVideos.forEach(botVideo => {
            log.debug(this.GetIdentity() + " constructor: Adding dynamic botVideo '" + botVideo.BotName + "-" + botVideo.Video + "'.");

            let newBotVideo = new BotVideo(botVideo.ID, botVideo.BotName, botVideo.Video, botVideo.Probability);
            this.BotVideos.push(newBotVideo);

            this[newBotVideo.GetOutputPortName()] = new NodePort(newBotVideo.GetOutputPortName(), "output", this);
        });
        
        // Normalize the botVideoes probas
        log.debug(this.GetIdentity() + " probas before normalization = " + this.BotVideos.map(botVideo => botVideo.Probability));
        let probasSum = this.BotVideos.reduce((total, botVideo) => total + botVideo.Probability, 0);
        this.BotVideos.forEach(botVideo => botVideo.Probability /= probasSum);
        log.debug(this.GetIdentity() + " probas after normalization = " + this.BotVideos.map(botVideo => botVideo.Probability));
    }

    OnActivated(iActivator, iInputPort)
    {
        log.debug(this.GetIdentity() + "' has been activated by '" + iActivator.GetIdentity() + "'.");
        super.OnActivated(iActivator, iInputPort);

        // Randomly choose among the available botVideoes, considering their probabilities and using the graph random seed
        let randomValue = this.Graph.GenerateRandomValue(); // Instead of Math.random() to be able to set the seed
        log.debug(this.GetIdentity() + " randomValue = " + randomValue);
        
        // Choose the botVideo
        let probasSum = 0;
        for(let i = 0; i < this.BotVideos.length; i++)
        {
            //log.debug(this.GetIdentity() + " probasSum = " + probasSum + ", randomValue = " + randomValue + ", this.BotVideos[i].Probability = " + this.BotVideos[i].Probability);
            probasSum += this.BotVideos[i].Probability;
            if(randomValue <= probasSum)
            {
                this.ChosenBotVideo = this.BotVideos[i];
                break;
            }
        }
                
        log.debug(this.GetIdentity() + " Bot '" + this.ChosenBotVideo.BotName + "' will play the video '" + this.ChosenBotVideo.Video + "'...");

        // Get the bot
        this.ChosenBot = ParticipantsModule.Instance.GetBot(this.ChosenBotVideo.BotName);	

        if(this.ChosenBot != null)
        {
            // Display automatically the speaking frame if allowed
            if(!this.PreventSpeakingFrame)
            {
                this.ChosenBot.setSpeakingState("speaking");
            }
            
            // Play the video
			this.ChosenBot.setConnectionState('connected');
            this.ChosenBot.PlayVideo(this.ChosenBotVideo.Video, {
                triggerTime: 0,
                onTimeTriggered: () => {
                },
                onEnded: () => {
                    this.OnActionFinished();
                }
            });

            // Log action to history
            this.Graph.History.AddBotSpeech(this.ID, this.ChosenBotVideo.BotName, this.ChosenBotVideo.Video);
        }
        else
        {
            log.debug(this.GetIdentity() + " Bot '" + this.ChosenBotVideo.BotName + "' not found!");
        }
    }
	
	Pause() {
		if(this.ChosenBot != null && this.m_IsActive)
            this.ChosenBot.Pause();
	}
	
	Resume() {
		if(this.ChosenBot != null && this.m_IsActive && this.Graph.IsRunning())
            this.ChosenBot.Resume();
	}

    FreezeSystem() {
        if(this.ChosenBot != null && this.m_IsActive)
            this.ChosenBot.Pause();
    }

    UnfreezeSystem() {
		if(this.ChosenBot != null && this.m_IsActive && this.Graph.IsRunning())
            this.ChosenBot.Resume();
    }

    Skip()
    {
        if(!this.m_IsActive)
        {        
            return;
        }

        log.debug(this.GetIdentity() + " Bot '" + this.ChosenBotVideo.BotName + "' skip.");
        
        // Make the video bot stop current action and return to wait loop
        this.ChosenBot.ReturnToWaitLoop();

        // Activate Outputs and stop the node
        this.SetActive(false);
        this.Output.ActivateAllConnections();
        this[this.ChosenBotVideo.GetOutputPortName()].ActivateAllConnections();
    }

    OnActionFinished()
    {
        if(!this.m_IsActive)
        {        
            log.debug(this.GetIdentity() + " Bot '" + this.ChosenBotVideo.BotName + "' finished its video play action, but ignored since not active.");
            return;
        }

        log.debug(this.GetIdentity() + " Bot '" + this.ChosenBotVideo.BotName + "' finished its video play action.");

        // Hide automatically the speaking frame if allowed
        if(!this.PreventSpeakingFrame && this.ChosenBot != null)
        {
            this.ChosenBot.setSpeakingState("no");
        }
        
        // Activate Output
        log.debug(this.GetIdentity() + "' activating output.");

        this.SetActive(false);

        this.Output.ActivateAllConnections();
        this[this.ChosenBotVideo.GetOutputPortName()].ActivateAllConnections();
    }
    

    GetDetailedIdentity()
    {
        return super.GetDetailedIdentity() + ":" + this.ChosenBotVideo.BotName + " - " + this.ChosenBotVideo.Video;
    }

    PrintParameters()
    {
        //log.debug("BotName = " + this.ChosenBotVideo.BotName + ", VideoName = " + this.ChosenBotVideo.Video);
    }
}

class BotVideo
{
    ID = -1;
    BotName = "";
    Video = "";
    Probability = 1;

    constructor(iID, iBotName, iVideo, iProbability)
    {
        this.ID = iID;
        this.BotName = iBotName;
        this.Video = iVideo;
        this.Probability = iProbability;
    }
    
    GetOutputPortName()
    {
        return "BotVideo" + this.ID;
    }

    ToString()
    {
        return "{" +
                "\n  BotName: '" + this.BotName + "'" +
                "\n  Video: '" + this.Video + "'" +
                "\n  OutputPort: '" + this.GetOutputPortName() + "'" +
                "\n  Probability: '" + this.Probability + "'" +
                "\n}";
    }
}
import log from "loglevel";
import AbstractParticipant from './AbstractParticipant'

export default class Human extends AbstractParticipant {
    
    constructor(iHumanInfo) {
        super();
        this.ID = "Human_" + iHumanInfo.name + "_" + this.ID;
        this.Name = iHumanInfo.name;
		
		this.ConnectionState = 'connected';

        log.debug("Human created with id " + this.ID, iHumanInfo);
    }
	
	setSpeakingState(SpeakingState) {
        super.setSpeakingState(SpeakingState);
		window.sdk.event().emit('humanParticipantSpeakingStateUpdated', SpeakingState);
    }
    
}
import log from "loglevel";
export default class AchievementsSolver {

    constructor(iGraph) {
        this.Graph = iGraph;
    }

    GetAchievementsToDisplay()
    {
        let achievementsToDisplay =[];

        // Get the list of available achievements
        let availableAchievements = this.Graph.GetAvailableAchievements();
        availableAchievements.forEach(achievement => {
            if(this.Graph.CountUserActionsByID(achievement.ID) > 0)
            {
                achievementsToDisplay.push(achievement);
            }
        })
        //log.debug("======= achievement to display : ",achievementsToDisplay)
        // Push the achievements done to trigger their content.
        return achievementsToDisplay;
    }
}
import log from "loglevel";
import React from 'react';
import BootstrapButton from 'react-bootstrap/Button';
import Button from '../../components/Utilities/Button';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';

class EditPassword extends React.Component {

  state = {
    password: '',
    newPassword: '',
    newPassword2: '',
    status: '',
    info: {},
    valid: false,
    showPassword1: false,
    showPassword2: false,
    valided: {
      number: false,
      special: false,
      length: false,
      min: false,
      maj: false,
      email: true,
      password: true,
      password2: true,
      entity: true,

    }
  };


  // On form submit
  submit = async() => {

    let check = this.check();
    if(!check.valid)
      return;

    let res = await window.sdk.user().passwordEdit({
      verificationCode: this.state.verificationCode,
      password: this.state.newPassword,
      email: this.state.email
    });

    log.debug('res', res)

    if (res.state === 'fail') {
      log.debug('res', res);
      this.setState({
        status: 'fail',
        info: res.info
      });
    }
    else {
      this.setState({
        status: 'success',
        info: res.info
      });
      if(window.sdk.redirect_url)
        document.location.href = window.sdk.redirect_url;
      else
        document.location.href = '/';
    }
  }

  // Handle field change
  updateVerificationCode = (value) => {
    this.setState({
      verificationCode: value
    })
  }

  updateEmail = (value) => {
    this.setState({
      email: value
    })
  }
  updateNewPassword = (value) => {
      const { valided } = this.state;
      valided.password = true;
      valided.number = value.search( /.*\d/ ) >= 0;
      valided.special = /[^a-zA-Z0-9]/.test(value);
      valided.length = value.length >= 8;
      valided.min = value.search( /.*[a-z]/ ) >= 0;
      valided.maj = value.search( /.*[A-Z]/ ) >= 0;     
      this.setState({
        newPassword: value,
        valided: valided,
        valid: false
      });
  }

  updateNewPassword2 = (value) => {
    this.setState({
      newPassword2: value
    })
  }

  check = () => {
    let newState = {
      status: '',
      valid: true,
      info: {
        message: '',
      }
    };

    let { verificationCode, email, newPassword, newPassword2 } = this.state;

    if (!verificationCode && !email && !newPassword && !newPassword2) {
      newState.valid = false;
      return newState;
    }

    try {
      if (!verificationCode) {
        throw new Error('Veuillez saisir votre code de vérification reçu par email');
      }
      if (!email) {
        throw new Error('Veuillez saisir votre email actuel');
      }
      if (!newPassword) {
        throw new Error('Veuillez saisir votre nouveau mot de passe');
      }
      if (!newPassword2) {
        throw new Error('Veuillez confirmer votre nouveau mot de passe');
      }
      if (newPassword !== newPassword2) {
        throw new Error('Veuillez verifier la confirmation');
      }
    }
    catch (e) {
      newState.status = 'fail';
      newState.info.message = e.message;
      newState.valid = false;
      this.setState(newState);
    }
    return newState;
  }

  render() {
    const { valided } = this.state;
    return (
      <div className="password-edit">
          <form className="password-edit__form" onSubmit={(e) => {e.preventDefault()}}>
            <h2 className="form__title">Modifier votre mot de passe</h2>
            <div className="password-edit__inputs">
              { this.state.status === 'fail' &&
                <Alert className="alert" variant="danger">{ this.state.info.message }</Alert>
              }
              { this.state.status === 'success' &&
                <Alert className="alert" variant="success">{ this.state.info.message }</Alert>
              }

              <Form.Group className="mb-3" controlId="password">
                <Form.Label>Code de vérification reçu par email</Form.Label>
                <Form.Control type="text" name="verificationCode" autoComplete={false} placeholder="Code de vérification" onChange={(e) => this.updateVerificationCode(e.target.value) } value={ this.state.verificationCode } required />
              </Form.Group>
             
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>Votre email</Form.Label>
                <Form.Control type="text" name="email" placeholder="Email" onChange={(e) => this.updateEmail(e.target.value) } value={ this.state.email } required />
              </Form.Group>

              <Form.Group className="mb-3 password-group" controlId="newPassword">
                <Form.Label>Nouveau mot de passe</Form.Label>
                <Form.Control type={this.state.showPassword1 ? "text" : "password"} name="newPassword" autoComplete={false} placeholder="Nouveau mot de passe" onChange={(e) => this.updateNewPassword(e.target.value) } value={ this.state.newPassword } required />
                <span className="password-toggle" onClick={() => this.setState({ showPassword1: !this.state.showPassword1 })}>
                  <FontAwesomeIcon icon={!this.state.showPassword1 ? faEye : faEyeSlash} />
                </span>
                <Form.Text className={"password-validated"}>
                <span className={ valided.number ? 'test-valided' : '' }><FontAwesomeIcon icon={faCheck} /> chiffres</span>
                <span className={ valided.special ? 'test-valided' : '' }><FontAwesomeIcon icon={faCheck} /> caractère spécial</span>
                <span className={ valided.length ? 'test-valided' : '' }><FontAwesomeIcon icon={faCheck} /> 8 caractère</span>
                <span className={ valided.min ? 'test-valided' : '' }><FontAwesomeIcon icon={faCheck} /> minuscule</span>
                <span className={ valided.maj ? 'test-valided' : '' }><FontAwesomeIcon icon={faCheck} /> majuscule</span>
              </Form.Text>
              </Form.Group>
              
              <Form.Group className="mb-3 password-group" controlId="newPassword2">
                <Form.Label>Confimation mot de passe</Form.Label>
                <Form.Control type={this.state.showPassword2 ? "text" : "password"} name="newPassword2" autoComplete={false} placeholder="Confirmation mot de passe" onChange={(e) => this.updateNewPassword2(e.target.value) } value={ this.state.newPassword2 } required />
                <span className="password-toggle" onClick={() => this.setState({ showPassword2: !this.state.showPassword2 })}>
                  <FontAwesomeIcon icon={!this.state.showPassword2 ? faEye : faEyeSlash} />
                </span>
              </Form.Group>

              <div className="submit-container mt-4 mb-3">
                <Button variant="primary" onClick={ this.submit }>Enregistrer</Button>
              </div>
            </div>
          </form>
          <div className=" my-3">
              <LinkContainer to="/">
                <BootstrapButton variant="link">Connexion</BootstrapButton>
              </LinkContainer>
            </div>
      </div>
    );
  }
}

export default EditPassword;

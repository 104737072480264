import log from "loglevel";
import * as Sentry from '@sentry/browser';
import Utils from '../Utils/Utils';

export default class FeedbackSolver 
{

    constructor(iGraph) {
        this.Graph = iGraph;
    }

    GetPedagologicalEndToDisplay()
    {
        // Get available Pedagological ends from Exercice graph
        let availablePedagologicalEnds = this.Graph.GetAvailablePedagologicalEnds();

        // For each pedagological end we check the logic activation
        for (let i = 0; i < availablePedagologicalEnds.length; i++) {
            const pedagologicalEndToDisplay = availablePedagologicalEnds[i];

            // Get the logic string from the graph
            let logicRuleString = pedagologicalEndToDisplay.DetectionRule;
            if(logicRuleString == "")
            {
                log.error("FeedbackSolver.GetPedagologicalEndToDisplay: End ID '" + pedagologicalEndToDisplay.ID + "' has no detection rule!");
                continue;
            }

            // Replace occurrences of CountNarrativeEnd(...) with the result of this.Graph.CountNarrativeEnd(...)
            const countNarrativeEndRegex = /CountNarrativeEnd\(\s*['"]([^'"]+)['"]\s*\)/g;
            logicRuleString = logicRuleString.replace(countNarrativeEndRegex, (match, narrativeEnd) => {
                // Call this.Graph.CountActions(...) and return the result
                return this.Graph.CountNarrativeEnd(narrativeEnd);
            });

            // Replace occurrences of CountActionsByID(...) with the result of this.Graph.CountActionsByID(...)
            const countUserActionsByIDRegex = /CountUserActionsByID\(\s*['"]([^'"]+)['"]\s*\)/g;
            logicRuleString = logicRuleString.replace(countUserActionsByIDRegex, (match, userActionID) => {
                //log.debug("============== Logic rule string : ",logicRuleString);
                // Call this.Graph.CountActionsByID(...) and return the result
                return this.Graph.CountUserActionsByID(userActionID);
            });

            // Replace occurrences of CountUserActionsByType(...) with the result of this.Graph.CountUserActionsByType(...)
            const countUserActionsByTypeRegex = /CountUserActionsByType\(\s*['"]([^'"]+)['"]\s*\)/g;
            logicRuleString = logicRuleString.replace(countUserActionsByTypeRegex, (match, userActionTypes) => {
                //log.debug("============== Logic rule string : ",logicRuleString);
                // Call this.Graph.CountUserActionsByType(...) and return the result
                return this.Graph.CountUserActionsByType(userActionTypes);
            });
            //log.debug("============== Logic rule string : ",logicRuleString);
            
            // Execute the logical string
            const logicRuleResult = Utils.ExecuteBooleanStringFunction(logicRuleString);
            
            // Return the first valid pedalogical end
            if (!logicRuleResult.error && logicRuleResult.result == true) {
                log.debug("FeedbackSolver.GetPedagologicalEndToDisplay: Pedagological end '" + pedagologicalEndToDisplay.ID + "' chosen, with DetectionRule = '" + pedagologicalEndToDisplay.DetectionRule + "' and resolved logicRuleString = '" + logicRuleString + "'.");
                return pedagologicalEndToDisplay;
            }
            else if (logicRuleResult.error) {
                const errorMessage = "FeedbackSolver.GetPedagologicalEndToDisplay: Pedagological end '" + pedagologicalEndToDisplay.ID + "' has an invalid DetectionRule = '" + pedagologicalEndToDisplay.DetectionRule + "' and resolved logicRuleString = '" + logicRuleString + "'. Error: " + logicRuleResult.error;
                Sentry.captureMessage(errorMessage);
                log.error(errorMessage);
            }
        }
    }

    GetPedagologicalRecommendationsToDisplay()
    {
        let pedagologicalsRecommendationsToDisplay = [];

        // Get available Pedagological Recommendations from Exercice graph
        let availablePedagologicalRecommendations = this.Graph.GetAvailablePedagologicalRecommendations();

        // For each pedagological Recommendation we check the logic activation
        for (let i = 0; i < availablePedagologicalRecommendations.length; i++) {
            const pedagologicalRecommendationToDisplay = availablePedagologicalRecommendations[i];

            // Get the logic string from the graph
            let logicRuleString = pedagologicalRecommendationToDisplay.DetectionRule;
            if(logicRuleString == "")
            {
                log.error("FeedbackSolver.GetPedagologicalRecommendationsToDisplay: Recommendation ID '" + pedagologicalRecommendationToDisplay.ID + "' has no detection rule!");
                continue;
            }

            // Replace occurrences of CountNarrativeEnd(...) with the result of this.Graph.CountNarrativeEnd(...)
            const countNarrativeEndRegex = /CountNarrativeEnd\(\s*['"]([^'"]+)['"]\s*\)/g;
            logicRuleString = logicRuleString.replace(countNarrativeEndRegex, (match, narrativeEnd) => {
                // Call this.Graph.CountNarrativeEnd(...) and return the result
                return this.Graph.CountNarrativeEnd(narrativeEnd);
            });

            // Replace occurrences of CountActionsByID(...) with the result of this.Graph.CountActionsByID(...)
            const countUserActionsByIDRegex = /CountUserActionsByID\(\s*['"]([^'"]+)['"]\s*\)/g;
            logicRuleString = logicRuleString.replace(countUserActionsByIDRegex, (match, userActionID) => {
                // Call this.Graph.CountActionsByID(...) and return the result
                return this.Graph.userActionID;
            });

            // Replace occurrences of CountUserActionsByType(...) with the result of this.Graph.CountUserActionsByType(...)
            const countUserActionsByTypeRegex = /CountUserActionsByType\(\s*['"]([^'"]+)['"]\s*\)/g;
            logicRuleString = logicRuleString.replace(countUserActionsByTypeRegex, (match, userActionTypes) => {
                // Call this.Graph.CountUserActionsByType(...) and return the result
                return this.Graph.userActionTypes;
            });

            // Exectute the logical string
            const logicRuleResult = Utils.ExecuteBooleanStringFunction(logicRuleString);

            // Add the pedalogical Recommendation if valid
            if (!logicRuleResult.error && logicRuleResult.result == true) {
                log.debug("FeedbackSolver.GetPedagologicalRecommendationsToDisplay: Recommendation '" + pedagologicalRecommendationToDisplay.ID + "' chosen, with DetectionRule = '" + pedagologicalRecommendationToDisplay.DetectionRule + "' and resolved logicRuleString = '" + logicRuleString + "'.");
                pedagologicalsRecommendationsToDisplay.push(pedagologicalRecommendationToDisplay);
            }
            else if (logicRuleResult.error) {
                const errorMessage = "FeedbackSolver.GetPedagologicalRecommendationsToDisplay: Recommendation '" + pedagologicalRecommendationToDisplay.ID + "' has an invalid DetectionRule = '" + pedagologicalRecommendationToDisplay.DetectionRule + "' and resolved logicRuleString = '" + logicRuleString + "'. Error: " + logicRuleResult.error;
                Sentry.captureMessage(errorMessage);
                log.error(errorMessage);
            }
        }

        return pedagologicalsRecommendationsToDisplay;
    }

    GetPedagologicalAdditionsToDisplay()
    {
        let pedagologicalsAdditionsToDisplay = [];

        // Get available Pedagological additions from Exercice graph
        let availablePedagologicalAdditions = this.Graph.GetAvailablePedalogologicalAdditions();

        // For each pedagological addition we check the logic activation
        for (let i = 0; i < availablePedagologicalAdditions.length; i++) {
            const pedagologicalAdditionToDisplay = availablePedagologicalAdditions[i];

            // Get the logic string from the graph
            let logicRuleString = pedagologicalAdditionToDisplay.DetectionRule;
            if(logicRuleString == "")
            {
                log.error("FeedbackSolver.GetPedagologicalAdditionsToDisplay: Addition ID '" + pedagologicalAdditionToDisplay.ID + "' has no detection rule!");
                continue;
            }

            // Replace occurrences of CountNarrativeEnd(...) with the result of this.Graph.CountNarrativeEnd(...)
            const countNarrativeEndRegex = /CountNarrativeEnd\(\s*['"]([^'"]+)['"]\s*\)/g;
            logicRuleString = logicRuleString.replace(countNarrativeEndRegex, (match, narrativeEnd) => {
                // Call this.Graph.CountActions(...) and return the result
                return this.Graph.CountNarrativeEnd(narrativeEnd);
            });

            // Replace occurrences of CountActionsByID(...) with the result of this.Graph.CountActionsByID(...)
            const countUserActionsByIDRegex = /CountUserActionsByID\(\s*['"]([^'"]+)['"]\s*\)/g;
            logicRuleString = logicRuleString.replace(countUserActionsByIDRegex, (match, userActionID) => {
                // Call this.Graph.CountActionsByID(...) and return the result
                return this.Graph.CountUserActionsByID(userActionID);
            });
            //log.debug("============== Logic rule string : ",logicRuleString);

            // Replace occurrences of CountUserActionsByType(...) with the result of this.Graph.CountUserActionsByType(...)
            const countUserActionsByTypeRegex = /CountUserActionsByType\(\s*['"]([^'"]+)['"]\s*\)/g;
            logicRuleString = logicRuleString.replace(countUserActionsByTypeRegex, (match, userActionTypes) => {
                // Call this.Graph.CountUserActionsByType(...) and return the result
                return this.Graph.CountUserActionsByType(userActionTypes);
            });

            // Exectute the logical string
            const logicRuleResult = Utils.ExecuteBooleanStringFunction(logicRuleString);

            // Add the pedalogical addition if valid
            if (!logicRuleResult.error && logicRuleResult.result == true) {
                log.debug("FeedbackSolver.GetPedagologicalAdditionsToDisplay: Addition '" + pedagologicalAdditionToDisplay.ID + "' chosen, with DetectionRule = '" + pedagologicalAdditionToDisplay.DetectionRule + "' and resolved logicRuleString = '" + logicRuleString + "'.");
                pedagologicalsAdditionsToDisplay.push(pedagologicalAdditionToDisplay);
            }
            else if (logicRuleResult.error) {
                const errorMessage = "FeedbackSolver.GetPedagologicalAdditionsToDisplay: Addition '" + pedagologicalAdditionToDisplay.ID + "' has an invalid DetectionRule = '" + pedagologicalAdditionToDisplay.DetectionRule + "' and resolved logicRuleString = '" + logicRuleString + "'. Error: " + logicRuleResult.error;
                Sentry.captureMessage(errorMessage);
                log.error(errorMessage);
            }
        }

        return pedagologicalsAdditionsToDisplay;
    }
}
import log from "loglevel";
import React, { useState, useEffect } from 'react';
import * as Sentry from "@sentry/react";

//import 'react-slidedown/lib/slidedown.css';
import './styles/components/App.scss';
import LoginLayout from './pages/LoginLayout';

import MainPage from './pages/MainPage';
import Sdk from './sdk/Sdk';

import Zendesk, { ZendeskAPI } from "./components/Utilities/Zendesk";

import CustomButton from './components/Utilities/Button';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import CookieConsent from "react-cookie-consent";
import {browserName, browserVersion} from 'react-device-detect';

import { useLocation, useNavigate, useParams } from "react-router-dom";

function CustomModal(props) {
  const handleClose = () => setShow(false);

  const [show, setShow] = useState(false);
  const [title, setTitle] = useState('Modal heading');
  const [hideCloseBt, setHideCloseBt] = useState(false);
  const [disableCloseOnHide, setDisableCloseOnHide] = useState(false);
  const [toTheTop, setToTheTop] = useState(false);
  const [body, setBody] = useState('Woohoo, you\'re reading this text in a modal!');
  const [footer, setFooter] = useState(
    <React.Fragment>
      <Button variant="secondary" onClick={handleClose}>
        Fermer
      </Button>
    </React.Fragment>
  );

  // const handleShow = () => setShow(true);
  const openModal = ({ title, hideCloseBt, disableCloseOnHide, toTheTop, body, footer }) => {
    setTitle(title);
    setBody(body);
    setFooter(footer);
    setShow(true);
    setToTheTop(toTheTop ? true : false)
    setHideCloseBt(hideCloseBt ? true : false);
    setDisableCloseOnHide(disableCloseOnHide ? true : false);
  };
  const closeModal = () => {
    setShow(false);
  };

  useEffect(() => {
    window.sdk.event().on('modalOpen', openModal);
    window.sdk.event().on('modalClose', closeModal);
    return () => {
      window.sdk.event().removeListener('modalOpen', openModal);
      window.sdk.event().removeListener('modalClose', closeModal);
    };
  });

  let onHide = disableCloseOnHide ? null : handleClose;
  let backdrop = disableCloseOnHide ? 'static' : true;

  return (
    <Modal className={'custom-modal' + toTheTop ? ' modal-to-the-top':''} backdrop={ backdrop } show={show} onHide={onHide} {...props}>      
      <Modal.Header className={'custom-modal__header'} closeButton={ !hideCloseBt }>
        <Modal.Title className={'custom-modal__title'}>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={'custom-modal__body'}>
        {body}
      </Modal.Body>
      {footer &&
      <Modal.Footer className={'custom-modal__footer'}>
        {footer}
      </Modal.Footer>}
    </Modal>
  );
}

function ConfirmationModal({ title, content, hideCloseBt, footer, ...props }) {
  return (
    <Modal show={true} centered={true} keyboard={false} className={'confirmation-modal'} backdropClassName={'confirmation-modal-backdrop'} {...props}>
      {title && (
        <Modal.Header className={'confirmation-modal__header'} closeBt={ !hideCloseBt }>
          <Modal.Title className={'confirmation-modal__title'}>{title}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body className={'confirmation-modal__body'}>
        {content}
      </Modal.Body>
      {footer && (
        <Modal.Footer className={'confirmation-modal__footer'}>
          {footer}
        </Modal.Footer>
      )}
    </Modal>
  );
}

export function withRouter(Children) {
  return (props) => {
      const location = useLocation() ;
      const navigate = useNavigate() ;
      return <Children {...props} match={location.state} navigate={navigate} />
  }
}

class App extends React.Component
{

  constructor(props) {

    log.debug("+++ " + (new Date).toUTCString() + " - Appplication started +++");

    super(props);

    this.state = {
      loggedIn: false,
      waitingInitialization: true,
      showSessionConfirmation: false,
      fullscreenExited: false,
      showExitedModal : false,
      showFullscreenExitedModal : false,
      exerciseURL: null,
	  alertCookie: false
    }
	  this.zendeskKey = "573753d1-2f9f-4343-8339-1e364ee6fd0b";
    this.browserAvailables  = {
      'Chrome' : 76,
      'Edge' : 88,
      'Safari' : 16,
      //'Firefox' : 100 < When ready
    }
  }

  async componentDidMount() {
    window.log = log;
    // DEBUG QA tests interface
    window.testMode = {
      functions: {
        skipNavigatorCheck: this.skipNavigatorCheck
      },
      skipSTTSetupTest: false,
      skipDeviceDisconnectCheck: false,
      fakeUserAudioToPlay: "",
      fillAppStateValues: false,
      appStateValues: {}
    };
	  
  const isCookieEnabled = navigator.cookieEnabled;

  var storageOrUndefined;
  try {
    storageOrUndefined = window.localStorage;
  } catch (e) {
    storageOrUndefined = null;
  }

  if(!isCookieEnabled || storageOrUndefined == null){
    this.setState({alertCookie: true})
  }
  
  log.debug("Browser '" + browserName + "' Version '" + browserVersion + "'")
  
  // Prevent unsupported browsers to run the app
  let unsupportedBrowser = !Object.keys(this.browserAvailables).includes(browserName); // TODO: allow Edge, Firefox and Safari when validated
  let unsupportedBrowserVersion = false; 

  if(unsupportedBrowser)
  {
    this.setState({alertBrowser: 'browser'})

  }
  else{
    unsupportedBrowserVersion = this.browserAvailables[browserName] > browserVersion;
  }
  
  if(unsupportedBrowserVersion)
  {
    this.setState({alertBrowser: 'version'})
  }
  
	let env, apiUrl;
	let res = await fetch('/env.json');
	try {
		let envData = await res.json();
		env = envData.env;
		apiUrl = envData.apiUrl;
	}
	catch(err) {
		if(window.location.port == '3000') {
			env = 'devlocalhost';
			apiUrl = window.location.host.split(':').shift() + ':3001';
		}
		else {
			log.error("Impossible to get environment and API URL!", err);
		}
	}
  if (env == 'dev' || env == 'devlocalhost') {
    log.setLevel('debug');
  }
  else {
    log.setLevel('warn');
  }
	window.policyUri = 'https://uploads-ssl.webflow.com/64536c788c1d11148f3105e0/649f08d1544cd9ef641e563b_Politique%20de%20confidentialit%C3%A9%20PractiVizio.pdf';
	
  window.sdk = new Sdk({
      apiUrl, env
    });	

    document.addEventListener('contextmenu', event => event.preventDefault());
    
    document.addEventListener("keydown", function (event) {    
      // Ctrl + B to toggle debug mode
      window.sdk.event().emit('keydownDetected');
      if(event.ctrlKey && event.altKey && event.key === 'd')
      {
        document.body.classList.toggle("debug-mode");
      }
    });

    window.sdk.event().on('userLogged', (url) => {
      this.setState({
        loggedIn: true,
        showFullscreenExitedModal: true,
        fullscreenExited: false
      })
    });

    window.sdk.event().on('userLogOut', () => {
      this.setState({
        loggedIn: false,
        showExitedModal : false,
        showFullscreenExitedModal : false
      })
    });
    window.sdk.event().on('goTo', (url) => {
      url = url.replace(/^.*\/\/[^\/]+/, '')
      this.props.navigate(url, { replace: false });
      this.setState({
        loggedIn: true,
        showFullscreenExitedModal: true,
        fullscreenExited: false
      })
      log.debug('--- DEBUG: Listener catched goTo to url: ', url);
    })  
	window.sdk.event().on('needSesionConfirmation', (tmpMe, confirmation) => {
		this.sessionConfirmationCallback = confirmation;
		this.setState({
			showSessionConfirmation: true,
			tmpMe: tmpMe
		})
	});

	window.sdk.event().on('fullscreenExited', () => {
		if(window.sdk.isInIframe()) {
			this.setState({
				fullscreenExited: true
			});
		}
	});
	
	window.sdk.event().on('showExitedModal ', (data) => {
		if(window.sdk.isInIframe()) {
      window.sdk.closeFullscreen();
			this.setState({
				showExitedModal : true,
        exitModalData: data
			});
			
			setTimeout(() => {
				//TODO clean that
				document.dispatchEvent(new KeyboardEvent('keydown', {'key': 'Escape'}));
			}, 2000);
		}
	});

    await window.sdk.init();
	
	window.infoVersion = await window.sdk.fetch().get('/version');
  
  // Update App version in Sentry
  if(!window.location.host.includes("localhost") && !window.location.host.includes("127.0.0.1")) {
	Sentry.configureScope(scope =>
		scope.addEventProcessor(
		event =>
			new Promise(resolve =>
			resolve({
				...event,
				release: "PractiVizio-front@" + window.infoVersion.version,
				environment: window.sdk.env()
			})
			)
		)
	);
  }
  // Sample Sentry message
  //Sentry.captureMessage("Fake: Something went wrong. Version = " + "PractiVizio-front@" + window.infoVersion.version);

    log.debug("Appplication initialized: environment = " + window.sdk.env() + ", version = " + window.infoVersion.version);
	
    // Get the exercise url and exclude the potential "?needCheck=1" in the end
    const exerciseURL = window.location.pathname;
    log.debug("Exercise URL = " + exerciseURL);
    const regex = /^\/exercise\//;
    if(regex.test(exerciseURL)){
      window.sdk.setRedirectUrl(exerciseURL);
    }
    this.setState({
      waitingInitialization: false,
      exerciseURL: exerciseURL
    })
  }

  sessionConfirmYES = () => {
	//if(window.sdk.isInIframe())
	//	window.sdk.openFullscreen();

	this.sessionConfirmationCallback(true);

	this.setState({
		showSessionConfirmation: false
	});
  }

  sessionConfirmNO = () => {
    window.localStorage.setItem('cookie-consent', false);

	this.sessionConfirmationCallback(false);

    this.setState({
		showSessionConfirmation: false
	});
  }

  goFullscreen = () => {
	this.setState({
		fullscreenExited: false
	});
	window.sdk.openFullscreen();
  }

  iframeRetry = () => {

    // TODO: better way to restart the exercise than reloading the full iframe?

    log.debug("iframeRetry: Current URL: '" + window.location.href + "'. Exercise URL: '" + this.state.exerciseURL + "'.");

    // Using navigate on this.state.exerciseURL to load the exercise URL
    // Because if we come from the feedbacks 'Stop' button, a simple reload loads back the feedbacks panel
    if(this.state.exerciseURL)
      window.sdk.event().emit('goTo', this.state.exerciseURL);

    // Forcing a reload of the iframe
    // Because without it, the iframe is in a broken state if we come from a 'Stop' button in the middle of an exercise
    window.location.reload(false);

    // Make the modal disappear
    this.setState({
      showExitedModal : false,
      showFullscreenExitedModal : false
    });
  }

  handleLoaded = (open = false) => {
    window.zESettings = {
      webWidget: {
        color: { theme: '#75309A' }
      }
    };
    if(!open){
      window.zE('webWidget', 'hide');
      window.zE('webWidget:on', 'close', function() {
        window.zE('webWidget', 'hide');
      })
    }
    else {
      window.zE('webWidget', 'show');
      window.zE('webWidget:on', 'close', function() {
        window.zE('webWidget', 'show');
      })
    }
  }; 

  skipNavigatorCheck = () => {
    this.setState({
      alertBrowser: false
    })
  }

  skipCookieDisabledModal = () => {
    this.setState({
      alertCookie: false
    })
  }
  skipBrowserNotAllowedModal = () => {
    this.setState({
      alertBrowser: false
    })
  }  
  render(){
    if(this.state.alertCookie){
      return <ConfirmationModal id="cookieDisabledModal"
      content={(
        <React.Fragment>
          <center>
          <div>Autorisez les cookies pour lancer PractiVizio.</div>
          <div>En cas de problème, merci de contacter <a href="mailto:support@practicio.fr">support@practicio.fr</a>
          <br /><br /></div>
          <img src={require('./assets/images/allow_cookie.gif')}/>
          <div className="hidden-input"><input type="text" value="" name="skip-cookieDisabledModal" onChange={this.skipCookieDisabledModal}/> </div>
          </center>
        </React.Fragment>
      )}
      footer={(
        <React.Fragment>
          <CustomButton variant="primary" onClick={ this.iframeRetry }>Rafraichir</CustomButton>
        </React.Fragment>
      )}
    />
    }
    if(this.state.alertBrowser){
      return <ConfirmationModal id="cookieBrowserModal"
      content={(
        <React.Fragment>
          <center>
          <div>{
          this.state.alertBrowser == 'browser' ?
            <React.Fragment><b>Votre navigateur {browserName} n'est actuellement pas supporté.</b><br></br><br></br>Veuillez utiliser <a href="https://www.google.com/intl/fr/chrome/gsem/download/" target="_blank">Google Chrome</a> </React.Fragment>
          :
            <React.Fragment><b>La version {browserVersion} de {browserName} n'est actuellement pas supportée.</b><br></br><br></br> Veuillez mettre à jour </React.Fragment>
          }
          {(() => {
            switch (browserName) {
              case 'Chrome':
                return <a href="https://www.google.com/intl/fr/chrome/gsem/download/" target="_blank">Google Chrome</a> 
                break;
              case 'Edge' : 
              return <a href="https://www.microsoft.com/fr-fr/edge/download" target="_blank">Edge</a> 
                break;
            }
          })()}
           <span> pour lancer PractiVizio.</span></div>
          <div><br></br>En cas de problème, merci de contacter notre support.</div>
          <div className="hidden-input"><input type="text" value="" name="skip-browserModal" onChange={this.skipBrowserNotAllowedModal}/> </div>
          </center>
          <div className={"debug debug_graph_controls"}>
            <Button id='skipNavigatorCheckButton' style={{ fontSize: 'small', padding: '4px 8px' }} onClick={ this.skipNavigatorCheck }>Ignore</Button>
          </div>
          {this.zendeskKey && <Zendesk defer zendeskKey={this.zendeskKey} onLoaded={() => {this.handleLoaded(true)}} />}
        </React.Fragment>
      )}
    />
    }    
    if(this.state.waitingInitialization)
      return  <div className="loader-wrapper loader-full">
            <div className="spinner-loader dark"></div>
                  </div>;

    return (
      <React.Fragment>
	    { !this.state.showSessionConfirmation &&
			<React.Fragment>
			{(this.state.loggedIn ? <MainPage /> : <LoginLayout />)}
			{this.zendeskKey && <Zendesk defer zendeskKey={this.zendeskKey} onLoaded={this.handleLoaded} />}
			</React.Fragment>
		}
        <CustomModal />
		{ window.localStorage.getItem('cookie-consent') != 'ok' &&
			<CookieConsent
				expires={ 30 }
				onAccept={(acceptedByScrolling) => {
					window.localStorage.setItem('cookie-consent', 'ok');
				}}
				location="bottom"
				buttonText="J'accepte"
				overlay={true}
				style={{
					backgroundColor: '#A6FDBB',
					color: '#000'
				}}
				buttonStyle={{
					backgroundColor: '#02182F',
					color: '#fff',
					borderRadius: '5px',
					fontWeight: 'bold'
				}}
				overlayStyle={{
					backgroundColor: 'rgba(0,0,0,0.5)',
          zIndex:99999999
				}}
				>
				Ce site utilise des cookies pour améliorer l'expérience utilisateur.
			</CookieConsent>
		}
		{ this.state.showSessionConfirmation &&
      <ConfirmationModal id="sessionConfirmationModal"
        content={'Êtes-vous bien ' + this.state.tmpMe.firstName + ' ' + this.state.tmpMe.lastName + ' ?'}
        footer={(
          <React.Fragment>
            <CustomButton variant="secondary" onClick={ this.sessionConfirmNO }>Non</CustomButton>
            <CustomButton variant="primary" onClick={ this.sessionConfirmYES }>Oui</CustomButton>
          </React.Fragment>
        )}
      />
		}
		{ this.state.showExitedModal ?
      <ConfirmationModal id="exitedModal"
        content={this.state.exitModalData.ExitMessage ? this.state.exitModalData.ExitMessage : 'Merci pour votre participation !'}
        footer={this.state.exitModalData.ExitedButton_Visible ? 
          <div className='text-center' style={{ width: '100%' }}>
            <CustomButton variant="primary" onClick={ this.iframeRetry }>{this.state.exitModalData.ExitedButton_Text ? this.state.exitModalData.ExitedButton_Text : "Recommencer"}</CustomButton>
          </div>
          :
          <React.Fragment>
          </React.Fragment>
        }
      />
		:
    <React.Fragment>
		{ (this.state.showFullscreenExitedModal && this.state.fullscreenExited && this.state.loggedIn) &&
      <ConfirmationModal id="fullscreenExitedModal"
        content={'Vous venez de quitter le mode plein écran. Cliquez sur \"Continuer\" pour reprendre où vous en étiez.'}
        footer={(
          <div className='text-center' style={{ width: '100%' }}>
            <CustomButton variant="primary" onClick={ this.goFullscreen }>Continuer</CustomButton>
          </div>
        )}
      />
		}
    </React.Fragment>
    }
      </React.Fragment>
    );
  }

}

let AppExport;
if(!window.location.host.includes("localhost") && !window.location.host.includes("127.0.0.1"))
	AppExport = Sentry.withProfiler(App);
else
	AppExport = App;


export default withRouter(AppExport);
import log from "loglevel";
import * as Sentry from '@sentry/browser';
import ExerciseNode from './ExerciseNode';
import NodePort from '../NodePort';
import Utils from '../../Utils/Utils';

export default class NarrativeSolver extends ExerciseNode {

    // Ports
    Input = new NodePort("Input", "input", this);
    Default = new NodePort("Default", "output", this);

    // Parameters
    NarrativeEnds = [];

    // Create list of narrative ends from the graph.
    constructor(iGraph, iProperties) {
        super(iGraph, iProperties);

        iProperties.NarrativeEnds.forEach(narrativeEnd => {
            //log.debug(this.GetIdentity() + " constructor: Adding dynamic end '" + narrativeEnd.Name + "'.");

            let newNarrativeEnd = new NarrativeEnd(narrativeEnd.ID, narrativeEnd.Name, narrativeEnd.DetectionRule);
            this.NarrativeEnds.push(newNarrativeEnd);

            this[newNarrativeEnd.GetOutputPortName()] = new NodePort(newNarrativeEnd.GetOutputPortName(), "output", this);
        });
    }

    OnActivated(iActivator, iInputPort) {
        super.OnActivated(iActivator, iInputPort);

        log.debug(this.GetIdentity() + " has been activated by '" + iActivator.GetIdentity() + "' on port '" + iInputPort.Name + "'.");

        const chosen_narrativeEnd = this.ChooseNarrativeEnd();

        // If no narrative end found, activate the default output port
        if (chosen_narrativeEnd == null) {
            log.debug(this.GetIdentity() + ".OnActivated: No end found, activating default output port.");
            this.Graph.History.AddNarrativeEnd(this.ID, "Fin_NonIntervention");
            this.ActivateDefaultOutput();
        }

        else {
            log.debug(this.GetIdentity() + ".OnActivated: End '" + chosen_narrativeEnd.Name + "' found, activating output port.");
            this.Graph.History.AddNarrativeEnd(this.ID, chosen_narrativeEnd.Name);
            this.ActivateEndOutput(chosen_narrativeEnd);
        }
    }

    ChooseNarrativeEnd()
    {  
        // For each end we check if the DetectionRule is fulfill
        for (let i = 0; i < this.NarrativeEnds.length; i++) {
            const narrativeEnd = this.NarrativeEnds[i];
    
            // Create the logical string
            let logicRuleString = narrativeEnd.DetectionRule;
            //log.debug("============== narrative end : ", narrativeEnd);
            //log.debug("============== logicRuleString : ",logicRuleString);
    
            // Replace occurrences of CountUserActionsByType(...) with the result of this.Graph.CountUserActionsByType(...)
            const countUserActionsByTypeRegex = /CountUserActionsByType\(\s*['"]([^'"]+)['"]\s*\)/g;
            logicRuleString = logicRuleString.replace(countUserActionsByTypeRegex, (match, userActionTypes) => {
                //log.debug("============== Logic rule string : ",logicRuleString);
                // Call this.Graph.CountUserActionsByType(...) and return the result
                return this.Graph.CountUserActionsByType(userActionTypes);
            });
            //log.debug("NarrativeSolver.ChooseNarrativeEnd: testing end '" + narrativeEnd + "', with resolved logicRuleString = ", logicRuleString);

            // Execute the logical string
            const logicRuleResult = Utils.ExecuteBooleanStringFunction(logicRuleString);
                
            // Return the first valid narrative end
            if (!logicRuleResult.error && logicRuleResult.result == true) {
                log.debug("NarrativeSolver.ChooseNarrativeEnd: Narrative end '" + narrativeEnd.Name + "' chosen, with DetectionRule = '" + narrativeEnd.DetectionRule + "' and resolved logicRuleString = '" + logicRuleString + "'.");
                return narrativeEnd;
            }
            else if (logicRuleResult.error) {
                const errorMessage = "NarrativeSolver.ChooseNarrativeEnd: Narrative end '" + narrativeEnd.Name + "' has an invalid DetectionRule = '" + narrativeEnd.DetectionRule + "' and resolved logicRuleString = '" + logicRuleString + "'. Error: " + logicRuleResult.error;
                Sentry.captureMessage(errorMessage);
                log.error(errorMessage);
            }
        }

        return null;
    }

    ActivateEndOutput(iEnd) {
        this.Reset();
        this[iEnd.GetOutputPortName()].ActivateAllConnections();
    }

    ActivateDefaultOutput() {
        this.Reset();
        this.Default.ActivateAllConnections();
    }
}

class NarrativeEnd {
    ID = -1;
    Name = "";
    DetectionRule = "";

    constructor(iID, iName, iDetectionRule) {
        this.ID = iID;
        this.Name = iName;
        this.DetectionRule = iDetectionRule;
    }

    GetOutputPortName() {
        return "NarrativeEnd" + this.ID;
    }

    ToString() {
        return "{" +
            "\n  Name: '" + this.Name + "'" +
            "\n  DetectionRule: '" + this.DetectionRule + "'" +
            "\n  OutputPort: '" + this.GetOutputPortName() + "'" +
            "\n}";
    }
}
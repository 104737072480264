import log from "loglevel";
export default class IndexedDB {
    
    constructor() {
    }
	
	init() {
		this.data = {};
	}
    
	async add(name, blob) {
		log.debug('add', name, blob);
		this.data[name] = blob;
	}
	
	async get(name) {
		if(!this.data[name])
			return false;
		log.debug('get', name, this.data[name]);
		return this.data[name];
	}
	
	async remove(name) {
		this.data[name] = null;
		delete this.data[name];
	}
	
}
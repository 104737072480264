import log from "loglevel";
import ExerciseNode from './ExerciseNode';
import NodePort from '../NodePort';
import ParticipantsModule from '../../Participants/ParticipantsModule';

export default class BotDisconnection extends ExerciseNode 
{
    // Ports
    Input = new NodePort("Input", "input", this);

    // Parameters
    BotName = "";

    // Internal values


    constructor(iGraph, iProperties) 
    {
        super(iGraph, iProperties);
 
        this.BotName = iProperties.BotName;

        //log.debug(this.GetIdentity() + " constructor: graph = " + this.Graph.ExerciseName + ", id = " + this.ID + ", botName = " + this.BotName); 
    }

    OnActivated(iActivator, iInputPort)
    {
        log.debug(this.GetIdentity() + " has been activated by '" + iActivator.GetIdentity() + "'.");
        super.OnActivated(iActivator, iInputPort);

        // Start and gives a callback when finished
        if(ParticipantsModule.Instance != null)
        {
            log.debug(this.GetIdentity() + " stopping Bot '" + this.BotName + "'...");
            ParticipantsModule.Instance.StopABot(this.BotName);
            this.Graph.History.AddEvent("BotDisconnection", {
                NodeID: this.ID, 
                Actor: this.BotName});
        }
        else
        {
            log.debug("BotsModule.Instance == null");
        }
        
        this.SetActive(false);
    }

    PrintParameters()
    {
        //log.debug("BotName = " + this.BotName + ", DefaultLoop = " + this.DefaultLoop + ", PreferredPosition = " + this.PreferredPosition);
    }
}
import log from "loglevel";
import ExerciseNode from './ExerciseNode';
import NodePort from '../NodePort';

export default class GoOnstageBriefing extends ExerciseNode 
{
    // Ports
    Input = new NodePort("Input", "input", this);

    // Parameters
    
    constructor(iGraph, iProperties) 
    {        
        super(iGraph, iProperties);

        //log.debug(this.GetIdentity() + " constructor: graph = " + this.Graph.ExerciseName + ", id = " + this.ID + ".");
    }

    OnActivated(iActivator, iInputPort)
    {
        super.OnActivated(iActivator, iInputPort);

        log.debug(this.GetIdentity() + " has been activated by '" + iActivator.GetIdentity() + "' but does nothing for now.");
    }
}
import log from "loglevel";
import React from 'react';
import '../../styles/components/Achievement.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faLock, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import Lottie from "./Animation";
import fallbackSrc from '../../assets/images/feedback/ending/Default.svg';

export default class Achievement extends React.Component {

  timer;
  state = {
    open: this.props.open
  }
  constructor(props) {
    super(props);
    this.achievement = React.createRef();
  }

  componentDidMount() {
  window.sdk.event().on('toggleAchievementDesc', () => {
    this.setState({ open : false});

  })
}
  toggle = () => {    
    if(this.props.name.startsWith("goals") && !this.state.open){
        window.sdk.event().emit('toggleAchievementDesc');
        this.setState({ open : true});
      }
      else{
      this.setState({
        open: !this.state.open
      })
      }
  }

  tryRequire = (key) => {
    try {
     return require('../../assets/images/feedback/lock/'+key+'.svg');
    } catch (err) {
      log.debug("Impossible to get image key.svg! ", err);
     return fallbackSrc;
    }
  };
  render() {
    if(!this.props.achievement)
      return null;

    let achivementDescription = this.props.achievement.Description
      {/*<Lottie type={this.props.achievement.Type} loop={false} name={this.props.name} />*/}
                    {/*<div className={"achievement-icon-image " + "achievement-icon-" + this.props.type}>
                  <img src={this.tryRequire(this.props.achievement.ID)} />
              </div>*/}
    
              return (
      <div className={'achievement-container'} ref={this.achievement}>
        <div className={'achievement achievement-' + this.props.achievement.ID + ((this.props.achievement.done) ? ((this.props.new) ? ' achievement-unlock-new' : ' achievement-unlock') : '') + (this.props.small ? ' achievement-small' : '')} >
          <div className={'achievement-row'}>
          {(() => {
            switch (true) {
              case this.props.name.startsWith("goals"):
                return <div className={"achievement-icon-image " + "achievement-icon-" + this.props.type}>
                  <img src={this.tryRequire(this.props.achievement.ID)} />
                </div>
                break;
              case this.props.achievement.Type.startsWith("Ending"):
              case this.props.achievement.Type == "PedagologicalRecommendation":
                return <Lottie type={this.props.achievement.ID} loop={false} autoplay={false} name={this.props.name} />
                default:
                  return <React.Fragment>
                      {this.props.achievement.Type == 'Action_Good' ?
                        <Lottie type='AchievementDone' loop={false} name={this.props.name} />
                        :
                        <Lottie type='AchievementFail' loop={false} name={this.props.name} />
                      }
                </React.Fragment>
            }
          })()}
            {/*<div className={"achievement-icon " +  ((this.props.achievement.done) ?  'icon-check' : 'icon-lock')} />}
                {this.props.achievement.done && this.props.new && <div className={"achievement-icon icon-lock-animate"} />*/}
            <div className="achievement-title" dangerouslySetInnerHTML={{__html: this.props.achievement.DisplayedName}} />
            {this.props.type != 'ending' && achivementDescription != "" &&
              <span className="achievement-more-toggle" onClick={this.toggle}>En savoir plus <FontAwesomeIcon icon={this.state.open ? faChevronUp : faChevronDown} /></span>
            }
          </div>

          {/*this.props.achievement.done && this.props.achievement.Description && !this.props.small && <span className="achievement-more"><span>-</span> {this.props.achievement.Description}</span>*/}
        </div>
        <p className={"feedback-ending-desc" + ((['ending'].includes(this.props.type) || this.state.open) ? ' open' : '')} style={(!this.state.open &&  this.props.type != 'ending')? {maxHeight:'0px'}:{}} dangerouslySetInnerHTML={{__html: achivementDescription}} />
        
      </div>
    );
  }
}
import log from "loglevel";
import { v4 as uuidv4 } from 'uuid';
import EventEmitter from '../../sdk/Utils/EventEmitter';

export default class AbstractParticipant extends EventEmitter {
    
    Name;
    ID;
    VideoSlot = null; 
    
    Pic; //pic of user when cam off
    ConnectionState = 'connecting'; //can be connecting / connected
    MicState //can be on / off
    CamState //can be on / off
    SpeakingState; //can be no, readyforSpeaking, speaking, raising
	
    constructor() {
        super();
        this.ID = uuidv4();
    }
    
    getID() {
        return this.ID;    
    }
    
    getName() {
        return this.Name;
    }
    
    getPic() {
        return this.Pic;
    }
    
    setPic(Pic) {
        this.Pic = Pic;
    }
    
    getConnectionState() {
        return this.ConnectionState;
    }
    
    setConnectionState(ConnectionState) {
        this.ConnectionState = ConnectionState;
		log.debug('onStateChange setConnectionState', this.getName()); 
        this.emit('stateChange', {
            state: 'connection',
            value: this.ConnectionState
        })
    }
    
    getMic() {
        return this.MicState;
    }
    
    setMic(MicState) {
        this.MicState = MicState;
        this.emit('stateChange', {
            state: 'mic',
            value: this.MicState
        })
    }
    
    getCamState() {
        return this.CamState;
    }
    
    setCamState(CamState) {
        this.CamState = CamState;
        this.emit('stateChange', {
            state: 'cam',
            value: this.CamState
        })
    }
    
    getSpeakingState() {
        return this.SpeakingState;
    }
    
    setSpeakingState(SpeakingState) {
        this.SpeakingState = SpeakingState;
        this.emit('stateChange', {
            state: 'speaking',
            value: this.SpeakingState
        })
    }
    
    
    
}
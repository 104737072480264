import log from "loglevel";
import ExerciseButton from './ExerciseButton';

export default class RaiseHandButton extends ExerciseButton {

    raiseHandText = "Lever la main";
    lowerHandText = "Patientez";
    handRaised = false;
    variant = "raising";

    constructor(props) {
      super(props);
      this.buttonText = this.raiseHandText;
    }

    // On clicked, change the label to "Lever la main"
    onClick() {
      log.debug("RaiseHandButton component: onClick");
      this.UpdateState(!this.handRaised);
      this.onclickedCallback(this.handRaised);
    }

    UpdateState(iHandRaised) {
      log.debug("RaiseHandButton component: UpdateState to " + iHandRaised);
      this.waiting = this.handRaised = iHandRaised;
      this.buttonText = this.handRaised ? this.lowerHandText : this.raiseHandText;
      this.forceUpdate();
    }
}

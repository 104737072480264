import log from "loglevel";
import ExerciseNode from './ExerciseNode';
import ValueInt from './ValueInt';
import NodePort from '../NodePort';

export default class SetInt extends ExerciseNode
{
    // Ports
    Input = new NodePort("Input", "input", this);
    Set = new NodePort("Set", "output", this);

    // Parameters
    Target = "";
    Value = false;

    constructor(iGraph, iProperties) 
    {      
        super(iGraph, iProperties);

        this.Target = iProperties.Target;
        this.Value = iProperties.Value;

        //log.debug(this.GetIdentity() + " constructor: graph = " + this.Graph.ExerciseName + ", id = " + this.ID + ", target = " + this.Target + ", value = " + this.Value); 
    }
    
    OnActivated(iActivator, iInputPort)
    {
        super.OnActivated(iActivator, iInputPort);
        log.debug(this.GetIdentity() + " has been activated by '" + iActivator.GetIdentity() + "'.");
        
        this.SetValue();
        this.SetActive(false);
    }

    SetValue()
    {
        // Directly set the value if specified
        if (this.Target != "")
        {
            log.debug(this.GetIdentity() + " SetValue '" + this.Value + "' to '" + this.Target + "'.");
            this.Graph.SetIntValue(this.Target, this.Value);
        }
        
        // Set the value to all connected nodes
        log.debug(this.GetIdentity() + " SetValue '" + this.Value + "' to " + this.Set.GetConnectionsCount() + " nodes: " + this.Set.ListPortConnections());
        this.Set.GetConnectedNodes().forEach(node => {
            //if(node instanceof ValueInt)
            {
                node.SetValue(this.Value);
            }
        });
    }

    PrintParameters()
    {
    }
}
import log from "loglevel";
import ExerciseNode from './ExerciseNode';
import NodePort from '../NodePort';

export default class UserAction extends ExerciseNode {
    // Ports
    Input = new NodePort("Input", "input", this);

    // Parameters
    UserActionID = "";
    UserActionTypes = [];

    // Dynamic values
    Phase = "";

    constructor(iGraph, iProperties) {
        super(iGraph, iProperties);

        this.UserActionID = iProperties.UserActionID;
        this.UserActionTypes = iProperties.UserActionTypes;

        log.debug(this.GetIdentity() + " constructor: graph = " + this.Graph.ExerciseName + ", UserActionID = " + this.UserActionID + ", UserActionTypes = " + this.UserActionTypes + ",Phase = " + this.Phase); 
    }

    OnActivated(iActivator, iInputPort) {
        super.OnActivated(iActivator, iInputPort);
        log.debug(this.GetIdentity() + " has been activated by '" + iActivator.GetIdentity() + "'.");

        // Get corresponding user action from graph
        let userAction = this.Graph.availableUserActions.find(item => item.ID == this.UserActionID);
        //log.debug(this.GetIdentity() + ".DetectUserActions: userAction = ", userAction);
        if(userAction != null)
        {
            // Add user action to history
            this.Graph.History.AddUserAction(this.ID, userAction.ID, userAction.Type, this.Graph.GetCurrentActName());

            // Make it pop up
            if(userAction.Strategic == true)
            {
                window.sdk.event().emit('showStrategicUserActions',[userAction]);
            }
        }
        else
        {
            log.debug(this.GetIdentity() + ".OnActivated: User action '" + this.UserActionID + "' not found in graph.");
        }

        this.SetActive(false);
    }
}


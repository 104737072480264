import log from "loglevel";
export default class ToWhomAPI {

    constructor(sdk) {
        this.sdk = sdk;
    }
    
    async Initialize() {
        
    }
    
    async Ask(iSpeech) {
        
        log.debug("ToWhomAPI Ask: iSpeech = " + iSpeech + ".");
        let result = await this.sdk.fetch().post('/towhomapi',  { 
		    body: {
                log: iSpeech
            }
		});

        return result;
    }
  }
  
import log from "loglevel";
import ExerciseNode from './ExerciseNode';
import NodePort from '../NodePort';

export default class ScenarioRoot extends ExerciseNode 
{
    // Ports
    OnStart = new NodePort("OnStart", "output", this);
    OnStop = new NodePort("OnStop", "output", this);

    constructor(iGraph, iProperties) 
    {   
        super(iGraph, iProperties);
        
        //log.debug(this.GetIdentity() + " constructor: graph = " + this.Graph.ExerciseName + ", id = " + this.ID + "."); 
    }

    OnActivated(iActivator, iInputPort)
    {
        super.OnActivated(iActivator, iInputPort);

        log.debug(this.GetIdentity() + " has been activated by '" + iActivator.GetIdentity() + "'.");

        this.SetActive(false);
        this.OnStart.ActivateAllConnections();
    }
}
import log from "loglevel";


function DoesGPTVersionRunOnChatAPI(iEngineVersion) {

    // Specific case
    if(iEngineVersion == "gpt-3.5-turbo-instruct")
        return false;

    // Check if the version starts with "gpt-" and get the version number if true
    const match = iEngineVersion.match(/gpt-(\d+)(\.\d+)?/);
    if (match) {
        const version = parseFloat(match[1] + (match[2] ? match[2] : ""));
        return version >= 3.5;
    }

    // Engine version doesn't match "gpt-" format
    return false;
}

export default class OpenAIAPI {

    constructor(sdk) {
        this.sdk = sdk;
    }
    
    async Initialize() {
        
    }

    async CallGPTAPI(iPrompt, iGPTEngine, iMaxTokens, iTemperature, iTopP, iFrequencyPenalty, iPresencePenalty, iStopSequence, iAbortSignal)
    {
        if(DoesGPTVersionRunOnChatAPI(iGPTEngine))
        {
            let messages = [
                {
                    role: "system", 
                    content: iPrompt
                },
            ];

            return await this.CallChatAPI(messages, iGPTEngine, iMaxTokens, iTemperature, iTopP, iFrequencyPenalty, iPresencePenalty, iStopSequence, iAbortSignal);
        }
        else
        {
            return await this.CallCompletionAPI(iPrompt, iGPTEngine, iMaxTokens, iTemperature, iTopP, iFrequencyPenalty, iPresencePenalty, iStopSequence, iAbortSignal);
        }
    }
    
    async CallCompletionAPI(iPrompt, iGPTEngine, iMaxTokens, iTemperature, iTopP, iFrequencyPenalty, iPresencePenalty, iStopSequence, iAbortSignal) {
        
        //log.debug("OpenAI CallCompletionAPI: iQuestion = " + iQuestion + ", iGPT3Engine = " + iGPT3Engine + ", iMaxTokens = " + iMaxTokens + ", iTemperature = " + iTemperature + ", iTopP = " + iTopP + ", iFrequencyPenalty = " + iFrequencyPenalty + ", iPresencePenalty = " + iPresencePenalty + ", iStopSequence = " + iStopSequence + ".");
        let result = await this.sdk.fetch().post('/openaicompletion',  { 
		    body: {
                engine: iGPTEngine,
                prompt: iPrompt,
                max_tokens: iMaxTokens,
                temperature: iTemperature,
                top_p: iTopP,
                frequency_penalty: iFrequencyPenalty,
                presence_penalty: iPresencePenalty,
                stop: iStopSequence
            },
            signal: iAbortSignal
		});

        return result;
    }
    
    async CallChatAPI(iMessages, iGPTEngine, iMaxTokens, iTemperature, iTopP, iFrequencyPenalty, iPresencePenalty, iStopSequence, iAbortSignal) {
        
        //log.debug("OpenAI CallChatAPI: iMessages = " + iMessages + ", iGPT3Engine = " + iGPT3Engine + ", iMaxTokens = " + iMaxTokens + ", iTemperature = " + iTemperature + ", iTopP = " + iTopP + ", iFrequencyPenalty = " + iFrequencyPenalty + ", iPresencePenalty = " + iPresencePenalty + ", iStopSequence = " + iStopSequence + ".");
        let result = await this.sdk.fetch().post('/openaichat',  { 
		    body: {
                engine: iGPTEngine,
                messages: iMessages,
                max_tokens: iMaxTokens,
                temperature: iTemperature,
                top_p: iTopP,
                frequency_penalty: iFrequencyPenalty,
                presence_penalty: iPresencePenalty,
                stop: iStopSequence
            },
            signal: iAbortSignal
		});

        return result;
    }
  }
  
import React from 'react';

import Achievement from '../Utilities/Achievement';
import Button from '../Utilities/Button';

export default class StepGoals_2 extends React.Component {

    render() {
        return (
            <div className={" feedback-step feedback-step-retry"}>
                <div className="feedback-step__inner">
                    <div className="feedback-step__header">
                        <h1 className="feedback-step__title">Passez à la suite du parcours et découvrez des clés afin de prendre la parole efficacement lors de cette réunion</h1>
                    </div>
                    <div className="feedback-step__content">
                        <div className="feedback-step-block feedback-ending">
                            {this.props.goals.map((action, key) => <Achievement key={action.ID} type="goals" achievement={action} name={"goals-" + key} />)}
                        </div>
                        {/*<div className={'retry__actions'}>
                        
                                <Button variant="light" onClick={this.props.stop}> Arrêter</Button>
                                <Button variant="primary" onClick={this.props.retry}>Recommencer</Button>

        </div>*/}
                    </div>
                </div>
            </div>)
    }

}
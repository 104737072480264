import log from "loglevel";
import React from 'react';
import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";

class CoachVoice extends React.Component {

    constructor(props) {
    super(props);
    this.state = {
      speaking: false
    };
    /*this.audioManager = window.sdk.videoconf().mediaDevices().getAudioManager();
    this.audioContext = this.audioManager.getContext();
    this.analyser = this.audioContext.createAnalyser();
    this.analyser.fftSize = 2048;
    this.bufferLength = this.analyser.frequencyBinCount;
    this.dataArray = new Uint8Array(this.bufferLength);*/
    }

    componentDidMount() {
      //this.startVumeter();
      
      const speechConfig = SpeechSDK.SpeechConfig.fromSubscription("7ac8d8f8db724005911cc721cb116623", "francecentral");
      speechConfig.speechSynthesisLanguage = "fr-FR"; // Choisir la langue française

      const audioConfig = SpeechSDK.AudioConfig.fromDefaultSpeakerOutput();
      this.synthesizer = new SpeechSDK.SpeechSynthesizer(speechConfig, audioConfig);

      // Ajout des log.debug pour chaque callback
      this.synthesizer.synthesisStarted = (sender, event) => {
          //log.debug("Synthèse vocale démarrée :", event);
          //this.setState({ speaking: true });
      };

      this.synthesizer.synthesizing = (sender, event) => {
          //log.debug("Synthèse vocale en cours :", event);
      };

      this.synthesizer.synthesisCompleted = (sender, event) => {
          log.debug("Synthèse vocale terminée :", event);
          //this.setState({ speaking: false });
      };

      this.synthesizer.SynthesisCanceled = (sender, event) => {
          //log.debug("Synthèse vocale annulée :", event);
          //this.setState({ speaking: false });
      };

      this.synthesizer.wordBoundary = (sender, event) => {
          //log.debug("Limite de mot atteinte :", event);
      };

      this.synthesizer.bookmarkReached = (sender, event) => {
          //log.debug("Marque-page atteint :", event);
      };

      this.synthesizer.visemeReceived = (sender, event) => {
          //log.debug("Visème reçu :", event);
      };
    }

    componentWillUnmount() {
    //this.audioManager.close();
    }

    speakText = async (text) => {
      
      log.debug("+++ speakText: ", text);
      // If state is speaking, stop the current speech
      /*if (this.state.speaking) {
          await this.synthesizer.stopSpeakingAsync();
      }*/

      this.setState({ speaking: true });
      const speakResult = await this.synthesizer.speakTextAsync(
      text,
      (result) => {
          if (result) {
              log.debug('Speech synthesis result:', result);
          }
          //this.synthesizer.close();
          
          this.setState({ speaking: false });
      },
      (error) => {
          log.error('Erreur lors de la synthèse vocale:', error);
          //this.synthesizer.close();
          
          this.setState({ speaking: false });
      }
    );

    log.debug("speakText result = ", speakResult);
  };
  /*speakText = async (text) => {
        const audio = document.createElement("audio");

        const resp = await fetch("https://francecentral.api.cognitive.microsoft.com/sts/v1.0/issuetoken", {
        "headers": {
            "content-type": "application/ssml+xml",
            "ocp-apim-subscription-key": "7ac8d8f8db724005911cc721cb116623",
            "x-microsoft-outputformat": "audio-24khz-160kbitrate-mono-mp3"
        },
        "body": "<speak version=\"1.0\" xmlns=\"http://www.w3.org/2001/10/synthesis\" xmlns:mstts=\"http://www.w3.org/2001/mstts\" xml:lang=\"fr-FR\"><voice name=\"AdaptVoice\">" + text + "</voice></speak>",
        "method": "POST"
        });
        const blob = await resp.blob();
        const url = await URL.createObjectURL(blob);
        audio.src = url;
        audio.play();
  }/*
/*
  speakText = async (text) => {
    const speechConfig = SpeechSDK.SpeechConfig.fromSubscription("7ac8d8f8db724005911cc721cb116623", "francecentral");
    speechConfig.speechSynthesisLanguage = "fr-FR";

    // Set the speech synthesis output format
    //speechConfig.SetSpeechSynthesisOutputFormat(SpeechSDK.SpeechSynthesisOutputFormat.Audio16Khz32KBitRateMonoMp3);

    const mediaStreamSource = this.audioContext.createMediaStreamDestination().stream;
    const audioConfig = SpeechSDK.AudioConfig.fromStreamInput(mediaStreamSource);
    const synthesizer = new SpeechSDK.SpeechSynthesizer(speechConfig, audioConfig);

    synthesizer.speakTextAsync(
      text,
      (result) => {
        if (result) {
          log.debug(`Speech synthesis result: ${result}`);
        }
        synthesizer.close();
      },
      (error) => {
        log.error(`Speech synthesis error: ${error}`);
        synthesizer.close();
      }
    );
  };
*/
/*
  drawVumeter = () => {
    requestAnimationFrame(this.drawVumeter);
    this.analyser.getByteFrequencyData(this.dataArray);
    const volume = this.dataArray.reduce((acc, cur) => acc + cur) / this.bufferLength;
    const percentage = volume / 255;
    const vumeterLevel = document.getElementById('vumeterLevel');
    
    if (vumeterLevel) {
      vumeterLevel.style.width = `${percentage * 100}%`;
    }
  }

  startVumeter = () => {
    const source = this.audioContext.createMediaStreamSource(this.audioManager.getStream());
    source.connect(this.analyser);
    this.drawVumeter();
  };*/

  render() {
    return (
      <div>
        {this.state.speaking === false && (
          <div
            style={{
              width: "50px",
              height: "50px",
              backgroundColor: "grey",
              position: "relative",
            }}
          ></div>
        )}
        {this.state.speaking && (
          <div
            style={{
              width: "50px",
              height: "50px",
              backgroundColor: "blue",
              position: "relative",
            }}
          ></div>
        )}
      </div>
    );
    /*
    return (
      <div className="vumeter" style={{
        width: '50px',
        height: '50px',
        backgroundColor: '#ccc',
        position: 'relative'
      }}>
        <div id="vumeterLevel" style={{
          width: '0%',
          height: '100%',
          backgroundColor: 'green',
          position: 'absolute'
        }}></div>
      </div>
    );*/
  }
}

export default CoachVoice;
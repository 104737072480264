import log from "loglevel";
import Abstract from './Abstract';

export default class SpeechRecognitionBrowser extends Abstract {

    async init() {
        log.debug('init browser transcripter with webkitSpeechRecognition API')
        
        //this.audioManager = null;
        
        this.recognition = new window.webkitSpeechRecognition();
        this.recognition.continuous = true;
        this.recognition.interimResults = true;
        this.recognition.lang = this.parameters.lang;
        this.final_transcript = '';

        this.recognition.onstart = () => {
            log.debug('recognition started');
        };

        this.recognition.onerror = (event) => {
            log.debug('GoogleBrowser onError', event)
        };

        this.recognition.onend = (event) => {
            log.debug('GoogleBrowser onend', event)
            if (this.status == 'started')
                this.recognition.start();
        };

        this.recognition.onresult = (event) => {
            var interim_transcript = '';
            this.final_transcript = '';
            let isFinal = false;
            for (var i = event.resultIndex; i < event.results.length; ++i) {
                if (event.results[i].isFinal) {
                    this.final_transcript += event.results[i][0].transcript;
                    isFinal = true;
                }
                else {
                    interim_transcript += event.results[i][0].transcript;
                }
            }

            if (this.final_transcript != '') {
                this.parameters.onTranscriptedTextFromClient({
                    text: this.final_transcript,
                    raw: event
                })
            }
            else {
                this.parameters.onPartialTranscriptedTextFromClient({
                    text: interim_transcript,
                    raw: event
                })
            }
        };
    }
    
    startAudioManager() {
    
    }
    
    stopAudioManager() {
    
    }

    _start() {
        this.recognition.start();
    }

    _stop() {
        this.recognition.stop();
    }
    
    

}

import log from "loglevel";
import MediaDevices from './MediaDevices/Manager';
import TranscriptionSession from './Transcription/Session';
import RecorderSession from './Recorder/Session';
import Utils from '../../AppClasses/Utils/Utils';

export default class Manager {
    
    constructor(sdk) {

        //log.debug("VideoConference Manager constructor: ", sdk);

        this._sdk = sdk;
        this._mediaDevice = new MediaDevices({
            onDeviceUpdate: (data) => {
                log.debug("VideoConf Manager constructor: new MediaDevices > onDeviceUpdate. data ", data);
                this._sdk.event().emit('mediaDeviceUpdated', data)                
            },
            onReady: () => {
                log.debug("VideoConf Manager constructor: new MediaDevices > onReady. AudioManager ", this._mediaDevice.getAudioManager());
                this._mediaDevice.getAudioManager().setBinaryDataProcessing('ScriptProcessor');
                
                this._mediaDevice.getAudioManager().on('volume', (data) => {
                   this._sdk.event().emit('volume', data)  
                })
                /*this._mediaDevice.getAudioManager().startListenVolume();
                this._mediaDevice.getAudioManager().on('binaryData', (data) => {
                    log.debug('binaryData', data);
                })
                
                this._mediaDevice.getAudioManager().startListenBinaryData(); 
                */
            }
        });        
    }
    
    mediaDevices() {
        return this._mediaDevice;
    }
    
    async startRecorderSession() {
        this._recorder = new RecorderSession(this._sdk.socket(), this._mediaDevice.getStream(), {
            encoding: { //see options here : https://developer.mozilla.org/en-US/docs/Web/API/MediaRecorder/MediaRecorder
                videoBitsPerSecond : 800000
            },
            onReady: () => {
                log.debug('on ready');
                this._recorder.start();        
            }
        });
    }
    

    toggleWebcamHide() {
        this._webcamHide ? 
            this._mediaDevice.resumeTrack('video')
        :
            this._mediaDevice.pauseTrack('video')
        
            this._webcamHide = !this._webcamHide
        this._sdk.event().emit('toggleWebcam', this._webcamHide)
    }

    isWebcamHide() {
        return this._webcamHide;
    }

    async stopRecorderSession() {
        this._recorder.stop();
    }

    getRecorderState() {
        return this._recorder.getState();
    }

    async checkIsFakeAudioSession() {
        return new Promise(async (resolve, reject) => {
            if (window.testMode.fakeUserAudioToPlay !== '') {
                //this.fakePlayer = document.querySelector('#fakeAudioSessionPlayer');
                log.debug('WE ARE IN FAKE SESSION');
                let { mediaStream, sourceNode } = await this._mediaDevice.getAudioManager().loadAudioFile(window.testMode.fakeUserAudioToPlay);
                this._mediaDevice.getAudioManager().setStream(mediaStream);
                this.fakePlayer = sourceNode;
                resolve(true);
            }
            else {
                resolve(false);
            }
        })
    }
    
    async createTranscriptionSession(opt) {
        log.debug((new Date).toUTCString() + " - Manager createTranscriptionSession: ", opt);
        const subtitleLang = Utils.GetSubtitleLanguage()
        if (subtitleLang == 'en'){
            opt.lang = 'en-US';
        }
        let isFakeSession = await this.checkIsFakeAudioSession();
        
        this._transcriptionSession = new TranscriptionSession(this._sdk.socket(), {
            provider: 'AzureApi', 
            audioManager: this._mediaDevice.getAudioManager(), 
            lang: opt.lang || 'fr-FR',
            customData: opt.customData || {},
            onTranscriptedText: (data) => {
                log.debug('onTranscriptedText', data.text);
                if(opt.onTranscriptedText)
                    opt.onTranscriptedText(data);
                if(isFakeSession)
                    this.fakePlayer.disconnect();
                volumeListenerStop();
                this._sdk.event().emit('transcriptedText', data)
            },
            onPartialTranscriptedText: (data) => {
                log.debug('onPartialTranscriptedText', data.text);
                if(opt.onPartialTranscriptedText)
                    opt.onPartialTranscriptedText(data);
                this._sdk.event().emit('partialTranscriptedText', data)
            },
            onFailed: (data) => {
                log.debug('onFailed', data, dataVolume);
                if(opt.onFailed)
                    opt.onFailed(data);
                volumeListenerStop();
                if(!opt.customData || !opt.customData.keepAlive )
                    this._sdk.event().emit('failedTranscription', data, dataVolume)
            }
        });
        
        await this._transcriptionSession.init();

        if(isFakeSession)
            this.fakePlayer.start();
        
        let dataVolume = [];
        let volumeListenerStop = this._sdk.event().on('volume', (volume) => {
            dataVolume.push(volume)
        })

        return this._transcriptionSession;
    }
    
    transcriptionSession() {
        return this._transcriptionSession;
    }
    
}
import React from 'react';

export default class StepCongrats_2 extends React.Component {

    render() {
        return (
            <div className="feedback-step feedback-step-congrats">
                <div className="feedback-step__inner feedback-step-inner-congrats">
                    <div className="feedback-step-congrats-star-left"></div>
                    <div className="feedback-step-congrats-star-right"></div>
                    <div className="feedback-step__header">
                        <h1 className="feedback-step__title">Félicitations !</h1>
                    </div>
                    <p>Vous avez terminé le scénario.</p>
                    <p>Passez à la page suivante pour découvrir vos badges de prise de parole !</p>
                </div>
            </div>)
    }

}
import React from 'react';

import '../../styles/components/Exercise.scss';

import { useParams } from "react-router-dom";

import Exercise from '../../components/VisioExercise/Exercise';


export function withRouter(Children) {
    return (props) => {
        const match = { params: useParams() };
        return <Children {...props}  match={match} />
    }
}

class ExercisePanel extends React.Component {
	
	componentDidMount() {
		setTimeout(() => {
			window.sdk.event().emit('ExercisePanelEntered');
		}, 1000);
	}

	
    render() {
        return (
            <React.Fragment>
                <input type="hidden" id="fakeAudioSession" value="" />
                <audio id="fakeAudioSessionPlayer" crossOrigin="anonymous" src="" playsInline style={{ display: 'none' }}></audio>
                <Exercise StepLabel = {this.props.match.params.step} ExerciseID = {this.props.match.params.id}/>
            </React.Fragment>
        );
    }
}

export default withRouter(ExercisePanel);

import log from "loglevel";
export default class Exercise {
    
    constructor(sdk) {
        this.sdk = sdk;
    }
    
    async init() {
        
    }
    
    async getAll() {
        let res = await this.sdk.fetch().get('/exercises');
        return res;
    }
    
    async downloadExerciseGraph(iExerciseID) {
        let res = await this.sdk.fetch().get('/exercise/' + iExerciseID);
        return res;
    }
	
	async preloadExerciseAssets(exerciseGraph, cacheProgression) {		
		
		// Get the list of files to preload in priority
		let prioritaryList = exerciseGraph.GetPiroritaryBotsVideosToCache();
		//log.debug('preloadExerciseAssets: prioritaryList', prioritaryList);

		// Get the list of the other files to preload
		let restList = exerciseGraph.GetBotsVideosToCache(false);
		//log.debug('preloadExerciseAssets: restList', restList);
		
		// Start the bots videos cache pre-loading
		await window.sdk.cacheManager().preload(prioritaryList, restList, (data) => {
			//log.debug('progression', data);
			cacheProgression(data);
		});
	}	
	
	async makeItFinished(iExerciseID) {
		let jsonGraph = await this.downloadAndPrepareExercise(iExerciseID);
		const reversedNodes = jsonGraph.Nodes.reverse();
		for(var i in reversedNodes) {
			let node = reversedNodes[i];
			if(node.Type == 'BotConnection' || node.Type == 'BotVideo') {
				let botUrl = window.sdk.CreateBotVideoURL(node.BotName, node.Type == 'BotConnection' ? node.DefaultLoop : node.Video);
				await window.sdk.cacheManager().remove(botUrl);
			}				
		}
		
	}
}
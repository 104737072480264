export default class EventEmitter {
  constructor() {
    this.events = {};
  }
  
  on(event, listener) {
      if (typeof this.events[event] !== 'object') {
          this.events[event] = [];
      }
      this.events[event].push(listener);
      return () => this.removeListener(event, listener);
  }
  
  removeListener(event, listener) {
    if (typeof this.events[event] === 'object') {
        let timeout = 0;
        if(this.events[event].length > 1)
          timeout = 100;
          setTimeout(() => {
          const idx = this.events[event].indexOf(listener);
          if (idx > -1) {
            this.events[event].splice(idx, 1);
          }
        }, timeout)
    }
  }
  
  emit(event, ...args) {
    if (typeof this.events[event] === 'object') {
      this.events[event].forEach(listener => listener.apply(this, args));
    }
  }
  
  once(event, listener) {
    const remove = this.on(event, (...args) => {
        remove();
        listener.apply(this, args);
    });
  }
  
};
import React from 'react';
import '../../styles/components/Range.scss';
import Form from 'react-bootstrap/Form';

const colors = [
  'transparent',
  '#F96E20',
  '#FF9900',
  '#FFB200',
  '#FFD02B',
  '#C2E75B',
  '#94D95E'
]

export default class CustomRange extends React.Component {

  constructor(props) {
    super(props);
    this.range = React.createRef();
    this.labelLeft = React.createRef();
    this.labelRight = React.createRef();
    this.value = props.default || 1;
    this.min = props.min || 1;
    this.max = props.max || 7;
    this.sizeLabel = 15;
    this.opacity = 0.7;
  }


  componentDidMount() {
    //this.range.current.style.backgroundSize = (this.value - this.min) * 100 / 6 + '% 100%';
    //this.range.current.style.backgroundImage = 'linear-gradient(270deg, '+ colors[this.value-1] +' 2.05%, '+ colors[this.value-1] +' 100%)';
    let widthLeft = this.labelLeft.current.offsetWidth;
    let widthRight= this.labelRight.current.offsetWidth;
    let width = Math.max(widthLeft,widthRight) 
    this.labelRight.current.style.width = width * (this.sizeLabel + (this.max -  this.min) / 2) / this.sizeLabel + 'px';
    this.labelLeft.current.style.width = width * (this.sizeLabel + (this.max -  this.min) / 2) / this.sizeLabel + 'px';
    this.updateRange(this.value)
  }


  updateRange(val){    
    let delta = ((this.max -  this.min) / 2) - val + 1;
    this.range.current.style.backgroundSize = (val - this.min) * 100 / (this.max - this.min) + '% 100%'
    this.range.current.style.backgroundImage = 'linear-gradient(270deg, '+ colors[val-1] +' 2.05%, '+ colors[val-1] +' 100%)';
    this.labelLeft.current.style.fontSize = this.sizeLabel + delta + 'px';
    this.labelRight.current.style.fontSize = this.sizeLabel - delta + 'px';
    this.labelLeft.current.style.opacity = this.opacity + delta * 0.1;
    this.labelRight.current.style.opacity = this.opacity - delta * 0.1;
  }

  change = (e) => {
    this.value = e.target.value;
    this.updateRange(this.value)
    this.props.onChange(e.target.value)
  }

  render() {
    return (
      <div className={"range-feedback range-container-" + this.props.identifier} >
        <span className="range-feedback__label range-feedback__label--left" ref={this.labelLeft}>{this.props.labelLeft}</span>
        <Form.Range  className="range-feedback__input" value={this.value} min="1" max="7" id={"range-"+this.props.identifier} onChange={this.change} ref={this.range}/>
        <span className="range-feedback__label range-feedback__label--right" ref={this.labelRight}>{this.props.labelRight}</span>
      </div>
    );
  }
}

/* global MediaStream */
import log from "loglevel";
import React from 'react';

export default class WebcamView extends React.PureComponent {

    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
        this.listeners = [];
    }

    async componentDidMount() {
        this.videoObj = this.videoRef.current;
        
        this.stream = window.sdk.videoconf().mediaDevices().getStream();
        log.debug('this.stream', this.stream);
        this.updateVideo();
        
        this.listeners.push(window.sdk.event().on('mediaDeviceUpdated', (data) => {
            if (data.kind == 'videoinput' || data.kind == 'video') {
                this.removeOldTrack();
                this.stream = data.stream;
                this.updateVideo();
            }
        }));

        this.listeners.push(window.sdk.event().on('toggleWebcam', (data) => {
            this.cameraOff = data;
            this.forceUpdate();
        }));   
    }

    updateVideo() {
        if (this.stream && this.stream.getVideoTracks().length > 0) {
            this.videoObj.srcObject = new MediaStream([this.stream.getVideoTracks()[0]]);
        }
    }

    removeOldTrack() {
        if (!this.videoRef.current.srcObject)
            return;

        const tracks = this.videoRef.current.srcObject.getVideoTracks();
        for (let j in tracks) {
            tracks[j].stop();
        }
    }

    componentWillUnmount() {
        this.removeOldTrack();

        for (var i in this.listeners) {
            this.listeners[i]();
        }
    }

    render() {
        return ( 
            <div className={'video_layer video_layer--1 webcam_layer' + (this.cameraOff ? ' webcam_off': '')}>
                <video playsInline autoPlay={true} ref={this.videoRef} muted></video> 
            </div>
        );
    }

}

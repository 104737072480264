import React from 'react';
import '../../styles/components/Achievement.scss';

export default class Goal extends React.Component {

  state = {
    selected: false
  }

  constructor(props) {
    super(props);
  }


  select = () => {
    this.setState({
      selected: !this.state.selected
    })
  }



  render() {
    return (
      <div className="feedback-goals-item">
        <div className={"button-goal" + (this.state.selected ? ' button-goal-selected' : '') + ' goal-' + this.props.goal.ID} onClick={this.select}>{this.props.goal.DisplayedName}</div>
      </div>
    );
  }
}
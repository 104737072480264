import Utils from "../../AppClasses/Utils/Utils";

export default class ExercisesAPIEndpoints {
    
    constructor(sdk) {
        this.sdk = sdk;
    }
    
    async init() {
    }
    
    async getOne(exerciseID) {
        let res = await this.sdk.fetch().get('/exercisesapiendpoints/' + exerciseID);
        return res;
    }
}
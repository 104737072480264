import log from "loglevel";
import LocalStorage from './Utils/LocalStorage';
import Fetch from './Utils/Fetch';
import CacheManager from './Cache/Manager';
import EventEmitter from './Utils/EventEmitter';
import User from './Models/User';
import UsersActivity from './Models/UsersActivity';
import Exercise from './Models/Exercise';
import ExerciseSession from './Models/ExerciseSession';
import BranchingDecision from './Models/BranchingDecision';
import AnalysisTask from './Models/AnalysisTask';
import BotVideo from './Models/BotVideo';
import ExercisesAPIEndpoints from './Models/ExercisesAPIEndpoints';
import OpenAIAPI from './MachineLearningAPIs/OpenAI/OpenAIAPI';
import ToWhomAPI from './MachineLearningAPIs/PracticIO/ToWhomAPI';
import BadWordsAPI from './MachineLearningAPIs/PracticIO/BadWordsAPI';
import SemanticSearchAPI from './MachineLearningAPIs/PracticIO/SemanticSearchAPI';
import BranchingDecisionAPI from './MachineLearningAPIs/PracticIO/BranchingDecisionAPI';
import UserActionsDetectionAPI from './MachineLearningAPIs/PracticIO/UserActionsDetectionAPI';
import Videoconf from './Videoconf/Manager';
import { io } from "socket.io-client";
import Sound from './Utils/Sound';
import  ForbiddenInteractionWarning from  './Utils/ForbiddenInteractionWarning';


export default class Sdk {
    // Parameters
	envUrl = this._env === 'prod' ? '' : '.dev';
    constructor(params) {
        this._env = params.env;
        this._apiUrl = params.apiUrl;
        this._user = new User(this);
		this._usersActivity = new UsersActivity(this);
        this._exercise = new Exercise(this);
		this._exerciseSession = new ExerciseSession(this);
		this._branchingDecision = new BranchingDecision(this);
		this._analysisTask = new AnalysisTask(this);
		this._botVideo = new BotVideo(this);
		this._exercisesAPIEndpoints = new ExercisesAPIEndpoints(this);
        this._openaiAPI = new OpenAIAPI(this);
        this._toWhomAPI = new ToWhomAPI(this);
        this._badWordsAPI = new BadWordsAPI(this);
		this._semanticSearchAPI = new SemanticSearchAPI(this);
		this._branchingDecisionAPI = new BranchingDecisionAPI(this);
		this._userActionsDetectionAPI = new UserActionsDetectionAPI(this);
        this._fetch = new Fetch(this);
		this._sound = new Sound(this);
		this._forbiddenInteractionWarning = new ForbiddenInteractionWarning(this)
        this._event = new EventEmitter();
        this._socket = io(this._apiUrl);
        this._socket.on('connect', () => {
            log.debug('Backend socket connected');
        })
        this._videoconf = new Videoconf(this);
		
		this._cacheManager = new CacheManager();
		
		this.fullscreenChange = this.fullscreenChange.bind(this);
		
		document.addEventListener('webkitfullscreenchange', this.fullscreenChange);
		document.addEventListener('mozfullscreenchange', this.fullscreenChange);
		document.addEventListener('fullscreenchange', this.fullscreenChange);
    }
    
    async init() {
        await this._user.init();
		await this._cacheManager.init();			
    }
    
    getApiUrl() {
        return this._apiUrl;
    }
    
    fetch() {
        return this._fetch;
    }
    sound() {
        return this._sound;
    }
    forbiddenInteractionWarning() {
        return this._forbiddenInteractionWarning;
    }
    env() {
        return this._env;
    }
    
	isDev() {
		return this._env == 'dev';
	}
    user() {
        return this._user;
    }

	usersActivity() {
		return this._usersActivity;
	}
    
    exercise() {
        return this._exercise;
    }

	exerciseSession() {
		return this._exerciseSession;
	}

	BranchingDecision() {
		return this._branchingDecision;
	}

	AnalysisTask() {
		return this._analysisTask;
	}

	BotVideo() {
		return this._botVideo;
	}

	ExercisesAPIEndpoints() {
		return this._exercisesAPIEndpoints;
	}

    openaiAPI() {
        return this._openaiAPI;
    }

    toWhomAPI() {
        return this._toWhomAPI;
    }

    badWordsAPI() {
        return this._badWordsAPI;
    }

    semanticSearchAPI() {
        return this._semanticSearchAPI;
    }

	branchingDecisionAPI() {
		return this._branchingDecisionAPI;
	}

	userActionsDetectionAPI() {
		return this._userActionsDetectionAPI;
	}
    
    event() {
        return this._event;
    }
    
    videoconf() {
        return this._videoconf;
    }
    
    socket() {
        return this._socket;
    }
	
	cacheVideos() {
		return this._cacheVideos;
	}
	
	cacheManager() {
		return this._cacheManager;
	}
    
	CreateBotVideoURL(iBotName, iVideoName) {
		return "https://bots" + this.envUrl + ".practivizio.ai/Bots" + "/" + iBotName + "/Videos/" + iVideoName + ".mp4";
	}
	
	CreateBotSubtitleURL(iVideoName, language) {
		return "https://bots" + this.envUrl + ".practivizio.ai/Subtitles/" + iVideoName + "." + language +".vtt";
	}

	isInIframe() {
		try {
			return window.self !== window.top;
		} catch (e) {
			return true;
		}
	}
	
	isFullscreen() {
  
		if (document.fullscreenElement
			|| document.webkitIsFullScreen
			|| document.webkitFullscreenElement
			|| document.mozFullScreenElement) {
			return true;
		}
	}
	
	fullscreenChange(e) {		
		log.debug('Fullscreen:' + this.isFullscreen());
		if ( this.isFullscreen() ) {
			this.fullscreenStatus = true;			
			document.body.classList.add('fullscreen');
			this.event().emit('fullscreenOpended');
		}
		else {
			this.fullscreenStatus = false;
			document.body.classList.remove('fullscreen');
			this.event().emit('fullscreenExited');
		}		
	}
	
	openFullscreen() {
		if(!this.fullscreenStatus) {
			let el = document.body;
			this.fullscreenStatus = true;
			if (el.requestFullscreen) {
				el.requestFullscreen();
			}
			else if (el.mozRequestFullScreen) {
				el.mozRequestFullScreen();
			}
			else if (el.webkitRequestFullScreen) {
				el.webkitRequestFullScreen();
			}
			else if (el.msRequestFullscreen) {
				el.msRequestFullscreen();
			}
		}
	}
	
	closeFullscreen() {
		//if(this.fullscreenStatus) {
			this.fullscreenStatus = false;
			if (document.fullscreenElement) {
				document.exitFullscreen();
			}
			else if (document.webkitExitFullscreen) {
				document.webkitExitFullscreen();
			}
			else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			}
			else if (document.msExitFullscreen) {
				document.msExitFullscreen();
			}
		//}
	}
	
	toggleFullscreen() {
		if(!this.fullscreenStatus) {
			this.openFullscreen();
		}
		else {
			this.closeFullscreen();
		}
	}
	
	getParam(k) {
        let url = new window.URL(window.location.href);
        return url.searchParams.get(k);
    }
	
	exitExercice(exerciseGraph) {
		//document.location.href= '/retry/' +  exerciseGraph.ExerciseID + '/' + exerciseGraph.CurrentExerciseSessionID;
	}
	
	setRedirectUrl(url){
		this.redirect_url = url;
	}
	
}
import log from "loglevel";
import ExerciseNode from './ExerciseNode';
import NodePort from '../NodePort';

export default class StopExercise extends ExerciseNode 
{
    // Ports
    Stop = new NodePort("Stop", "input", this);

    // Parameters

    // Internal values


    constructor(iGraph, iProperties) 
    {
        super(iGraph, iProperties);

        //log.debug(this.GetIdentity() + " constructor: graph = " + this.Graph.ExerciseName + ", id = " + this.ID + ".");
    }

    OnActivated(iActivator, iInputPort)
    {
        log.debug(this.GetIdentity() + " has been activated by '" + iActivator.GetIdentity() + "'.");
        super.OnActivated(iActivator, iInputPort);

        // Stop the current exercise
        this.Graph.Stop();
        
        this.SetActive(false);
    }

    PrintParameters()
    {
        //log.debug("BotName = " + this.BotName + ", DefaultLoop = " + this.DefaultLoop + ", PreferredPosition = " + this.PreferredPosition);
    }
}
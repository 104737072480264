import log from "loglevel";
export default class Abstract {

  constructor(parameters) {
    this.parameters = parameters;
    this.status = 'stopped';
  }
  
  getStatus() {
    return this.status;
  }
  
  async init() {
    
    log.debug("Abstract init this.parameters.audioManager: ", this.parameters);
    this.parameters.audioManager.on('binaryData', (data) => {
        if(this.status == 'started') {
          //log.debug(data);
          this.parameters.onBinaryData(data);
        }
    })
  }

  start() {
    log.debug('start');
    this.status = 'started';
    this.startAudioManager();
    this._start();
  }
  
  startAudioManager() {
    if(this.parameters.audioManager) {
      this.parameters.audioManager.startListenBinaryData();
    }
  }
  
  _start() {
    
  }

  stop() {
    this.status = 'stopped';
    this.stopAudioManager();
    this._stop();
  }
  
  stopAudioManager() {
    if(this.parameters.audioManager) {
      this.parameters.audioManager.stopListenBinaryData();
    }
  }
  
  _stop() {
    
  }
  
  close() {
    this.status = 'closed';
    this.stop();
    this._close()
  }
  
  _close() {
    
  }


}
